import React, { useEffect, useState } from "react";
import "./Sites.scss";
import "../styles/table.scss";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import Menu from "./Menu";
import TableStyles from "../styles/tableStyles";
import Config from "../Config";
import SearchBar from "./SearchBar";
import Site from "../models/Site";
import Roles from "../utils/Roles";
import { Scopes } from "../models/Scopes";
import moment from "moment";
import PopupStyle from "../styles/popupContentStyle";
import PopPop from "react-poppop";
import ConfirmPopup from "./ConfirmPopup";
import ToastComponent from "./ToastComponent";
import Toast from "../models/Toast";
import { ToastTypes } from "../models/ToastTypes";
import { t } from "i18next";

const Sites = React.memo(() => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResource, setTotalResource] = useState(0);
  const [toasts, setToast] = useState<Toast[]>([]);

  const [sortColumn, setSortColumn] = useState("externalReference");
  const [sortDirection, setSortDirection] = useState("asc");

  const [currentSite, setCurrentSite] = useState<Site | undefined>();
  const [isOpenUpdateSiteWithdrawal, setIsOpenUpdateSiteWithdrawal] = useState(false);

  const columns: any[] = [
    {
      name: "",
      sortable: false,
      right: true,
      cell: (row: any) => (
        <Link className="table-link" to={seeSiteDns(row)}>
          {t("seeDns")}
        </Link>
      ),
      button: true,
    },
    {
      name: "Reference",
      selector: (row: Site) => row.externalReference,
      cell: (row: Site) => <div className="firstColumn">{row.externalReference}</div>,
      sortable: true,
      right: false,
      id: "externalReference",
    },
    {
      name: t("name"),
      selector: (row: Site) => row.name,
      sortable: true,
      right: false,
      id: "name",
    },
  ];

  if (Roles.isAffiliate() || Roles.isRegion()) {
    columns.push({
      name: t("lastUsedTime"),
      selector: (row: Site) => row.lastUsedAt,

      cell: (row: Site) => (
        <div className="">{row.lastUsedAt != null ? moment(row.lastUsedAt).format("DD/MM/YY HH:mm") : ""}</div>
      ),
      sortable: true,
      right: false,
      id: "lastUsedAt",
    });

    columns.push({
      name: t("deliverynote.filter.departure"),
      selector: (row: Site) => row.withdrawalPoint,
      cell: (row: Site) => <div className="">{row.withdrawalPoint ? t("yes") : t("no")}</div>,
      sortable: true,
      right: false,
      id: "withdrawalPoint",
    });
  }
  if (Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_VIEW) && Roles.hasBMD()) {
    columns.unshift({
      name: "",
      sortable: true,
      cell: (row: Site) => (
        <Link className="table-link" to={seeSiteBMD(row)}>
          {t("seeBMD")}
        </Link>
      ),
      button: true,
    });
  }
  if (Roles.hasRight(Scopes.SCOPE_EXTRANET_SITE_MANAGE)) {
    columns.push({
      name: "",
      // selector: "vehicle.",
      sortable: false,
      cell: (row: Site) => (
        <div
          className="validateBtn"
          style={{ padding: 12, marginTop: 0 }}
          onClick={() => {
            setCurrentSite(row);
            setIsOpenUpdateSiteWithdrawal(true);
          }}
        >
          <h6>{row.withdrawalPoint ? t("sites.deactiveDeparture") : t("sites.activeDeparture")}</h6>
        </div>
      ),
    });
  }

  const [filteredItems, setFilterereditems] = useState<Site[]>([]);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    fetchSites(1, perPage);
  }, []);

  const seeSiteDns = (site: Site) => {
    let url = "deliverynotes?site=" + site.name;
    // history.push(url);
    return url;
  };
  const seeSiteBMD = (site: Site) => {
    let url = "pumpingNotes?site=" + site.name;
    return url;
  };

  const fetchSites = (
    page: number,
    newPerPage: number,
    newFilterText: string = filterText,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection
  ) => {
    const token = localStorage.getItem("token");
    let url =
      "sites?page=" +
      page +
      "&limit=" +
      newPerPage +
      "&search=" +
      newFilterText +
      "&sort_by=" +
      newSortColumn +
      "&order_by=" +
      newSortDirection;
    setCurrentPage(page);

    if (newFilterText != filterText) {
      setFilterText(newFilterText);
    }

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    axios
      .get(Config.getUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.totalResource);
        setFilterereditems(res.data.content.items);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const handlePageChange = (pageNumber: number) => {
    fetchSites(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchSites(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == "asc") {
        newSortOrder = "desc";
      } else {
        newSortOrder = "asc";
      }
    } else {
      newSortOrder = "asc";
    }
    fetchSites(1, perPage, filterText, data.id, newSortOrder);
  };
  const onUpdateSiteWithdrawal = () => {
    setIsOpenUpdateSiteWithdrawal(false);
    let url = `sites/${currentSite?.uuid}/update-withdrawal-point`;
    const token = localStorage.getItem("token");
    axios
      .get(Config.getUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async () => {
        fetchSites(currentPage, perPage);
        addToast(
          currentSite?.withdrawalPoint
            ? t("sites.deactiveWithdrawalPointSuccess")
            : t("sites.activeWithdrawalPointSuccess"),
          ToastTypes.success
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };

  return (
    <div className="sites">
      <Menu selectedPage="sites"></Menu>
      <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

      <div className={"right table-container"}>
        <div className="title">{t("menu.sites")}</div>

        <div className="filterBar">
          <SearchBar
            placeholder={t("sites.search")}
            minLength={3}
            onFilter={(value) => fetchSites(1, perPage, value)}
          />
        </div>
        <div className="nbElements">
          {filterText != "" ? <span>{filteredItems.length}/</span> : <span>{totalRows}/</span>}
          {totalResource} {totalResource > 1 ? t("results") : t("result")}
        </div>

        {isLoading ? (
          <div>{t("loading")}</div>
        ) : (
          <DataTable
            className="table"
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            data={filteredItems}
            noDataComponent={<div className="noResults">{t("sites.noOne")}</div>}
            defaultSortAsc={sortDirection == "asc"}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            paginationComponentOptions={{
              rowsPerPageText: t("table.perpage"),
              rangeSeparatorText: t("table.on"),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
          />
        )}
      </div>

      <PopPop
        open={isOpenUpdateSiteWithdrawal}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenUpdateSiteWithdrawal(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <ConfirmPopup
          message={
            currentSite?.withdrawalPoint
              ? t("sites.deactiveWithdrawalPointQuestion", {
                  site: `${currentSite?.externalReference} - ${currentSite?.name}`,
                })
              : t("sites.activeWithdrawalPointQuestion", {
                  site: `${currentSite?.externalReference} - ${currentSite?.name}`,
                })
            // t("sites.activeWithdrawalPointQuestion")`Etes-vous sûr ${
            //     currentSite?.withdrawalPoint ? "de désactiver" : "d'activer"
            //   } les Bls départ sur le site ${currentSite?.externalReference} - ${currentSite?.name}  ?`
          }
          confirm={() => onUpdateSiteWithdrawal()}
          cancel={() => setIsOpenUpdateSiteWithdrawal(false)}
          cancelMessage={t("no")}
          close={() => setIsOpenUpdateSiteWithdrawal(false)}
          confirmMessage={t("yes")}
        />
      </PopPop>
    </div>
  );
});

export default Sites;
