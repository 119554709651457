export default {
  menu: {
    deliverynotes: "Bons de livraison",
    pumpingnotes: "Bons de mise à disposition",
    depaturenotes: "Bons de livraison Départ",
    carriers: "Transporteurs",
    affiliates: "Affiliés",
    regions: "Régions",
    customers: "Clients",
    deliveries: "Chantiers",
    drivers: "Chauffeurs",
    sites: "Sites",
    users: "Utilisateurs",
    account: "Mon compte",
    logout: "Me déconnecter",
    stats: "Statistiques",
    vehicles: "Camions",
    map: "Suivi camion",
    "account.create": "Créer mon compte",
  },
};
