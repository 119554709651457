import Affiliate from "./Affiliate";
import Carrier from "./Carrier";

export default class Vehicle {
  uuid: string | undefined;
  affiliate: Affiliate | undefined;
  carrier: Carrier | undefined;
  externalReference: string | undefined;
  registrationPlate: string | undefined;
  status: string | undefined;
  type?: VehicleTypes;
}

export enum VehicleTypes {
  DUMP_TRUCK = "dump_truck",
  CONCRETE_MIXER = "concrete_mixer",
  PUMI = "pumi",
  PUMP_TRUCK = "pump_truck",
}
