import { AxiosResponse } from "axios";
import ApiResponse from "../models/ApiResponse";

export type BaseApiResponse = AxiosResponse<ApiResponse>;
export type BaseApiResponsePromise = Promise<BaseApiResponse>;
const BaseApi = {
  getToken: () => {
    let token = localStorage.getItem("token");
    return token;
  },
  getHeaders: (params?: any): { headers: any; params: any } => {
    const token = BaseApi.getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    };
  },
  getMultipartHeaders: (): { headers: any } => {
    const token = BaseApi.getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
      },
    };
  },
};
export default BaseApi;
