export enum Colors {
  primary = "#2052F5",
  graphPrimary = "#0984e3",
  graphPrimaryBis = "#74b9ff",
  graphSecondary = "#00b894",
  graphSecondaryBis = "#55efc4",
  graphThird = "#e17055",
  graphThirdBis = "#fab1a0",

  grey900 = "#010F4B",
  grey500 = "#90a0b7",
  grey50010 = "#90a0b71a",
  grey400 = "#e3e7f2",
  grey300 = "#f5f6f9",
  grey100 = "#fdfdff",
}

export const geolocationColors = [
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
  "#9E9E9E",
  "#607D8B",
  "#333333",
  "#FFFFFF",
  "#000000",
  "#F48FB1",
  "#CE93D8",
  "#9FA8DA",
  "#81D4FA",
  "#80DEEA",
  "#80CBC4",
  "#A5D6A7",
  "#E6EE9C",
];
