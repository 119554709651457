import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import DeliveryNote from "./components/DeliveryNote";
import DeliveryNotes, { DeliveryNoteTypes } from "./components/DeliveryNotes";
import Login from "./components/Login";
import * as Sentry from "@sentry/react";
import Users from "./components/Users";
import Vehicles from "./components/Vehicles";
import Carriers from "./components/Carriers";
import Drivers from "./components/Drivers";
import LoginForOneDeliveryNote from "./components/LoginForOneDeliveryNote";
import AskNewAutologin from "./components/AskNewAutologin";
import Account from "./components/Account";
import AutoLogin from "./components/AutoLogin";
import ResetPassword from "./components/ResetPassword";
import Store from "./components/Store";
import Sites from "./components/Sites";
import AutoRefreshDnDeparture from "./components/AutoRefreshDnDeparture";
import Regions from "./components/Regions";
import "./App.scss";
import AppCode from "./components/AppCode";
import StatsContainer from "./components/StatsContainer";
import Customers from "./components/Customers";
import UpdateLanguage from "./components/UpdateLanguage";
import { useTranslation } from "react-i18next";
import Privacy from "./components/Privacy";
import Map from "./components/Map";
//pour la release

function App() {
  const { t } = useTranslation();
  return (
    <Sentry.ErrorBoundary fallback={<p>{t("common.error")}</p>}>
      <Router>
        <div>
          <AutoRefreshDnDeparture />
          <UpdateLanguage />

          <Switch>
            <Route path="/.well-known/apple-app-site-association">
              <Redirect to={process.env.PUBLIC_URL + "../static/media/apple-app-site-association"} />
            </Route>

            <Route path="/.well-known/assetlinks.json">
              <Redirect to={process.env.PUBLIC_URL + "../static/media/assetlinks.json"} />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>

            <Route path="/map">
              <Map />
            </Route>

            <Route path="/login">
              <Login />
            </Route>

            <Route path="/code/:code">
              <AppCode />
            </Route>
            <Route path="/auth">
              <AutoLogin />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/stats">
              <StatsContainer />
            </Route>

            <Route path="/store">
              <Store />
            </Route>
            <Route path="/vehicles">
              <Vehicles />
            </Route>
            <Route path="/sites">
              <Sites />
            </Route>
            <Route path="/regions">
              <Regions />
            </Route>
            <Route path="/carriers">
              <Carriers />
            </Route>

            <Route path="/account">
              <Account />
            </Route>

            <Route path="/delivery-notes/:id/preview">
              <LoginForOneDeliveryNote isPumping={false} />
            </Route>

            <Route path="/pumping-notes/:id/preview">
              <LoginForOneDeliveryNote isPumping={true} />
            </Route>

            <Route path="/resetpassword">
              <ResetPassword />
            </Route>

            <Route path="/asknewAutologin">
              <AskNewAutologin />
            </Route>
            <Route path="/drivers">
              <Drivers />
            </Route>

            <Route path="/billingcustomers">
              <Customers isDelivery={false} />
            </Route>

            <Route path="/deliverycustomers">
              <Customers isDelivery={true} />
            </Route>
            <Route path="/deliveryNotes/:id/:mode/:autosign?">
              <DeliveryNote />
            </Route>

            <Route path="/pumpingNotes/:id/:mode/:autosign?">
              <DeliveryNote isPumping={true} />
            </Route>

            <Route path="/deliveryNotes">
              <DeliveryNotes type={DeliveryNoteTypes.normal} />
            </Route>

            <Route path="/pumpingNotes">
              <DeliveryNotes type={DeliveryNoteTypes.pumping} />
            </Route>

            <Route path="/deliveryNotes-departure">
              <DeliveryNotes type={DeliveryNoteTypes.departure} />
            </Route>
            {/* <Route path="/document">
              <Document />
            </Route> */}
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </div>
      </Router>
    </Sentry.ErrorBoundary>
  );
}

export default App;
