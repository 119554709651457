/* eslint-disable */
import { useEffect, useState } from "react";
import "./SelectWorkspace.scss";
import { useHistory } from "react-router-dom";
import Deliverynote from "../models/Deliverynote";
import { useTranslation } from "react-i18next";

interface SignProps {
  dl: Deliverynote | undefined;
  onPadConnected(isConnected: boolean): void;
  onSignatureOk(signature: string): void;
}
function SignaturePadComponent(props: SignProps) {
  const [signature, setSignature] = useState("");
  const [isConnected, setisConnected] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const globalAny: any = global;
  STPadServerLibDefault = globalAny.STPadServerLibDefault;
  STPadServerLibDefault = globalAny.STPadServerLibDefault;
  useEffect(() => {
    onMainWindowLoad();
    // globalAny.STPadServerLibCommons.handleLogging = "";
    // console.log(globalAny.STPadServerLibCommons);
    // STPadServerLibCommons.createConnection(wsUri, onOpen, onClose, onError);

    // clearSignature();
    // check_boxes_selectedElements_onchange();
    // ModeListName_onchange();
  }, [props.dl]);

  function onMainWindowBeforeUnload() {
    globalAny.STPadServerLibCommons.destroyConnection();
  }

  const startSignature = () => {
    getSignature();
  };

  function onOpen(evt: any) {
    // state.className = "success";
    if (evt.target === undefined || evt.target.url === undefined) {
      // state.innerHTML = "ActiveX loaded";
    } else {
      setisConnected(true);
      props.onPadConnected(true);
      getSignature();
      console.log("Connected to " + evt.target.url);
      // state.innerHTML = "Connected to " + evt.target.url;
    }
  }

  function onClose(evt: any) {
    // state.className = "fail";
    if (evt.target === undefined || evt.target.url === undefined) {
      // state.innerHTML = "ActiveX unloaded";
    } else {
      setisConnected(false);
      console.log("Disconnected from " + evt.target.url);
    }
  }

  function onError(evt: any) {
    // state.className = "fail";
    if (evt.target === undefined || evt.target.url === undefined) {
      // state.innerHTML = "Communication error";
      console.log("Communication error");
    } else {
      console.log("Communication error " + evt.target.url);
    }
  }

  function logMessage(msg: any) {
    console.log(msg);
    // log.innerHTML = "<li>" + msg + "</li>";
    console.log(msg);
  }

  var padStates = {
    closed: 0,
    opened: 1,
  };
  var padState = padStates.closed;

  var padModes = {
    Default: 0,
    API: 1,
  };
  var padMode = padModes.Default;

  var padTypes = {
    sigmaUSB: 1,
    sigmaSerial: 2,
    zetaUSB: 5,
    zetaSerial: 6,
    omegaUSB: 11,
    omegaSerial: 12,
    gammaUSB: 15,
    gammaSerial: 16,
    deltaUSB: 21,
    deltaSerial: 22,
    deltaIP: 23,
    alphaUSB: 31,
    alphaSerial: 32,
    alphaIP: 33,
  };
  var padType = 0;

  var deviceCapabilities = {
    HasColorDisplay: 0x00000001,
    HasBacklight: 0x00000002,
    SupportsVerticalScrolling: 0x00000004,
    SupportsHorizontalScrolling: 0x00000008,
    SupportsPenScrolling: 0x00000010,
    SupportsServiceMenu: 0x00000020,
    SupportsRSA: 0x00000040,
    SupportsContentSigning: 0x00000080,
    SupportsH2ContentSigning: 0x00000100,
    CanGenerateSignKey: 0x00000200,
    CanStoreSignKey: 0x00000400,
    CanStoreEncryptKey: 0x00000800,
    CanSignExternalHash: 0x00001000,
    SupportsRSAPassword: 0x00002000,
    SupportsSecureModePassword: 0x00004000,
    Supports4096BitKeys: 0x00008000,
    HasNFCReader: 0x00010000,
    SupportsKeyPad: 0x00020000,
    SupportsKeyPad32: 0x00040000,
    HasDisplay: 0x00080000,
    SupportsRSASignPassword: 0x00100000,
  };

  var docHashes = {
    kSha1: 0,
    kSha256: 1,
  };

  var cancelButton = -1;
  var retryButton = -1;
  var confirmButton = -1;
  var buttonDiff = 0;
  var buttonLeft = 0;
  var buttonTop = 0;
  var buttonSize = 0;
  var backgroundImage: any;
  var scaleFactorX = 1.0;
  var scaleFactorY = 1.0;

  var supportsRSA = false;
  var canStoreEncryptKey = false;

  let dlReference = props.dl?.folioReference != null ? props.dl?.folioReference : props.dl?.externalReference;
  var field_name = "Signature du bon de livraison " + dlReference + " - " + props.dl?.deliveryCustomer.name;

  var custom_text = "";

  if (props.dl != null && props.dl.products != null && props.dl.products?.length > 0) {
    custom_text =
      t("deliverynote.product") +
      " : " +
      props.dl?.products[0].designation +
      t("deliverynote.quantity") +
      " : " +
      props.dl?.products[0].quantity +
      " " +
      props.dl?.products[0].unit;
  }

  var docHash_type = docHashes.kSha256;
  var sha1_dochash = "AAECAwQFBgcICQoLDA0ODxAREhM=";
  var sha256_dochash = "AAECAwQFBgcICQoLDA0ODxAREhMUFRYXGBkaGxwdHh8=";
  var encryption_cert =
    "MIICqTCCAZGgAwIBAgIBATANBgkqhkiG9w0BAQUFADAYMRYwFAYDVQQKEw1EZW1vIHNpZ25vdGVjMB4XDTE1MTAwNzA5NDc1MFoXDTI1MTAwNDA5NDc1MFowGDEWMBQGA1UEChMNRGVtbyBzaWdub3RlYzCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAOFFpsZexYW28Neznn26Bp9NVCJywFFj1QYXg3DDsaSyr6ubuqXKSC4jkenIGBnom/zKPxwPDtNXuy+nyDYFXYNn87TUdh/51CCr3uk9kR9hvRIzBKwkOx0DGLdCoSGAKDOPHwx1rE0m/SOqYOQh6XFjlybw+KzDZcPvhf2Fq/IFNXHpk8m0YHMAReW8q34CYjk9ZtcIlrcYGTikQherOtYM8CaEUPDd6vdJgosGWEnDeNXDCAIWTFc5ECJm9Hh7a47eF3BG5Pjl1QfOSA8lQBV5eTjQc1n1rWCWULt143nIbN5yCFrn0D8W6+eKJV5urETxWUQ208iqgeU1bIgKSEUCAwEAATANBgkqhkiG9w0BAQUFAAOCAQEAt2ax8iwLFoOmlAOZTQcRQtjxseQAhgOTYL/vEP14rPZhF1/gkI9ZzhESdkqR8mHIIl7FnfBg9A2v9ZccC7YgRb4bCXNzv6TIEyz4EYXNkIq8EaaQpvsX4+A5jKIP0PRNZUaLJaDRcQZudd6FMyHxrHtCUTEvORzrgGtRnhBDhAMiSDmQ958t8RhET6HL8C7EnL7f8XBMMFR5sDC60iCu/HeIUkCnx/a2waZ13QvhEIeUBmTRi9gEjZEsGd1iZmgf8OapTjefZMXlbl7CJBymKPJgXFe5mD9/yEMFKNRy5Xfl3cB2gJka4wct6PSIzcQVPaCts6I0V9NfEikXy1bpSA==";
  var encryption_cert_only_when_empty = "TRUE";
  var rsa_scheme = globalAny.STPadServerLibDefault.RsaScheme.PSS;

  var padIndex = 0;
  var padConnectionType: any;
  var sampleRate: any;

  var wsUri = "wss://local.signotecwebsocket.de:49494";

  var state = document.getElementById("status");
  var sigcanvas: any = document.getElementById("sigCanvas");

  const PEN_COLOR_GREY = "0x007f7f7f";
  const PEN_COLOR_RED = "0x000000ff";
  const PEN_COLOR_GREEN = "0x0000ff00";
  const PEN_COLOR_BLUE = "0x00ff0000";
  const PEN_COLOR_BLACK = "0x00000000";

  const MODE_LIST_DEFAULT = "Default";
  const MODE_LIST_API = "API";

  if (window.WebSocket === undefined) {
    // state.innerHTML = "sockets not supported " + evt.target.url;
    // state.className = "fail";
    console.log("sockets not supported");
  } else {
    if (typeof String.prototype.startsWith != "function") {
      String.prototype.startsWith = function (str) {
        return this.indexOf(str) == 0;
      };
    }
  }

  function onMainWindowLoad() {
    // globalAny.STPadServerLibCommons.handleLogging = logMessage;
    globalAny.STPadServerLibCommons.destroyConnection();
    globalAny.STPadServerLibCommons.createConnection(wsUri, onOpen, onClose, onError);

    // clearSignature();
    // check_boxes_selectedElements_onchange();
    ModeListName_onchange();
  }

  /**
   * Draws a stroke start point into the canvas
   */
  function drawStrokeStartPoint(canvasContext: any, softCoordX: any, softCoordY: any) {
    // open new stroke's path
    canvasContext.beginPath();
    canvasContext.arc(softCoordX, softCoordY, 0.1, 0, 2 * Math.PI, true);
    canvasContext.fill();
    canvasContext.stroke();
    canvasContext.moveTo(softCoordX, softCoordY);
  }

  /**
   * Draws a stroke point into the canvas
   */
  function drawStrokePoint(canvasContext: any, softCoordX: any, softCoordY: any) {
    // continue after start or not start point
    canvasContext.lineTo(softCoordX, softCoordY);
    canvasContext.stroke();
  }

  /**
   * The send events
   */
  globalAny.STPadServerLibCommons.handleDisconnect = function (index: any) {
    disconnect_send(index);
  };

  globalAny.STPadServerLibCommons.handleNextSignaturePoint = function (x: any, y: any, p: any) {
    signature_point_send(x, y, p);
  };

  globalAny.STPadServerLibDefault.handleRetrySignature = function () {
    signature_retry_send();
  };

  globalAny.STPadServerLibDefault.handleConfirmSignature = function () {
    signature_confirm_send();
  };

  globalAny.STPadServerLibDefault.handleCancelSignature = function () {
    signature_cancel_send();
  };

  globalAny.STPadServerLibDefault.handleConfirmSelection = function () {
    selection_confirm_send();
  };

  globalAny.STPadServerLibDefault.handleSelectionChange = function (fieldId: any, fieldChecked: any) {
    selection_change_send(fieldId, fieldChecked);
  };

  globalAny.STPadServerLibDefault.handleCancelSelection = function () {
    selection_cancel_send();
  };

  globalAny.STPadServerLibDefault.handleError = function (
    error_context: any,
    return_code: any,
    error_description: any
  ) {
    error_send(error_context, return_code, error_description);
  };

  globalAny.STPadServerLibApi.Sensor.handleHotSpotPressed = function (button: any) {
    api_sensor_hot_spot_pressed_send(button);
  };

  globalAny.STPadServerLibApi.Sensor.handleDisplayScrollPosChanged = function (xPos: any, yPos: any) {
    api_display_scroll_pos_changed_send(xPos, yPos);
  };

  // disconnect send begin
  function disconnect_send(index: any) {
    var msg = "The pad (index: " + index + ") has been disconnected.";

    padState = padStates.closed;
  }
  // disconnect send end

  // signature point send begin
  function signature_point_send(x: any, y: any, p: any) {
    if (sigcanvas != null) {
      var ctx = sigcanvas.getContext("2d");

      ctx.fillStyle = "#fff";

      // let element : HTMLElement | null= document?.getElementById("signaturePenColorSelect");
      // switch (element?.value) {
      //   case PEN_COLOR_GREY:
      //     ctx.strokeStyle = "#7F7F7F";
      //     break;

      //   case PEN_COLOR_RED:
      //     ctx.strokeStyle = "#FF0000";
      //     break;

      //   case PEN_COLOR_GREEN:
      //     ctx.strokeStyle = "#00FF00";
      //     break;

      //   case PEN_COLOR_BLUE:
      //     ctx.strokeStyle = "#0000FF";
      //     break;

      //   case PEN_COLOR_BLACK:
      //     ctx.strokeStyle = "#000000";
      //     break;

      //   default:
      ctx.strokeStyle = "#FF0000";
      // break;
      // }

      ctx.lineWidth = 4.5;
      ctx.lineCap = "round";

      if (p == 0) {
        drawStrokeStartPoint(ctx, x * scaleFactorX, y * scaleFactorY);
      } else {
        drawStrokePoint(ctx, x * scaleFactorX, y * scaleFactorY);
      }
    }
  }
  // signature point send end

  // signature retry send begin
  function signature_retry_send() {
    if (padMode == padModes.Default) {
      // default mode
      globalAny.STPadServerLibDefault.retrySignature()
        .then(function (value: any) {
          var ctx = sigcanvas.getContext("2d");
          ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(null, function (reason: any) {
          error_message(reason);
          default_close_pad();
        });
    } else if (padMode == padModes.API) {
      // API mode
      globalAny.STPadServerLibApi.Signature.retry()
        .then(function (value: any) {
          var ctx = sigcanvas.getContext("2d");
          ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(null, function (reason: any) {
          error_message(reason);
          api_close_pad();
        });
    } else {
      // alert("invalid padMode");
      return;
    }
  }
  // signature retry send end

  // signature confirm send begin
  function signature_confirm_send() {
    if (padMode == padModes.Default) {
      // default mode
      globalAny.STPadServerLibDefault.confirmSignature()
        .then(function (value: any) {
          // check if there are enough points for a valid signature
          if (value.countedPoints / sampleRate <= 0.2) {
            alert("The signature is too short. Please sign again!");
            return globalAny.STPadServerLibDefault.retrySignature();
          }
          if (supportsRSA) {
            return globalAny.STPadServerLibDefault.getSigningCert();
          } else {
            return value;
          }
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(function (value: any) {
          if (value.command == "TOKEN_CMD_SIGNATURE_RETRY") {
            var ctx = sigcanvas.getContext("2d");
            ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);
            return value;
          }
          if (supportsRSA) {
            if (value.signingCert !== undefined) {
              //  // document.getElementById("signatureCert_0").innerHTML = value.signingCert;
            }
          } else {
            //  // document.getElementById("signatureCert_0").innerHTML = "none";
          }
          var getSignatureImageParams = new globalAny.STPadServerLibDefault.Params.getSignatureImage();
          getSignatureImageParams.setFileType(globalAny.STPadServerLibDefault.FileType.PNG);
          getSignatureImageParams.setPenWidth(5);
          return globalAny.STPadServerLibDefault.getSignatureImage(getSignatureImageParams);
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(function (value: any) {
          if (value.command == "TOKEN_CMD_SIGNATURE_RETRY") {
            // just do nothing but returning original promise object
            return value;
          }
          if (value.file !== undefined) {
            console.log("data:image/png;base64," + value.file);
            setSignature("data:image/png;base64," + value.file);
            globalAny.STPadServerLibCommons.destroyConnection();
            props.onSignatureOk(value.file);
            //  // document.getElementById("Signature_0").src = "data:image/png;base64," + value.file;
          }
          var getSignatureDataParams = new globalAny.STPadServerLibDefault.Params.getSignatureData();
          getSignatureDataParams.setRsaScheme(rsa_scheme);
          return globalAny.STPadServerLibDefault.getSignatureData(getSignatureDataParams);
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(function (value: any) {
          if (value.command == "TOKEN_CMD_SIGNATURE_RETRY") {
            // just do nothing but returning original promise object
            return value;
          }
          if (supportsRSA) {
            if (value.certId !== undefined) {
              // document.getElementById("biometryCertID_0").innerHTML = value.certId;
            }
            // document.getElementById("RSAScheme_0").innerHTML = rsa_scheme;
            if (value.rsaSignature !== undefined) {
              // document.getElementById("RsaSignature_0").value = value.rsaSignature;
            }
          } else {
            // document.getElementById("biometryCertID_0").innerHTML = "none";
            // document.getElementById("RSAScheme_0").innerHTML = "none";
            // document.getElementById("RsaSignature_0").value = "";
          }
          if (value.signData !== undefined) {
            // document.getElementById("SignData_0").value = value.signData;
          }
          default_close_pad();
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(null, function (reason: any) {
          error_message(reason);
          default_close_pad();
        });
    } else if (padMode == padModes.API) {
      // API mode
      globalAny.STPadServerLibApi.Signature.confirm()
        .then(function (value: any) {
          // check if there are enough points for a valid signature
          if (value.countedPoints / sampleRate <= 0.2) {
            alert("The signature is too short. Please sign again!");
            return globalAny.STPadServerLibApi.Signature.retry();
          }
          var saveAsStreamExParams = new globalAny.STPadServerLibApi.Signature.Params.saveAsStreamEx(
            300,
            0,
            0,
            globalAny.STPadServerLibApi.FileType.PNG,
            5,
            // document.getElementById("signaturePenColorSelect").value,
            0
          );
          return globalAny.STPadServerLibApi.Signature.saveAsStreamEx(saveAsStreamExParams);
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(function (value: any) {
          if (value.command == "TOKEN_CMD_API_SIGNATURE_RETRY") {
            var ctx = sigcanvas.getContext("2d");
            ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);
            return value;
          }
          if (value.image !== undefined) {
            // document.getElementById("Signature_0").src = "data:image/png;base64," + value.image;
          }
          return globalAny.STPadServerLibApi.Signature.getSignData();
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(function (value: any) {
          if (value.command == "TOKEN_CMD_API_SIGNATURE_RETRY") {
            // just do nothing but returning original promise object
            return value;
          }
          if (value.signData !== undefined) {
            // document.getElementById("SignData_0").value = value.signData;
          }
          api_close_pad();
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(
          function (value: any) {},
          function (reason: any) {
            error_message(reason);
            api_close_pad();
          }
        );
    } else {
      alert("invalid padMode");
      return;
    }
  }
  // signature confirm send end

  // signature cancel send begin
  function signature_cancel_send() {
    if (padMode == padModes.Default) {
      // default mode
      globalAny.STPadServerLibDefault.cancelSignature()
        .then(function (value: any) {
          var ctx = sigcanvas.getContext("2d");
          ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);
          default_close_pad();
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(null, function (reason: any) {
          error_message(reason);
          default_close_pad();
        });
    } else if (padMode == padModes.API) {
      // API mode
      var cancelParams = new globalAny.STPadServerLibApi.Signature.Params.cancel();
      cancelParams.setErase(0);
      globalAny.STPadServerLibApi.Signature.cancel(cancelParams)
        .then(function (value: any) {
          var ctx = sigcanvas.getContext("2d");
          ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);
          api_close_pad();
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(
          function (value: any) {},
          function (reason: any) {
            error_message(reason);
            api_close_pad();
          }
        );
    } else {
      alert("invalid padMode");
      return;
    }
  }
  // signature cancel send end

  // selection confirm send begin
  function selection_confirm_send() {
    if (padMode == padModes.Default) {
      // default mode
      var status = "";
      //   for (let i = 1; i <= document.getElementById("check_boxes_selectedElements").value; i++) {
      //     status += "Feld " + i + " = " + document.getElementById("fieldChecked" + i).checked + "\n";
      //   }
      // alert(status);
      signature_start();
    } else if (padMode == padModes.API) {
      // API mode
      // do nothing
    } else {
      // alert("invalid padMode");
      return;
    }
  }
  // selection confirm send end

  // selection change send begin
  function selection_change_send(fieldId: any, fieldChecked: any) {
    if (padMode == padModes.Default) {
      // default mode
      //   for (let i = 1; i <= document?.getElementById("check_boxes_selectedElements").value; i++) {
      //     if (document?.getElementById("fieldID" + i).value == fieldId) {
      //       if (fieldChecked == "TRUE") {
      //         document?.getElementById("fieldChecked" + i).checked = true;
      //       } else {
      //         document?.getElementById("fieldChecked" + i).checked = false;
      //       }
      //     }
      //   }
    } else if (padMode == padModes.API) {
      // API mode
      // do nothing
    } else {
      // alert("invalid padMode");
      return;
    }
  }
  // selection change send end

  // selection cancel send begin
  function selection_cancel_send() {
    if (padMode == padModes.Default) {
      // default mode
      var ctx = sigcanvas.getContext("2d");
      ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);

      globalAny.STPadServerLibDefault.cancelSignature()
        .then(function (value: any) {
          default_close_pad();
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(null, function (reason: any) {
          error_message(reason);
          default_close_pad();
        });
    } else if (padMode == padModes.API) {
      // API mode
      // do nothing
    } else {
      // alert("invalid padMode");
      return;
    }
  }
  // selection cancel send end

  // error send begin
  function error_send(error_context: any, return_code: any, error_description: any) {
    var ret = return_code;
    if (ret < 0) {
      // alert("Failed to confirm the signature. Reason: " + error_description + ", Context: " + error_context);
    }
  }
  // error send end

  // api sensor hot spot pressed send begin
  function api_sensor_hot_spot_pressed_send(button: any) {
    switch (button) {
      // cancel signing process
      case cancelButton:
        signature_cancel_send();
        break;

      // restart signing process
      case retryButton:
        signature_retry_send();
        break;

      // confirm signing process
      case confirmButton:
        signature_confirm_send();
        break;

      default:
        alert("unknown button id: " + button);
    }
  }
  // api sensor hot spot pressed send end

  // api display scroll pos changed send begin
  function api_display_scroll_pos_changed_send(xPos: any, yPos: any) {
    console.log(xPos + "," + yPos);
  }
  // api display scroll pos changed send end

  // getSignature begin
  function getSignature() {
    //var version;

    //version = "1.0.0.0";
    //version = "1.0.2.4";
    //version = "1.7.0.0";

    // set the json interface version
    //var setInterfaceVersionParams = new STPadServerLibCommons.Params.setInterfaceVersion(version);
    //STPadServerLibCommons.setInterfaceVersion(setInterfaceVersionParams);

    //reset the pads properties
    // document.getElementById("PadType_0").innerHTML = "";
    // document.getElementById("SerialNumber_0").innerHTML = "";
    // document.getElementById("FirmwareVersion_0").innerHTML = "";
    // document.getElementById("RSASupport_0").innerHTML = "";
    // document.getElementById("biometryCertID_0").innerHTML = "";
    // document.getElementById("RSAScheme_0").innerHTML = "";
    // document.getElementById("signatureCert_0").innerHTML = "";

    //delete the previous signature
    // var ctx = sigcanvas.getContext("2d");
    // ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);

    // document.getElementById("Signature_0").src = "White.png";
    // document.getElementById("SignData_0").value = "";
    // document.getElementById("RsaSignature_0").value = "";

    // var padConnectionTypeList =  document.getElementById("PadConnectionTypeList");
    // if (padConnectionTypeList.selectedIndex == -1) {
    //   //if the pad type is not selected message to user and return
    //   alert("Please select a pad type from the list!");
    //   padConnectionTypeList.focus();
    //   return;
    // }

    padConnectionType = "HID";

    // if (padConnectionTypeList.selectedIndex == 0) {
    //   //search for USB pads
    //   padConnectionType = "HID";
    // } else {
    //   //search for serial pads
    //   padConnectionType = "All";
    // }

    padMode = padModes.Default;
    if (padMode == padModes.Default) {
      // default mode
      getSignatureDefault();
    } else if (padMode == padModes.API) {
      // API mode
      getSignatureAPI();
    } else {
      alert("invalid padMode");
      return;
    }
  }
  // getSignature end

  // getSignatureDefault begin
  function getSignatureDefault() {
    // search for pads begin
    var searchForPadsParams = new globalAny.STPadServerLibDefault.Params.searchForPads();
    padConnectionType = "HID";
    searchForPadsParams.setPadSubset(padConnectionType);
    globalAny.STPadServerLibDefault.searchForPads(searchForPadsParams)
      .then(function (pads: any) {
        if (pads.foundPads.length == 0) {
          alert("No connected pads have been found.");
          return Promise.reject("No connected pads have been found.");
        }

        padType = pads.foundPads[padIndex].type;

        // document.getElementById("PadType_0").innerHTML = getReadableType(padType);
        // document.getElementById("SerialNumber_0").innerHTML = pads.foundPads[padIndex].serialNumber;
        // document.getElementById("FirmwareVersion_0").innerHTML = pads.foundPads[padIndex].firmwareVersion;

        if (pads.foundPads[padIndex].capabilities & deviceCapabilities.SupportsRSA) {
          supportsRSA = true;
        } else {
          supportsRSA = false;
        }

        if (pads.foundPads[padIndex].capabilities & deviceCapabilities.CanStoreEncryptKey) {
          canStoreEncryptKey = true;
        } else {
          canStoreEncryptKey = false;
        }

        if (supportsRSA) {
          // document.getElementById("RSASupport_0").innerHTML = "Yes";
        } else {
          // document.getElementById("RSASupport_0").innerHTML = "No";
        }
      }, globalAny.STPadServerLibCommons.defaultReject)
      // search for pads end

      // open pad begin
      .then(function (value: any) {
        var openPadParams = new globalAny.STPadServerLibDefault.Params.openPad(padIndex);
        return globalAny.STPadServerLibDefault.openPad(openPadParams);
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (padInfo: {
        padInfo: {
          displayWidth: number;
          displayHeight: number;
          xResolution: number;
          yResolution: number;
          samplingRate: any;
        };
      }) {
        padState = padStates.opened;

        // sigcanvas.width = padInfo.padInfo.displayWidth;
        // sigcanvas.height = padInfo.padInfo.displayHeight;

        //get scale factor from signature resolution to canvas
        scaleFactorX = padInfo.padInfo.displayWidth / padInfo.padInfo.xResolution;
        scaleFactorY = padInfo.padInfo.displayHeight / padInfo.padInfo.yResolution;

        //get sample rate
        sampleRate = padInfo.padInfo.samplingRate;

        //start the signature process
        selection_dialog();
      }, globalAny.STPadServerLibCommons.defaultReject)
      // open pad end

      .then(
        function (selection: any) {},
        function (reason: any) {
          // alert(reason);
          error_message(reason);
          default_close_pad();
        }
      );
  }
  // getSignatureDefault end

  // getSignatureAPI begin
  function getSignatureAPI() {
    // api search for pads begin
    var setComPortParams = new globalAny.STPadServerLibApi.Device.Params.setComPort(padConnectionType);
    globalAny.STPadServerLibApi.Device.setComPort(setComPortParams)
      .then(function (value: any) {
        return globalAny.STPadServerLibApi.Device.getCount();
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: { countedDevices: number }) {
        if (value.countedDevices == 0) {
          alert("No connected pads have been found.");
          return Promise.reject("No connected pads have been found.");
        }
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        return globalAny.STPadServerLibApi.Device.getInfo(
          new globalAny.STPadServerLibApi.Device.Params.getInfo(padIndex)
        );
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (info: { type: number }) {
        padType = info.type;
        // document.getElementById("PadType_0").innerHTML = getReadableType(padType);
        // document.getElementById("SerialNumber_0").innerHTML = info.serial;
        var imageName = getReadableType(padType).split(" ");
        var padName = imageName[0];
        switch (padName) {
          case "Sigma":
            buttonSize = 36;
            buttonTop = 2;
            break;
          case "Zeta":
            buttonSize = 36;
            buttonTop = 2;
            break;
          case "Omega":
          case "Gamma":
          case "Delta":
            buttonSize = 48;
            buttonTop = 4;
            break;
          case "Alpha":
            buttonSize = 80;
            buttonTop = 10;
            break;
        }
        getBackgroundImage(padName);
        return globalAny.STPadServerLibApi.Device.getVersion(
          new globalAny.STPadServerLibApi.Device.Params.getVersion(padIndex)
        );
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (version: any) {
        // document.getElementById("FirmwareVersion_0").innerHTML = version.version;
      }, globalAny.STPadServerLibCommons.defaultReject)
      // api search for pads end

      // api device open begin
      .then(function (value: any) {
        var openParams = new globalAny.STPadServerLibApi.Device.Params.open(padIndex);
        openParams.setEraseDisplay(true);
        return globalAny.STPadServerLibApi.Device.open(openParams);
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        padState = padStates.opened;
        var params = new globalAny.STPadServerLibApi.Display.Params.configPen(
          3
          // document.getElementById("signaturePenColorSelect").value
        );
        return globalAny.STPadServerLibApi.Display.configPen(params);
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        return globalAny.STPadServerLibApi.Display.getWidth();
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (width: { width: any }) {
        sigcanvas.width = width.width;
        return globalAny.STPadServerLibApi.Display.getHeight();
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (height: { height: any }) {
        sigcanvas.height = height.height;
        return globalAny.STPadServerLibApi.Signature.getResolution();
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (resolution: { xResolution: number; yResolution: number }) {
        scaleFactorX = sigcanvas.width / resolution.xResolution;
        scaleFactorY = sigcanvas.height / resolution.yResolution;
        return globalAny.STPadServerLibApi.Sensor.getSampleRateMode();
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (mode: { sampleRateMode: string }) {
        //get sample rate
        switch (mode.sampleRateMode) {
          case "0":
            sampleRate = 125;
            break;
          case "1":
            sampleRate = 250;
            break;
          case "2":
            sampleRate = 500;
            break;
          case "3":
            sampleRate = 280;
            break;
          default:
            alert("Failed to get sample rate. Reason: Unexpected sample rate mode: " + mode.sampleRateMode);
            return;
        }
      }, globalAny.STPadServerLibCommons.defaultReject)
      // api device open end

      // api signature start begin
      .then(function (value: any) {
        var displayRotation = 0;
        return globalAny.STPadServerLibApi.Display.setRotation(
          new globalAny.STPadServerLibApi.Display.Params.setRotation(displayRotation)
        );
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        return globalAny.STPadServerLibApi.Display.getRotation();
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        var displayRotation = value;
        var params = new globalAny.STPadServerLibApi.Display.Params.setTarget(1);
        return globalAny.STPadServerLibApi.Display.setTarget(params);
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        var setImageParams = new globalAny.STPadServerLibApi.Display.Params.setImage(0, 0, backgroundImage);
        return globalAny.STPadServerLibApi.Display.setImage(setImageParams);
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        buttonDiff = sigcanvas.width / 3;
        buttonLeft = (buttonDiff - buttonSize) / 2;
        return globalAny.STPadServerLibApi.Sensor.addHotSpot(
          new globalAny.STPadServerLibApi.Sensor.Params.addHotSpot(
            Math.round(buttonLeft),
            buttonTop,
            buttonSize,
            buttonSize
          )
        );
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: { hotspotId: number }) {
        cancelButton = value.hotspotId;
        buttonLeft = buttonLeft + buttonDiff;
        return globalAny.STPadServerLibApi.Sensor.addHotSpot(
          new globalAny.STPadServerLibApi.Sensor.Params.addHotSpot(
            Math.round(buttonLeft),
            buttonTop,
            buttonSize,
            buttonSize
          )
        );
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: { hotspotId: number }) {
        retryButton = value.hotspotId;
        buttonLeft = buttonLeft + buttonDiff;
        return globalAny.STPadServerLibApi.Sensor.addHotSpot(
          new globalAny.STPadServerLibApi.Sensor.Params.addHotSpot(
            Math.round(buttonLeft),
            buttonTop,
            buttonSize,
            buttonSize
          )
        );
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: { hotspotId: number }) {
        confirmButton = value.hotspotId;
        var top = 0;
        switch (padType) {
          case padTypes.sigmaUSB:
          case padTypes.sigmaSerial:
            top = 40;
            break;
          case padTypes.zetaUSB:
          case padTypes.zetaSerial:
            top = 40;
            break;
          case padTypes.omegaUSB:
          case padTypes.omegaSerial:
          case padTypes.gammaUSB:
          case padTypes.gammaSerial:
          case padTypes.deltaUSB:
          case padTypes.deltaSerial:
          case padTypes.deltaIP:
            top = 56;
            break;
          case padTypes.alphaUSB:
          case padTypes.alphaSerial:
          case padTypes.alphaIP:
            top = 100;
            break;
          default:
            alert("unkown pad type: " + padType);
            return;
        }

        return globalAny.STPadServerLibApi.Sensor.setSignRect(
          new globalAny.STPadServerLibApi.Sensor.Params.setSignRect(0, top, sigcanvas.width, sigcanvas.height)
        );
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        var left;
        var top;
        var width;
        var height;
        switch (padType) {
          case padTypes.sigmaUSB:
          case padTypes.sigmaSerial:
            left = 15;
            top = 43;
            width = 285;
            height = 18;
            break;
          case padTypes.zetaUSB:
          case padTypes.zetaSerial:
            left = 15;
            top = 43;
            width = 285;
            height = 18;
            break;
          case padTypes.omegaUSB:
          case padTypes.omegaSerial:
            left = 40;
            top = 86;
            width = 570;
            height = 40;
            break;
          case padTypes.gammaUSB:
          case padTypes.gammaSerial:
            left = 40;
            top = 86;
            width = 720;
            height = 40;
            break;

          case padTypes.deltaUSB:
          case padTypes.deltaSerial:
          case padTypes.deltaIP:
            left = 40;
            top = 86;
            width = 1200;
            height = 50;
            break;
          case padTypes.alphaUSB:
          case padTypes.alphaSerial:
          case padTypes.alphaIP:
            left = 20;
            top = 120;
            width = 730;
            height = 30;
            break;
          default:
            alert("unkown pad type: " + padType);
            return;
        }
        var setTextInRectParams = new globalAny.STPadServerLibApi.Display.Params.setTextInRect(
          left,
          top,
          width,
          height,
          globalAny.STPadServerLibApi.TextAlignment.LEFT,
          field_name,
          0
        );
        return globalAny.STPadServerLibApi.Display.setTextInRect(setTextInRectParams);
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (textWidth: any) {
        var left;
        var top;
        var width;
        var height;
        switch (padType) {
          case padTypes.sigmaUSB:
          case padTypes.sigmaSerial:
            left = 15;
            top = 110;
            width = 265;
            height = 18;
            break;
          case padTypes.zetaUSB:
          case padTypes.zetaSerial:
            left = 15;
            top = 150;
            width = 265;
            height = 18;
            break;
          case padTypes.omegaUSB:
          case padTypes.omegaSerial:
            left = 40;
            top = 350;
            width = 520;
            height = 40;
            break;
          case padTypes.gammaUSB:
          case padTypes.gammaSerial:
            left = 40;
            top = 350;
            width = 670;
            height = 40;
            break;
          case padTypes.deltaUSB:
          case padTypes.deltaSerial:
          case padTypes.deltaIP:
            left = 40;
            top = 640;
            width = 670;
            height = 50;
            break;
          case padTypes.alphaUSB:
          case padTypes.alphaSerial:
          case padTypes.alphaIP:
            left = 20;
            top = 1316;
            width = 730;
            height = 30;
            break;
          default:
            alert("unkown pad type: " + padType);
            return;
        }

        var setTextInRectParams = new globalAny.STPadServerLibApi.Display.Params.setTextInRect(
          left,
          top,
          width,
          height,
          globalAny.STPadServerLibApi.TextAlignment.LEFT,
          custom_text,
          0
        );
        return globalAny.STPadServerLibApi.Display.setTextInRect(setTextInRectParams);
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        var params = new globalAny.STPadServerLibApi.Display.Params.setTarget(0);
        return globalAny.STPadServerLibApi.Display.setTarget(params);
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        var params = new globalAny.STPadServerLibApi.Display.Params.setImageFromStore(1);
        return globalAny.STPadServerLibApi.Display.setImageFromStore(params);
      }, globalAny.STPadServerLibCommons.defaultReject)
      .then(function (value: any) {
        return globalAny.STPadServerLibApi.Signature.start();
      }, globalAny.STPadServerLibCommons.defaultReject)
      // api signature start end

      .then(
        function (value: any) {},
        function (reason: any) {
          error_message(reason);
          api_close_pad();
        }
      );
  }
  // getSignatureAPI end

  // selection dialog begin
  function selection_dialog() {
    if (padMode == padModes.Default) {
      signature_start();
      // default mode
      //   var selectedElement = // document.getElementById("check_boxes_selectedElements").value;
      //   if (selectedElement > 0) {
      //     // prepare selection dialog
      //     var checkBoxInformation = [];
      //     for (var i = 1; i <= selectedElement; i++) {
      //       var box = {};
      //       box.id = // document.getElementById("fieldID" + i).value;
      //       box.text = // document.getElementById("fieldText" + i).value;
      //       box.checked = // document.getElementById("fieldChecked" + i).checked;
      //       box.required = // document.getElementById("fieldRequired" + i).checked;
      //       checkBoxInformation[i - 1] = box;

      // var startSelectionDialogParams = new globalAny.STPadServerLibDefault.Params.startSelectionDialog();
      // // startSelectionDialogParams.addCheckboxInformation(checkBoxInformation);
      // globalAny.STPadServerLibDefault.startSelectionDialog(startSelectionDialogParams)
      //   .then(function (value: any) {}, globalAny.STPadServerLibCommons.defaultReject)
      //   .then(null, function (reason: any) {
      //     error_message(reason);
      //     default_close_pad();
      //   });
      // } else {
      //   // start signature capture
      //   signature_start();
      // }
    } else if (padMode == padModes.API) {
      // API mode
      // do nothing
    } else {
      alert("invalid padMode");
      return;
    }
  }
  // selection dialog end

  // signature start begin
  function signature_start() {
    var dochash;

    switch (docHash_type) {
      case docHashes.kSha1:
        dochash = sha1_dochash;
        break;

      case docHashes.kSha256:
        dochash = sha256_dochash;
        break;

      default:
        alert("unknown doc hash");
        return;
    }

    if (supportsRSA) {
      var startSignatureParams = new globalAny.STPadServerLibDefault.Params.startSignature();
      startSignatureParams.setFieldName(field_name);
      startSignatureParams.setCustomText(custom_text);
      if (canStoreEncryptKey) {
        startSignatureParams.enablePadEncryption(dochash, encryption_cert, encryption_cert_only_when_empty);
      } else {
        startSignatureParams.enablePadEncryption(dochash, null);
      }
      globalAny.STPadServerLibDefault.startSignature(startSignatureParams)
        .then(function (value: any) {}, globalAny.STPadServerLibCommons.defaultReject)
        .then(null, function (reason: any) {
          error_message(reason);
          default_close_pad();
        });
    } else {
      var startSignatureParams = new globalAny.STPadServerLibDefault.Params.startSignature();
      startSignatureParams.setFieldName(field_name);
      startSignatureParams.setCustomText(custom_text);
      globalAny.STPadServerLibDefault.startSignature(startSignatureParams)
        .then(function (value: any) {}, globalAny.STPadServerLibCommons.defaultReject)
        .then(null, function (reason: any) {
          error_message(reason);
          default_close_pad();
        });
    }
  }
  // signature start end

  // close pad begin
  function default_close_pad() {
    if (padState == padStates.opened) {
      var closePadParams = new globalAny.STPadServerLibDefault.Params.closePad(padIndex);
      globalAny.STPadServerLibDefault.closePad(closePadParams)
        .then(function (value: any) {
          padState = padStates.closed;
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(null, function (reason: any) {
          error_message(reason);
          return;
        });
    }
  }

  function api_close_pad() {
    if (padState == padStates.opened) {
      var closePadParams = new globalAny.STPadServerLibApi.Device.Params.close(padIndex);
      globalAny.STPadServerLibApi.Device.close(closePadParams)
        .then(function (value: any) {
          padState = padStates.closed;
        }, globalAny.STPadServerLibCommons.defaultReject)
        .then(null, function (reason: any) {
          error_message(reason);
          return;
        });
    }
  }
  // close pad end

  function getBackgroundImage(padName: string) {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = function () {
      // var sigcanvas = // document.createElement("canvas");
      // sigcanvas.width = this.width;
      // sigcanvas.height = this.height;

      var ctx = sigcanvas.getContext("2d");
      ctx.drawImage(this, 0, 0);

      var dataURL = sigcanvas.toDataURL("image/png");
      backgroundImage = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    };
    // var element = // document.getElementById(padName);
    // img.src = element.src;
  }

  function check_boxes_selectedElements_onchange() {
    // var sender = // document.getElementById("check_boxes_selectedElements");
    // var selectedElem = sender.value;
    // var elemCount = sender.childElementCount;
    // for (let i = 1; i < elemCount; i++) {
    //   // document.getElementById("fieldNumber" + i).style.visibility = "hidden";
    //   // document.getElementById("fieldID" + i).style.visibility = "hidden";
    //   // document.getElementById("fieldText" + i).style.visibility = "hidden";
    //   // document.getElementById("fieldChecked" + i).style.visibility = "hidden";
    //   // document.getElementById("fieldRequired" + i).style.visibility = "hidden";
    // }
    // for (let i = 1; i <= selectedElem; i++) {
    //   // document.getElementById("fieldNumber" + i).style.visibility = "visible";
    //   // document.getElementById("fieldID" + i).style.visibility = "visible";
    //   // document.getElementById("fieldText" + i).style.visibility = "visible";
    //   // document.getElementById("fieldChecked" + i).style.visibility = "visible";
    //   // document.getElementById("fieldRequired" + i).style.visibility = "visible";
    // }
  }

  function ModeListName_onchange() {
    // var sender = // document.getElementById("ModeList");
    // var selectedElem = sender.value;
    // // the signature pen color select
    // var scl = // document.getElementById("signatureColorLabel");
    // var spcs = // document.getElementById("signaturePenColorSelect");
    // spcs.selectedIndex = 0;
    // // the check boxes select
    // var cbsEL = // document.getElementById("check_boxes_selectedElementsLabel");
    // var cbsE = // document.getElementById("check_boxes_selectedElements");
    // var elemCount = cbsE.childElementCount;
    // switch (selectedElem) {
    //   case MODE_LIST_DEFAULT:
    //     // disable the signature pen color select
    //     scl.disabled = true;
    //     spcs.disabled = true;
    //     // enable the check boxes select and table elements
    //     cbsEL.disabled = false;
    //     cbsE.disabled = false;
    //     for (let i = 1; i < elemCount; i++) {
    //       // document.getElementById("fieldNumber" + i).disabled = false;
    //       // document.getElementById("fieldID" + i).disabled = false;
    //       // document.getElementById("fieldText" + i).disabled = false;
    //       // document.getElementById("fieldChecked" + i).disabled = false;
    //       // document.getElementById("fieldRequired" + i).disabled = false;
    //     }
    //     padMode = padModes.Default;
    //     break;
    //   case MODE_LIST_API:
    //     // enable the signature pen color select
    //     scl.disabled = false;
    //     spcs.disabled = false;
    //     // disable the check boxes select and table elements
    //     cbsEL.disabled = true;
    //     cbsE.disabled = true;
    //     for (let i = 1; i < elemCount; i++) {
    //       // document.getElementById("fieldNumber" + i).disabled = true;
    //       // document.getElementById("fieldID" + i).disabled = true;
    //       // document.getElementById("fieldText" + i).disabled = true;
    //       // document.getElementById("fieldChecked" + i).disabled = true;
    //       // document.getElementById("fieldRequired" + i).disabled = true;
    //     }
    //     padMode = padModes.API;
    //     break;
    //   default:
    //     alert("invalid padMode");
    //     break;
    // }
  }

  function getReadableType(intTypeNumber: number) {
    switch (intTypeNumber) {
      case padTypes.sigmaUSB:
        return "Sigma USB";
      case padTypes.sigmaSerial:
        return "Sigma serial";
      case padTypes.zetaUSB:
        return "Zeta USB";
      case padTypes.zetaSerial:
        return "Zeta serial";
      case padTypes.omegaUSB:
        return "Omega USB";
      case padTypes.omegaSerial:
        return "Omega serial";
      case padTypes.gammaUSB:
        return "Gamma USB";
      case padTypes.gammaSerial:
        return "Gamma serial";
      case padTypes.deltaUSB:
        return "Delta USB";
      case padTypes.deltaSerial:
        return "Delta serial";
      case padTypes.deltaIP:
        return "Delta IP";
      case padTypes.alphaUSB:
        return "Alpha USB";
      case padTypes.alphaSerial:
        return "Alpha serial";
      case padTypes.alphaIP:
        return "Alpha IP";
      default:
        return "Unknown";
    }
  }

  function clearSignature() {
    // document.getElementById("ModeList").selectedIndex = 0;
    // document.getElementById("PadConnectionTypeList").selectedIndex = 0;
    // document.getElementById("Signature_0").src = "White.png";
    // document.getElementById("SignData_0").value = "";
    // document.getElementById("RsaSignature_0").value = "";
  }

  function error_message(param: any) {
    if (param.errorCode < 0) {
      location.reload();
      // alert("Function " + param.command + " failed. Reason: " + param.errorMessage);
    }
  }
  return (
    <>
      <div>
        {isConnected ? (
          <>
            <div>Pad connecté</div>
            <div>Vous pouvez faire signer le chauffeur sur le pad</div>

            <div>Signature : </div>
            <img key={"signature"} src={signature} width="300"></img>
          </>
        ) : (
          <div>Pas de pad connecté, vous pouvez faire signer le chauffeur dans le cadre ci dessous</div>
        )}
      </div>
    </>
  );
}

export default SignaturePadComponent;
