import login from "./login";
import menu from "./menu";
import deliveynote from "./deliverynote";
import stats from "./stats";

let trad = {
  "login.error.email.required": "Email is required",
  store: "Click on your store and download",
  common: {
    "translate-button": "FR",
    error: "An error has occurred. Please try again",
  },
  and: "and",
  see: "See",
  others: "other(s)",
  account: {
    create: "Create my account",
    edit: "Edit my account",
    delete: "Delete my account",
    updated: "Account modified",
    confirmPassword: "Password confirmation",
    phoneNumber: "Phone number",
  },
  role: {
    title: "Role",
    choose: "Choose a role",
    admin: "Administrator",
    user: "User",
  },
  users: {
    added: "User added",
    addToSite: "Add to one or more site(s)",
    addToRegion: "Add to one or more region(s)",
    sites: "Site(s)",
    regions: "Region(s)",
    update: "Edit a user",
    delete: "Delete a user",
    deleted: "User deleted",
    updated: "User modified",
    created: "User created",
    selectDeliveries: "Choose one or more deliveries",
    selectRegion: "Choose one or more regions",
    selectSite: "Choose one or more sites",
    organisations: "Organisations",
    changeRole: "Switch to ",
    deleteQuestion: "Are you sure you want to delete the user {{username}} for the organisation {{workspacename}} ?",
    deleteQuestion2: "Are you sure you want to delete the user {{username}} ?",
    search: "Search a user",
    noOne: "No user",
    add: {
      title: "Add a user",
    },
  },
  optional: "Optional",
  dn: "Delivery note",
  bmd: "BMD ",
  designation: "Designation",
  denomination: "Denomination",
  label: "Label",
  back: "Back",
  goBackHome: "Back to home",
  yes: "Yes",
  no: "No",
  reference: "Reference",
  type: "Type",
  emails: "Emails",
  seeDetails: "See details",
  actions: {
    create: "Create",
    edit: "Edit",
    delete: "Delete",
    save: "Save",
    cancel: "Cancel",
    close: "Close",
    add: "Add",
    validate: "Validate",
    search: "Search",
    clear: "Clear",
    sign: "Sign",
  },
  carrier: "Carrier",
  region: "Region",
  affiliate: "Affiliate",
  billing: "Customer",
  delivery: "Delivery",
  site: "Site",
  pumper: "Pumper",
  admin: "Admin",
  user: "User",
  result: "result",
  results: "results",
  vehicle: "Vehicle",
  vehicleLabel: "Vehicle label",
  customer: "Customer",
  notFilled: "Not filled",
  table: {
    perpage: "Per page",
    on: "on",
    total: "Total",
    lastupdated: "Last updated the {{day}} at {{hour}}h{{minute}}",
  },
  name: "Name",
  firstname: "Firstname",
  lastUsedTime: "Last used time",
  loading: "Loading",
  seeMore: "See more",
  "searchLength.error": "You must enter at least {{length}} characters",
  noOptions: "No result",
  noSite: "No site",
  seeDns: "See delivery notes",
  seeBMD: "See BMD",
  saving: "Saving",
  "email.added": "Email added",
  "user.deleted": "User deleted",
  regions: {
    noOne: "No region",
    search: "Search a region",
    deleteSite: {
      question: "Are you sure you want to delete the site {{site}} from the region {{region}} ?",
      success: "Site deleted from region",
    },
    add: {
      title: "Add a region",
      success: "Region added",
      error: "Region already exists",
    },
    addSite: {
      title: "Add a site",
      successMultiple: "Sites added to region",
      success: "Site added to region",
      list: "List of sites from region",
      list_noSite: "No site in this region",
      choose: "Choose a site",
    },
    update: {
      inprogress: "Modification in progress",
    },
    save: {
      inprogress: "Saving in progress",
    },
  },
  sites: {
    activeDeparture: "Active departure mode",
    deactiveDeparture: "Deactive departure mode",
    deactiveWithdrawalPointSuccess: "Departure point deactivated",
    activeWithdrawalPointSuccess: "Departure point activated",
    search: "Search a site",
    noOne: "No site",
    deactiveWithdrawalPointQuestion: "Are you sure you want to deactivate the departure point for {{site}} ?",
    activeWithdrawalPointQuestion: "Are you sure you want to activate the departure point for {{site}} ?",
  },
  carriers: {
    choose: "Choose a carrier",
    search: "Search a carrier",
    noOne: "No carrier",
    choosePumper: "Choose a pumper",
    assignBMPPump: "Assign a license plate for the BMD",

    add: {
      title: "Add a carrier",
    },
  },
  drivers: {
    search: "Search a driver",
    noOne: "No driver",
    add: {
      title: "Add a driver",
      success: "Driver added",
    },
    create: "Driver createad",
    deleteQuestion: "Are you sure you want to delete the driver {{driver}} ?",
    deleteSuccess: "Driver deleted",
  },
  deliveries: {
    search: "Search a delivery",
    noOne: "No delivery",
  },
  customers: {
    search: "Search a customer",
    noOne: "No customer",
    emailPlaceholder: "Add an email and validate",
  },
  vehicles: {
    search: "Search a vehicle",
    immat: "License plate",
    label: "Label",
    type: "Type",
    chooseType: "Choose a type",
    types: {
      concrete_mixer: "Concrete mixer",
      dump_truck: "Dump truck",
      pumi: "Pumi",
      pump_truck: "Pump truck",
      unknown: "Unknown",
    },
    noOne: "No vehicle",
    add: {
      title: "Add a vehicle",
    },
    status: {
      associated: "Associated",
      available: "Available",
    },
    updated: "Vehicle updated",
    created: "Vehicle created",
  },
  errors: {
    "lastname.required": "Lastname is required",
    "firstname.required": "Firstname is required",
    "email.required": "Email is required",
    "email.invalid": "Email is invalid",
    "password.required": "Password is required",
    "password.confirm.required": "Password confirmation is required",
    "password.confirmation": "Password confirmation is not the same as password",
    "password.minlength": "Password must be at least 8 characters",
    "phone.required": "Phone is required",
    "phone.invalid": "Phone is invalid",
    "role.required": "Role is required",
    "region.required": "Region is required",
    "site.required": "Site is required",
    "carrier.required": "Carrier is required",
    "user.alreadyExist": "User already exists",
    "name.required": "Name is required",
    "denomination.required": "Denomination is required",
    saveColumn: "Error while saving column order",
    "vehicle.required": "Vehicle is required",
    "vehicle.alreadyExist": "Vehicle already exists",
    "immat.required": "License plate is required",
    "immat.minLength": "License plate must be at least 4 characters",
    "vehicle.label.required": "Vehicle label is required",
    "immat.invalid": "License plate is invalid",
    "link.invalid": "Link is invalid",
    "updatePumper.cantUpdate": "Actual BMD status block the BMD update",
    "signerName.required": "Signer name is required",
    madeby: "Made in",
  },
  appCode: {
    title: "If the redirection does not work ",
    description: "Enter the following code :",
  },
};

let finalTrad = Object.assign(trad, login);
finalTrad = Object.assign(finalTrad, menu);
finalTrad = Object.assign(finalTrad, deliveynote);
finalTrad = Object.assign(finalTrad, stats);

export default finalTrad;
