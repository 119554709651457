import axios, { AxiosResponse } from "axios";
import Config from "../Config";
import ApiResponse from "../models/ApiResponse";
import BaseApi from "./base-api";

export type BaseApiResponse = AxiosResponse<ApiResponse>;
export type BaseApiResponsePromise = Promise<BaseApiResponse>;
const DeliveryNoteApi = {
  getCurrentVehiculesLocation: (): BaseApiResponsePromise => {
    let url = Config.getUrl("vehicles/location");
    return axios.get(url, BaseApi.getHeaders());
  },
};
export default DeliveryNoteApi;
