export default {
  stats: {
    date: {
      today: "Aujourd'hui",
      yesterday: "Hier",
      lastweek: "7 derniers jours",
      last15days: "15 derniers jours",
      last30days: "30 derniers jours",
      thismonth: "Ce mois",
      thisyear: "12 derniers mois",
      all: "Tous",
    },
    totalDigitalDeliveryNote: "Total Démat",
    deliveredDigitalDeliveryNote: "Rendus Démat",
    deliveredDeliveryNote: "Rendus",
    totaldeliveredDeliveryNote: "Rendus",
    collectedDigitalDeliveryNote: "Départ Démat",
    collectedDeliveryNote: "Départ",
    totalCollectedDeliveryNote: "Départ",
    totalDigitalPumpingNote: "Total Démat",
  },
};
