import React, { useEffect, useState } from "react";
import "./UpdateCustomer.scss";
import "../styles/table.scss";
import "../styles/popup.scss";
// import * as yup from "yup";
import PopPop from "react-poppop";
import { useForm } from "react-hook-form";
import { ReactComponent as Close } from "../assets/svg/close.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Config from "../Config";
import ConfirmPopup from "./ConfirmPopup";
import PopupStyle from "../styles/popupContentStyle";
import { ToastTypes } from "../models/ToastTypes";
import Toast from "../models/Toast";
import ToastComponent from "./ToastComponent";
import Driver from "../models/Driver";
import { Scopes } from "../models/Scopes";
import Roles from "../utils/Roles";
import { useTranslation } from "react-i18next";
const schema = yup.object().shape({
  currentEmail: yup.string().email("L'email doit être valide"),
  role: yup.string().required("errors.role.required"),
});

export interface UpdateCustomerProps {
  onsuccess: () => void;
  oncancel: () => void;
  onDeleteEmail: () => void;
  customer: any;
  userType: "carriers" | "billing-customers" | "delivery-customers";
  isPumpers?: boolean;
}

const UpdateCustomer = React.memo((props: UpdateCustomerProps) => {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<string[]>([]);
  const [currentEmail, setEmail] = useState<string>("");
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [isDriversLoading, setIsDriversLoading] = useState(false);

  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [currentEmailToDelete, setCurrentEmailToDelete] = useState("");
  const [currentDriverToDelete, setCurrentDriverToDelete] = useState<Driver | null>(null);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [role, setRole] = useState("");
  const [toasts, setToast] = useState<Toast[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  const onSubmit = () => {
    if (currentEmail !== "") {
      setIsAddingLoading(true);
      let token = localStorage.getItem("token");

      let finalRole = `extranet.carrier.${role}`;
      if (props.userType == "billing-customers") {
        finalRole = `extranet.billing.${role}`;
      }

      if (props.userType == "delivery-customers") {
        finalRole = `extranet.delivery.${role}`;
      }

      if (props.isPumpers) {
        finalRole = `extranet.pumper.${role}`;
      }

      let dataToSend: any = {
        email: currentEmail,
        role: finalRole,
      };
      let baseUrl = props.userType;
      let url = `${baseUrl}/${props?.customer.uuid}/user`;

      axios
        .post(Config.getUrl(url), dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setIsAddingLoading(false);
          props.onsuccess();
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (error.response.status == 401) {
              if (error.response.data.code == "ERR4010003") {
                setErrorMessage("user.alreadyExist");
              }
            }
          } else if (error.request) {
            setErrorMessage("common.error");
          } else {
            setErrorMessage("common.error");
          }
        });
    } else {
      props.oncancel();
    }
  };

  // const addEmail = (email: string) => {
  //   setEmails([...emails, email]);
  //   setEmail("");
  //   setErrorMessage("");
  // };

  const onDeleteEmail = (email: string) => {
    setCurrentEmailToDelete(email);
    setCurrentDriverToDelete(null);
    setIsOpenConfirmDelete(true);
  };

  const onDeleteDriver = (driver: Driver) => {
    setCurrentDriverToDelete(driver);
    setCurrentEmailToDelete("");
    setIsOpenConfirmDelete(true);
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };
  const onDeleteOk = () => {
    //call backend

    if (currentDriverToDelete != null) {
      let url = `carriers/${props.customer.uuid}/drivers/remove-permission`;

      let dataToSend = {
        driverUuid: currentDriverToDelete.uuid,
      };
      let token = localStorage.getItem("token");

      axios
        .post(Config.getUrl(url), dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setIsOpenConfirmDelete(false);
          var array: any[] = [...drivers]; // make a separate copy of the array
          array = array.filter((e) => e.uuid != currentDriverToDelete.uuid);
          setDrivers(array);
          addToast(t("drivers.deleteSuccess"), ToastTypes.success);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (error.response.status == 401) {
              setErrorMessage("common.error");
            }
          } else if (error.request) {
            setErrorMessage("common.error");
          } else {
            setErrorMessage("common.error");
          }
        });
    } else {
      let url = "";
      switch (props.userType) {
        case "carriers":
          url = `carriers/${props.customer.uuid}/user/remove-permission`;
          break;

        case "billing-customers":
          url = `billing-customers/${props.customer.uuid}/user/remove-permission`;
          break;

        case "delivery-customers":
          url = `delivery-customers/${props.customer.uuid}/user/remove-permission`;
          break;
      }

      let dataToSend = {
        email: currentEmailToDelete,
      };
      let token = localStorage.getItem("token");

      axios
        .post(Config.getUrl(url), dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setIsOpenConfirmDelete(false);
          props.onDeleteEmail();
          var array: string[] = [...emails]; // make a separate copy of the array
          array = array.filter((e) => e != currentEmailToDelete);
          setEmails(array);
          addToast(t("user.deleted"), ToastTypes.success);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (error.response.status == 401) {
              setErrorMessage("common.error");
            }
          } else if (error.request) {
            setErrorMessage("common.error");
          } else {
            setErrorMessage("common.error");
          }
        });
      // extranet/:organizationUuid/carriers/:carrierUuid/user/remove-permission
      // extranet/:organizationUuid/billing-customers/:billing-customersUuid/user/remove-permission
      // extranet/:organizationUuid/delivery-customers/:delivery-customersUuid/user/remove-permission
    }
  };

  const updateRole = (role: string) => {
    setRole(role);
    setErrorMessage("");
  };

  useEffect(() => {
    if (props.userType == "carriers") {
      getDrivers();
    }

    if (props.customer.users == null) {
      setEmails([]);
    } else {
      setEmails(props?.customer?.users);
    }
  }, [props.customer?.users]);

  const getDrivers = () => {
    setIsDriversLoading(true);
    const token = localStorage.getItem("token");
    let baseUrl = "carriers";
    let url = `${baseUrl}/${props.customer.uuid}/drivers?page=1&limit=200000`;
    axios
      .get(Config.getUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsDriversLoading(false);
        setDrivers(res.data.content.items);
      })
      .catch(() => {
        setIsDriversLoading(false);
      });
  };

  return (
    <div className="popup">
      <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />
      <div className="title">
        {t("actions.edit")}{" "}
        {props.userType == "carriers" ? (props.isPumpers ? t("pumper") : t("carrier")) : t("client")}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="label">{t("name")}</div>
        <input type="text" disabled={true} value={props.customer?.name} placeholder={t("firstname")} />
        <p>{t(errors.firstname?.message)}</p>

        <div className="label">{t("reference")}</div>
        <input type="text" disabled={true} value={props.customer?.externalReference} placeholder={t("name")} />
        <p>{t(errors.lastname?.message)}</p>

        {props.userType == "carriers" ? (
          <>
            <div className="label">{t("menu.drivers")}</div>

            {drivers != null && drivers?.length > 0 ? (
              <table style={{ marginTop: 10, marginBottom: 10, width: "100%", textAlign: "center" }}>
                <thead>
                  <tr>
                    {Roles.hasRight(Scopes.SCOPE_EXTRANET_DRIVER_REMOVE) ? <th></th> : null}
                    <th>{t("name")}</th>
                    <th>{t("firstname")}</th>
                    <th>{t("account.phoneNumber")}</th>
                  </tr>
                </thead>
                <tbody>
                  {drivers?.map((driver: Driver) => {
                    return (
                      <tr key={driver.username}>
                        {Roles.hasRight(Scopes.SCOPE_EXTRANET_DRIVER_REMOVE) ? (
                          <td>
                            <Close
                              className="icon"
                              width="20"
                              height="20"
                              fill={"#90a0b7"}
                              onClick={() => onDeleteDriver(driver)}
                            />
                          </td>
                        ) : null}
                        <td>{driver.lastname} </td>
                        <td>{driver.firstname} </td>
                        <td>{driver.username} </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="customerEmail"> {isDriversLoading ? t("loading") : "Aucun chauffeur"} </div>
            )}
          </>
        ) : null}

        <div className="label">{t("emails")}</div>
        {emails?.map((email: string) => {
          return (
            <div className="customerEmail" key={email}>
              <Close className="icon" width="20" height="20" fill={"#90a0b7"} onClick={() => onDeleteEmail(email)} />
              <div> {email}</div>
            </div>
          );
        })}
        <div>{errorMessage}</div>

        <input
          type="text"
          value={currentEmail}
          name="currentEmail"
          placeholder={t("customers.emailPlaceholder")}
          ref={register}
          onChange={(e) => setEmail(e?.target.value.toString())}
        ></input>
        <p>{t(errors.currentEmail?.message)}</p>

        <div className="label">{t("role.title")}</div>
        <select
          name="role"
          onChange={(value) => updateRole(value.target.value.toString())}
          value={role.toString()}
          ref={register}
        >
          <option value="">{t("role.choose")}</option>
          <option value="user">{t("role.user")}</option>
          <option value="admin">{t("role.admin")}</option>
        </select>

        <p>{t(errors.role?.message)}</p>

        <button type="submit" disabled={isAddingLoading}>
          <div>{isAddingLoading ? t("saving") : t("actions.validate")}</div>
        </button>
      </form>

      <PopPop
        open={isOpenConfirmDelete}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenConfirmDelete(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <ConfirmPopup
          message={
            currentDriverToDelete != null
              ? t("drivers.deleteQuestion", { driver: currentDriverToDelete.username })
              : t("users.deleteQuestion", {
                  username: currentEmailToDelete,
                  workspacename: props.customer?.name,
                })
          }
          confirm={onDeleteOk}
          cancel={() => setIsOpenConfirmDelete(false)}
          cancelMessage={t("no")}
          confirmMessage={t("yes")}
        />
      </PopPop>
    </div>
  );
});

export default UpdateCustomer;
