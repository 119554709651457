import { Colors } from "../utils/Colors";
import { ToastTypes } from "./ToastTypes";

export default class Toast {
  id: number | undefined;
  title: string | undefined;
  description: string | undefined;
  backgroundColor: string | undefined;
  icon: string | undefined;

  constructor(title: string, description: string, type: ToastTypes) {
    this.title = title;
    this.description = description;
    this.backgroundColor = this.getBackgroundColor(type);
    // this.backgroundColor = type == ToastTypes.success ? "#27ae60" : "red";
  }

  getBackgroundColor(type: ToastTypes): string {
    switch (type) {
      case ToastTypes.success:
        return "#27ae60";
      case ToastTypes.error:
        return "red";
      case ToastTypes.info:
        return Colors.primary;
      default:
        return "white";
    }
  }
}
