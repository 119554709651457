import React, { useEffect, useState } from "react";
import "./Vehicles.scss";
import "../styles/table.scss";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Menu from "./Menu";
import TableStyles from "../styles/tableStyles";
import Config from "../Config";
import Vehicle from "../models/Vehicle";
import SearchBar from "./SearchBar";

import Roles from "../utils/Roles";
import { Scopes } from "../models/Scopes";
import PopPop from "react-poppop";
import PopupStyle from "../styles/popupContentStyle";
import AddVehicle from "./AddVehicle";
import ToastComponent from "./ToastComponent";
import Toast from "../models/Toast";
import { ToastTypes } from "../models/ToastTypes";
import { Colors } from "../utils/Colors";
import { useTranslation } from "react-i18next";

const Vehicles = React.memo(() => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [totalResource, setTotalResource] = useState(0);
  const [toasts, setToast] = useState<Toast[]>([]);

  const [filteredItems, setFilterereditems] = useState<Vehicle[]>([]);
  const [filterText, setFilterText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [sortColumn, setSortColumn] = useState("registrationPlate");
  const [sortDirection, setSortDirection] = useState("asc");

  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false);
  const [isUpdatingVehicle, setIsUpdatingVehicle] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState<Vehicle | undefined>(undefined);

  const columns: any[] = [
    {
      name: t("vehicles.immat"),
      selector: (row: Vehicle) => row.registrationPlate,
      id: "registrationPlate",
      cell: (row: any) => <div className="firstColumn">{row.registrationPlate}</div>,
      sortable: true,
      right: false,
    },
    {
      name: t("vehicles.label"),
      id: "externalReference",
      selector: (row: Vehicle) => row.externalReference,
      sortable: true,
      right: false,
    },

    {
      name: t("type"),
      id: "type",
      selector: (row: Vehicle) => <div>{t("vehicles.types." + row.type)}</div>,
      sortable: true,
      sortFunction: (rowA: Vehicle, rowB: Vehicle) => {
        if (rowA?.type != undefined && rowB?.type != undefined && rowA.type != undefined && rowB.type != undefined) {
          return t("vehicles.types." + rowA.type) > t("vehicles.types." + rowB.type) ? 1 : -1;
        } else {
          return 1;
        }
      },
      right: false,
    },

    {
      name: t("deliverynote.status.title"),
      id: "status",
      selector: (row: Vehicle) => <div className={`status ${row.status}`}>{t("vehicles.status." + row.status)}</div>,
      sortable: false,
      right: false,
    },
  ];

  if (!Roles.isCarrier() && !Roles.isPumper()) {
    columns.push({
      name: t("carrier"),
      id: "carrier.name",
      selector: (row: Vehicle) => row.carrier?.name,
      sortable: false,
      right: false,
    });
  }

  if (Roles.hasRight(Scopes.SCOPE_EXTRANET_VEHICLE_MANAGE) && Roles.isPumper()) {
    columns.unshift({
      name: "",

      id: "voir",
      selector: (row: Vehicle) => (
        <div className="table-link" onClick={() => openVehicleDetails(row)}>
          {t("see")}
        </div>
      ),
      sortable: false,
      right: false,
    });
  }

  useEffect(() => {
    setStatusFilter(["associated"]);
    fetchVehicles(1, perPage, "", ["associated"]);
  }, []);

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const openVehicleDetails = (vehicle: Vehicle) => {
    setCurrentVehicle(vehicle);
    setIsOpenAddPopup(true);
    setIsUpdatingVehicle(true);
  };
  const FilterInput = () => (
    <div className="row " style={{ marginLeft: 10 }}>
      <div style={{ marginRight: 30 }}>
        <label className="checkbox">
          <input
            style={{ marginTop: 17 }}
            name="vehicleStatus"
            type="radio"
            checked={statusFilter.filter((s) => s == "associated").length > 0}
            onClick={() => updateStatusFilter("associated", true)}
          />
          <span style={{ color: Colors.grey500 }}>{t("vehicles.status.associated")}</span>
        </label>
      </div>
      <label className="checkbox">
        <input
          style={{ marginTop: 17 }}
          name="vehicleStatus"
          type="radio"
          checked={statusFilter.filter((s) => s == "available").length > 0}
          onClick={() => updateStatusFilter("available", true)}
        />
        <span style={{ color: Colors.grey500 }}>{t("vehicles.status.available")}</span>
      </label>
    </div>
  );

  const updateStatusFilter = (status: string, isChecked: boolean) => {
    let newStatusFilter: string[] = statusFilter;
    if (isChecked) {
      newStatusFilter.push(status);
    } else {
      newStatusFilter = newStatusFilter.filter((s) => s != status);
      // setStatusFilter(newStatusFilter);
    }
    newStatusFilter = [status];
    setStatusFilter([status]);

    fetchVehicles(1, perPage, filterText, newStatusFilter);
  };

  const fetchVehicles = (
    page: number,
    newPerPage: number,
    newFilterText: string = filterText,
    newStatusFilter: string[] = statusFilter,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection
  ) => {
    setIsLoading(true);
    setCurrentPage(page);
    const token = localStorage.getItem("token");
    let url =
      "vehicles?page=" + page + "&limit=" + newPerPage + "&sort_by=" + newSortColumn + "&order_by=" + newSortDirection;

    if (newFilterText != filterText) {
      setFilterText(newFilterText);
    }

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    if (newFilterText.length > 0) {
      url += "&search=" + newFilterText;
    }

    if (newStatusFilter.length == 1) {
      url += "&status=" + newStatusFilter.join(",");
    }

    axios
      .get(Config.getUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setFilterereditems(res.data.content.items);
        setTotalResource(res.data.meta.totalResource);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };

  const handlePageChange = (pageNumber: number) => {
    fetchVehicles(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchVehicles(1, newPerPage);
  };

  const openAddPopup = () => {
    setIsUpdatingVehicle(false);
    setCurrentVehicle(undefined);
    setIsOpenAddPopup(true);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == "asc") {
        newSortOrder = "desc";
      } else {
        newSortOrder = "asc";
      }
    } else {
      newSortOrder = "asc";
    }
    fetchVehicles(1, perPage, filterText, statusFilter, data.id, newSortOrder);
  };

  const onCreateVehicleSuccess = () => {
    fetchVehicles(currentPage, perPage);
    setIsOpenAddPopup(false);

    if (isUpdatingVehicle) {
      addToast(t("vehicles.updated"), ToastTypes.success);
    } else {
      addToast(t("vehicles.created"), ToastTypes.success);
    }

    setIsUpdatingVehicle(false);
    setCurrentVehicle(undefined);
  };

  return (
    <div className="vehicles">
      <Menu selectedPage="vehicles"></Menu>

      <div className={"right table-container"}>
        <div className="title">{t("menu.vehicles")}</div>
        <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

        <div className="filterBar row align-center">
          <SearchBar
            placeholder={t("vehicles.search")}
            onFilter={(value) => fetchVehicles(currentPage, perPage, value, statusFilter)}
          />

          <FilterInput />
        </div>

        <div className="buttonContainer">
          <div className="nbElements">
            {totalRows}/{totalResource} {totalResource > 1 ? t("results") : t("result")}
          </div>
          {!isLoading && Roles.isPumper() ? (
            <button className="validateBtn right-btn" onClick={() => openAddPopup()}>
              <h6>{t("vehicles.add.title")}</h6>
            </button>
          ) : null}
        </div>

        {isLoading ? (
          <div>{t("loading")}</div>
        ) : (
          <DataTable
            className="table"
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            data={filteredItems}
            noDataComponent={<div className="noResults">{t("vehicles.noOne")}</div>}
            defaultSortAsc={sortDirection == "asc"}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            paginationComponentOptions={{
              rowsPerPageText: t("table.perpage"),
              rangeSeparatorText: t("table.on"),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
          />
        )}
      </div>

      <PopPop
        open={isOpenAddPopup}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenAddPopup(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <AddVehicle
          onsuccess={() => onCreateVehicleSuccess()}
          isUpdate={isUpdatingVehicle}
          currentVehicle={currentVehicle}
        />
      </PopPop>
    </div>
  );
});

export default Vehicles;
