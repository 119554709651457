import React from "react";
import "./ConfirmPopup.scss";
import "../styles/popup.scss";

interface ConfirmPopupProps {
  message: string;
  cancel(): void;
  confirm(): void;
  close?(): void;
  confirmMessage: string;
  cancelMessage: string;
  closeMessage?: string;
}

const ConfirmPopup = React.memo((props: ConfirmPopupProps) => {
  const cancel = () => {
    props.cancel();
  };

  const confirm = () => {
    props.confirm();
  };

  const close = () => {
    if (props.close) {
      props.close();
    }
  };

  return (
    <div className={"popup confirmPopup"}>
      <div className="title">{props.message}</div>

      <div className={"row"}>
        <button type="button" className="cancel" onClick={cancel}>
          <div>{props.cancelMessage}</div>
        </button>
        <button type="button" onClick={confirm}>
          <div>{props.confirmMessage}</div>
        </button>
      </div>
      {props.close != null ? (
        <div className="close" onClick={close}>
          <div>{props.closeMessage}</div>
        </div>
      ) : null}
    </div>
  );
});
export default ConfirmPopup;
