/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Config from "../Config";
import axios from "axios";
// import { useHistory } from "react-router";
import Roles from "../utils/Roles";
import { Scopes } from "../models/Scopes";
import moment from "moment";
import eventBus from "./EventBus";
import pusherJs, { Channel } from "pusher-js";
import Workspace from "../models/Workspace";
import ToastComponent from "./ToastComponent";
import Toast from "../models/Toast";
import { ToastTypes } from "../models/ToastTypes";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AutoRefreshDnDeparture = React.memo(() => {
  // const history = useHistory();
  const [toasts, setToast] = useState<Toast[]>([]);
  const { t } = useTranslation();

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const location = useLocation();

  useEffect(() => {
    eventBus.on("updateWorkspace", (data: any) => {
      let workspace = Roles.getCurrentWorkspace();

      subscribe(workspace);
      fetchDns(1, 10);
    });

    let workspace = Roles.getCurrentWorkspace();
    if (location.pathname != "/deliverynotes-departure") {
      subscribe(workspace);
    }
  }, [location]);

  const subscribe = (workspace: Workspace | null) => {
    let currentWindow: any = window;

    if (workspace?.organization?.uuid != undefined && Roles.isSite()) {
      let pusher: any = null;
      if (currentWindow.Pusher.instances.length > 0) {
        pusher = currentWindow.Pusher.instances[0];
      } else {
        let pusherKey = process.env.REACT_APP_PUSHER_KEY ?? "";
        pusher = new pusherJs(pusherKey, {
          cluster: "eu",
        });
      }

      // let pusheKey = process.env.REACT_APP_PUSHER_KEY ?? "";
      // const pusher = new pusherJs(pusheKey, {
      //   cluster: "eu",
      // });

      pusher.allChannels().forEach((channel: Channel) => {
        pusher.unsubscribe(channel.name);
      });
      const channel1 = pusher.subscribe(workspace?.organization?.uuid);

      channel1.bind("new_delivery_note", function (data: any) {
        let currentWP = Roles.getCurrentWorkspace();
        if (currentWP != null) {
          addToast(t("deliverynote.newDn"), ToastTypes.info);

          fetchDns(1, 10);
        }
      });
    }
  };

  const fetchDns = async (page: number, newPerPage: number) => {
    if (Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_NOTE_VIEW) && Roles.hasWithdrawalPoint()) {
      const token = localStorage.getItem("token");

      let url = "delivery-notes?page=" + page + "&limit=" + newPerPage;

      let dataToSend: any = {};

      dataToSend.collectedByClient = true;

      let startDate = new Date();
      dataToSend.beginDate = moment(startDate).format("YYYYMMDD");

      await axios
        .post(Config.getUrl(url), dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.meta.totalResource > 0) {
            //pastille rouge à mettre
            localStorage.setItem("totalCollectedBlToSign", res.data.meta.totalResource);
            // let newDn = res.data.content.items[0];
            // history.push(`/deliveryNotes/${newDn.uuid}/view/true`);
          } else {
            localStorage.setItem("totalCollectedBlToSign", "0");
          }
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    fetchDns(1, 10);
    // setInterval(() => addToast(t("deliverynote.newDn"), ToastTypes.info), 1*1000);
    setInterval(() => fetchDns(1, 10), 30 * 60 * 1000);
  }, []);

  return (
    <>
      <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={3000} key={1} />
    </>
  );
});
export default AutoRefreshDnDeparture;
