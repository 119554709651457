import BillingCustomer from "./BillingCustomer";
import Cancelation from "./Cancelation";
import Carrier from "./Carrier";
import Vehicle from "./Vehicle";

export default class Deliverynote {
  uuid: string | undefined;
  billingCustomer: BillingCustomer = new BillingCustomer();
  delayReason: string | undefined;
  deliveryCustomerComment: string | undefined;
  driverComment: string | undefined;
  cancelation: Cancelation | undefined;

  externalReference: string | undefined;
  loadedAt: Date | undefined;
  status: string | undefined;
  folioReference: string | undefined;

  worksiteAccess: boolean | undefined;
  presenceOfElectricalLines: boolean | undefined;
  parkingVehicle: boolean | undefined;
  otherRisks: string | undefined;
  machineStabilization: boolean | undefined;
  worksiteWithGutter: boolean | undefined;

  completedByApp: boolean | undefined;
  updatedAt: Date | undefined;
  collectedByClient?: boolean;

  additionalDocs: DigitalNote[] | undefined;
  deliveryCustomer: BillingCustomer = new BillingCustomer();
  site: BillingCustomer = new BillingCustomer();

  products: Product[] | undefined;
  services: Service[] | undefined;

  estimatedDeliveryTime: Date | undefined;
  worksiteArrivalTime: Date | undefined;
  worksiteUnloadingEndTime: Date | undefined;
  worksiteUnloadingStartTime: Date | undefined;
  worksiteDepartureTime: Date | undefined;

  estimatedPumpingDate: Date | undefined | null;
  pumpingDate: Date | undefined | null;
  worksitePumpingEndTime: Date | undefined | null;
  worksitePumpingStartTime: Date | undefined | null;
  worksiteInstallationEndTime: Date | undefined | null;
  worksiteInstallationStartTime: Date | undefined | null;
  realPumpedQuantity: number | undefined;
  estimatedPumpedQuantity: number | undefined;

  signature: Signature | undefined;
  digitalNote: DigitalNote | undefined;
  driverPhotos?: DriverPhoto[];

  type: string | undefined;

  carrier: Carrier | undefined;
  pumper: Carrier | undefined;
  vehicle: Vehicle | undefined;

  tracking: Tracking[] | undefined;
  pump: Pump | undefined;

  digitalDisclaimer: DigitalNote | undefined;

  transportLetter?: string;

  refusal: Refusal | undefined;

  transportZone: string | undefined;
  purchaseZone: string | undefined;
  orderReference: string | undefined;
  customField1: string | undefined;
  roadTrip?: Position[];
  co2Emission?: number;
  color?: string;
}
export class DigitalNote {
  name: string | undefined;
  id: string | undefined;
  url: string | undefined;
}

class Refusal {
  comment: string | undefined;
  reason: string | undefined;
}

class DriverPhoto {
  name?: string;
  url?: string;
}
class Signature {
  signedAt: Date | undefined;
  signerName: string | undefined;

  file: File | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}

class File {
  name: string | undefined;
  url: string | undefined;
}

class Product {
  code: string | undefined;
  designation: string | undefined;
  quantity: number | undefined;
  unit: string | undefined;
  returnable: boolean | undefined;
  refusedQuantity?: number;
}

class Service {
  code: string | undefined;
  name: string | undefined;
  quantity: string | undefined;
  unit: string | undefined;
}
class Tracking {
  step?: DeliveryNoteTrackingSteps;
  date?: Date;
}

class Pump {
  externalReference: string | undefined;
  pumpType: string | undefined;
}

export enum DeliveryNoteTrackingSteps {
  SUPPORTED = "supported",
  CHARTERED = "chartered",
  ARRIVAL_ON_WORKSITE = "arrivalOnWorksite",
  START_OF_UNLOADING = "startOfUnloading",
  END_OF_UNLOADING = "endOfUnloading",
  START_OF_INSTALLATION = "startOfInstallation",
  END_OF_INSTALLATION = "startOfInstallation",

  START_OF_PUMPING = "startOfPumping",
  END_OF_PUMPING = "endOfPumping",
  BACK_TO_SITE = "backToSite",
  SIGNATURE = "signature",
}

export enum PumpingNoteStatus {
  ACCEPTED = "accepted",
  ACCEPTED_ORDER = "accepted_order",
  CANCELED = "canceled",
  CONFIRMED_ORDER = "confirmed_order",
  DELETED = "deleted",
  INACTIVE = "inactive",
  INSTALLED = "installed",
  ORDERED = "ordered",
  PUMPING = "pumping",
  REFUSED = "refused",
  REFUSED_ORDER = "refused_order",
  VALIDATED = "validated",
}

export interface Position {
  latitude: number;
  longitude: number;
  timestamp: Date;
}
