import React, { useEffect, useState } from "react";
import "./SelectWorkspace.scss";
import { ReactComponent as User } from "../assets/svg/user.svg";
import { ReactComponent as Up } from "../assets/svg/arrow-ios-upward.svg";
import { ReactComponent as Down } from "../assets/svg/arrow-ios-downward.svg";
import Roles from "../utils/Roles";
import Workspace from "../models/Workspace";
import WorkspaceByType from "../models/WorkspacebByType";
import { useHistory } from "react-router-dom";
import Filters from "../utils/Filters";
import { FilterType } from "../models/FilterType";
import { WorkspaceTypes } from "../models/WorkspaceTypes";
import { useTranslation } from "react-i18next";

function SelectWorkspace(props: any) {
  const [currentWorkspace, setcurrentWorkspace] = useState<Workspace | null>();
  const [isOpenSelectWorkspace, setIsOpenSelectWorkspace] = useState(false);
  const [workspaces, setWorkspaces] = useState<WorkspaceByType[]>([]);

  const history = useHistory();
  const { t } = useTranslation();

  const onChangeWorkspace = (workspace: Workspace) => {
    setcurrentWorkspace(workspace);
    setIsOpenSelectWorkspace(false);
    Roles.setWorkspace(workspace);
    Filters.clear(FilterType.DeliveryNote);
    if (props.parent == "menu") {
      history.push("/deliverynotes");
      location.reload();
    }
  };

  const openSelectWorkspace = () => {
    setIsOpenSelectWorkspace(!isOpenSelectWorkspace);
  };

  useEffect(() => {
    let workspaces = Roles.getWorkspaces();
    setWorkspaces(workspaces);
    if (props.parent == "menu") {
      let curretntWp = Roles.getCurrentWorkspace();
      setcurrentWorkspace(curretntWp);
    } else {
      if (workspaces.length > 1) {
        let wpsOrdered = workspaces
          ?.filter((w) => w.type != undefined)
          .sort((a, b) => {
            return getWorkspaceTypeOrder(a.type) - getWorkspaceTypeOrder(b.type);
          });

        let firstType = wpsOrdered[0];
        if (firstType != null && firstType?.workspaces != null && firstType?.workspaces.length > 0) {
          let firstWorspace = firstType.workspaces[0];
          setcurrentWorkspace(firstWorspace);
        }
      }
    }
  }, []);

  const getWorkspaceTypeOrder = (type: string | undefined) => {
    switch (type) {
      case WorkspaceTypes.affiliate:
        return 1;
      case WorkspaceTypes.region:
        return 2;
      case WorkspaceTypes.site:
        return 3;
      case WorkspaceTypes.delivery:
        return 4;
      case WorkspaceTypes.billing:
        return 5;
      case WorkspaceTypes.carrier:
        return 6;
      case WorkspaceTypes.pumper:
        return 7;
    }

    return 0;
  };

  return (
    <div className="">
      <div className={"workspace selected"} onClick={() => openSelectWorkspace()}>
        <div className="selectContent">
          <div className="selectOrganizationName">{currentWorkspace?.organization?.name}</div>
          <div className="selectorganizationRole">{t(Roles.getLibelle(currentWorkspace?.scopes))}</div>
        </div>
        <div className="buttonsSelect">
          <div>
            <Up className="" width="25" height="20" fill={"#90a0b7"} />
          </div>
          <div>
            <Down className="" width="25" height="20" fill={"#90a0b7"} />
          </div>
        </div>
      </div>
      {isOpenSelectWorkspace ? (
        <div className={"selectWorkspace en"}>
          <div className="user">
            <User className="icon" width="25" height="20" fill={"#90a0b7"} />
            <div>{Roles.getEmail()}</div>
          </div>
          {workspaces
            ?.filter((w) => w.type != undefined)
            .sort((a, b) => {
              return getWorkspaceTypeOrder(a.type) - getWorkspaceTypeOrder(b.type);
            })
            .map((workspaceByType) => {
              return (
                <div key={workspaceByType.type}>
                  {Roles.getOrganizationDisplayType(workspaceByType.type)?.toString() != undefined && (
                    <div className="title">{t(Roles.getOrganizationDisplayType(workspaceByType.type))}</div>
                  )}
                  {workspaceByType.workspaces?.map((workspace) => {
                    return (
                      <div
                        key={workspace.organization?.uuid}
                        className={
                          currentWorkspace?.organization?.uuid == workspace?.organization?.uuid
                            ? "workspace selected "
                            : "workspace"
                        }
                        onClick={() => onChangeWorkspace(workspace)}
                      >
                        <div className="selectContent">
                          <div className="selectOrganizationName">{workspace?.organization?.name}</div>
                          <div className="selectorganizationRole">{t(Roles.getLibelle(workspace?.scopes))}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
}

export default SelectWorkspace;
