import React, { useEffect, useState } from "react";
import "./Login.scss";

import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

import axios from "axios";
import Config from "../Config";
import Roles from "../utils/Roles";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../logo.svg";
import { Colors } from "../utils/Colors";

function LoginForOneDeliveryNote(props: { isPumping: boolean }) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingNewCode, setIsLoadingNewCode] = useState(false);
  const [isNewCodeSend, setNewCodeSend] = useState(false);
  const [firstToken, setFirstToken] = useState("");
  const [isFirstToken, setIsFirstToken] = useState(true);
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    localStorage.clear();

    let token = new URLSearchParams(window.location.search).get("token");
    if (token != null) {
      try {
        let decoded = atob(token);
        onSubmitToken(decoded);
      } catch (e) {
        setErrorMessage("errors.link.invalid");
      }
    } else {
      setErrorMessage("errors.link.invalid");
    }
  }, []);

  const onSubmitToken = (token: string) => {
    setFirstToken(token);
    localStorage.setItem("token", token);

    if (token != null) {
      let decoded = jwt_decode<any>(token?.toString());
      localStorage.setItem("email", decoded.sub);
      // localStorage.setItem("email", dataToSend.username);
      let uuid = decoded?.data.deliveryNote?.uuid;
      if (props.isPumping) {
        uuid = decoded?.data.pumpingNote?.uuid;
      }
      getScopes(token, true, uuid);
    }
  };

  const getScopes = (token: string, isFirstToken: boolean, deliveryNoteuuid: string) => {
    axios
      .get(Config.get_API_URL() + "me?d=" + new Date().toISOString(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Roles.saveWorkspaces(res.data.content.workspaces);
        if (props.isPumping) {
          history.push("/pumpingNotes/" + deliveryNoteuuid + "/preview");
        } else {
          history.push("/deliveryNotes/" + deliveryNoteuuid + "/preview");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            if (isFirstToken) {
              setErrorMessage("errors.link.invalid");
            } else {
              setErrorMessage("common.error");
            }
          } else if (error.response.status == 404) {
            setErrorMessage("common.error");
          } else if (error.response.status == 403) {
            setErrorMessage("errors.link.invalid");
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const askNewLink = () => {
    setIsLoadingNewCode(true);
    setIsFirstToken(false);
    axios
      .get(Config.get_API_EXTRANET_URL() + "auth/token/refresh", {
        headers: {
          Authorization: `Bearer ${firstToken}`,
        },
      })
      .then((res) => {
        setIsLoadingNewCode(false);
        setNewCodeSend(true);

        const token = res.data.content.refreshToken;
        localStorage.setItem("token", token);

        let decoded = jwt_decode<any>(token?.toString());

        localStorage.setItem("email", decoded.sub);
        getScopes(token, true, decoded.data.deliveryNote.uuid);
      })
      .catch((error) => {
        setIsLoadingNewCode(false);
        setNewCodeSend(true);
        setIsError(true);
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage("errors.link.invalid");
          } else if (error.response.status == 404) {
            setErrorMessage("common.error");
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  return (
    <div className="login">
      <div className="leftImage"></div>
      <div className="right">
        <div className="content">
          <div style={{ textAlign: "center" }}>
            <Logo fill={Colors.primary}></Logo>
          </div>
          <h1>{t("login.goToDn")} </h1>

          {isFirstToken ? (
            <div>
              {errorMessage == "" ? (
                <div>{t("loading")} </div>
              ) : (
                <>
                  <div>
                    <div className="errorMessage">{t("login.expiredLink.title")} Lien de connextion expiré</div>
                    <div>{t("login.expiredLink.description1")}</div>
                    <div>{t("login.expiredLink.description2")}</div>
                  </div>
                </>
              )}

              {errorMessage != "" ? (
                <button onClick={() => askNewLink()} className="validateBtn">
                  <h6>{t("login.sendNewLink")}</h6>
                </button>
              ) : null}
            </div>
          ) : (
            <div>
              {isLoadingNewCode ? <div>{t("loading")} </div> : null}
              {isNewCodeSend && !isError ? (
                <>
                  <div>
                    <div className="errorMessage">{t("login.newLinkSend")}</div>
                    <div>{t("login.newLinkSendDescription")}</div>
                  </div>
                </>
              ) : null}
              {isNewCodeSend && isError ? (
                <div>
                  <div className="errorMessage">{t(errorMessage)}</div>
                  <div>{t("common.error")}</div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginForOneDeliveryNote;
