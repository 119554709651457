const tableStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      color: "#90A0B7",
      backgroundColor: "#f5f6f9",
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
    },
  },
  cells: {
    style: {
      color: "#333333",
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
    },
  },
};

export default tableStyles;
