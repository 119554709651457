import React, { useEffect, useRef, useState } from "react";
import TableStyles from "../styles/tableStyles";

import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from "react-leaflet";
import DeliveryNoteApi from "../api/delivery-notes-api";
import Menu from "./Menu";
import { NoteVehicleLocation } from "../models/Geolocation";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Roles from "../utils/Roles";
import DataTable, { TableColumn } from "react-data-table-component";
import Config from "../Config";
import axios from "axios";
import Deliverynote from "../models/Deliverynote";
import { Colors, geolocationColors } from "../utils/Colors";
import L from "leaflet";
import pusherJs from "pusher-js";

const Map = React.memo(() => {
  const [markers, setMarkers] = useState<NoteVehicleLocation[]>([]);
  const markersRef = useRef(markers);
  const [dns, setDns] = useState<any[]>([]);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const { t } = useTranslation();

  const refresh = () => {
    DeliveryNoteApi.getCurrentVehiculesLocation().then((res) => {
      setMarkers(res.data.content);
    });
    fetchDns();
  };
  useEffect(() => {
    if (Roles.isUserSite()) {
      refresh();
      subscribeToEvents();
    } else {
      alert("You don't have the right to access this page. Please contact your administrator. ");
      history.push("/deliverynotes");
    }
  }, []);

  useEffect(() => {
    markersRef.current = markers;
  }, [markers]);
  const subscribeToEvents = () => {
    let workspace = Roles.getCurrentWorkspace();

    if (workspace?.organization?.uuid != undefined) {
      let currentWindow: any = window;

      let pusher: any = null;
      if (currentWindow.Pusher.instances.length > 0) {
        pusher = currentWindow.Pusher.instances[0];
      } else {
        let pusherKey = process.env.REACT_APP_PUSHER_KEY ?? "";
        pusher = new pusherJs(pusherKey, {
          cluster: "eu",
        });
      }

      const channel1 = pusher.subscribe(workspace?.organization?.uuid);
      channel1.bind("truck_new_location", function (data: any) {
        let message = data.message;
        let finalData = JSON.parse(message);
        let vehicles = [...markersRef.current];
        let updatedVehicle = vehicles.find((marker) => marker.noteUuid == finalData.noteUuid);
        if (updatedVehicle != undefined) {
          updatedVehicle.geolocation = [...finalData.geolocation, ...updatedVehicle.geolocation];
          vehicles = vehicles.filter((marker) => marker.noteUuid != finalData.noteUuid);
          vehicles.push(updatedVehicle);
          setMarkers(vehicles);
        } else {
          //ATTENTION NOUVEAU CAMION : comment faire ?
          //refresh de la liste des camions ?
          refresh();
        }
      });
    }
  };

  const goToDl = (id: string | undefined): string => {
    // let url = "test" == DeliveryNoteTypes.pumping ? "pumpingnotes" : "deliverynotes";
    let url = "test";
    // history.push(`/${url}/${id}/view`);
    return `/${url}/${id}/view`;
  };

  const getProduct = (row: Deliverynote) => {
    if (row?.products != undefined) {
      let hasReturnableProduct = row?.products?.filter((p) => p.returnable).length > 0;
      if (hasReturnableProduct) {
        return row?.products?.filter((p) => p.returnable)[0];
      }
      const products = row?.products
        ?.filter(
          (p) =>
            p.unit != undefined &&
            (p.unit.toLowerCase() === "M3".toLowerCase() ||
              p.unit.toLowerCase() === "TON".toLowerCase() ||
              p.unit.toLowerCase() === "T".toLowerCase())
        )
        .sort((a, b) => {
          return a.quantity != undefined && b.quantity != undefined ? a.quantity - b.quantity : 0;
        });

      if (products != undefined && products?.length > 0) {
        return products[0];
      } else {
        return null;
      }
    }
    return null;
  };

  const [count, setCount] = useState(0);

  const columns: TableColumn<Deliverynote>[] = [
    {
      // isAuthorized: true,
      id: "folioReference",
      // name: props.type == DeliveryNoteTypes.pumping ? "Num BMD" : "Num BL",
      cell: (row: any) => (
        <Link
          // onClick={() => updateFilters(row.uuid, DnFiltersType.LastOpennedDnUuid)}
          className="table-link"
          to={goToDl(row.uuid)}
          style={{ textDecoration: "underline" }}
        >
          {row.folioReference != undefined ? row?.folioReference : row?.externalReference}
        </Link>
      ),
      sortable: true,
      reorder: false,
      omit: false,
    },
    {
      name: t("vehicles.label"),
      id: "registrationPlate",
      // selector: (row: Deliverynote) => {
      //   <div style={{ color: row.color }}>{row.vehicle?.registrationPlate}</div>;
      // },
      cell: (row: Deliverynote) => (
        <div
          onClick={() => {
            setSelectedRow(row);
            setCount((count) => count + 1);
          }}
          style={{ color: row.color }}
        >
          {row.vehicle?.registrationPlate}
        </div>
      ),

      sortable: false,
      right: false,
    },
    {
      name: t("deliverynote.status.title"),
      id: "status",
      reorder: false,
      cell: (row: Deliverynote) => (
        <div
          style={{ color: row.color }}
          onClick={() => {
            setSelectedRow(row);
            setCount((count) => count + 1);
          }}
        >
          {t("deliverynote.status." + row.status)}
        </div>
      ),
      sortable: false,
      sortFunction: (rowA: any, rowB: any) => {
        if (t("deliverynote.status." + rowA.status) < t("deliverynote.status." + rowB.status)) {
          return 1;
        } else return -1;
      },
    },

    {
      // isAuthorized: !Roles.isSite(),
      name: t("site"),
      id: "site",

      cell: (row: Deliverynote) => (
        <div
          onClick={() => {
            setSelectedRow(row);
            setCount((count) => count + 1);
          }}
          style={{ color: row.color }}
        >
          {row?.site?.name}
        </div>
      ),
      sortable: false,
      right: false,
    },
    {
      // isAuthorized: !(Roles.isUserDelivery() || Roles.isUserBilling()),
      name: t("customer"), //"Client",
      id: "billingCustomerName",
      cell: (row: Deliverynote) => (
        <div
          onClick={() => {
            setSelectedRow(row);
            setCount((count) => count + 1);
          }}
          style={{ color: row.color }}
        >
          {row?.billingCustomer?.name}
        </div>
      ),
      reorder: true,
      sortable: false,
      right: false,
    },
    {
      name: t("delivery"),
      id: "deliveryCustomer",
      cell: (row: Deliverynote) => (
        <div
          onClick={() => {
            setSelectedRow(row);
            setCount((count) => count + 1);
          }}
          style={{ color: row.color }}
        >
          {row?.deliveryCustomer?.name}
        </div>
      ),
      sortable: false,
      right: false,
      reorder: true,
    },
    {
      name: t("deliverynote.product"),
      id: "product",
      cell: (row: Deliverynote) => (
        <div
          onClick={() => {
            setSelectedRow(row);
            setCount((count) => count + 1);
          }}
          style={{ color: row.color }}
        >
          {getProduct(row)?.designation}
        </div>
      ),
      sortable: false,
      right: false,
      reorder: true,
    },

    {
      name: t("deliverynote.productQuantity"), // "Quantité",

      id: "productQuantity",
      cell: (row: Deliverynote) => (
        <div
          onClick={() => {
            setSelectedRow(row);
            setCount((count) => count + 1);
          }}
          style={{ color: row.color }}
        >
          {getProduct(row)?.quantity}
        </div>
      ),
      sortable: false,
      right: false,
      reorder: true,
    },

    {
      name: t("deliverynote.productUnit"), // "Unité",
      id: "productUnit",
      cell: (row: Deliverynote) => (
        <div
          onClick={() => {
            setSelectedRow(row);
            setCount((count) => count + 1);
          }}
          style={{ color: row.color }}
        >
          {getProduct(row)?.unit}
        </div>
      ),
      sortable: false,
      right: false,
      reorder: true,
    },
  ];

  useEffect(() => {
    if (dns.length == 0 || markers.length == 0) {
      return;
    }
    if (markers[0].color != undefined) {
      return;
    }
    let newMarkers: NoteVehicleLocation[] = [...markers];
    let newDns: any[] = [...dns];
    newMarkers.forEach((marker: NoteVehicleLocation, index: number) => {
      marker.color = index < geolocationColors.length ? geolocationColors[index] : Colors.primary;
      let currentDn = newDns.find((dn: Deliverynote) => dn.uuid == marker.noteUuid);
      if (currentDn != undefined) {
        currentDn.color = geolocationColors.length ? geolocationColors[index] : Colors.primary;
      }
    });
    setMarkers(newMarkers);
    setDns(newDns);
  }, [dns, markers]);

  const fetchDns = async () => {
    const token = localStorage.getItem("token");

    let baseUrl = "delivery-notes";
    let url = `${baseUrl}?page=${1}&limit=${200}&sort_by=${"loadedAt"}&order_by=${"desc"}`;
    let dataToSend: any = {};

    // {"status":["chartered","delivering"],"beginDate":"20230228","endDate":"20230228"}

    dataToSend.status = ["chartered", "delivering"];

    dataToSend.beginDate = moment().format("YYYYMMDD");

    dataToSend.endDate = moment().format("YYYYMMDD");

    await axios
      .post(Config.getUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setDns(res.data.content.items);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };

  return (
    <div className="deliveryNote">
      <Menu selectedPage="deliveryNote"></Menu>
      <div className="right">
        <div className="title">{t("menu.map")}</div>

        <div style={{ height: "60%", width: "100%" }}>
          <MapContainer
            center={[48.866667, 2.333333]}
            zoom={5}
            scrollWheelZoom={false}
            style={{ position: "inherit", height: "100%", width: "100%" }}
          >
            <InsideMap markers={markers} selectedDn={selectedRow} count={count} />
          </MapContainer>
        </div>
        <div className="nbElements">
          {markers.length} {markers.length > 1 ? t("menu.vehicles") : t("deliverynote.vehicle")}
        </div>

        {isLoading ? (
          <div>{t("loading")}</div>
        ) : (
          <DataTable
            className="table"
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            data={dns}
            noDataComponent={<div className="noResults">{t("vehicles.noOne")}</div>}
            // defaultSortAsc={sortDirection == "asc"}
            // defaultSortFieldId={sortColumn}
            // onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            sortServer
            onRowClicked={(row) => {
              setSelectedRow(row);
              setCount((count) => count + 1);
            }}
            // pagination
            // paginationServer
            // paginationTotalRows={totalRows}
            // paginationDefaultPage={currentPage}
            // onChangeRowsPerPage={handlePerRowsChange}
            // onChangePage={handlePageChange}
            // paginationPerPage={perPage}
            // paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            // paginationComponentOptions={{
            //   rowsPerPageText: t("table.perpage"),
            //   rangeSeparatorText: t("table.on"),
            //   noRowsPerPage: false,
            //   selectAllRowsItem: false,
            //   selectAllRowsItemText: "All",
            // }}
          />
        )}
      </div>
    </div>
  );
});

const InsideMap = React.memo((props: { markers: any; selectedDn: Deliverynote; count: number }) => {
  const map = useMap();
  const { t } = useTranslation();

  const [isFistLoad, setIsFirstLoad] = useState(true);
  useEffect(() => {
    if (isFistLoad && props.markers.length > 0) {
      let bounds = props.markers.map((marker: any) => {
        return [marker.geolocation[0].coords.latitude, marker.geolocation[0].coords.longitude];
      });
      map.fitBounds(bounds, { padding: [50, 50], maxZoom: 13 });
      setIsFirstLoad(false);
    }
  }, [props.markers]);

  useEffect(() => {
    if (props.selectedDn != undefined) {
      let marker = props.markers.find((m: NoteVehicleLocation) => m.noteUuid == props.selectedDn.uuid);

      if (marker != undefined) {
        var vehicleBounds = L.latLng(
          marker.geolocation[0].coords.latitude,
          marker.geolocation[0].coords.longitude
        ).toBounds(2000);
        map.fitBounds(vehicleBounds, { padding: [50, 50], maxZoom: 14 });
      }
    }
  }, [props.selectedDn, props.count]);

  const to = (route: string) => {
    return "/" + route;
  };

  const getMarker = (color?: string) => {
    return `data:image/svg+xml;utf8,${encodeURIComponent(`<?xml version="1.0" encoding="iso-8859-1"?>
    <svg fill="${color}" width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_437_985)">
    <path d="M15.8333 4.16616H14.1667V3.33282C14.1667 2.66978 13.9033 2.0339 13.4344 1.56506C12.9656 1.09622 12.3297 0.832825 11.6667 0.832825H2.5C1.83696 0.832825 1.20107 1.09622 0.732233 1.56506C0.263392 2.0339 0 2.66978 0 3.33282L0 15.8328H1.70083C1.67939 15.9707 1.66797 16.11 1.66667 16.2495C1.66667 17.023 1.97396 17.7649 2.52094 18.3119C3.06792 18.8589 3.80979 19.1662 4.58333 19.1662C5.35688 19.1662 6.09875 18.8589 6.64573 18.3119C7.19271 17.7649 7.5 17.023 7.5 16.2495C7.49869 16.11 7.48727 15.9707 7.46583 15.8328H12.5342C12.5127 15.9707 12.5013 16.11 12.5 16.2495C12.5 17.023 12.8073 17.7649 13.3543 18.3119C13.9013 18.8589 14.6431 19.1662 15.4167 19.1662C16.1902 19.1662 16.9321 18.8589 17.4791 18.3119C18.026 17.7649 18.3333 17.023 18.3333 16.2495C18.332 16.11 18.3206 15.9707 18.2992 15.8328H20V8.33282C19.9987 7.22816 19.5593 6.16912 18.7782 5.38801C17.997 4.60689 16.938 4.16748 15.8333 4.16616ZM15.8333 5.83282C16.4964 5.83282 17.1323 6.09622 17.6011 6.56506C18.0699 7.0339 18.3333 7.66978 18.3333 8.33282V9.16616H14.1667V5.83282H15.8333ZM5.83333 16.2495C5.83333 16.581 5.70164 16.899 5.46722 17.1334C5.2328 17.3678 4.91485 17.4995 4.58333 17.4995C4.25181 17.4995 3.93387 17.3678 3.69945 17.1334C3.46503 16.899 3.33333 16.581 3.33333 16.2495C3.33385 16.1071 3.3601 15.9659 3.41083 15.8328H5.75583C5.80657 15.9659 5.83282 16.1071 5.83333 16.2495ZM12.5 14.1662H1.66667V3.33282C1.66667 3.11181 1.75446 2.89985 1.91074 2.74357C2.06702 2.58729 2.27899 2.49949 2.5 2.49949H11.6667C11.8877 2.49949 12.0996 2.58729 12.2559 2.74357C12.4122 2.89985 12.5 3.11181 12.5 3.33282V14.1662ZM16.6667 16.2495C16.6667 16.581 16.535 16.899 16.3005 17.1334C16.0661 17.3678 15.7482 17.4995 15.4167 17.4995C15.0851 17.4995 14.7672 17.3678 14.5328 17.1334C14.2984 16.899 14.1667 16.581 14.1667 16.2495C14.167 16.1071 14.1933 15.9659 14.2442 15.8328H16.5892C16.64 15.9659 16.6663 16.1071 16.6667 16.2495ZM14.1667 14.1662V10.8328H18.3333V14.1662H14.1667Z" />
    </g>
    <defs>
    <clipPath id="clip0_437_985">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    `)}`;
  };

  const getIcon = (color?: string) => {
    const BoatIcon = L.icon({
      iconUrl: getMarker(color),
      iconSize: [20, 20],
      iconAnchor: [10, 10],
      popupAnchor: [0, 0],
    });

    return BoatIcon;
  };

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {props.markers.map((truck: NoteVehicleLocation, index: number) => {
        return (
          <Polyline
            key={"line" + index}
            pathOptions={{ color: truck.color }}
            positions={truck.geolocation.map((position) => {
              return [position.coords.latitude, position.coords.longitude];
            })}
          />
        );
      })}
      {props.markers.map((marker: NoteVehicleLocation) => {
        return (
          <Marker
            icon={getIcon(marker.color)}
            key={marker.noteUuid}
            position={[marker.geolocation[0].coords.latitude, marker.geolocation[0].coords.longitude]}
          >
            <Popup>
              <div key={marker.noteRef}>
                <Link to={to("deliverynotes/" + marker.noteUuid + "/view")}>{marker.noteRef}</Link>
              </div>
              <div key={marker.vehicleUuid}>
                {marker.vehicleType} {marker.registrationPlate}
              </div>
              <div>{t("deliverynote.status." + marker.noteStatus)}</div>
              <div>Précision : {marker.geolocation[0].coords.accuracy} m</div>
              <div>Date : {moment(marker.geolocation[0].timestamp).format("DD/MM/YY HH:mm:SS")}</div>
              {marker?.geolocation[0]?.battery?.level != undefined && marker?.geolocation[0]?.battery?.level != -1 && (
                <div>{(marker?.geolocation[0]?.battery?.level * 100).toFixed(0)} %</div>
              )}
            </Popup>
          </Marker>
        );
      })}
    </>
  );
});

export default Map;
