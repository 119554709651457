export default {
  stats: {
    date: {
      today: "Today",
      yesterday: "Yesterday",
      lastweek: "Last 7 days",
      last15days: "Last 15 days",
      last30days: "Last 30 days",
      thismonth: "This month",
      thisyear: "Last 12 months",
      all: "All",
    },
    totalDigitalDeliveryNote: "Total Digital",
    deliveredDigitalDeliveryNote: "Delivered Digital",
    deliveredDeliveryNote: "Delivered",
    totaldeliveredDeliveryNote: "Delivered",
    collectedDigitalDeliveryNote: "Departure Digital",
    collectedDeliveryNote: "Departure",
    totalCollectedDeliveryNote: "Departure",
    totalDigitalPumpingNote: "Total Digital",
  },
};
