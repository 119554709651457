import React, { useEffect, useState } from "react";
import "./Customers.scss";
import "../styles/table.scss";
import "../styles/popup.scss";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Menu from "./Menu";
import TableStyles from "../styles/tableStyles";
import PopPop from "react-poppop";
import PopupStyle from "../styles/popupContentStyle";
import Roles from "../utils/Roles";
import ToastComponent from "./ToastComponent";
import Toast from "../models/Toast";
import { ToastTypes } from "../models/ToastTypes";
import Config from "../Config";
import Driver from "../models/Driver";
import SearchBar from "./SearchBar";
import { Scopes } from "../models/Scopes";
import AddDriver from "./AddDriver";
import ConfirmPopup from "./ConfirmPopup";
import { ReactComponent as Close } from "../assets/svg/close.svg";
import { useTranslation } from "react-i18next";

const Drivers = React.memo(() => {
  // const [drivers, setDrivers] = useState<Driver[]>([]);
  const { t } = useTranslation();
  const [toasts, setToast] = useState<Toast[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false);
  const [isOpenUpdateDriver, setIsOpenUpdateDriver] = useState(false);
  const [isOpenConfirmDeleteDriver, setIsOpenConfirmDeleteDriver] = useState(false);
  const [currentDriverToDelete, setCurrentDriverToDelete] = useState<Driver>();
  const history = useHistory();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResource, setTotalResource] = useState(0);

  const [sortColumn, setSortColumn] = useState("lastname");
  const [sortDirection, setSortDirection] = useState("asc");

  const columns: any[] = [
    {
      name: t("name"),
      selector: "lastname",
      cell: (row: any) => <div className="firstColumn">{row.lastname}</div>,
      sortable: true,
      right: false,
      id: "lastname",
    },
    {
      name: t("firstname"),
      selector: "firstname",
      sortable: true,
      right: false,
      id: "firstname",
    },
    {
      name: t("account.phoneNumber"),
      selector: "mobile",
      sortable: true,
      right: false,
      id: "username",
    },
    {
      name: t("deliverynote.status.title"),
      selector: "status",
      sortable: false,
      right: false,
      id: "status",
    },

    {
      name: "",
      sortable: false,
      cell: (row: any) => (
        <a className="table-link" onClick={() => updateDriver(row)}>
          {t("actions.edit")}
        </a>
      ),
      button: true,
    },
    {
      name: "",
      sortable: false,
      cell: (row: any) => (
        <Close className="icon" width="20" height="20" fill={"#90a0b7"} onClick={() => onStartDeleteDriver(row)} />
      ),
      button: true,
    },
  ];

  const [filteredItems, setFilterereditems] = useState<Driver[]>([]);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    fetchDrivers(1, perPage);
  }, []);

  const fetchDrivers = (
    page: number,
    newPerPage: number,
    newFilterText: string = filterText,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection
  ) => {
    setCurrentPage(page);
    setIsLoading(true);
    setFilterereditems([]);

    if (newFilterText != filterText) {
      setFilterText(newFilterText);
    }

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    const token = localStorage.getItem("token");
    let url =
      "drivers?page=" +
      page +
      "&limit=" +
      newPerPage +
      "&search=" +
      newFilterText +
      "&sort_by=" +
      newSortColumn +
      "&order_by=" +
      newSortDirection;
    axios
      .get(Config.getUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTotalRows(res.data.meta.total);

        setIsLoading(false);
        setTotalResource(res.data.meta.totalResource);
        setFilterereditems(res.data.content.items);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };

  const onSuccessAddDriver = () => {
    setIsOpenAddPopup(false);
    addToast(t("drivers.create"), ToastTypes.success);
    fetchDrivers(1, perPage);
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const onSuccessUpdateDriver = (newDriver: Driver) => {
    setIsOpenUpdateDriver(false);
    addToast("Le chauffeur a bien été modifié", ToastTypes.success);

    let index = filteredItems.findIndex((d) => d.mobile == currentDriverToDelete?.mobile);

    const updatedDrivers = [...filteredItems]; // creates a copy of the array

    updatedDrivers[index] = newDriver;
    setFilterereditems(updatedDrivers);
  };

  const onStartDeleteDriver = (driver: Driver) => {
    setCurrentDriverToDelete(driver);
    setIsOpenConfirmDeleteDriver(true);
  };

  const onDeleteDriverOk = () => {
    let dataToSend = {
      mobile: currentDriverToDelete?.mobile,
    };
    let token = localStorage.getItem("token");

    axios
      .post(Config.getUrl("drivers/delete"), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsOpenConfirmDeleteDriver(false);
        addToast(t("drivers.deleteSuccess"), ToastTypes.success);
        fetchDrivers(1, perPage);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            addToast(t("common.error"), ToastTypes.error);
          }
        } else if (error.request) {
          addToast("common.error", ToastTypes.error);
        } else {
          addToast("common.error", ToastTypes.error);
        }
      });
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == "asc") {
        newSortOrder = "desc";
      } else {
        newSortOrder = "asc";
      }
    } else {
      newSortOrder = "asc";
    }
    fetchDrivers(1, perPage, filterText, data.id, newSortOrder);
  };

  const handlePageChange = (pageNumber: number) => {
    fetchDrivers(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchDrivers(1, newPerPage);
  };

  const updateDriver = (driver: Driver) => {
    setCurrentDriverToDelete(driver);
    setIsOpenUpdateDriver(true);
  };

  return (
    <div className="carriers">
      <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

      <Menu selectedPage="drivers"></Menu>

      <div className={"right table-container"}>
        <div className="title">{t("menu.drivers")}</div>

        <div className="filterBar">
          <SearchBar placeholder={t("drivers.search")} onFilter={(value) => fetchDrivers(1, perPage, value)} />
        </div>

        <div className="buttonContainer">
          <div className="nbElements">
            {filterText != "" ? <span>{filteredItems.length}/</span> : null}
            {totalResource} {totalResource > 1 ? t("results") : t("result")}
          </div>

          {Roles.hasRight(Scopes.SCOPE_EXTRANET_DRIVER_CREATE) && !isLoading ? (
            <button className="validateBtn right-btn" onClick={() => setIsOpenAddPopup(true)}>
              <h6>{t("drivers.add.title")}</h6>
            </button>
          ) : null}
        </div>

        {isLoading ? (
          <div>{t("loading")}</div>
        ) : (
          <DataTable
            className="table"
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            data={filteredItems}
            noDataComponent={<div className="noResults">{t("drivers.noOne")}</div>}
            pagination
            paginationTotalRows={totalRows}
            paginationServer
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            defaultSortAsc={sortDirection == "asc"}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[perPage]}
            paginationComponentOptions={{
              rowsPerPageText: t("table.perpage"),
              rangeSeparatorText: t("table.on"),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
          />
        )}
      </div>

      <PopPop
        open={isOpenAddPopup}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenAddPopup(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <AddDriver onsuccess={onSuccessAddDriver} isUpdate={false} from="" />
      </PopPop>

      <PopPop
        open={isOpenUpdateDriver}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenUpdateDriver(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <AddDriver
          onsuccess={(newDriver: Driver) => onSuccessUpdateDriver(newDriver)}
          isUpdate={true}
          currentDriver={currentDriverToDelete}
        />
      </PopPop>

      <PopPop
        open={isOpenConfirmDeleteDriver}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenConfirmDeleteDriver(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <ConfirmPopup
          message={t("drivers.deleteQuestion", { driver: currentDriverToDelete?.mobile })}
          confirm={onDeleteDriverOk}
          cancel={() => setIsOpenConfirmDeleteDriver(false)}
          cancelMessage={t("no")}
          confirmMessage={t("yes")}
        />
      </PopPop>
    </div>
  );
});

export default Drivers;
