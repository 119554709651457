export default {
  menu: {
    deliverynotes: "Delivery notes",
    pumpingnotes: "Pumping notes",
    depaturenotes: "Departure notes",
    carriers: "Carriers",
    affiliates: "Affiliates",
    regions: "Regions",
    customers: "Customers",
    deliveries: "Shipyards",
    drivers: "Drivers",
    sites: "Sites",
    users: "Users",
    account: "My account",
    logout: "Log out",
    stats: "Statistics",
    vehicles: "Trucks",
    map: "Truck follow-up",
    "account.create": "Create an account",
  },
};
