import React, { useEffect, useState } from "react";
import "./Login.scss";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "../logo.svg";

import axios from "axios";
import Config from "../Config";
import Roles from "../utils/Roles";
import SelectWorkspace from "./SelectWorkspace";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import { Colors } from "../utils/Colors";
import { MadeBySynaxe } from "./Login";

function AutoLogin() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);

  const [isSelectingOrga, setIsSelectingOrga] = useState(false);
  const [isLoadingNewCode, setIsLoadingNewCode] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");

  const schema = yup.object().shape({
    firstname: yup.string().required("errors.firstname.required"),
    lastname: yup.string().required("errors.lastname.required"),
    password: yup
      .string()
      .required("errors.password.required")
      .test("len", "errors.password.minLength", (val) => val != null && val?.length >= 8),
    confirmPassword: yup
      .string()
      .required("errors.password.confirm.required")
      .oneOf([yup.ref("password"), null], "errors.password.confirmation"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  const updateLastName = (lastname: string) => {
    setLastname(lastname);
    setErrorMessage("");
  };

  const updateFirstName = (firstname: string) => {
    setFirstname(firstname);
    setErrorMessage("");
  };

  const updatePassword = (password: string) => {
    setPassword(password);
    setErrorMessage("");
  };

  const updateConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);
    setErrorMessage("");
  };

  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    let token = new URLSearchParams(window.location.search).get("token");
    if (token != null) {
      localStorage.setItem("token", atob(token));
      getScopes(atob(token));
    }
  }, []);

  const chooseWorkspace = () => {
    history.push("/deliverynotes");
  };

  const getScopes = (token: string) => {
    axios
      .get(Config.get_API_URL() + "me?d=" + new Date().toISOString(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let lastConnectedAt = res.data.content.lastConnectedAt;
        if (lastConnectedAt != null) {
          history.push("/login");
        } else {
          //il faut qu'il mette un mot de passe
          setIsLoading(false);
          let decoded = jwt_decode<any>(token?.toString());
          localStorage.setItem("email", decoded.sub);

          setFirstname(res.data.content.firstname);
          setLastname(res.data.content.lastname);
          setEmail(decoded.sub);

          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        askNewLink();
      });
  };

  const askNewLink = () => {
    setIsLoadingNewCode(true);

    const token = localStorage.getItem("token");
    axios
      .get(Config.get_API_EXTRANET_URL() + "auth/token/refresh", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setErrorMessage("");
        setIsLoadingNewCode(false);

        const token = res.data.content.refreshToken;

        localStorage.setItem("token", token);

        let decoded = jwt_decode<any>(token?.toString());
        localStorage.setItem("email", decoded.sub);
        getScopes(token);
      })
      .catch(() => {
        setIsLoadingNewCode(false);
        setIsTokenInvalid(true);
        setErrorMessage("errors.link.invalid");
      });
  };

  const onSubmitPassword = () => {
    //  setIsAddingLoading(true);

    let token = localStorage.getItem("token");

    let dataToSend: any = {
      password: password,
      firstname: firstname,
      lastname: lastname,
    };

    axios
      .post(Config.get_API_EXTRANET_URL() + "auth/register", dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        login();
      })
      .catch((error) => {
        // setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            if (error.response.data.code == "ERR4010002") {
              setErrorMessage("common.error");
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const login = () => {
    let dataToSend = {
      username: email,
      password: password,
    };
    axios
      .post(Config.get_API_EXTRANET_URL() + "auth/login", dataToSend)
      .then((res) => {
        const token = res.data.content.accessToken;
        localStorage.setItem("token", token);
        localStorage.setItem("email", dataToSend.username);
        getScopesAfterLogin();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage("login.error.invalid");
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const getScopesAfterLogin = () => {
    const token = localStorage.getItem("token");
    axios
      .get(Config.get_API_URL() + "me?d=" + new Date().toISOString(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Roles.saveWorkspaces(res.data.content.workspaces);

        if (res.data.content.workspaces.length == 2) {
          history.push("/deliverynotes");
        } else {
          setIsSelectingOrga(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage("login.error.invalid");
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  return (
    <div className="login">
      <div className="leftImage"></div>
      <div className="right">
        <div className="content">
          <div style={{ textAlign: "center" }}>
            <Logo fill={Colors.primary}></Logo>
          </div>
          <h1>{t("login.accessTitle")}</h1>
          {isLoading ? (
            <div>{t("loading")} </div>
          ) : isSelectingOrga || isTokenInvalid ? null : (
            <>
              <form onSubmit={handleSubmit(onSubmitPassword)}>
                <h5>{t("login.email")}</h5>

                <input type="text" name="email" readOnly={true} disabled={true} ref={register} value={email} />

                <div className="label">{t("firstname")}</div>
                <input
                  type="text"
                  name="firstname"
                  ref={register}
                  value={firstname}
                  placeholder={t("firstname")}
                  onChange={(e) => updateFirstName(e?.target.value.toString())}
                />
                <p>{t(errors.firstname?.message)}</p>

                <div className="label">{t("name")}</div>
                <input
                  type="text"
                  name="lastname"
                  ref={register}
                  value={lastname}
                  placeholder={t("name")}
                  onChange={(e) => updateLastName(e?.target.value.toString())}
                />
                <p>{t(errors.lastname?.message)}</p>

                <h5>{t("login.password")}</h5>
                <input
                  type="password"
                  name="password"
                  ref={register}
                  value={password}
                  placeholder={t("login.password")}
                  onChange={(e) => updatePassword(e?.target.value.toString())}
                />
                <p>{t(errors.password?.message)}</p>

                <h5>{t("account.confirmPassword")}</h5>
                <input
                  type="password"
                  name="confirmPassword"
                  ref={register}
                  value={confirmPassword}
                  placeholder={t("account.confirmPassword")}
                  onChange={(e) => updateConfirmPassword(e?.target.value.toString())}
                />
                <p>{t(errors.confirmPassword?.message)}</p>

                <p>{t(errorMessage)}</p>

                <button className="validateBtn" type="submit">
                  <h6>{t("account.create")}</h6>
                </button>

                <MadeBySynaxe />
              </form>
            </>
          )}

          {isTokenInvalid ? (
            <div>
              <div>{t("errors.link.invalid")}</div>

              <button className="validateBtn" type="submit" onClick={() => history.push("/login")}>
                <h6>{t("goBackHome")}</h6>
              </button>
            </div>
          ) : null}

          {isSelectingOrga ? (
            <div>
              <p className="chooseWorkspaceExplanation">{t("login.choose-workspace-explanation")}</p>
              <SelectWorkspace />

              <button className="validateBtn" type="submit" onClick={() => chooseWorkspace()}>
                <h6>{t("choose-workspace.submit")}</h6>
              </button>
            </div>
          ) : null}

          {errorMessage != "" ? (
            isLoadingNewCode || isTokenInvalid ? null : (
              <button onClick={() => askNewLink()} className="validateBtn">
                <h6>{t("login.sendNewLink")}</h6>
              </button>
            )
          ) : null}

          {/* {isError ? (
            <div>
              <div className="errorMessage">{errorMessage}</div>
              <div>Une erreur est survenue.</div>
            </div>
          ) : null} */}
        </div>
      </div>
    </div>
  );
}

export default AutoLogin;
