import React, { useEffect } from "react";
import { useParams } from "react-router";
import "./Store.scss";
import logo from "../assets/logo.jpeg";
import { useTranslation } from "react-i18next";
const AppCode = React.memo(() => {
  let { code } = useParams<any>();

  const { t } = useTranslation();
  useEffect(() => {
    window.location.replace("https://app.sxdelivery.io/" + code);
  }, [code]);
  return (
    <div className="storeContent" style={{}}>
      <img src={logo} alt="logo sx delivery" width="200" className="logo" />
      <h1>{t("appCode.title")} </h1>

      <h2>{t("appCode.description")}</h2>

      <h2>{code}</h2>
    </div>
  );
});

export default AppCode;
