/*eslint-disable */
import React, { useEffect, useState } from "react";
import "./Stats.scss";
import "../styles/table.scss";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import TableStyles from "../styles/tableStyles";
import Config from "../Config";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as BackIcon } from "../assets/svg/arrow-circle-left.svg";

import { Colors } from "../utils/Colors";
import Roles from "../utils/Roles";
import { Scopes } from "../models/Scopes";

import { CartesianGrid, Tooltip, YAxis, ResponsiveContainer, BarChart, Bar, Legend, LabelList } from "recharts";
import { useTranslation } from "react-i18next";

enum StatisticPeriod {
  ALL = "all",
  THIS_YEAR = "thisYear",
  THIS_MONTH = "thisMonth",
  THIRTY_DAYS = "thirtyDays",
  FIFTEEN_DAYS = "fifteenDays",
  SEVEN_DAYS = "sevenDays",
  YESTERDAY = "yesterday",
  TODAY = "today",
}

interface DeliveryNoteStats {
  totalDeliveryNote: any;
  totalDigitalDeliveryNote: any;
  deliveredDigitalDeliveryNote: any;
  collectedDigitalDeliveryNote: any;
  organization: string;
  collectedDeliveryNote: number;
  deliveredDeliveryNote: number;
  uuid: string;
}

enum Keys {
  totalDigitalDeliveryNote,
  totalDeliveryNote,
  deliveredDigitalDeliveryNote,
  totaldeliveredDeliveryNote,
  collectedDigitalDeliveryNote,
  totalCollectedDeliveryNote,
}

const Stats = React.memo((props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [filteredItems, setFilterereditems] = useState<DeliveryNoteStats[]>([]);
  const [currentTab, setCurrentTab] = useState(Roles.isAffiliate() ? "regions" : "sites");
  const [sortDirection] = useState("desc");
  const [sortColumn] = useState("total");
  const [currentRegion, setCurrentRegion] = useState<DeliveryNoteStats>();
  const [disabledKeys, setDisabledKeys] = useState<Keys[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    fetchStats(currentTab, props.currentPeriod, currentRegion);
  }, [props.currentPeriod]);

  useEffect(() => {
    fetchStats(currentTab, props.currentPeriod, currentRegion);
  }, []);

  const keys = [
    {
      key: Keys.totalDigitalDeliveryNote,
      label: t("stats.totalDigitalDeliveryNote"),
      color: Colors.graphPrimary,
    },
    {
      key: Keys.totalDeliveryNote,
      label: t("table.total"),
      color: Colors.graphPrimaryBis,
    },
    {
      key: Keys.deliveredDigitalDeliveryNote,
      label: t("stats.deliveredDigitalDeliveryNote"),
      color: Colors.graphSecondary,
    },
    {
      key: Keys.totaldeliveredDeliveryNote,
      label: t("stats.totaldeliveredDeliveryNote"),
      color: Colors.graphSecondaryBis,
    },
    {
      key: Keys.collectedDigitalDeliveryNote,
      label: t("stats.collectedDigitalDeliveryNote"),
      color: Colors.graphThird,
    },
    { key: Keys.totalCollectedDeliveryNote, label: t("stats.totalCollectedDeliveryNote"), color: Colors.graphThirdBis },
  ];

  const openRegionStats = (region: DeliveryNoteStats) => {
    setCurrentRegion(region);
    setCurrentTab("sites");
    fetchStats("sites", props.currentPeriod, region);
  };

  const columns: any[] = [
    {
      name: t("name"), // {t("name")},

      cell: (row: DeliveryNoteStats) => (
        <div
          className={
            row.uuid == null
              ? "firstColumn totalLine"
              : currentTab == "regions"
              ? "firstColumn link primary"
              : "firstColumn"
          }
          onClick={() => (row.uuid != null && currentTab == "regions" ? openRegionStats(row) : null)}
        >
          {row.organization}
        </div>
      ),
      sortable: true,
      selector: (row: DeliveryNoteStats) => row.organization,

      right: false,
      id: "organization",
    },
    {
      name: t("table.total"),

      cell: (row: DeliveryNoteStats) => (
        <div className={row.uuid == null ? "firstColumn totalLine" : ""}>{row.totalDeliveryNote}</div>
      ),
      sortable: true,
      selector: (row: DeliveryNoteStats) => row.totalDeliveryNote,

      right: false,
      sortFunction: (rowA: DeliveryNoteStats, rowB: DeliveryNoteStats) => {
        return rowA.totalDeliveryNote > rowB.totalDeliveryNote ? 1 : -1;
      },
      id: "totalDeliveryNote",
    },

    {
      name: t("stats.totalDigitalDeliveryNote"),

      cell: (row: DeliveryNoteStats) => (
        <div className={row.uuid == null ? "firstColumn totalLine" : ""}>{row.totalDigitalDeliveryNote}</div>
      ),
      sortable: true,
      selector: (row: DeliveryNoteStats) => row.totalDigitalDeliveryNote,

      right: false,
      sortFunction: (rowA: DeliveryNoteStats, rowB: DeliveryNoteStats) => {
        return rowA.totalDigitalDeliveryNote > rowB.totalDigitalDeliveryNote ? 1 : -1;
      },
      id: "totalDigitalDeliveryNote",
    },

    {
      name: t("stats.deliveredDeliveryNote"), //"Rendus",

      cell: (row: DeliveryNoteStats) => (
        <div className={row.uuid == null ? "firstColumn totalLine" : ""}>{row.deliveredDeliveryNote}</div>
      ),
      sortable: true,
      selector: (row: DeliveryNoteStats) => row.deliveredDeliveryNote,

      right: false,
      id: "deliveredDeliveryNote",
    },
    {
      name: t("stats.deliveredDigitalDeliveryNote"), // "Rendus Démat.",

      cell: (row: DeliveryNoteStats) => (
        <div className={row.uuid == null ? "firstColumn totalLine" : ""}>{row.deliveredDigitalDeliveryNote}</div>
      ),
      sortable: true,
      selector: (row: DeliveryNoteStats) => row.deliveredDigitalDeliveryNote,
      right: false,
      id: "deliveredDigitalDeliveryNote",
    },

    {
      name: t("stats.collectedDeliveryNote"), // "Départ",

      cell: (row: DeliveryNoteStats) => (
        <div className={row.uuid == null ? "firstColumn totalLine" : ""}>{row.collectedDeliveryNote}</div>
      ),
      sortable: true,
      selector: (row: DeliveryNoteStats) => row.collectedDeliveryNote,
      right: false,
      id: "collectedDeliveryNote",
    },

    {
      name: t("stats.collectedDigitalDeliveryNote"), //"Départ Démat.",

      cell: (row: DeliveryNoteStats) => (
        <div className={row.uuid == null ? "firstColumn totalLine" : ""}>{row.collectedDigitalDeliveryNote}</div>
      ),
      sortable: true,
      selector: (row: DeliveryNoteStats) => row.collectedDigitalDeliveryNote,
      right: false,
      id: "collectedDigitalDeliveryNote",
    },
  ];

  if (Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_VIEW) && currentTab == "sites" && Roles.hasBMD()) {
    columns.unshift({
      name: "",
      sortable: true,
      cell: (row: DeliveryNoteStats) =>
        row.organization == "Total" || row.uuid == null ? null : (
          <Link className="table-link" to={seeSiteBMD(row)}>
            {t("seeBMD")}
          </Link>
        ),
      button: true,
    });
  }

  if (currentTab == "sites") {
    columns.unshift({
      name: "",
      sortable: true,
      cell: (row: any) =>
        row.uuid == null ? null : (
          <Link className="table-link" to={seeSiteDns(row)}>
            {t("seeDns")}
          </Link>
        ),
      button: true,
    });
  }

  const seeSiteDns = (stats: DeliveryNoteStats) => {
    let url = "deliverynotes?site=" + stats.organization;
    return url;
  };
  const seeSiteBMD = (stats: DeliveryNoteStats) => {
    let url = "pumpingNotes?site=" + stats.organization;
    return url;
  };

  const fetchStats = (tab: string, period: StatisticPeriod, region: DeliveryNoteStats | undefined = undefined) => {
    setIsLoading(true);
    setFilterereditems([]);

    const token = localStorage.getItem("token");

    let dataToSend: any = {};

    dataToSend.period = period;

    if (region != null) {
      dataToSend.region = region.uuid;
      props.onUpdateCurrentRegion(region);
      setCurrentRegion(region);
    }
    axios
      .post(Config.getUrl("stats/delivery-notes/" + tab), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFilterereditems(res.data.content);
        if (res.data.content?.length > 0) {
          props.onUpdateLastUpdateDate(res.data.content[0].lastUpdate);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };
  const getSortedArray = (): DeliveryNoteStats[] => {
    return filteredItems.sort((rowA: DeliveryNoteStats, rowB: DeliveryNoteStats) => {
      return rowA.totalDeliveryNote > rowB.totalDeliveryNote ? -1 : 1;
    });
  };

  const getData = () => {
    let labels = Array.from(
      getSortedArray().filter((f: DeliveryNoteStats) => f.uuid != null),
      (x) => x.organization
    );

    let totalDigitalDeliveryNote = Array.from(
      getSortedArray().filter((f: DeliveryNoteStats) => f.uuid != null),
      (x) => x.totalDigitalDeliveryNote
    );

    let totalDeliveryNote = Array.from(
      getSortedArray().filter((f: DeliveryNoteStats) => f.uuid != null),
      (x) => x.totalDeliveryNote - x.totalDigitalDeliveryNote
    );

    let deliveredDigitalDeliveryNote = Array.from(
      getSortedArray().filter((f: DeliveryNoteStats) => f.uuid != null),
      (x) => x.deliveredDigitalDeliveryNote
    );

    let totaldeliveredDeliveryNote = Array.from(
      getSortedArray().filter((f: DeliveryNoteStats) => f.uuid != null),
      (x) => x.deliveredDeliveryNote - x.deliveredDigitalDeliveryNote
    );

    let collectedDigitalDeliveryNote = Array.from(
      getSortedArray().filter((f: DeliveryNoteStats) => f.uuid != null),
      (x) => x.collectedDigitalDeliveryNote
    );

    let totalCollectedDeliveryNote = Array.from(
      getSortedArray().filter((f: DeliveryNoteStats) => f.uuid != null),
      (x) => x.collectedDeliveryNote - x.collectedDigitalDeliveryNote
    );
    let data: any[] = [];
    for (let i = 0; i < getSortedArray().length; i++) {
      let newData = {
        name: labels[i],
        totalDigitalDeliveryNote: totalDigitalDeliveryNote[i],
        totalDeliveryNote: totalDeliveryNote[i],
        deliveredDigitalDeliveryNote: deliveredDigitalDeliveryNote[i],
        totaldeliveredDeliveryNote: totaldeliveredDeliveryNote[i],
        collectedDigitalDeliveryNote: collectedDigitalDeliveryNote[i],
        totalCollectedDeliveryNote: totalCollectedDeliveryNote[i],
      };
      data.push(newData);
    }

    return data;
  };

  const isInactive = (dataKey: Keys) => {
    return disabledKeys.filter((k) => k === dataKey).length > 0;
  };

  const renderCusomizedLegend = () => {
    return (
      <div className="customized-legend">
        {keys.map((key: any) => {
          return (
            <span
              key={key.key}
              className="legend-item"
              onClick={() => handleLegendClick(key.key)}
              style={{ marginRight: 10 }}
            >
              <div className="legend-icon" style={{ backgroundColor: isInactive(key.key) ? "#AAA" : key.color }}></div>

              <div style={{ color: isInactive(key.key) ? "#AAA" : key.color }}>{key.label}</div>
            </span>
          );
        })}
      </div>
    );
  };

  const handleLegendClick = (key: Keys) => {
    if (disabledKeys.filter((k) => k === key).length > 0) {
      let newKeys = [...disabledKeys];
      newKeys = newKeys.filter((k) => k !== key);
      setDisabledKeys(newKeys);
    } else {
      setDisabledKeys([...disabledKeys, key]);
    }
  };

  const getPayload = (data: any, dataKey: string) => {
    const { payload } = data;
    if (payload.filter((p: any) => p.dataKey === dataKey).length > 0) {
      return payload.filter((p: any) => p.dataKey === dataKey)[0];
    } else {
      return null;
    }
  };
  const CustomTooltip = (data: any) => {
    if (data.active && data.payload && data.payload.length > 0) {
      return (
        <div className="custom-tooltip">
          <div>{getData()[data.label].name}</div>
          {getPayload(data, "totalDigitalDeliveryNote") != null ? (
            <p className="tooltip-value body-s-medium" style={{ color: Colors.graphPrimary }}>
              {`${getPayload(data, "totalDigitalDeliveryNote").name}`} :{" "}
              {`${getPayload(data, "totalDigitalDeliveryNote").value}`}
            </p>
          ) : null}

          {getPayload(data, "totalDeliveryNote") != null ? (
            <p className="tooltip-value body-s-medium" style={{ color: Colors.graphPrimaryBis }}>
              {`${getPayload(data, "totalDeliveryNote").name}`} :{" "}
              {`${
                getPayload(data, "totalDigitalDeliveryNote") != null
                  ? getPayload(data, "totalDeliveryNote").value + getPayload(data, "totalDigitalDeliveryNote").value
                  : getPayload(data, "totalDeliveryNote").value
              }`}
            </p>
          ) : null}

          {getPayload(data, "deliveredDigitalDeliveryNote") != null ? (
            <p className="tooltip-value body-s-medium" style={{ color: Colors.graphSecondary }}>
              {`${getPayload(data, "deliveredDigitalDeliveryNote").name}`} :{" "}
              {`${getPayload(data, "deliveredDigitalDeliveryNote").value}`}
            </p>
          ) : null}
          {getPayload(data, "totaldeliveredDeliveryNote") != null ? (
            <p className="tooltip-value body-s-medium" style={{ color: Colors.graphSecondaryBis }}>
              {`${getPayload(data, "totaldeliveredDeliveryNote").name}`} :{" "}
              {`${
                getPayload(data, "deliveredDigitalDeliveryNote") != null
                  ? getPayload(data, "deliveredDigitalDeliveryNote").value +
                    getPayload(data, "totaldeliveredDeliveryNote").value
                  : getPayload(data, "totaldeliveredDeliveryNote").value
              }`}
            </p>
          ) : null}
          {getPayload(data, "collectedDigitalDeliveryNote") != null ? (
            <p className="tooltip-value body-s-medium" style={{ color: Colors.graphThird }}>
              {`${getPayload(data, "collectedDigitalDeliveryNote").name}`} :{" "}
              {`${getPayload(data, "collectedDigitalDeliveryNote").value}`}
            </p>
          ) : null}
          {getPayload(data, "totalCollectedDeliveryNote") != null ? (
            <p className="tooltip-value body-s-medium" style={{ color: Colors.graphThirdBis }}>
              {`${getPayload(data, "totalCollectedDeliveryNote").name}`} :{" "}
              {`${
                getPayload(data, "collectedDigitalDeliveryNote") != null
                  ? getPayload(data, "collectedDigitalDeliveryNote").value +
                    getPayload(data, "totalCollectedDeliveryNote").value
                  : getPayload(data, "totalCollectedDeliveryNote").value
              }`}
            </p>
          ) : null}
        </div>
      );
    }

    return null;
  };

  const toggleTab = (tab: string) => {
    setCurrentTab(tab);
    fetchStats(tab, props.currentPeriod);
  };

  const renderCustomizedLabel = (labelProps: any) => {
    const { x, y, width, value, index } = labelProps;
    const radius = 10;

    return (
      <g>
        <text x={x + width} y={y - radius - index * 2} fill="#000000" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    );
  };

  return (
    <div className={"table-container"}>
      {Roles.isAffiliate() && currentTab == "sites" ? (
        <div className="returnBtn" onClick={() => toggleTab("regions")} style={{ marginBottom: 30 }}>
          <BackIcon className="icon" width="25" height="20"></BackIcon>
          <span style={{ marginLeft: 10 }}>
            {t("back")}
            {/* Retour */}
          </span>
        </div>
      ) : null}

      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={500}
          height={600}
          data={getData()}
          style={{ paddingTop: 20 }}
          margin={{
            top: 30,
            right: 0,
            left: 0,
            bottom: 60,
          }}
        >
          <Legend
            margin={{ top: 1000, bottom: 1000 }}
            wrapperStyle={{ top: -10, marginBottom: 100 }}
            verticalAlign="top"
            content={renderCusomizedLegend}
          />
          <CartesianGrid strokeDasharray="1 1" />
          {/* <XAxis
              dataKey="name"
              minTickGap={0}
              allowDataOverflow={true}
              stroke={Colors.grey500}
              type="category"
              width={10000}
            /> */}
          <YAxis stroke={Colors.grey500} />
          {/* <XAxis dataKey="name" color={Colors.neutral400} /> */}

          <Tooltip cursor={{ fill: "none" }} content={<CustomTooltip />} />
          {isInactive(Keys.totalDigitalDeliveryNote) ? null : (
            <Bar
              isAnimationActive={false}
              name={t("stats.totalDigitalDeliveryNote")}
              dataKey="totalDigitalDeliveryNote"
              stackId="a"
              fill={Colors.graphPrimary}
            />
          )}
          {isInactive(Keys.totalDeliveryNote) ? null : (
            <Bar
              isAnimationActive={false}
              name={t("table.total")}
              dataKey="totalDeliveryNote"
              stackId="a"
              fill={Colors.graphPrimaryBis}
            >
              <LabelList dataKey="name" position="top" content={renderCustomizedLabel} />
            </Bar>
          )}
          {isInactive(Keys.deliveredDigitalDeliveryNote) ? null : (
            <Bar
              isAnimationActive={false}
              name={t("stats.deliveredDigitalDeliveryNote")}
              dataKey="deliveredDigitalDeliveryNote"
              stackId="b"
              fill={Colors.graphSecondary}
            />
          )}
          {isInactive(Keys.totaldeliveredDeliveryNote) ? null : (
            <Bar
              isAnimationActive={false}
              name={t("stats.totaldeliveredDeliveryNote")}
              dataKey="totaldeliveredDeliveryNote"
              stackId="b"
              fill={Colors.graphSecondaryBis}
            />
          )}
          {isInactive(Keys.collectedDigitalDeliveryNote) ? null : (
            <Bar
              isAnimationActive={false}
              name={t("stats.collectedDigitalDeliveryNote")}
              dataKey="collectedDigitalDeliveryNote"
              stackId="c"
              fill={Colors.graphThird}
            />
          )}
          {isInactive(Keys.totalCollectedDeliveryNote) ? null : (
            <Bar
              isAnimationActive={false}
              name={t("stats.totalCollectedDeliveryNote")}
              dataKey="totalCollectedDeliveryNote"
              stackId="c"
              fill={Colors.graphThirdBis}
            />
          )}
        </BarChart>
      </ResponsiveContainer>

      {isLoading ? (
        <div>{t("loading")}</div>
      ) : (
        <DataTable
          className="table"
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          data={filteredItems}
          noDataComponent={<div className="noResults">{t("noSite")}</div>}
          defaultSortAsc={sortDirection == "asc"}
          defaultSortFieldId={sortColumn}
        />
      )}
    </div>
  );
});

export default Stats;
