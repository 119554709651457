import React from "react";
import "./Document.scss";
import logo from "../assets/svg/file.svg";
import { DigitalNote } from "../models/Deliverynote";

interface DocumentProps {
  isDisclaimer?: boolean;
  isTransportLetter?: boolean;
  doc?: DigitalNote;
  docUrl?: any;
}

function Document(props: DocumentProps) {
  const handleClick = () => {
    if (props.docUrl != null) {
      window.open(props.docUrl.url, "_blank");
    } else {
      window.open(props.doc?.url, "_blank");
    }
  };

  return (
    <div className="document" onClick={() => handleClick()}>
      <div className="icon">
        <img src={logo} alt="file name" width="20" height="16" />
      </div>

      <div className="name">
        {props.doc?.name}
        {/* {props.isDisclaimer
          ? t("deliverynote.digitalDisclaimer") + ".pdf"
          : props.isTransportLetter
          ? t("deliverynote.transportLetter") + ".pdf"
          : t("deliverynote.weightTicket") + ".pdf"} */}
      </div>
    </div>
  );
}

export default Document;
