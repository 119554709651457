import React, { useEffect, useState } from "react";
import "./Account.scss";
import "../styles/table.scss";
import "../styles/popup.scss";
import axios from "axios";
import Menu from "./Menu";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ToastComponent from "./ToastComponent";
import Toast from "../models/Toast";
import { ToastTypes } from "../models/ToastTypes";
import Config from "../Config";
import UpdatePassword from "./UpdatePassword";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Account = React.memo(() => {
  const { t } = useTranslation();
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToast] = useState<Toast[]>([]);
  const token = localStorage.getItem("token");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");

  const schema = yup.object().shape({
    firstname: yup.string().required("errors.firstname.required"),
    lastname: yup.string().required("errors.lastname.required"),
  });

  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  const updateFirstname = (firstname: string) => {
    setFirstname(firstname);
    setErrorMessage("");
  };

  const updateLastname = (lastname: string) => {
    setLastname(lastname);
    setErrorMessage("");
  };

  // const updateAcceptNotification = (accept: boolean) => {
  //   setacceptNotification(accept);
  //   setErrorMessage("");
  // };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const onSubmit = () => {
    setIsAddingLoading(true);

    let token = localStorage.getItem("token");
    let dataToSend = {
      firstname: firstname,
      lastname: lastname,
    };

    axios
      .post(Config.get_API_EXTRANET_URL() + "account/update", dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsAddingLoading(false);
        addToast(t("account.updated"), ToastTypes.success);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            if (error.response.data.code == "ERR4010002") {
              setErrorMessage("common.error");
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  useEffect(() => {
    let url = Config.get_API_EXTRANET_URL() + "account";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsAddingLoading(false);
        setLastname(res.data.content.lastname);
        setFirstname(res.data.content.firstname);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  }, []);

  return (
    <div className="updatePassword">
      <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

      <Menu selectedPage="account"></Menu>

      <div className="right">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="title">{t("menu.account")}</h3>
          <div className="label">{t("name")}</div>
          <input
            type="text"
            name="lastname"
            ref={register}
            value={lastname}
            placeholder={t("name")}
            onChange={(e) => updateLastname(e?.target.value.toString())}
          />
          <p>{t(errors.lastname?.message)}</p>

          <div className="label">{t("firstname")}</div>
          <input
            type="text"
            name="firstname"
            ref={register}
            value={firstname}
            placeholder={t("firstname")}
            onChange={(e) => updateFirstname(e?.target.value.toString())}
          />
          <p>{t(errors.firstname?.message)}</p>

          <div>{t(errorMessage)}</div>
          <button type="submit" disabled={isAddingLoading}>
            <div>{isAddingLoading ? t("saving") : t("actions.edit")}</div>
          </button>
        </form>

        <UpdatePassword />
      </div>
    </div>
  );
});

export default Account;
