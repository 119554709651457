import { DeliveryNoteTypes } from "../components/DeliveryNotes";

const localStorageKey = "columns";

export default class ColumnsFilter {
  static updateColumns(filters: any[], type: DeliveryNoteTypes) {
    localStorage.removeItem(localStorageKey + "_" + type);
    localStorage.setItem(localStorageKey + "_" + type, JSON.stringify(filters));
  }
  static getColumnsFromLocal = (type: DeliveryNoteTypes): any[] | null => {
    let filters = localStorage.getItem(localStorageKey + "_" + type);
    if (filters != null) {
      return JSON.parse(filters);
    } else {
      return [];
    }
  };
}
