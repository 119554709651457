import React, { useEffect, useState } from "react";
import "./Login.scss";
import jwt_decode from "jwt-decode";
import { ReactComponent as Logo } from "../logo.svg";

import { useHistory } from "react-router-dom";
import axios from "axios";
import Config from "../Config";
import { useTranslation } from "react-i18next";
import { Colors } from "../utils/Colors";
import { MadeBySynaxe } from "./Login";

function AskNewAutologin() {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token != null) {
      onSubmitToken(token);
    }
  });

  const onSubmitToken = (token: string) => {
    axios
      .get(Config.get_API_EXTRANET_URL() + "auth/token/refresh", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const refreshToken = res.data.content.refreshToken;
        localStorage.setItem("token", refreshToken);
        if (refreshToken != null) {
          authorize(refreshToken);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage("errors.link.invalid");
          } else if (error.response.status == 404) {
            setErrorMessage("common.error");
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const authorize = (token: string) => {
    let dataToSend = {
      scope: "deliverynote.readonly",
    };
    axios
      .post(Config.get_API_EXTRANET_URL() + "auth/token/authorize", dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const accessToken = res.data.content.accessToken;
        localStorage.setItem("token", accessToken);

        if (accessToken != null) {
          let decoded = jwt_decode<any>(accessToken?.toString());
          history.push("/deliveryNotes/" + decoded.deliveryNote.uuid + "/view");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage("errors.link.invalid");
          } else if (error.response.status == 404) {
            setErrorMessage("common.error");
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  return (
    <div className="login">
      <div className="leftImage"></div>
      <div className="right">
        <div className="content">
          <div style={{ textAlign: "center" }}>
            <Logo fill={Colors.primary}></Logo>
          </div>
          <h1>{t("login.goToDn")}</h1>
          {errorMessage == "" ? (
            <div>{t("loading")} </div>
          ) : (
            <>
              <div>
                <div className="successMessage">{t("login.newLinkSend")} </div>
                <div>{t("login.newLinkSendDescription")}</div>
              </div>
              <MadeBySynaxe />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AskNewAutologin;
