/*eslint-disable*/
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Deliverynote from "../models/Deliverynote";
import "./DeliveryNote.scss";
import Document from "./Document";
import moment from "moment";
import Menu from "./Menu";
import { ReactComponent as BackIcon } from "../assets/svg/arrow-circle-left.svg";
import { ReactComponent as Leaf } from "../assets/svg/co2.svg";
import { ReactComponent as Download } from "../assets/svg/download.svg";

import Config from "../Config";
import Roles from "../utils/Roles";
import ImageViewer from "react-simple-image-viewer";
import { ReactComponent as Down } from "../assets/svg/chevron-down.svg";
import { useOuterClick } from "react-outer-click";
import { ToastTypes } from "../models/ToastTypes";
import Toast from "../models/Toast";
import { ReactComponent as Eye } from "../assets/svg/eye.svg";
import { ReactComponent as EyeOff } from "../assets/svg/eye-off.svg";
import { Scopes } from "../models/Scopes";
import Email from "../models/Email";
import ReactTooltip from "react-tooltip";
import PopPop from "react-poppop";
import PopupStyle from "../styles/popupContentStyle";
import SignDeliveryNote from "./SignDeliveryNote";
import ToastComponent from "./ToastComponent";
import ConfirmPopup from "./ConfirmPopup";
import { Colors } from "../utils/Colors";
import { useTranslation } from "react-i18next";
interface DeliveryNoteProps {
  isPumping?: boolean;
}

const DeliveryNote = React.memo((props: DeliveryNoteProps) => {
  const { t } = useTranslation();
  const ref = React.useRef(null);
  useOuterClick(ref, () => {
    setIsEmailListOpen(false);
  });

  interface Params {
    mode: string;
    id: string;
    autosign?: string;
  }

  let { mode, id, autosign } = useParams<Params>();
  const history = useHistory();
  const [dl, setDl] = useState<Deliverynote>();
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [isOpenSignDeliveryNote, setIsOpenSignDeliveryNote] = useState(false);
  const [isOpenCancelConfirm, setIsOpenCancelConfirm] = useState(false);
  const [isOpenCancelIsDelivered, setIsOpenCancelIsDelivered] = useState(false);
  const [isDelivered, setIsDelivered] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToast] = useState<Toast[]>([]);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isEmailListOpen, setIsEmailListOpen] = useState(false);
  const [emails, setEmails] = useState<Email[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<Email[]>([]);
  const [images, setImages] = useState<string[]>([]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const goHome = () => {
    if (
      dl?.collectedByClient &&
      dl?.status != "validated" &&
      dl?.status != "refused" &&
      dl?.status != "canceled" &&
      dl?.status != "partially_refused"
    ) {
      history.push("/deliverynotes-departure");
    } else if (props.isPumping) {
      history.push("/pumpingnotes");
    } else {
      history.push("/deliverynotes");
    }
  };

  const toggleEmailList = () => {
    setIsEmailListOpen(!isEmailListOpen);
  };

  const selectAllEmail = (isSelected: boolean) => {
    if (isSelected) {
      let finalEmails: Email[] = [];
      emails?.forEach((email) => {
        finalEmails.push(email);
      });
      setSelectedEmails(finalEmails);
    }
  };

  const addToSelectedEmail = (email: Email) => {
    if (selectedEmails.filter((e) => e.email == email.email).length == 0) {
      let emails = JSON.parse(JSON.stringify(selectedEmails));
      emails.push(email);
      setSelectedEmails(emails);
    } else {
      let filteredEmails = selectedEmails.filter((e) => e.email != email.email);
      let emails = JSON.parse(JSON.stringify(filteredEmails));
      setSelectedEmails(emails);
    }
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const reSendEmails = () => {
    if (selectedEmails.length > 0) {
      setIsAddingLoading(true);
      let token = localStorage.getItem("token");

      let emailsToSend: string[] = [];
      selectedEmails.forEach((s) => {
        emailsToSend.push(s.email);
      });
      let dataTosend: any = {
        emails: emailsToSend,
      };

      let baseUrl = props.isPumping ? "pumping-notes/" : "delivery-notes/";

      axios
        .post(Config.getUrl(baseUrl + id + "/emails/send"), dataTosend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setIsAddingLoading(false);
          let message = emailsToSend.length > 1 ? t("deliverynote.emailssend") : t("deliverynote.emailsend");
          addToast(message, ToastTypes.success);
          setIsEmailListOpen(false);
        })
        .catch(() => {
          setIsAddingLoading(false);
          setErrorMessage("common.error");
        });
    }
  };

  const getEmails = () => {
    let baseUrl = props.isPumping ? "pumping-notes/" : "delivery-notes/";
    // let baseUrl ="delivery-notes/";
    let url = Config.getUrl(baseUrl + id + "/emails");

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmails(res.data.content);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };

  const startSignature = () => {
    setIsOpenSignDeliveryNote(true);
  };

  const onSuccesSign = () => {
    addToast(t("delivenynote.validateSuccess"), ToastTypes.success);
    setIsOpenSignDeliveryNote(false);
    getCurrentDl();
    if (dl?.collectedByClient) {
      localStorage.setItem("totalCollectedBlToSign", getTotalBlToSign().toString());
      history.push("/deliveryNotes-departure");
    } else {
      location.reload();
    }
  };

  const getTotalBlToSign = (): number => {
    let total = localStorage.getItem("totalCollectedBlToSign");
    if (total != null) {
      let totalInt = Number.parseInt(total);
      return Math.max(0, totalInt - 1);
    } else {
      return 0;
    }
  };

  const onSignError = () => {
    addToast(t("deliverynote.signError"), ToastTypes.error);
    setIsOpenSignDeliveryNote(false);
    getCurrentDl();
  };

  const onCancelSign = () => {
    setIsOpenSignDeliveryNote(false);
  };

  const getCurrentDl = () => {
    let baseUrl = props.isPumping ? "pumping-notes/" : "delivery-notes/";
    let url = Config.getUrl(baseUrl + id);
    if (mode == "preview") {
      url = Config.get_API_EXTRANET_URL() + baseUrl + id + "/preview";
    }

    if (Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_NOTE_EMAILS)) {
      getEmails();
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setDl(res.data.content);

        if (
          autosign == "true" &&
          (res.data.content.signature == undefined || res.data.content.signature.signedAt == undefined)
        ) {
          setIsOpenSignDeliveryNote(true);
        }
        if (res.data.content.driverPhotos != null && res.data.content.driverPhotos.length > 0) {
          setImages(res.data.content.driverPhotos.map((r: any) => r.url));
        }

        if (
          res.data.content.roadTrip != undefined &&
          res.data.content.roadTrip != null &&
          res.data.content.roadTrip.length > 0
        ) {
          localStorage.setItem("roadTrip-" + id, JSON.stringify(res.data.content.roadTrip));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };

  useEffect(() => {
    getCurrentDl();
  }, [id, autosign]);

  const canBeCancelled = () => {
    return (
      Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_NOTE_MANAGE) &&
      (dl?.status == "new" || dl?.status == "chartered" || dl?.status == "active")
    );
  };

  const onStartCancel = () => {
    setIsOpenCancelIsDelivered(true);
  };

  const onCancelIsDeliveredSuccess = (result: boolean) => {
    setIsDelivered(result);
    setIsOpenCancelIsDelivered(false);
    setIsOpenCancelConfirm(true);
  };

  const onCancelIsDeliveredCancel = () => {
    setIsOpenCancelIsDelivered(false);
  };

  const onCancelSuccess = () => {
    let token = localStorage.getItem("token");

    let dataTosend = {
      delivered: isDelivered,
    };
    axios
      .post(Config.getUrl(`delivery-notes/${id}/inactived`), dataTosend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsOpenCancelConfirm(false);
        setIsOpenCancelIsDelivered(false);
        getCurrentDl();

        if (isDelivered) {
          addToast(t("deliverynote.manualValidateSuccess"), ToastTypes.success);
        } else {
          addToast(t("deliverynote.cancelledDn.success"), ToastTypes.success);
        }
      })
      .catch((error) => {
        if (error.response) {
          addToast(t("common.error"), ToastTypes.error);
        }
      });
  };

  const onCancelCancel = () => {
    setIsOpenCancelConfirm(false);
    setIsOpenCancelIsDelivered(false);
  };

  const hasSecurityMeasures = () => {
    return (
      dl?.parkingVehicle != null ||
      dl?.presenceOfElectricalLines != null ||
      (dl?.otherRisks != "" && dl?.otherRisks != null) ||
      dl?.machineStabilization != null ||
      dl?.worksiteAccess != null
    );
  };

  const hasCoordinates = () => {
    return (
      // (dl?.site?.latitude != null && dl?.site?.longitude != null) ||
      (dl?.deliveryCustomer?.latitude != null && dl?.deliveryCustomer?.longitude != null) ||
      (dl?.signature?.latitude != null && dl?.signature?.longitude != null)
    );
  };

  const canShowDetails = () => {
    return (
      (dl?.deliveryCustomerComment != "" && dl?.deliveryCustomerComment != null) ||
      dl?.signature?.signedAt != null ||
      !(
        isLoading ||
        !dl?.collectedByClient ||
        dl?.status == "validated" ||
        dl?.status == "refused" ||
        dl?.status == "canceled" ||
        dl?.status == "partially_refused" ||
        dl?.signature?.signedAt != null ||
        !Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_NOTE_VALIDATE)
      )
    );
  };
  const getFinalProductQuantity = (product: any) => {
    let quantity = product.quantity - product.refusedQuantity;
    var decimals = quantity - Math.floor(quantity);
    if (decimals > 0) {
      return quantity.toFixed(2);
    }
    return quantity;
  };

  const showDeliveryDate = () => {
    return (props.isPumping && dl?.pumpingDate != null) || (!props.isPumping && dl?.worksiteArrivalTime != null);
  };

  return (
    <div className="deliveryNote">
      <Menu selectedPage="deliveryNote"></Menu>
      <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />
      <div className="right">
        <div className="buttonContainer">
          {!Roles.isReadOnly() ? (
            <div className="returnBtn" onClick={() => goHome()}>
              <BackIcon className="icon" width="25" height="20"></BackIcon>
              <span style={{ marginLeft: 10 }}>{t("back")}</span>
            </div>
          ) : (
            <div></div>
          )}

          <div className="row right-btn">
            {!isLoading &&
            dl != null &&
            emails != null &&
            emails?.length > 0 &&
            Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_NOTE_EMAILS) &&
            (dl?.status == "validated" ||
              dl?.status == "terminated" ||
              dl?.status == "refused" ||
              dl?.status == "partially_refused") ? (
              <div className="notificationMailContainer" ref={ref}>
                <div className={"validateBtn alternate"} style={{ marginRight: 10 }} onClick={() => toggleEmailList()}>
                  <div className="row">
                    <h6>{t("deliverynote.mailNotification")}</h6>
                    <ReactTooltip className="tooltip" />
                    <Down className="icon" width="18" height="18" fill={Colors.primary}></Down>
                  </div>
                </div>

                <div className={isEmailListOpen ? "emailList visible" : "emailList"}>
                  <label className="checkbox">
                    <input
                      name="associated"
                      type="checkbox"
                      checked={selectedEmails.length == emails.length}
                      onChange={(e) => selectAllEmail(e?.target.checked)}
                    />
                    {t("deliverynote.selectAll")}
                  </label>

                  <div className="title">{t("menu.customers")}</div>
                  {emails.map((email: Email, i: any) => (
                    <div key={i} className={`email`}>
                      <label className="checkbox">
                        <input
                          name={email.email}
                          type="checkbox"
                          checked={selectedEmails.filter((s: Email) => s.email == email.email).length > 0}
                          onChange={() => addToSelectedEmail(email)}
                        />
                        <span
                          data-tip={t("delivernyNote.sentAt", {
                            date: moment(email.sentAt).format("DD/MM/YYYY HH:mm"),
                          })}
                        >
                          {email.email}
                        </span>
                      </label>
                      <div>
                        {email.openedAt != null ? (
                          <Eye
                            className="eye"
                            width="25"
                            height="25"
                            fill={Colors.primary}
                            data-tip={t("deliverynote.emailOpennedAt", {
                              date: moment(email.openedAt).format("DD/MM/YY"),
                              hour: moment(email.openedAt).format("HH:mm"),
                            })}
                          />
                        ) : (
                          <EyeOff
                            className="eye"
                            width="25"
                            height="25"
                            fill={"#90a0b7"}
                            data-tip={t("deliverynote.emailNotopen")}
                          />
                        )}
                      </div>
                    </div>
                  ))}

                  {isAddingLoading ? (
                    <div className="validateBtn">
                      <h6>{t("deliverynote.sending")}</h6>
                    </div>
                  ) : (
                    <div className="validateBtn" onClick={() => reSendEmails()}>
                      <h6>{t("deliverynote.resend")}l</h6>
                    </div>
                  )}

                  <div className="errorMessage">{errorMessage}</div>
                </div>
              </div>
            ) : null}
            {isLoading || dl?.digitalNote == null ? null : (
              <>
                {/* <div onClick={() => dlTest()}>Test</div> */}
                <a
                  className="validateBtn btn"
                  href={dl?.digitalNote?.url}
                  download={`${props.isPumping ? "BMD-" : "BL-"}${
                    dl?.folioReference != null ? dl?.folioReference : dl?.externalReference
                  }-${
                    props.isPumping
                      ? moment(dl?.pumpingDate).format("DD-MM-YYYY")
                      : moment(dl?.worksiteArrivalTime).format("DD-MM-YYYY")
                  }.pdf`}
                >
                  <Download className="icon" width="25" height="25" fill={"white"} />
                  <h6>{t("deliverynote.dlPdf")}</h6>
                </a>
              </>
            )}

            {isLoading || !canBeCancelled() ? null : (
              <div className={"validateBtn cancelBtn"} onClick={() => onStartCancel()}>
                <h6>{t("actions.cancel")}</h6>
              </div>
            )}
          </div>
        </div>

        {dl?.updatedAt != null ? (
          <div className="updateDate">
            {t("table.lastupdated", {
              day: moment(dl?.updatedAt).format("DD/MM/YYYY"),
              hour: moment(dl?.updatedAt).format("HH"),
              minute: moment(dl?.updatedAt).format("mm"),
            })}
          </div>
        ) : null}

        {isLoading ? (
          <div style={{ marginTop: 30 }}>{t("loading")}</div>
        ) : (
          <div className="content">
            <h3>{dl?.folioReference != undefined ? dl?.folioReference : dl?.externalReference}</h3>

            {dl?.cancelation?.canceledAt != null ? (
              <>
                <h5>{t("deliverynote.cancelledDn.title")}</h5>
                <div className="panelRow">
                  <div>
                    <div className="panelTitle">
                      <div className="panelTitleText">{t("deliverynote.cancelledDn.date")}</div>
                      <div className="panelSubTitle">{moment(dl?.cancelation?.canceledAt).format("DD/MM HH:mm")}</div>
                    </div>
                  </div>
                  <div>
                    <div className="panelTitle">
                      <div className="panelTitleText">{t("deliverynote.cancelledDn.reason")}</div>
                      <div className="panelSubTitle">{dl?.cancelation?.comment}</div>
                    </div>
                  </div>
                </div>{" "}
              </>
            ) : null}

            {dl?.refusal?.reason != null ? (
              <>
                <h5>
                  {dl?.status == "terminated" || dl?.status == "validated"
                    ? t("deliverynote.refusedDn.incident")
                    : t("deliverynote.refusedDn.title")}
                </h5>
                <div className="panelRow">
                  <div>
                    <div className="panelTitle">
                      <div className="panelTitleText">{t("deliverynote.cancelledDn.reason")}</div>
                      <div className="panelSubTitle">{dl?.refusal?.reason} &nbsp;</div>
                    </div>
                  </div>
                  <div>
                    <div className="panelTitle">
                      <div className="panelTitleText">{t("deliverynote.comments")}</div>
                      <div className="panelSubTitle">{dl?.refusal?.comment} &nbsp;</div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <h5>Client</h5>
            <div>
              <div className="panelContent">{dl?.billingCustomer.name}</div>
              <div className={showDeliveryDate() ? "panelRow" : "panelRow oneChild"}>
                <div>
                  <div className="panelTitle">
                    <div className="panelTitleText">{t("delivery")}</div>
                    <div className="panelSubTitle">{dl?.deliveryCustomer.name}</div>
                  </div>
                </div>
                <div>
                  {showDeliveryDate() && (
                    <div className="panelTitle">
                      <div className="panelTitleText">{t("deliverynote.deliveryDate")}</div>
                      <div className="panelSubTitle">
                        {props.isPumping
                          ? moment(dl?.pumpingDate).format("DD/MM/YYYY")
                          : moment(dl?.worksiteArrivalTime).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {dl?.carrier?.name != null ? (
                <>
                  <h5>{t("deliverynote.transport")}</h5>
                  {/* {dl?.co2Emission != null && dl?.co2Emission != 0 ? (
                    <div className="co2Panel">
                      <div className="row" style={{ alignItems: "center" }}>
                        <Leaf width="25" height="25" fill={"#4f7c14"} />
                        <div style={{ marginLeft: 10 }}>
                          {t("deliverynote.co2emission", { value: dl?.co2Emission })}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  <div className="panelContent">{dl?.carrier?.name}</div>

                  <div className="panelRow">
                    <div>
                      <div className="panelTitle">
                        <div className="panelTitleText">{t("deliverynote.registrationPlate")}</div>
                        <div className="panelSubTitle">{dl?.vehicle?.registrationPlate}</div>
                      </div>
                    </div>
                    <div>
                      <div className="panelTitle">
                        <div className="panelTitleText">{t("label")}</div>
                        <div className="panelSubTitle">{dl?.vehicle?.externalReference} </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {dl?.pumper?.name != null ? (
                <>
                  <h5>{t("deliverynote.transport")}</h5>
                  <div className="panelContent">{dl?.pumper?.name}</div>
                  <div className="panelRow">
                    {dl?.vehicle?.registrationPlate != null ? (
                      <>
                        <div>
                          <div className="panelTitle">
                            <div className="panelTitleText">{t("deliverynote.registrationPlate")}</div>
                            <div className="panelSubTitle">{dl?.vehicle?.registrationPlate}</div>
                          </div>
                        </div>
                        <div>
                          <div className="panelTitle">
                            <div className="panelTitleText">{t("label")}</div>
                            <div className="panelSubTitle">{dl?.vehicle?.externalReference}</div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div>
                      <div className="panelTitle noborder-top">
                        <div className="panelTitleText">{t("type")}</div>
                        <div className="panelSubTitle">{dl?.pump?.pumpType}</div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {dl?.products != undefined && dl?.products.length > 0 ? (
                <>
                  <h5>{t("deliverynote.productList")}</h5>

                  <div className="panelRow">
                    {dl?.products?.map((product) => {
                      return (
                        <div key={product.code}>
                          <div className="panelContent">{product.designation}</div>
                          <div className="panelTitle">
                            <div className="quantityLine">
                              <div>
                                <div className="panelTitleText">{t("deliverynote.quantity")}</div>
                                {product.refusedQuantity != null &&
                                product.refusedQuantity > 0 &&
                                product?.quantity != null ? (
                                  <div className="panelSubTitle">
                                    <b>{getFinalProductQuantity(product)}</b>/{product.quantity}
                                  </div>
                                ) : (
                                  <div className="panelSubTitle">{product.quantity}</div>
                                )}
                              </div>
                              <div>
                                <div className="panelTitleText">{t("deliverynote.unitev")}</div>
                                <div className="panelSubTitle">{product.unit}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}

              {props.isPumping ? (
                <div>
                  <h5>{t("deliverynote.realPumpedQuantity")}</h5>
                  <>
                    <div className="panelContent noborder-top">{t("deliverynote.estimatedPumpedQuantity")}</div>
                    <div className="panelTitle">
                      <div className="quantityLine">
                        <div>
                          <div className="panelTitleText">{t("deliverynote.quantity")}</div>
                          <div className="panelSubTitle">
                            {dl?.estimatedPumpedQuantity != null ? dl?.estimatedPumpedQuantity : t("notFilled")}
                          </div>
                        </div>
                        <div>
                          <div className="panelTitleText">{t("deliverynote.unitev")}</div>
                          <div className="panelSubTitle">m3</div>
                        </div>
                      </div>
                    </div>
                  </>
                  {/* ) : null} */}
                  {dl?.realPumpedQuantity != null ? (
                    <>
                      <div className="panelContent noborder-top">{t("deliverynote.realPumpedQuantity")}</div>
                      <div className="panelTitle">
                        <div className="quantityLine">
                          <div>
                            <div className="panelTitleText">{t("deliverynote.quantity")}</div>
                            <div className="panelSubTitle">{dl?.realPumpedQuantity}</div>
                          </div>
                          <div>
                            <div className="panelTitleText">{t("deliverynote.unitev")}</div>
                            <div className="panelSubTitle">m3</div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}

              {(props.isPumping || dl?.type == "concrete") && hasSecurityMeasures() ? (
                <>
                  <h5>{t("deliverynote.securityMeasures.title")}</h5>

                  {dl?.worksiteAccess != null ? (
                    <div className="hour">
                      <label className="checkbox ">
                        <input name="associated" type="checkbox" checked={dl?.worksiteAccess} disabled />
                        <span className="securityMeasuresLabel">
                          {t("deliverynote.securityMeasures.worksiteAccess")}
                        </span>
                      </label>
                    </div>
                  ) : null}
                  {dl?.presenceOfElectricalLines != null ? (
                    <div className="hour">
                      <label className="checkbox ">
                        <input name="associated" type="checkbox" checked={dl?.presenceOfElectricalLines} disabled />
                        <span className="securityMeasuresLabel">
                          {t("deliverynote.securityMeasures.presenceOfElectricalLines")}
                        </span>
                      </label>
                    </div>
                  ) : null}
                  {dl?.worksiteWithGutter != null ? (
                    <div className="hour">
                      <label className="checkbox ">
                        <input name="associated" type="checkbox" checked={dl?.worksiteWithGutter} disabled />
                        <span className="securityMeasuresLabel">
                          {t("deliverynote.securityMeasures.worksiteWithGutter")}
                        </span>
                      </label>
                    </div>
                  ) : null}
                  {dl?.parkingVehicle != null ? (
                    <div className="hour">
                      <label className="checkbox ">
                        <input name="associated" type="checkbox" checked={dl?.parkingVehicle} disabled />
                        <span className="securityMeasuresLabel">
                          {t("deliverynote.securityMeasures.parkingVehicle")}
                        </span>
                      </label>
                    </div>
                  ) : null}
                  {dl?.machineStabilization != null ? (
                    <div className="hour">
                      <label className="checkbox ">
                        <input name="associated" type="checkbox" checked={dl?.machineStabilization} disabled />
                        <span className="securityMeasuresLabel">
                          {t("deliverynote.securityMeasures.machineStabilization")}
                        </span>
                      </label>
                    </div>
                  ) : null}

                  {dl?.otherRisks != null && dl?.otherRisks != "" ? (
                    <div>
                      <div className="hour">
                        <div className="label">{t("deliverynote.securityMeasures.otherRisks")}</div>
                        <div className="time">{dl?.otherRisks}</div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}

              {/* {dl?.services != null && dl?.services.length > 0 ? <h5>{t("deliverynote.securityMeasures.otherRisks")}</h5> : null} */}

              <div className="panelRow">
                {dl?.services?.map((service) => {
                  return (
                    <div key={service.code}>
                      <div className="panelContent">{service.name}</div>
                      <div className="panelTitle">
                        <div className="quantityLine">
                          <div>
                            <div className="panelTitleText">{t("deliverynote.productQuantity")}</div>
                            <div className="panelSubTitle">{service.quantity}</div>
                          </div>
                          <div>
                            <div className="panelTitleText">{t("deliverynote.unitev")}</div>
                            <div className="panelSubTitle">{service.unit}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {hasCoordinates() && (
              <>
                <h5>{t("deliverynote.geoloc.map")}</h5>
                <div style={{ width: "100%", border: 0, backgroundColor: Colors.grey100 }}>
                  <div style={{ color: Colors.grey900, padding: 20 }}>{t("deliverynote.geoloc.title")}</div>
                </div>
                <iframe
                  style={{ width: "100%", height: 400, border: 0 }}
                  src={
                    process.env.PUBLIC_URL +
                    "/map.html?startLabel=" +
                    t("deliverynote.geoloc.startLabel") +
                    "&endLabel=" +
                    t("deliverynote.geoloc.endLabel") +
                    "&deliveryLabel=" +
                    t("deliverynote.geoloc.deliveryLabel") +
                    "&sitelat=" +
                    dl?.site?.latitude +
                    "&dlId=" +
                    id +
                    "&sitelng=" +
                    dl?.site?.longitude +
                    "&clientlat=" +
                    dl?.deliveryCustomer?.latitude +
                    "&clientlng=" +
                    dl?.deliveryCustomer?.longitude +
                    "&signlat=" +
                    dl?.signature?.latitude +
                    "&signlng=" +
                    dl?.signature?.longitude
                  }
                ></iframe>
              </>
            )}

            <h5>{t("deliverynote.deliveryTimes.title")}</h5>

            {!props.isPumping && (
              <div className="hour">
                <div className="label">{t("deliverynote.deliveryTimes.production")}</div>
                <div className="time">{moment(dl?.loadedAt).format("DD/MM HH:mm")}</div>
              </div>
            )}

            {
              // Roles.canSeeTracking() &&
              dl?.tracking != null &&
                dl?.tracking?.length > 0 &&
                dl?.tracking.filter((t) => t.step == "chartered").length > 0 &&
                dl?.tracking.filter((t) => t.step == "chartered")[0].date != null && (
                  <div className="tracking">
                    {moment(dl?.tracking.filter((t) => t.step == "chartered")[0].date).format("DD/MM HH:mm")}{" "}
                    <i>{t("deliverynote.deliveryTimes.chartered")}</i>
                  </div>
                )
            }

            {dl?.worksiteArrivalTime != null && (
              <div className="hour">
                <div className="label">{t("deliverynote.deliveryTimes.worksiteArrivalTime")}</div>
                <div className="time">{moment(dl?.worksiteArrivalTime).format("DD/MM HH:mm")}</div>
              </div>
            )}

            {dl?.type == "concrete" &&
              (dl?.worksiteUnloadingStartTime != null || dl?.worksiteUnloadingEndTime != null) && (
                <div>
                  {dl?.worksiteUnloadingStartTime != null && (
                    <div className="hour">
                      <div className="label">{t("deliverynote.deliveryTimes.worksiteUnloadingStartTime")}</div>

                      <div className="time">{moment(dl?.worksiteUnloadingStartTime).format("DD/MM HH:mm")}</div>

                      {/* <div className="time">{t("notFilled")}</div> */}
                    </div>
                  )}
                  {dl?.worksiteUnloadingEndTime != null && (
                    <div className="hour">
                      <div className="label">{t("deliverynote.deliveryTimes.worksiteUnloadingEndTime")}</div>

                      <div className="time">{moment(dl?.worksiteUnloadingEndTime).format("DD/MM HH:mm")}</div>
                    </div>
                  )}
                </div>
              )}

            {props.isPumping ? (
              <div>
                {dl?.worksiteInstallationStartTime != null && (
                  <div className="hour">
                    <div className="label">{t("deliverynote.deliveryTimes.worksiteInstallationStartTime")}</div>
                    <div className="time">{moment(dl?.worksiteInstallationStartTime).format("DD/MM HH:mm")}</div>
                  </div>
                )}

                {dl?.worksiteInstallationEndTime != null && (
                  <div className="hour">
                    <div className="label">{t("deliverynote.deliveryTimes.worksiteInstallationEndTime")}</div>
                    <div className="time">{moment(dl?.worksiteInstallationEndTime).format("DD/MM HH:mm")}</div>
                  </div>
                )}

                {dl?.worksitePumpingStartTime != null && (
                  <div className="hour">
                    <div className="label">{t("deliverynote.deliveryTimes.worksitePumpingStartTime")}</div>
                    <div className="time">{moment(dl?.worksitePumpingStartTime).format("DD/MM HH:mm")}</div>
                  </div>
                )}

                {dl?.worksitePumpingEndTime != null && (
                  <div className="hour">
                    <div className="label">{t("deliverynote.deliveryTimes.worksitePumpingEndTime")}</div>
                    <div className="time">{moment(dl?.worksitePumpingEndTime).format("DD/MM HH:mm")}</div>
                  </div>
                )}
              </div>
            ) : null}

            {
              // Roles.canSeeTracking() &&
              dl?.tracking != null &&
              dl?.tracking?.length > 0 &&
              dl?.tracking.filter((t) => t.step == "signature").length > 0 &&
              dl?.tracking.filter((t) => t.step == "signature")[0].date != null ? (
                <div className="tracking">
                  {moment(dl?.tracking.filter((t) => t.step == "signature")[0].date).format("DD/MM HH:mm")}{" "}
                  <i>{t("deliverynote.deliveryTimes.signature")}</i>
                </div>
              ) : null
            }

            {dl?.worksiteDepartureTime != null && (
              <div className="hour">
                <div className="label">{t("deliverynote.deliveryTimes.worksiteDepartureTime")}</div>
                <div className="time">{moment(dl?.worksiteDepartureTime).format("DD/MM HH:mm")}</div>
              </div>
            )}

            {dl?.additionalDocs != null && dl?.additionalDocs.length > 0 ? (
              <h5>{t("deliverynote.documents")}</h5>
            ) : null}

            <div className="documents">
              {dl?.additionalDocs?.map((doc) => {
                return <Document doc={doc} key={doc.id}></Document>;
              })}
            </div>

            {dl?.digitalDisclaimer != null ? (
              <>
                <h5>{t("deliverynote.digitalDisclaimer")}</h5>
                <div className="documents">
                  <Document doc={dl?.digitalDisclaimer} isDisclaimer={true} key={dl?.digitalDisclaimer.name}></Document>
                </div>
              </>
            ) : null}

            {dl?.transportLetter != null ? (
              <>
                <h5>{t("deliverynote.transportLetter")}</h5>
                <div className="documents">
                  <Document
                    docUrl={dl?.transportLetter}
                    isTransportLetter={true}
                    key={"Lettre de transport"}
                  ></Document>
                </div>
              </>
            ) : null}

            {
              // Roles.canSeeDriverInfos() &&
              (dl?.driverComment != "" && dl?.driverComment != null) ||
              (dl?.driverPhotos != null && dl?.driverPhotos?.length) > 0 ? (
                <div className="driverComments">
                  <h5>{t("deliverynote.driverDetails")}</h5>

                  {console.log("comment", dl?.driverComment)}
                  {dl?.driverComment != "" && dl?.driverComment != null && (
                    <div className="">
                      <div className="hour">
                        <div>{t("deliverynote.driverComment")}</div>
                        <div className="clientComment">{dl?.driverComment}</div>
                      </div>
                    </div>
                  )}

                  <div className="driverPhotos">
                    {dl?.driverPhotos?.map((photo, index) => {
                      return <img key={index} src={photo.url} onClick={() => openImageViewer(index)}></img>;
                    })}
                  </div>
                </div>
              ) : null
            }

            {isViewerOpen ? (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
              />
            ) : null}

            {canShowDetails() && (
              <>
                <h5>{t("deliverynote.clientDetails")}</h5>

                <div className="details">
                  {dl?.deliveryCustomerComment != "" && dl?.deliveryCustomerComment != null && (
                    <>
                      <h5>{t("deliverynote.clientDetails")}</h5>
                      <div className="hour">
                        <div>{t("deliverynote.clientComments")}</div>
                        <div className="clientComment">{dl?.deliveryCustomerComment}</div>
                      </div>
                    </>
                  )}
                  <div className="hour">
                    {/* <div>Signature</div> */}
                    {dl?.signature?.signedAt != null ? (
                      <>
                        <div>{t("deliverynote.signature")}</div>
                        <div className="signerName">{dl?.signature?.signerName}</div>
                        <img src={dl?.signature?.file?.url} height="200" className="signature" />
                      </>
                    ) : null}

                    {isLoading ||
                    !dl?.collectedByClient ||
                    dl?.status == "validated" ||
                    dl?.status == "refused" ||
                    dl?.status == "canceled" ||
                    dl?.status == "partially_refused" ||
                    dl?.signature?.signedAt != null ||
                    !Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_NOTE_VALIDATE) ? null : (
                      <div className="validateBtn" onClick={() => startSignature()}>
                        <h6>{t("actions.sign")}</h6>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div style={{ height: 100 }}></div>
      </div>

      <PopPop
        open={isOpenCancelIsDelivered}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenCancelIsDelivered(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <ConfirmPopup
          message={t("deliverynote.confirmDelivery")}
          confirm={() => onCancelIsDeliveredSuccess(true)}
          cancel={() => onCancelIsDeliveredSuccess(false)}
          cancelMessage={t("no")}
          closeMessage={t("actions.close")}
          close={onCancelIsDeliveredCancel}
          confirmMessage={t("yes")}
        />
      </PopPop>

      <PopPop
        open={isOpenCancelConfirm}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenCancelConfirm(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <ConfirmPopup
          message={isDelivered ? t("deliverynote.confirmDeliveryQuestion") : t("deliverynote.cancelDeliveryQuestion")}
          confirm={onCancelSuccess}
          cancel={onCancelCancel}
          cancelMessage={t("no")}
          confirmMessage={t("yes")}
        />
      </PopPop>

      <PopPop
        open={isOpenSignDeliveryNote}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenSignDeliveryNote(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <SignDeliveryNote
          onSuccessSign={onSuccesSign}
          onSignError={onSignError}
          onCancelSign={onCancelSign}
          deliveryNote={dl}
          deliveryNoteUuid={id}
        />
      </PopPop>
    </div>
  );
});

export default DeliveryNote;
