import React, { useEffect, useState } from "react";
import "./Customers.scss";
import "../styles/table.scss";
import "../styles/popup.scss";
import axios from "axios";
import { useForm } from "react-hook-form";
import Config from "../Config";
import Select, { createFilter } from "react-select";
import Site from "../models/Site";
import Region from "../models/Region";
import { ReactComponent as Close } from "../assets/svg/close.svg";
import PopupStyle from "../styles/popupContentStyle";
import PopPop from "react-poppop";
import ConfirmPopup from "./ConfirmPopup";
import { useTranslation } from "react-i18next";

interface AddRegionSiteProps {
  onSuccess(siteLength: number): void;
  onSuccessDeleteSite(siteUuid: string | undefined): void;
  currentRegion?: Region;
}

const AddRegionSite = React.memo((props: AddRegionSiteProps) => {
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [isLoadingSites, setIsLoadingSites] = useState(false);
  const [isOpenConfirmDeleteSite, setIsOpenConfirmDeleteSite] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterText, setFilterText] = useState("");
  const [sites, setSites] = useState<any[]>([]);
  const [currentSiteToDelete, setCurrentSiteToDelete] = useState<Site>();

  useEffect(() => {}, [props.currentRegion]);

  const { t } = useTranslation();

  const { handleSubmit, errors } = useForm({
    // resolver: yupResolver(schema),

    reValidateMode: "onBlur",
  });

  const updateSites = (newSites: any) => {
    setSites(newSites);
    setErrorMessage("");
  };

  const deleteSite = (site: Site | undefined) => {
    setCurrentSiteToDelete(site);
    setIsOpenConfirmDeleteSite(true);
  };

  const onDeleteSiteOk = () => {
    let token = localStorage.getItem("token");

    let dataToSend = {
      region: props.currentRegion?.uuid,
      site: currentSiteToDelete?.uuid,
    };

    let url = `regions/sites/remove`;
    axios
      .post(Config.getUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsAddingLoading(false);
        props.onSuccessDeleteSite(currentSiteToDelete?.uuid);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 400) {
            if (error.response.data.code == "ERR4010003" || error.response.data.code == "ERR4000301") {
              //   setErrorMessage("Le site est déjà associé à la région");
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const onSubmit = () => {
    setIsAddingLoading(true);

    let token = localStorage.getItem("token");

    let dataToSend = {
      region: props.currentRegion?.uuid,
      sites: Array.from(sites, (x) => x.value),
    };

    let url = `regions/sites/add`;
    axios
      .post(Config.getUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsAddingLoading(false);
        props.onSuccess(sites.length);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 400) {
            if (error.response.data.code == "ERR4010003" || error.response.data.code == "ERR4000301") {
              setErrorMessage(t("regions.addSite.error")); //"Le site est déjà associé à la région"
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const filterConfig = {
    stringify: (option: any) => `${option.label}`,
  };

  const fetchSites = (fitlerText: string) => {
    setFilterText(filterText);
    if (fitlerText.length > 1) {
      setIsLoadingSites(true);
      const token = localStorage.getItem("token");

      let baseUrl = "sites";
      let url = `${baseUrl}?page=${1}&limit=${200}&search=${fitlerText}`;

      axios
        .get(Config.getUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoadingSites(false);
          setSitesOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoadingSites(false);
          setSitesOptions([]);
        });
    } else {
      setSitesOptions([]);
    }
  };

  const setSitesOptions = (sites: Site[]) => {
    let options: any[] = [];

    if (sites != null) {
      sites.forEach((s) => {
        let option = {
          value: s.uuid,
          label: s.name,
        };
        options.push(option);
      });

      setOptions(options);
    }
  };

  return (
    <div className="popup">
      <div className="title">{t("sites.add.title")}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="label" style={{ marginBottom: 20 }}>
            {t("regions.addSite.list")}
          </div>

          {props.currentRegion != null &&
          props.currentRegion.sites != null &&
          props.currentRegion?.sites?.length > 0 ? (
            <div>
              {props.currentRegion?.sites.map((site: Site) => {
                return (
                  <div key={site.uuid} className="row">
                    <div style={{ paddingTop: 0, cursor: "pointer" }} onClick={() => deleteSite(site)}>
                      <Close
                        className="icon"
                        width="20"
                        height="20"
                        fill={"#90a0b7"}
                        // onClick={() => onDeleteEmail(email)}
                      />
                    </div>
                    <div style={{ marginLeft: 10 }}> {site.externalReference}</div>
                    <div style={{ marginLeft: 10 }}>{site.name}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div> {t("regions.addSite.list_noSite")}</div>
          )}

          <Select
            className="input"
            isMulti={true}
            isLoading={isLoadingSites}
            options={options}
            loadingMessage={() => t("loading")}
            noOptionsMessage={() =>
              filterText.length < 2 ? t("deliverynote.filter.placeholderLength", { length: 2 }) : t("noOptions")
            }
            placeholder={t("regions.addSite.choose")}
            onInputChange={(e) => fetchSites(e)}
            isSearchable={true}
            onChange={(input) => {
              input != null ? updateSites(input) : null;
            }}
            filterOption={createFilter(filterConfig)}
          />

          <p>{t(errors.role?.message)}</p>
        </div>
        <div>{errorMessage}</div>
        <button type="submit" disabled={isAddingLoading}>
          <div>{isAddingLoading ? t("saving") : t("actions.add")}</div>
        </button>

        <PopPop
          open={isOpenConfirmDeleteSite}
          closeOnOverlay={true}
          closeOnEsc={true}
          onClose={() => setIsOpenConfirmDeleteSite(false)}
          className="popup"
          position="topCenter"
          contentStyle={PopupStyle.popupContentStyle}
          overlayStyle={PopupStyle.overlaystyle}
        >
          <ConfirmPopup
            message={t("regions.deleteSite.question", {
              site: currentSiteToDelete?.name,
              region: props.currentRegion?.name,
            })}
            confirm={() => onDeleteSiteOk()}
            cancel={() => setIsOpenConfirmDeleteSite(false)}
            cancelMessage={t("no")}
            confirmMessage={t("yes")}
          />
        </PopPop>
      </form>
    </div>
  );
});

export default AddRegionSite;
