export enum Scopes {
  SCOPE_EXTRANET_DELIVERY_NOTE_MANAGE = "extranet.delivery_note.manage",
  SCOPE_EXTRANET_DELIVERY_NOTE_VIEW = "extranet.delivery_note.view",
  SCOPE_EXTRANET_DRIVER_MANAGE = "extranet.driver.manage",
  SCOPE_EXTRANET_DRIVER_VIEW = "extranet.driver.view",
  SCOPE_EXTRANET_DRIVER_CREATE = "extranet.driver.create",
  SCOPE_EXTRANET_DRIVER_REMOVE = "extranet.driver.remove",
  SCOPE_EXTRANET_USER_MANAGE = "extranet.user.manage",
  SCOPE_EXTRANET_USER_VIEW = "extranet.user.view",
  SCOPE_CORE_USER_CHANGE_PASSWORD = "core.user.password",
  SCOPE_EXTRANET_VEHICLE_MANAGE = "extranet.vehicle.manage",
  SCOPE_EXTRANET_VEHICLE_VIEW = "extranet.vehicle.view",
  SCOPE_EXTRANET_BILLING_CUSTOMER_MANAGE = "extranet.billing_customer.manage",
  SCOPE_EXTRANET_BILLING_CUSTOMER_VIEW = "extranet.billing_customer.view",
  SCOPE_EXTRANET_DELIVERY_CUSTOMER_MANAGE = "extranet.delivery_customer.manage",
  SCOPE_EXTRANET_DELIVERY_CUSTOMER_VIEW = "extranet.delivery_customer.view",
  SCOPE_EXTRANET_CARRIER_MANAGE = "extranet.carrier.manage",
  SCOPE_EXTRANET_CARRIER_VIEW = "extranet.carrier.view",
  SCOPE_EXTRANET_SITE_MANAGE = "extranet.site.manage",
  SCOPE_EXTRANET_SITE_VIEW = "extranet.site.view",
  SCOPE_EXTRANET_DELIVERY_NOTE_EMAILS = "extranet.delivery_note.emails",
  SCOPE_EXTRANET_BILLING_CUSTOMER_EMAILS = "extranet.billing_customer.emails",
  SCOPE_EXTRANET_DELIVERY_CUSTOMER_EMAILS = "extranet.delivery_customer.emails",
  SCOPE_EXTRANET_CARRIER_EMAILS = "extranet.carrier.emails",
  SCOPE_EXTRANET_DELIVERY_NOTE_VALIDATE = "extranet.delivery_note.validate",

  SCOPE_EXTRANET_PUMPING_NOTE_MANAGE = "extranet.pumping_note.manage",
  SCOPE_EXTRANET_PUMPING_NOTE_VALIDATE = "extranet.pumping_note.validate",
  SCOPE_EXTRANET_PUMPING_NOTE_VIEW = "extranet.pumping_note.view",
  SCOPE_EXTRANET_PUMPING_NOTE_EMAILS = "extranet.pumping_note.emails",
  SCOPE_EXTRANET_PUMPING_NOTE_VALIDATION_NOTIFICATION = "extranet.pumping_note.validation_notification",
  SCOPE_EXTRANET_PUMPING_NOTE_PREVIEW = "extranet.pumping_note.preview",

  SCOPE_EXTRANET_PUMPER_MANAGE = "extranet.pumper.manage",
  SCOPE_EXTRANET_PUMPER_VIEW = "extranet.pumper.view",
  SCOPE_EXTRANET_PUMPER_EMAILS = "extranet.pumper.emails",
  SCOPE_EXTRANET_PUMPING_NOTE_ASSOCIATE = "extranet.pumping_note.associate",
  SCOPE_EXTRANET_DELIVERY_NOTE_STATS = "extranet.delivery_note.stats",
  SCOPE_EXTRANET_PUMPING_NOTE_STATS = "extranet.pumping_note.stats",

  SCOPE_EXTRANET_REGION_VIEW = "extranet.region.view",
  SCOPE_EXTRANET_REGION_MANAGE = "extranet.region.manage",

  SCOPE_EXTRANET_PUMPING_NOTE_ACCEPT = "extranet.pumping_note.accept",

  SCOPE_EXTRANET_VEHICLES_MANAGE_LOCATION = "extranet.vehicle.manage.location",
}
