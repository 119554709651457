/*eslint-disable */
import React, { useEffect, useState } from "react";
import "./Login.scss";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Config from "../Config";
import SelectWorkspace from "./SelectWorkspace";
import Roles from "../utils/Roles";
import PopupStyle from "../styles/popupContentStyle";
import PopPop from "react-poppop";
import Filters from "../utils/Filters";
import { FilterType } from "../models/FilterType";
import ColumnsFilter from "../utils/ColumnsFilter";
import { DeliveryNoteTypes } from "./DeliveryNotes";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../logo.svg";
import { Colors } from "../utils/Colors";

const schema = yup.object().shape({
  email: yup.string().email("login.error.email.invalid").required("login.error.email.required"),
  password: yup.string().required("errors.password.required"),
});

function Login() {
  const [email, setEmail] = useState("");

  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resultResetPassword, setResultResetPassword] = useState("");

  const [isSelectingOrga, setIsSelectingOrga] = useState(false);
  const [isOpenForgotPassword, setIsOpenForgotPassword] = useState(false);

  const history = useHistory();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const { t } = useTranslation();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  const updatePassword = (password: string) => {
    setPassword(password);
    setErrorMessage("");
  };

  const updateEmail = (email: string) => {
    let emailWithoutSpace = email.replace(/\s/g, "");
    setEmail(emailWithoutSpace);
    setErrorMessage("");
  };

  const chooseWorkspace = () => {
    history.push("/deliverynotes");
  };
  const onSubmit = () => {
    let dataToSend = {
      username: email,
      password: password,
    };
    axios
      .post(Config.get_API_EXTRANET_URL() + "auth/login", dataToSend)
      .then((res) => {
        const token = res.data.content.accessToken;
        localStorage.setItem("token", token);
        localStorage.setItem("email", dataToSend.username);
        getScopes();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage("login.error.invalid");
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const onSendEmail = () => {
    let dataToSend = {
      email: resetPasswordEmail,
    };
    axios
      .post(Config.get_API_EXTRANET_URL() + "auth/reset-password", dataToSend)
      .then(() => {
        setResultResetPassword("login.resetpassword.success");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage("login.error.invalid");
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const getScopes = () => {
    const token = localStorage.getItem("token");
    axios
      .get(Config.get_API_URL() + "me?d=" + new Date().toISOString(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Roles.saveWorkspaces(res.data.content.workspaces);
        Roles.saveUser(res.data.content?.uuid);

        if (res.data.content.workspaces.length == 2) {
          Filters.clear(FilterType.DeliveryNote);
          history.push("/deliverynotes");
        } else {
          setIsSelectingOrga(true);
        }

        if (res.data.content.tablesCustomization != null) {
          let normalColumns = res.data.content.tablesCustomization.normal;
          if (normalColumns != null && normalColumns.length > 0) {
            ColumnsFilter.updateColumns(normalColumns, DeliveryNoteTypes.normal);
          }

          let pumpingColumns = res.data.content.tablesCustomization.pumping;
          if (pumpingColumns != null && pumpingColumns.length > 0) {
            ColumnsFilter.updateColumns(pumpingColumns, DeliveryNoteTypes.pumping);
          }
          let departureColumns = res.data.content.tablesCustomization.departure;
          if (departureColumns != null && departureColumns.length > 0) {
            ColumnsFilter.updateColumns(departureColumns, DeliveryNoteTypes.departure);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage("login.error.invalid");
          } else {
            setErrorMessage("common.error");
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  return (
    <div className="login">
      <div className="leftImage"></div>
      <div className="right">
        <div className="content">
          <div style={{ textAlign: "center" }}>
            <Logo fill={Colors.primary}></Logo>
          </div>

          <h1>{isSelectingOrga ? t("login.selectWorkspace") : t("login.goToDn")} </h1>

          {!isSelectingOrga ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h5>{t("login.email")}</h5>

              <input
                type="text"
                name="email"
                ref={register}
                value={email}
                placeholder="John.snow@gmail.com"
                onChange={(e) => updateEmail(e?.target.value.toString())}
              />
              <p>{t(errors.email?.message)}</p>

              <h5>{t("login.password")}</h5>

              <input
                type="password"
                name="password"
                ref={register}
                value={password}
                onChange={(e) => updatePassword(e?.target.value?.toString())}
              />

              <p>{t(errors.password?.message)}</p>

              <p>{t(errorMessage)}</p>

              <div onClick={() => setIsOpenForgotPassword(true)} className="link">
                {t("login.forgot-password")}
              </div>
              <button className="validateBtn" type="submit">
                <h6>{t("login.submit")}</h6>
              </button>

              <MadeBySynaxe />
            </form>
          ) : (
            <div>
              <p className="chooseWorkspaceExplanation">{t("login.choose-workspace-explanation")}</p>
              <SelectWorkspace />

              <button className="validateBtn" type="submit" onClick={() => chooseWorkspace()}>
                <h6>{t("login.choose-workspace.submit")}</h6>
              </button>
            </div>
          )}
        </div>
      </div>

      <PopPop
        open={isOpenForgotPassword}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenForgotPassword(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <div className={"popup confirmPopup"}>
          <div className="title">{t("login.forgot-password")}</div>

          <h5>{t("login.email")}</h5>

          <input
            type="text"
            name="email"
            value={resetPasswordEmail}
            placeholder="John.snow@gmail.com"
            onChange={(e) => setResetPasswordEmail(e?.target.value.toString())}
          />

          <div style={{ marginTop: 20 }}>{t(resultResetPassword)}</div>
          <div className={"row"}>
            <button type="button" className="cancel" onClick={() => setIsOpenForgotPassword(false)}>
              <div>{t("actions.cancel")}</div>
            </button>
            <button type="button" onClick={() => onSendEmail()}>
              <div>{t("actions.validate")}</div>
            </button>
          </div>
        </div>
      </PopPop>
    </div>
  );
}

export function MadeBySynaxe() {
  return (
    <div style={{ textAlign: "center" }}>
      <h6>
        <span style={{ color: Colors.grey500, fontWeight: 500 }}>Made in </span>
        <a
          className="link"
          style={{ textDecoration: "underline", fontWeight: 500, display: "inline", color: Colors.primary }}
          href="https://www.synaxe.com/"
          target="_blank"
        >
          Synaxe
        </a>
      </h6>
    </div>
  );
}
export default Login;
