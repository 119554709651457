import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Languages } from "../models/Languages";

const UpdateLanguage = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState<Languages>(i18n.language as Languages);

  let changeLanguage = (language: string) => {
    switch (language) {
      case Languages.EN:
        setLang(Languages.EN);
        i18n.changeLanguage(Languages.EN);
        localStorage.setItem("sxd_language", "en");
        break;
      case Languages.FR:
      default:
        setLang(Languages.FR);
        localStorage.setItem("sxd_language", "fr");
        i18n.changeLanguage(Languages.FR);
        break;
    }
  };

  useEffect(() => {
    let lang = localStorage.getItem("sxd_language");

    setTimeout(() => {
      if (lang !== null) {
        changeLanguage(lang);
      }
    }, 100);
  }, []);

  return (
    <div className={"languageChoose"}>
      <div>
        <select value={lang} name="language" onChange={(data) => changeLanguage(data.target.value)}>
          <option value={Languages.FR}>FR</option>
          <option value={Languages.EN}>EN</option>
        </select>
      </div>
    </div>
  );
};

export default UpdateLanguage;
