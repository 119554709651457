/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import "./DeliveryNotes.scss";
import axios from "axios";
import Deliverynote, { PumpingNoteStatus } from "../models/Deliverynote";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import Menu from "./Menu";
import TableStyles from "../styles/tableStyles";
import "../styles/table.scss";
import Config from "../Config";
import SearchBar from "./SearchBar";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { ReactComponent as Calendar } from "../assets/svg/calendar.svg";
import Roles from "../utils/Roles";
import Filters from "../utils/Filters";
import Select from "react-select";
import Site from "../models/Site";
import PopupStyle from "../styles/popupContentStyle";
import PopPop from "react-poppop";
import { ReactComponent as Settings } from "../assets/svg/settings.svg";
import { ReactComponent as Cloud } from "../assets/svg/cloud.svg";
import ExportCSV from "./ExportCSV";
import { FilterType } from "../models/FilterType";
import AssignPumperTruck from "./AssignPumperTruck";
import Vehicle from "../models/Vehicle";
import { Scopes } from "../models/Scopes";
import { ReactComponent as Eye } from "../assets/svg/eye.svg";
import { ReactComponent as EyeOff } from "../assets/svg/eye-off.svg";
import { saveAs } from "file-saver";
import ToastComponent from "./ToastComponent";
import Toast from "../models/Toast";
import { ToastTypes } from "../models/ToastTypes";
import ConfirmPopup from "./ConfirmPopup";
import { useOuterClick } from "react-outer-click";
import { Colors } from "../utils/Colors";
import ColumnsFilter from "../utils/ColumnsFilter";
import JSZip from "jszip";
import usePrevious from "./usePrevious";
import pusherJs, { Channel } from "pusher-js";
import { ReactComponent as Search } from "../assets/svg/search.svg";
import { useTranslation } from "react-i18next";
import i18n from "../Lang";

export interface DeliveryotesProps {
  type: DeliveryNoteTypes;
}

export enum DeliveryNoteTypes {
  normal = "normal",
  departure = "departure",
  pumping = "pumping",
}

interface DnFilters {
  filterDeliveryCustomer?: string | undefined;
  filterCarrier?: string | undefined;
  startDate?: Date | null;
  endDate?: Date | null;
  filterBillingCustomer?: string | undefined;
  filterTruck?: string | undefined;
  filterDnNumber?: string | undefined;
  withDriverCommentsFilter?: boolean;
  withCustomerCommentsFilter?: boolean;
  withAdditionnalServices?: boolean;
  statusesFilter?: any[];
  filterSiteText?: string;
  sitesFilterValue?: FilterSite[];
  filterSites?: FilterSite[];
  isOpenFilters?: boolean;
  perPage?: number;
  pageNumber?: number;
  searchGlobal?: string;
  lastOpennedDnUuid?: string;
  urlFilterBySite?: string;
  urlFilterByCarrier?: string;
  urlFilterByDeliveryCustomer?: string;
  urlFilterByBillingCustomer?: string;
  collectedFilterValue?: FilterSite[];
  dematFilterValue?: FilterSite[];
  withReturnedProduct?: boolean;
  sort?: any;
  orderReference?: string;
  customField1?: string;
}

interface FilterSite {
  label: string | undefined;
  value: string | undefined;
}

enum DnFiltersType {
  DeliveryCustomer,
  StartDate,
  EndDate,
  Carrier,
  BillingCustomer,
  WithDriverCommentsFilter,
  WithCustomerCommentsFilter,
  WithAdditionnalServices,
  DnNumber,
  Truck,
  IsOpenFilters,
  DnStatus,
  SitesFilter,
  PageNumber,
  PerPage,
  SearchGlobal,
  LastOpennedDnUuid,
  CollectedFilters,
  DematFilters,
  WithReturnedProduct,
  Sort,
  OrderReference,
  CustomField1,
}

let timer: any;
const DeliveryNotes = React.memo((props: DeliveryotesProps) => {
  const ref = React.useRef(null);

  useOuterClick(ref, () => {
    setIsOpenFilterColumns(false);

    // console.log(ref);
    // let tet: any = ref?.current;
    // if (tet.className != "filter") {

    // }
  });

  const { t } = useTranslation();

  const perPageDefault = 50;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingNewDns, setIsLoadingNewDns] = useState<boolean>(false);
  const [isDateSelected, setIsDateSelected] = useState<boolean>(false);
  const [filteredItems, setFilterereditems] = useState<Deliverynote[]>([]);

  const [isLoadingSites, setIsLoadingSites] = useState(false);
  const [isOpenAssignPumpingNote, setIsOpenAssignPumpingNote] = useState(false);
  const [currrentPumpingNoteToAssign, setCurrrentPumpingNoteToAssign] = useState<Deliverynote>();
  const [filters, setFilters] = useState<DnFilters>({});

  const [filterSiteText, setFilterSiteText] = useState("");
  const [siteOptions, setSiteOptions] = useState<any[]>([]);
  const [totalRows, settotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);

  const [sortedField, setSortField] = useState("loadedAt");
  const [sortAsc, setSortAsc] = useState(false);
  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const [toasts, setToast] = useState<Toast[]>([]);

  const [startRefuseCommand, setStartRefuseCommand] = useState(false);
  const [isOpenUpdateCommand, setIsOpenUpdateCommand] = useState(false);

  const [dataSended, setDataSended] = useState<any>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [fullColumns, setFullColumns] = useState<any[]>([]);

  const [isOpenFilterColumns, setIsOpenFilterColumns] = useState(false);

  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

  const [isLastPage, setIsLastPage] = useState(false);

  const filterByCarrier = new URLSearchParams(window.location.search).get("carrier");
  const filterByBillingCustomer = new URLSearchParams(window.location.search).get("bcustomer");
  const filterByDeliveryCustomer = new URLSearchParams(window.location.search).get("dcustomer");
  const filterBySite = new URLSearchParams(window.location.search).get("site");

  const clearParams = () => {
    clearParam("carrier");
    clearParam("bcustomer");
    clearParam("site");
    clearParam("dcustomer");
  };

  const clearParam = (param: string) => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has(param)) {
      queryParams.delete(param);
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  const subscribe = () => {
    let workspace = Roles.getCurrentWorkspace();

    if (workspace?.organization?.uuid != undefined && Roles.isSite()) {
      let currentWindow: any = window;

      let pusher: any = null;
      if (currentWindow.Pusher.instances.length > 0) {
        pusher = currentWindow.Pusher.instances[0];
      } else {
        let pusherKey = process.env.REACT_APP_PUSHER_KEY ?? "";
        pusher = new pusherJs(pusherKey, {
          cluster: "eu",
        });
      }

      pusher.allChannels().forEach((channel: Channel) => {
        pusher.unsubscribe(channel.name);
      });

      const channel1 = pusher.subscribe(workspace?.organization?.uuid);
      channel1.bind("new_delivery_note", function (data: any) {
        addToast(t("deliverynote.newDn"), ToastTypes.info); //"Un nouveau bon de livraison Départ est disponible"

        let sortBy = {
          sortedField: "loadedAt",
          orderBy: "desc",
        };

        let filters: DnFilters = {
          startDate: new Date(),
          endDate: new Date(),
        };

        if (currentType.current == DeliveryNoteTypes.departure) {
          fetchDns(1, perPageDefault, sortBy, filters);
        }

        //Code which runs when your channel listens to a new message
      });
    }
  };

  const CalendarInput = ({ onClick, style }: any) => (
    <div className={isDateSelected ? "calendar-input dateselected" : "calendar-input "} style={style} onClick={onClick}>
      <span className="icon">
        <Calendar className={"icon"} width="25" height="20" fill={isDateSelected ? "white" : Colors.primary} />
      </span>
      {isDateSelected ? (
        <div className="date-filter-badge" style={{ top: style != null ? -4 : 10 }}>
          {" "}
        </div>
      ) : null}
    </div>
  );
  const saveFilter = (currentFilters: DnFilters) => {
    Filters.updateFilter(currentFilters, FilterType.DeliveryNote);
  };

  const startAssignPumpingNote = (pumpingNote: Deliverynote) => {
    setCurrrentPumpingNoteToAssign(pumpingNote);
    setIsOpenAssignPumpingNote(true);
  };

  const startAcceptPumpingNote = (pumpingNote: Deliverynote) => {
    setStartRefuseCommand(false);
    setCurrrentPumpingNoteToAssign(pumpingNote);

    setIsOpenUpdateCommand(true);
  };

  const startRefusePumpingNote = (pumpingNote: Deliverynote) => {
    setStartRefuseCommand(true);
    setCurrrentPumpingNoteToAssign(pumpingNote);

    setIsOpenUpdateCommand(true);
  };

  const confirmUpdateCommand = () => {
    let token = localStorage.getItem("token");
    let dataToSend: any = {
      accepted: !startRefuseCommand,
    };
    axios
      .post(Config.getUrl(`pumping-notes/${currrentPumpingNoteToAssign?.uuid}/acceptedOrder`), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        addToast(
          t(startRefuseCommand ? "deliverynote.refuseCommand.success" : "deliverynote.acceptCommand.success"),
          ToastTypes.success
        );
        setIsOpenUpdateCommand(false);
        fetchDns(filters.pageNumber, filters.perPage, filters.sort);
      })
      .catch(() => {
        // setIsAddingLoading(false);
        addToast(t("common.error"), ToastTypes.error);
      });
  };

  const onSuccessAssign = (registrationPlate: string | undefined) => {
    let newFilteredItems = JSON.parse(JSON.stringify(filteredItems));
    let newItem: Deliverynote = newFilteredItems.filter(
      (f: Deliverynote) => f.uuid == currrentPumpingNoteToAssign?.uuid
    )[0];
    newItem.vehicle = new Vehicle();
    newItem.vehicle.registrationPlate = registrationPlate;
    newItem.vehicle.externalReference = registrationPlate;
    setFilterereditems(newFilteredItems);
    setIsOpenAssignPumpingNote(false);

    addToast(t("deliverynote.assign.success"), ToastTypes.success); //"Le bon de mise à disposition a bien été assigné"
    fetchDns(filters.pageNumber, filters.perPage, filters.sort);
  };

  const onErrorAssign = () => {
    setIsOpenAssignPumpingNote(false);
    addToast(t("deliverynote.assign.error"), ToastTypes.error);
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const hasCommand = () => {
    return (
      Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_ACCEPT) &&
      props.type == DeliveryNoteTypes.pumping &&
      filteredItems.filter((p) => p.status == PumpingNoteStatus.ORDERED).length > 0
    );
  };

  const history = useHistory();

  const getProduct = (row: Deliverynote) => {
    if (row?.products != undefined) {
      let hasReturnableProduct = row?.products?.filter((p) => p.returnable).length > 0;
      if (hasReturnableProduct) {
        return row?.products?.filter((p) => p.returnable)[0];
      }
      const products = row?.products
        ?.filter(
          (p) =>
            p.unit != undefined &&
            (p.unit.toLowerCase() === "M3".toLowerCase() ||
              p.unit.toLowerCase() === "TON".toLowerCase() ||
              p.unit.toLowerCase() === "T".toLowerCase())
        )
        .sort((a, b) => {
          return a.quantity != undefined && b.quantity != undefined ? a.quantity - b.quantity : 0;
        });

      if (products != undefined && products?.length > 0) {
        return products[0];
      } else {
        return null;
      }
    }
    return null;
  };

  const getDefaultColumns = () => {
    let columns: any[] = [
      {
        id: "folioReference",
        isHidden: false,
      },
    ];

    columns.push({
      id: "completedByApp",
      isHidden: false,
    });

    if (props.type == DeliveryNoteTypes.pumping) {
      columns.push({
        id: "pumpingDate",
        isHidden: false,
      });
    } else {
      columns.push({
        id: "loadedAt",
        isHidden: false,
      });
    }

    if (props.type != DeliveryNoteTypes.departure) {
      columns.push({
        id: "status",
        isHidden: false,
      });
    }

    columns.push({
      id: "site",
      isHidden: false,
    });

    if (Roles.isTransporter() && props.type == DeliveryNoteTypes.normal) {
      columns.push({
        id: "purchaseZone",
        isHidden: false,
      });
    }

    if (Roles.isCarrier() && props.type == DeliveryNoteTypes.normal) {
      columns.push({
        id: "transportZone",
        isHidden: false,
      });
    }

    if (Roles.isUserClient() && props.type == DeliveryNoteTypes.normal) {
      columns.push({
        id: "transportZone",
        isHidden: false,
      });
    }

    columns.push({
      id: "billingCustomerName",
      isHidden: false,
    });

    // columns.push({
    //   id: "billingCustomerReference",
    //   isHidden: false,
    // });

    columns.push({
      id: "deliveryCustomer",
      isHidden: false,
    });

    if (props.type == DeliveryNoteTypes.normal) {
      columns.push({
        id: "carrier",
        isHidden: false,
      });

      columns.push({
        id: "registrationPlate",
        isHidden: false,
      });

      columns.push({
        id: "vehicleName",
        isHidden: false,
      });
    }

    if (props.type != DeliveryNoteTypes.pumping) {
      columns.push({
        id: "product",
        isHidden: false,
      });

      columns.push({
        id: "productQuantity",
        isHidden: false,
      });

      columns.push({
        id: "productUnit",
        isHidden: false,
      });
    }

    if (props.type == DeliveryNoteTypes.pumping) {
      columns.push({
        id: "pumper",
        isHidden: false,
      });

      columns.push({
        id: "pumpType",
        isHidden: false,
      });

      columns.unshift({
        id: "registrationPlate",
        isHidden: false,
      });

      columns.push({
        id: "estimatedPumpedQuantity",
        isHidden: false,
      });

      columns.push({
        id: "realPumpedQuantity",
        isHidden: false,
      });
    }

    columns.push({
      id: "deliveryCustomerComment",
      isHidden: false,
    });

    if (props.type == DeliveryNoteTypes.departure) {
      columns.unshift({
        id: "signBl",
        isHidden: false,
      });
    }

    if (Roles.affiateSiteRegion() && props.type == DeliveryNoteTypes.normal) {
      columns.push({
        id: "purchaseZone",
        isHidden: false,
      });
      columns.push({
        id: "transportZone",
        isHidden: false,
      });
    }

    columns.push({
      id: "orderReference",
      isHidden: false,
    });

    columns.push({
      id: "customField1",
      isHidden: false,
    });

    return columns;
  };

  const getColumns = () => {
    let newColumns: any[] = [];
    var orderColumns = getColumnsOrder();

    orderColumns.forEach((c) => {
      let col = createColumn(c);
      if (col != null) newColumns.push(col);
    });
    return newColumns;
  };

  const toggleColumn = (id: string) => {
    let newColumns = [...columns];
    let index = newColumns.findIndex((c) => c.id == id);
    if (index != -1) {
      newColumns[index].omit = !newColumns[index].omit;
      newColumns[index].isHidden = !newColumns[index].isHidden;
    }

    setColumns(newColumns);

    setTimeout(() => toggleFullColumn(id), 300);
  };

  const toggleFullColumn = (id: string) => {
    let newfullColumns = [...fullColumns];
    let indexFull = newfullColumns.findIndex((nc) => nc.id == id);
    if (indexFull != -1) {
      newfullColumns[indexFull].omit = newfullColumns[indexFull].omit;
      newfullColumns[indexFull].isHidden = newfullColumns[indexFull].isHidden;
    }
    setFullColumns(newfullColumns);

    saveColumnOrder(newfullColumns);
  };

  const onColumnOrderChange = (cols: any[]) => {
    setColumns(cols);

    let newfullColumns: any[] = [];
    cols.forEach((c) => {
      let currentCol = fullColumns.find((f) => f.id == c.id);
      newfullColumns.push(currentCol);
    });

    fullColumns.forEach((c) => {
      cols.filter((co) => co.id == c.id).length == 0 ? newfullColumns.push(c) : null;
    });
    setFullColumns(newfullColumns);
    saveColumnOrder(newfullColumns);
  };
  const showAllColumns = () => {
    let newColumns = [...columns];
    newColumns
      .filter((c) => c.name != "")
      .forEach((c) => {
        c.omit = false;
        c.isHidden = false;
      });

    setColumns(newColumns);

    let newFullColumns = [...fullColumns];
    newFullColumns
      .filter((c) => c.name != "")
      .forEach((c) => {
        c.omit = false;
        c.isHidden = false;
      });

    setFullColumns(newFullColumns);

    saveColumnOrder(newFullColumns);
  };

  const hideAllColumns = () => {
    let newColumns = [...columns];
    newColumns
      .filter((c) => c.cantHide != true)
      .forEach((c) => {
        c.omit = true;
        c.isHidden = true;
      });

    setColumns(newColumns);

    let newFullColumns = [...fullColumns];
    newFullColumns
      .filter((c) => c.cantHide != true)
      .forEach((c) => {
        c.omit = true;
        c.isHidden = true;
      });

    setFullColumns(newFullColumns);
    saveColumnOrder(newFullColumns);
  };

  const createColumn = (c: any) => {
    switch (c.id) {
      case "folioReference":
        return {
          isAuthorized: true,
          id: "folioReference",
          name: props.type == DeliveryNoteTypes.pumping ? "Num BMD" : "Num BL",
          cell: (row: any) => (
            <Link
              onClick={() => updateFilters(row.uuid, DnFiltersType.LastOpennedDnUuid)}
              className="table-link"
              to={goToDl(row.uuid)}
              style={{ textDecoration: "underline" }}
            >
              {row.folioReference != undefined ? row?.folioReference : row?.externalReference}
            </Link>
          ),
          sortable: true,
          reorder: false,
          omit: false,
          isHidden: false,
          cantHide: true,
        };
      case "pumpingDate":
        return {
          id: "pumpingDate",
          name: t("deliverynote.date"),
          reorder: false,
          isAuthorized: true,
          omit: false,
          isHidden: false,
          cantHide: true,
          selector: (row: Deliverynote) => (
            <div>
              {moment(row.pumpingDate).format("HH:mm")} <br /> {moment(row.pumpingDate).format("DD/MM/YYYY")}
            </div>
          ),
          sortable: false,
          sortFunction: (rowA: Deliverynote, rowB: Deliverynote) => {
            if (rowA.pumpingDate != null && rowB.pumpingDate != null) {
              return new Date(rowA.pumpingDate).getTime() - new Date(rowB.pumpingDate).getTime();
            } else {
              return 1;
            }
          },
        };
      case "transportZone": {
        if (props.type != DeliveryNoteTypes.normal || Roles.isTransporter()) {
          return null;
        }

        return {
          id: "transportZone",
          name: t("deliverynote.transportZone"), //"Zone transport
          reorder: true,
          isAuthorized: true,
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          selector: (row: Deliverynote) => row.transportZone,
          sortable: false,
        };
      }
      case "purchaseZone": {
        if (props.type != DeliveryNoteTypes.normal || Roles.isUserClient()) {
          return null;
        }
        return {
          id: "purchaseZone",
          name: t("deliverynote.purchaseZone"), // "Zone Achat",
          reorder: true,
          isAuthorized: true,
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          selector: (row: Deliverynote) => row.purchaseZone,
          sortable: false,
        };
      }
      case "completedByApp": {
        return {
          id: "completedByApp",
          name: t("deliverynote.completedByApp"), // "Démat.",
          reorder: true,
          isAuthorized: true,
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          center: true,
          selector: (row: Deliverynote) => (
            <div>
              {row.completedByApp ? (
                <div className="row" style={{ alignItems: "center", justifyContent: "center" }}>
                  <Cloud width={20} fill={Colors.primary} />{" "}
                </div>
              ) : null}{" "}
            </div>
          ),
          sortable: false,
        };
      }

      case "estimatedPumpedQuantity": {
        return {
          id: "estimatedPumpedQuantity",
          name: t("deliverynote.estimatedPumpedQuantity"), //"Quantité estimée",
          reorder: true,
          isAuthorized: true,
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          selector: (row: Deliverynote) => row.estimatedPumpedQuantity,
          sortable: false,
        };
      }

      case "orderReference": {
        return {
          id: "orderReference",
          name: t("deliverynote.orderReference"), //"Réf commande",
          reorder: true,
          isAuthorized: true,
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          selector: (row: Deliverynote) => row.orderReference,
          sortable: false,
        };
      }

      case "customField1": {
        return {
          id: "customField1",
          name: t("deliverynote.customField1"), //"Réf chantier",
          reorder: true,
          isAuthorized: true,
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          selector: (row: Deliverynote) => row.customField1,
          sortable: false,
        };
      }

      case "realPumpedQuantity": {
        return {
          id: "realPumpedQuantity",
          name: t("deliverynote.realPumpedQuantity"),
          reorder: true,
          isAuthorized: true,
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          selector: (row: Deliverynote) => row.realPumpedQuantity,
          sortable: false,
        };
      }
      case "pumpType": {
        return {
          id: "pumpType",
          isAuthorized: true,
          name: t("deliverynote.pumpType"),
          reorder: true,
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          selector: (row: Deliverynote) => row.pump?.pumpType,
          sortable: false,
        };
      }
      case "loadedAt":
        return {
          name: t("deliverynote.date"),
          id: "loadedAt",
          isAuthorized: true,
          omit: false,
          isHidden: false,
          cantHide: true,
          selector: (row: Deliverynote) => (
            <div>
              {moment(row.loadedAt).format("HH:mm")} <br /> {moment(row.loadedAt).format("DD/MM/YYYY")}
            </div>
          ),
          sortable: false,
          reorder: false,
          sortFunction: (rowA: Deliverynote, rowB: Deliverynote) => {
            if (rowA.loadedAt != null && rowB.loadedAt != null) {
              return new Date(rowA.loadedAt).getTime() - new Date(rowB.loadedAt).getTime();
            } else {
              return 1;
            }
          },
        };
      case "status":
        return {
          isAuthorized: !(Roles.isUserDelivery() || Roles.isUserBilling()),
          center: true,
          name: t("deliverynote.status.title"), //"Statut",
          omit: false,
          isHidden: false,
          cantHide: true,
          id: "status",
          reorder: false,
          selector: (row: Deliverynote) => (
            <div className={`status ${row.status}`}>{t("deliverynote.status." + row.status)}</div>
          ),
          sortable: false,
          sortFunction: (rowA: any, rowB: any) => {
            if (t("deliverynote.status." + rowA.status) < t("deliverynote.status." + rowB.status)) {
              return 1;
            } else return -1;
          },
        };
      case "site":
        return {
          isAuthorized: !Roles.isSite(),
          name: t("site"),
          id: "site",
          omit: Roles.isAffiliate() || Roles.isRegion() ? false : c.isHidden,
          isHidden: Roles.isAffiliate() || Roles.isRegion() ? false : c.isHidden,
          cantHide: Roles.isAffiliate() || Roles.isRegion(),
          reorder: Roles.isAffiliate() ? false : true,
          cell: (row: Deliverynote) => <div>{row?.site?.name}</div>,
          sortable: false,
          right: false,
        };
      case "billingCustomerName":
        return {
          isAuthorized: !(Roles.isUserDelivery() || Roles.isUserBilling()),
          name: t("customer"), //"Client",
          omit: c.isHidden,
          isHidden: c.isHidden,
          id: "billingCustomerName",
          cell: (row: Deliverynote) => <div>{row?.billingCustomer?.name}</div>,
          reorder: true,
          sortable: false,
          right: false,
        };
      case "billingCustomerReference":
        return null;

      case "product":
        return {
          name: t("deliverynote.product"),
          isAuthorized: true,
          id: "product",
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          cell: (row: Deliverynote) => <div>{getProduct(row)?.designation}</div>,
          sortable: false,
          right: false,
          reorder: true,
        };

      case "productQuantity":
        return {
          name: t("deliverynote.productQuantity"), // "Quantité",
          omit: c.isHidden,
          isAuthorized: true,
          isHidden: c.isHidden ?? false,
          id: "productQuantity",
          cell: (row: Deliverynote) => <div>{getProduct(row)?.quantity}</div>,
          sortable: false,
          right: false,
          reorder: true,
        };

      case "productUnit":
        return {
          name: t("deliverynote.productUnit"), // "Unité",
          omit: c.isHidden,
          isAuthorized: true,
          isHidden: c.isHidden ?? false,
          id: "productUnit",
          cell: (row: Deliverynote) => <div>{getProduct(row)?.unit}</div>,
          sortable: false,
          right: false,
          reorder: true,
        };
      case "deliveryCustomer":
        return {
          name: t("delivery"),
          id: "deliveryCustomer",
          omit: c.isHidden,
          isAuthorized: true,
          isHidden: c.isHidden ?? false,
          cell: (row: Deliverynote) => <div>{row?.deliveryCustomer?.name}</div>,
          sortable: false,
          right: false,
          reorder: true,
        };

      case "carrier":
        return {
          isAuthorized: !Roles.isCarrier() && !Roles.isPumper(),
          name: t("carrier"),
          id: "carrier",
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          cell: (row: Deliverynote) => <div>{row?.carrier?.name}</div>,
          sortable: false,
          reorder: true,
        };

      case "vehicleName":
        return {
          name: t("deliverynote.vehicleName"), //"Lib Camion",
          id: "vehicleName",
          isAuthorized: true,
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
          cell: (row: Deliverynote) => <div>{row?.vehicle?.externalReference}</div>,
          sortable: false,
          reorder: true,
        };
      case "deliveryCustomerComment":
        return {
          id: "deliveryCustomerComment",
          name: t("deliverynote.comments"), // "Commentaires",
          omit: c.isHidden,
          isAuthorized: true,
          isHidden: c.isHidden ?? false,
          selector: (row: any) => row.deliveryCustomerComment, // "deliveryCustomerComment",
          sortable: false,
          reorder: true,
        };
      case "registrationPlate":
        if (props.type == DeliveryNoteTypes.pumping) {
          return {
            name: t("deliverynote.vehicle"), // "Camion",
            id: "registrationPlate",
            selector: (row: any) => row.vehicle.registrationPlate,
            omit: false,
            isHidden: false,
            cantHide: true,
            sortable: false,
            reorder: true,
            isAuthorized: true,
            minWidth: "300",

            grow: hasCommand() ? 2 : 1,
            cell: (row: Deliverynote) =>
              row.vehicle != null &&
              row.vehicle.registrationPlate != null &&
              row.status != PumpingNoteStatus.ORDERED ? (
                Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_ASSOCIATE) &&
                (row.status == "active" ||
                  row.status == "accepted_order" ||
                  row.status == "confirmed_order" ||
                  row.status == "accepted") ? (
                  <div className="link" onClick={() => startAssignPumpingNote(row)}>
                    {row.vehicle.registrationPlate}
                  </div>
                ) : (
                  <div>{row.vehicle.registrationPlate}</div>
                )
              ) : canBeAssigned(row) ? (
                <div
                  className="validateBtn"
                  style={{ padding: 12, marginTop: 0 }}
                  onClick={() => startAssignPumpingNote(row)}
                >
                  <h6>{t("deliverynote.assign.title")}</h6>
                </div>
              ) : row.status == PumpingNoteStatus.ORDERED &&
                Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_ACCEPT) ? (
                <div className="" style={{ paddingTop: 5, paddingBottom: 5 }}>
                  <div
                    className="validateBtn"
                    style={{ padding: 6, marginTop: 6, textAlign: "center", fontSize: 12, width: "100%" }}
                    onClick={() => startAcceptPumpingNote(row)}
                  >
                    <h6>{t("deliverynote.acceptCommand.title")}</h6>
                  </div>

                  <div
                    className="cancelBtn"
                    style={{ padding: 6, marginTop: 6, textAlign: "center", fontSize: 12, width: "100%" }}
                    onClick={() => startRefusePumpingNote(row)}
                  >
                    {/* <h6>Refuser la commande</h6> */}
                    <h6>{t("deliverynote.refuseCommand.title")}</h6>
                  </div>
                </div>
              ) : null,
          };
        } else {
          return {
            name: t("vehicle"),
            isAuthorized: true,
            id: "registrationPlate",
            cell: (row: Deliverynote) => <div>{row?.vehicle?.registrationPlate}</div>,
            sortable: false,
            reorder: true,
            omit: c.isHidden ?? false,
            isHidden: c.isHidden ?? false,
          };
        }
      case "pumper":
        return {
          isAuthorized: !Roles.isPumper() && !Roles.isCarrier(),
          id: "pumper",
          name: t("pumper"),
          reorder: true,
          cell: (row: Deliverynote) => <div>{row?.pumper?.name}</div>,
          sortable: false,
          omit: c.isHidden ?? false,
          isHidden: c.isHidden ?? false,
        };
      // case "seeBl":
      //   return {
      //     name: "",
      //     id: "seeBl",
      //     sortable: false,
      //     reorder: false,
      //     omit: false,
      //     isAuthorized: true,
      //     isHidden: false,
      //     cantHide: true,
      //     cell: (row: any) => (
      //       <Link
      //         onClick={() => updateFilters(row.uuid, DnFiltersType.LastOpennedDnUuid)}
      //         className="table-link"
      //         to={goToDl(row.uuid)}
      //       >
      //         Voir
      //       </Link>
      //     ),
      //     button: true,
      //   };
      case "signBl":
        return {
          name: "",
          id: "signBl",
          sortable: false,
          reorder: true,
          omit: false,
          isHidden: false,
          cantHide: true,
          isAuthorized: true,
          cell: (row: any) => (
            <div className="validateBtn" style={{ padding: 12, marginTop: 0 }} onClick={() => goTosignDl(row.uuid)}>
              <h6>{t("actions.sign")}</h6>
            </div>
          ),
          button: true,
        };
    }
  };

  const conditionalRowStyles = [
    {
      when: (row: Deliverynote) => row.uuid == filters.lastOpennedDnUuid,
      style: {
        backgroundColor: "#f5f6f9",
      },
    },
  ];

  const canBeAssigned = (pumpinNote: Deliverynote) => {
    return (
      Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_ASSOCIATE) &&
      (pumpinNote.status == PumpingNoteStatus.ACCEPTED_ORDER || pumpinNote.status == PumpingNoteStatus.CONFIRMED_ORDER)
    );
  };

  const goToDl = (id: string | undefined): string => {
    let url = props.type == DeliveryNoteTypes.pumping ? "pumpingnotes" : "deliverynotes";
    // history.push(`/${url}/${id}/view`);
    return `/${url}/${id}/view`;
  };

  const goTosignDl = (id: string) => {
    updateFilters(id, DnFiltersType.LastOpennedDnUuid);
    history.push("/deliverynotes/" + id + "/view/true");
  };

  const handleDateChange = (data: any) => {
    setFilters({ ...filters, startDate: data[0], endDate: data[1] });

    setIsDateSelected(() => {
      return data[0] != null || data[1] != null;
    });
  };

  useEffect(() => {
    let newFullColumns = getColumns();
    let newColumns = [...newFullColumns];

    let fileteredColumns = newColumns.filter((c: any) => c != null && c.isAuthorized);
    setColumns(fileteredColumns);
    setFullColumns(newFullColumns);

    let oldFilters: DnFilters = Filters.getFilters(FilterType.DeliveryNote);

    if (oldFilters != null) {
      if (oldFilters.startDate != null) {
        oldFilters.startDate = new Date(oldFilters.startDate);
      }

      if (oldFilters.endDate != null) {
        oldFilters.endDate = new Date(oldFilters.endDate);
      }
    }

    let startDate: Date | undefined =
      Roles.isSite() || Roles.isAffiliate() || Roles.isRegion() ? new Date() : undefined;
    let endDate: Date | undefined = Roles.isSite() || Roles.isAffiliate() || Roles.isRegion() ? new Date() : undefined;
    setIsDateSelected(startDate != undefined || endDate != undefined);

    let filters: DnFilters = {
      startDate: startDate,
      endDate: endDate,
    };

    if (oldFilters != null) {
      filters = oldFilters;
      setFilters(oldFilters);
    }

    registerLocale("fr", fr);

    if (filterByCarrier != null) {
      filters = {};
      filters.filterCarrier = filterByCarrier;
      filters.isOpenFilters = true;
      filters.startDate = startDate;
      filters.endDate = endDate;
      setIsDateSelected(startDate != undefined || endDate != undefined);
    }

    if (filterByBillingCustomer != null) {
      filters = {};
      filters.isOpenFilters = true;
      filters.filterBillingCustomer = filterByBillingCustomer;
      filters.startDate = undefined;
      filters.endDate = undefined;
      filters.startDate = startDate;
      filters.endDate = endDate;
      setIsDateSelected(startDate != undefined || endDate != undefined);
    }

    if (filterByDeliveryCustomer != null) {
      filters = {};
      filters.isOpenFilters = true;
      filters.filterDeliveryCustomer = filterByDeliveryCustomer;
      filters.startDate = startDate;
      filters.endDate = endDate;
      setIsDateSelected(startDate != undefined || endDate != undefined);
    }

    if (oldFilters == null) {
      filters.startDate = startDate;
      filters.endDate = endDate;
      setIsDateSelected(startDate != undefined || endDate != undefined);
    } else {
      if (oldFilters.startDate == null && oldFilters.endDate == null) {
        clearDateFilter();
      }
    }

    if (
      filterBySite != null ||
      filterByCarrier != null ||
      // filterByDeliveryCustomer != null ||
      filterByBillingCustomer != null
    ) {
      filters.startDate = startDate;
      filters.endDate = endDate;
      setIsDateSelected(startDate != undefined || endDate != undefined);
      clearParams();
    }

    if (filterBySite != null) {
      filters = {};
      filters.isOpenFilters = true;
      filters.startDate = undefined;
      filters.endDate = undefined;
      filters.startDate = startDate;
      filters.endDate = endDate;
      setIsDateSelected(startDate != undefined || endDate != undefined);
      clearParam("site");
      fetchSites(filterBySite, filters, false, true);
    } else {
      if (filters.filterSites != null && filters.filterSites.length > 0) {
        let sitesToFilters = filters.filterSites;

        filters.filterSites = sitesToFilters;
        filters.isOpenFilters = true;
        filters.startDate = undefined;
        filters.endDate = undefined;
        filters.startDate = startDate;
        filters.endDate = endDate;
        setIsDateSelected(startDate != undefined || endDate != undefined);
        clearParam("site");
        fetchSites("", filters, false, true);
      } else {
        if (filters.pageNumber == null) {
          filters.pageNumber = 1;
        }

        if (filters.perPage == null) {
          filters.perPage = perPageDefault;
        }

        fetchDns(filters.pageNumber, filters.perPage, filters.sort, filters);
      }
    }
    currentType.current = props.type;
    if (props.type == DeliveryNoteTypes.departure) {
      subscribe();
    }
  }, [props.type]);

  const currentType = useRef<DeliveryNoteTypes>();

  const getLastDayOfTheMonth = () => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);

    return currentDate;
  };

  const clearDateFilter = () => {
    setIsDateSelected(false);
  };
  const fetchDns = async (
    page: number | undefined,
    newPerPage: number | undefined = 50,
    sort: any | undefined,
    currentFilters: DnFilters | undefined = filters,
    newPage: boolean = false
  ) => {
    if (newPage) {
      setIsLoadingNewDns(true);
    }
    setSelectedRows([]);
    if (newPerPage == null) {
      newPerPage = perPageDefault;
    }
    if (page != currentFilters.pageNumber) {
      currentFilters.pageNumber = page;
    }

    if (newPerPage != currentFilters.perPage) {
      currentFilters.perPage = newPerPage;
    }

    if (sort != null && sort != currentFilters.sort) {
      currentFilters.sort = sort;
    }

    const token = localStorage.getItem("token");
    if (!newPage) {
      setFilterereditems([]);
    }

    setCurrentPage(page);
    if (!newPage) {
      setIsLoading(true);
    }

    setIsFinishedLoading(false);
    saveFilter(currentFilters);
    if (filters != currentFilters) {
      setFilters(currentFilters);
    }

    let baseUrl = props.type == DeliveryNoteTypes.pumping ? "pumping-notes" : "delivery-notes";
    let url = `${baseUrl}?page=${currentFilters.pageNumber}&limit=${currentFilters.perPage}&sort_by=${
      props.type == "pumping" ? "pumpingDate" : "loadedAt"
    }&order_by=${"desc"}`;

    if (sort != null) {
      url = `${baseUrl}?page=${currentFilters.pageNumber}&limit=${currentFilters.perPage}&sort_by=${sort?.sortedField}&order_by=${sort?.orderBy}`;
      setSortField(sort.sortedField);
      setSortAsc(sort.orderBy == "asc");
    }

    let dataToSend: any = {};

    if (props.type == DeliveryNoteTypes.departure) {
      dataToSend.collectedByClient = true;
    }

    if (currentFilters != null && currentFilters != undefined && currentFilters.isOpenFilters) {
      if (
        currentFilters.filterBillingCustomer != "" &&
        currentFilters.filterBillingCustomer != undefined &&
        currentFilters.filterBillingCustomer.length > 2
      ) {
        dataToSend.billingCustomer = currentFilters.filterBillingCustomer;
      }

      if (
        currentFilters.filterDeliveryCustomer != "" &&
        currentFilters.filterDeliveryCustomer != undefined &&
        currentFilters.filterDeliveryCustomer.length > 2
      ) {
        dataToSend.deliveryCustomer = currentFilters.filterDeliveryCustomer;
      }

      if (
        currentFilters.filterCarrier != "" &&
        currentFilters.filterCarrier != undefined &&
        currentFilters.filterCarrier.length > 1 &&
        props.type != DeliveryNoteTypes.departure
      ) {
        if (props.type == DeliveryNoteTypes.normal) {
          dataToSend.carrier = currentFilters.filterCarrier;
        } else {
          dataToSend.pumper = currentFilters.filterCarrier;
        }
      }

      if (
        currentFilters.filterDnNumber != "" &&
        currentFilters.filterDnNumber != undefined &&
        currentFilters.filterDnNumber.length > 2
      ) {
        dataToSend.reference = currentFilters.filterDnNumber;
      }

      if (currentFilters.withAdditionnalServices) {
        dataToSend.additionalsServices = currentFilters.withAdditionnalServices;
      }

      if (currentFilters.withCustomerCommentsFilter && props.type != DeliveryNoteTypes.departure) {
        dataToSend.additionalsServices = currentFilters.withCustomerCommentsFilter;
      }

      if (currentFilters.withDriverCommentsFilter && props.type != DeliveryNoteTypes.departure) {
        dataToSend.driverComment = currentFilters.withDriverCommentsFilter;
      }

      if (
        currentFilters.filterTruck != "" &&
        currentFilters.filterTruck != undefined &&
        currentFilters.filterTruck.length > 1 &&
        props.type != DeliveryNoteTypes.departure
      ) {
        dataToSend.vehicle = currentFilters.filterTruck;
      }

      if (
        currentFilters.orderReference != "" &&
        currentFilters.orderReference != undefined &&
        currentFilters.orderReference.length > 2
      ) {
        dataToSend.orderReference = currentFilters.orderReference;
      }

      if (
        currentFilters.customField1 != "" &&
        currentFilters.customField1 != undefined &&
        currentFilters.customField1.length > 2
      ) {
        dataToSend.customField1 = currentFilters.customField1;
      }

      if (
        currentFilters.statusesFilter != undefined &&
        currentFilters.statusesFilter.length > 0 &&
        props.type != DeliveryNoteTypes.departure
      ) {
        dataToSend.status = currentFilters.statusesFilter.map((s) => s.value);
      }
      //TO DO
      if (currentFilters.sitesFilterValue != undefined && currentFilters.sitesFilterValue.length > 0) {
        dataToSend.sites = currentFilters.sitesFilterValue.map((s) => s.value);
      }

      if (
        props.type == DeliveryNoteTypes.normal &&
        currentFilters.collectedFilterValue != null &&
        currentFilters.collectedFilterValue.length == 1
      ) {
        dataToSend.deliveryNoteCollected = currentFilters.collectedFilterValue[0].value == "departure";
      }

      if (
        props.type != DeliveryNoteTypes.departure &&
        currentFilters.dematFilterValue != null &&
        currentFilters.dematFilterValue.length == 1
      ) {
        dataToSend.completedByApp = currentFilters.dematFilterValue[0].value == "demat";
      }

      if (props.type == DeliveryNoteTypes.normal) {
        dataToSend.returnedProduct = currentFilters.withReturnedProduct;
      }
    }

    if (currentFilters.startDate != null) {
      dataToSend.beginDate = moment(currentFilters.startDate).format("YYYYMMDD");
    }

    if (currentFilters.endDate != null) {
      dataToSend.endDate = moment(currentFilters.endDate).format("YYYYMMDD");
    }

    if (
      !currentFilters.isOpenFilters &&
      currentFilters.searchGlobal != undefined &&
      currentFilters.searchGlobal.length >= 3
    ) {
      dataToSend.search = currentFilters.searchGlobal;
    }

    setDataSended(dataToSend);

    await axios
      .post(Config.getUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        // settotalRows(res.data.meta.total);

        // setTotalResource(res.data.meta.totalResource);

        setIsLastPage(res.data.meta.count < perPageDefault);

        setIsLoading(false);
        setIsFinishedLoading(true);
        setIsLoadingNewDns(false);
        setFilterereditems((filtered) => {
          if (!newPage) {
            return res.data.content.items;
          }
          return [...filtered, ...res.data.content.items];
        });

        // if (nbPages > 1) {
        //   getNewPage(2, newPerPage, res.data.meta.total, startDate, endDate);
        //   // for (let i = 2; i <= nbPages; i++) {
        //   //   getNewPage(i, newPerPage, res.data.meta.total,startDate, endDate);
        //   // }
        // } else {

        // onFilter(res.data.content.items, filterText, null);
        // }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        // setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };

  const saveColumnOrder = (cols: any) => {
    ColumnsFilter.updateColumns(cols, props.type);
    let dataToSend = {
      normal:
        props.type == DeliveryNoteTypes.normal ? cols : ColumnsFilter.getColumnsFromLocal(DeliveryNoteTypes.normal),
      departure:
        props.type == DeliveryNoteTypes.departure
          ? cols
          : ColumnsFilter.getColumnsFromLocal(DeliveryNoteTypes.departure),
      pumping:
        props.type == DeliveryNoteTypes.pumping ? cols : ColumnsFilter.getColumnsFromLocal(DeliveryNoteTypes.pumping),
    };

    let token = localStorage.getItem("token");
    axios
      .post(Config.get_API_EXTRANET_URL(`account/customization`), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        // addToast("Colonnes sauvagardés", ToastTypes.success);
      })
      .catch(() => {
        // setIsAddingLoading(false);
        addToast(t("errors.saveColumn"), ToastTypes.error); //
      });
  };

  const getColumnsOrder = (): any[] => {
    let cols = ColumnsFilter.getColumnsFromLocal(props.type);
    if (cols != undefined && cols.length > 0) {
      cols = addMissingColumn(cols);
      return cols;
    } else {
      return getDefaultColumns();
    }
  };

  const addMissingColumn = (cols: any[]) => {
    if (cols.filter((c) => c.id == "signBl").length == 0 && props.type == DeliveryNoteTypes.departure) {
      cols.unshift({ id: "signBl" });
    }

    if (cols.filter((c) => c.id == "folioReference").length == 0) {
      cols.unshift({ id: "folioReference" });
    } else {
      if (cols.findIndex((c) => c.id == "folioReference") != 0) {
        cols.splice(cols.findIndex((c) => c.id == "folioReference") + 0, 1);
        cols.unshift({ id: "folioReference" });
      }
    }

    if (cols.filter((c) => c.id == "completedByApp").length == 0) {
      cols.push({ id: "completedByApp" });
    }

    if (cols.filter((c) => c.id == "customField1").length == 0) {
      cols.push({ id: "customField1" });
    }

    if (cols.filter((c) => c.id == "orderReference").length == 0) {
      cols.push({ id: "orderReference" });
    }

    if (!Roles.isUserClient() && cols.filter((c) => c.id == "purchaseZone").length == 0) {
      cols.push({ id: "purchaseZone" });
    }
    if (!Roles.isTransporter() && cols.filter((c) => c.id == "transportZone").length == 0) {
      cols.push({ id: "transportZone" });
    }

    if (props.type == DeliveryNoteTypes.pumping && cols.filter((c) => c.id == "realPumpedQuantity").length == 0) {
      cols.push({ id: "realPumpedQuantity" });
    }
    return cols;
  };

  const updateFilters = (value: any, filterType: DnFiltersType): void => {
    switch (filterType) {
      case DnFiltersType.DeliveryCustomer:
        setFilters({ ...filters, filterDeliveryCustomer: value });
        break;
      case DnFiltersType.BillingCustomer:
        setFilters({ ...filters, filterBillingCustomer: value });
        break;
      case DnFiltersType.Carrier:
        setFilters({ ...filters, filterCarrier: value });
        break;
      case DnFiltersType.EndDate:
        setFilters({ ...filters, endDate: value });
        break;
      case DnFiltersType.StartDate:
        setFilters({ ...filters, startDate: value });
        break;
      case DnFiltersType.WithAdditionnalServices:
        setFilters({ ...filters, withAdditionnalServices: value });
        break;
      case DnFiltersType.WithCustomerCommentsFilter:
        setFilters({ ...filters, withCustomerCommentsFilter: value });
        break;
      case DnFiltersType.WithDriverCommentsFilter:
        setFilters({ ...filters, withDriverCommentsFilter: value });
        break;
      case DnFiltersType.DnNumber:
        setFilters({ ...filters, filterDnNumber: value });
        break;
      case DnFiltersType.Truck:
        setFilters({ ...filters, filterTruck: value });
        break;
      case DnFiltersType.IsOpenFilters:
        setFilters({ ...filters, isOpenFilters: value });
        break;
      case DnFiltersType.DnStatus:
        setFilters({ ...filters, statusesFilter: value });
        break;
      case DnFiltersType.SitesFilter:
        setFilters({ ...filters, sitesFilterValue: value });
        break;
      case DnFiltersType.CollectedFilters:
        setFilters({ ...filters, collectedFilterValue: value });
        break;
      case DnFiltersType.DematFilters:
        setFilters({ ...filters, dematFilterValue: value });
        break;
      case DnFiltersType.WithReturnedProduct:
        setFilters({ ...filters, withReturnedProduct: value });
        break;
      case DnFiltersType.SearchGlobal:
        setFilters({ ...filters, searchGlobal: value });
        break;
      case DnFiltersType.CustomField1:
        setFilters({ ...filters, customField1: value });
        break;
      case DnFiltersType.OrderReference:
        setFilters({ ...filters, orderReference: value });
        break;
      case DnFiltersType.LastOpennedDnUuid:
        setFilters((currentFilters) => {
          saveFilter({ ...currentFilters, lastOpennedDnUuid: value });
          return { ...currentFilters, lastOpennedDnUuid: value };
        });
        break;
      case DnFiltersType.Sort: {
        setFilters({ ...filters, sort: value });
        break;
      }
    }
  };

  const onSort = (data: any) => {
    // setSortAsc(direction == "asc");

    let currentField = filters.sort?.sortedField;
    if (currentField == null) {
      props.type == DeliveryNoteTypes.pumping ? "pumpingDate" : "loadedAt";
    }

    let currentDirection = filters.sort?.orderBy;
    if (currentDirection == null) {
      // props.type = "desc";
      currentDirection = "desc";
      // // props.type == DeliveryNoteTypes.pumping ? "asc" : "desc";
    }

    let direction = "asc";
    if (data.id == currentField) {
      if (currentDirection == "asc") {
        direction = "desc";
      }
    }

    setSortField(data.id);
    setSortAsc(direction == "asc");

    let sortBy = {
      sortedField: data.id,
      orderBy: direction,
    };

    fetchDns(filters.pageNumber, filters.perPage, sortBy);
  };

  // const handlePerRowsChange = async (newPerPage: number, page: number) => {
  //   setIsLoading(true);
  //   fetchDns(page, newPerPage, filters.sort, filters);
  // };

  // const handlePageChange = (pageNumber: number) => {
  //   fetchDns(pageNumber, filters.perPage, filters.sort, filters);
  // };

  const search = () => {
    fetchDns(1, filters.perPage, filters.sort, filters);
  };

  const getDnStatuses = (): any[] => {
    let statuses = [
      {
        value: "validated",
        label: t("deliverynote.status.validated"), // Deliverynote.getStatusText("validated"),
      },
      {
        value: "active",
        label: t("deliverynote.status.active"), // Deliverynote.getStatusText("active"),
      },
      {
        value: "canceled",
        label: t("deliverynote.status.canceled"), // Deliverynote.getStatusText("canceled"),
      },
      {
        value: "deleted",
        label: t("deliverynote.status.deleted"), //Deliverynote.getStatusText("deleted"),
      },
      {
        value: "inactive",
        label: t("deliverynote.status.inactive"), //Deliverynote.getStatusText("inactive"),
      },
      {
        value: "partially_refused",
        label: t("deliverynote.status.partially_refused"), // Deliverynote.getStatusText("partially_refused"),
      },
      {
        value: "refused",
        label: t("deliverynote.status.refused"), // Deliverynote.getStatusText("refused"),
      },
      {
        value: "terminated",
        label: t("deliverynote.status.terminated"), // Deliverynote.getStatusText("terminated"),
      },
      {
        value: "delivered",
        label: t("deliverynote.status.delivered"),
      },
    ];

    if (props.type == DeliveryNoteTypes.pumping) {
      statuses.push({
        value: "confirmed_order",
        label: t("deliverynote.status.confirmed_order"), // Deliverynote.getStatusText("confirmed_order"),
      });

      statuses.push({
        value: "supported",
        label: t("deliverynote.status.supported"), // Deliverynote.getStatusText("supported"),
      });

      statuses.push({
        value: "ordered",
        label: t("deliverynote.status.ordered"), //Deliverynote.getStatusText("ordered"),
      });

      statuses.push({
        value: "installed",
        label: t("deliverynote.status.installed"), //Deliverynote.getStatusText("installed"),
      });

      statuses.push({
        value: "pumping",
        label: t("deliverynote.status.pumping"), // Deliverynote.getStatusText("pumping"),
      });

      statuses.push({
        value: "refused_order",
        label: t("deliverynote.status.refused_order"), //Deliverynote.getStatusText("refused_order"),
      });
      statuses.push({
        value: "accepted_order",
        label: t("deliverynote.status.accepted_order"), // eliverynote.getStatusText("accepted_order"),
      });
    } else {
      statuses.push({
        value: "delivering",
        label: t("deliverynote.status.delivering"), // Deliverynote.getStatusText("delivering"),
      });

      statuses.push({
        value: "chartered",
        label: t("deliverynote.status.chartered"), //Deliverynote.getStatusText("chartered"),
      });
    }

    return statuses.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
  };

  const updateSiteFilters = (newSites: any) => {
    let sitesUuids: any[] = [];
    newSites.forEach((s: any) => {
      let site = s.value;
      sitesUuids.push(site);
    });
    updateFilters(newSites, DnFiltersType.SitesFilter);
  };

  const updateDeliveryNoteCollectedFilters = (newFiltersCollected: any) => {
    let sitesUuids: any[] = [];
    newFiltersCollected.forEach((s: any) => {
      let site = s.value;
      sitesUuids.push(site);
    });
    updateFilters(newFiltersCollected, DnFiltersType.CollectedFilters);
  };

  const updateDeliveryNoteDematFilters = (newFiltersDemat: any) => {
    let sitesUuids: any[] = [];
    newFiltersDemat.forEach((s: any) => {
      let site = s.value;
      sitesUuids.push(site);
    });
    updateFilters(newFiltersDemat, DnFiltersType.DematFilters);
  };

  const createFilterSiteOptions = (
    sites: Site[],
    currentFilters: DnFilters = filters,
    setSavedValues = false,
    setDefautlValue = false
  ) => {
    let options: FilterSite[] = [];

    if (sites != null) {
      sites.forEach((s) => {
        let option: FilterSite = {
          value: s.uuid,
          label: s?.name,
        };
        options.push(option);
      });

      setSiteOptions(options);
      if (setDefautlValue && options.length > 0) {
        // setSitesFilterValue([options[0]]);
        // setSitesFilter([options[0].value]);

        let newFilters = currentFilters;
        newFilters.sitesFilterValue = [options[0]];

        fetchDns(1, filters.perPage, filters.sort, newFilters);
      } else {
        // if(currentFilters.site)
        if (setSavedValues) {
          if (filters.sitesFilterValue != undefined) {
            setSiteOptions(filters.sitesFilterValue);
            // setSitesFilterValue(filters.sitesFilterValue);
          }

          // // setSitesFilterValue(options[0]);
          // let selectedOptions: any[] = [];
          // options.forEach((o) => {
          //   if (siteOptionsIsSelected(o.value, currentFilters.sitesFilterValue)) {
          //     selectedOptions.push(options);
          //   }
          // });
          // if (selectedOptions.length > 0) {
          //   setSitesFilter([selectedOptions[0].value]);
          // }

          fetchDns(1, filters.perPage, filters.sort, currentFilters);
        }
      }
    }
  };

  // const siteOptionsIsSelected = (uuid: string, sites: string[] | undefined) => {
  //   let isSelected = false;
  //   if (sites != undefined) {
  //     sites.forEach((s) => {
  //       if (s == uuid) {
  //         isSelected = true;
  //       }
  //     });
  //   }

  //   return isSelected;
  // };

  const rowDisabledCriteria = (row: Deliverynote) => row.digitalNote == null;

  const prevFilterSiteText = usePrevious(filterSiteText ?? "");

  useEffect(() => {
    if (prevFilterSiteText !== undefined && prevFilterSiteText != filterSiteText) {
      clearTimeout(timer);
      if (filterSiteText.length < 1) {
        // return;
        createFilterSiteOptions([]);
      }

      timer = setTimeout(() => {
        fetchSites(filterSiteText, filters);
      }, 500);
    }
  }, [filterSiteText]);

  const startSearchingSites = (newSitefilterText: string) => {
    setFilterSiteText(newSitefilterText);
  };

  const fetchSites = (
    newfitlerSiteText: string,
    newFilters: DnFilters = filters,
    setSavedValues: boolean = false,
    setDefaultValue: boolean = false
  ) => {
    if (newfitlerSiteText != filterSiteText) {
      setFilterSiteText(newfitlerSiteText);
    }

    if (newfitlerSiteText.length > 0) {
      setIsLoadingSites(true);
      const token = localStorage.getItem("token");
      let url = "sites?page=" + 1 + "&limit=" + 200 + "&search=" + newfitlerSiteText;
      axios
        .get(Config.getUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoadingSites(false);
          createFilterSiteOptions(res.data.content.items, newFilters, setSavedValues, setDefaultValue);
        })
        .catch(() => {
          setIsLoadingSites(false);
          createFilterSiteOptions([]);
        });
    } else {
      createFilterSiteOptions([]);
    }
  };

  const handleSelectedRowsChange = (data: any) => {
    setSelectedRows(data.selectedRows);
  };

  const downloadPdf = async () => {
    let files: any[] = [];

    for (let i = 0; i < selectedRows.length; i++) {
      let dl = selectedRows[i];
      if (dl.digitalNote != null) {
        let data = await dlOnepdf(dl);
        let file = {
          data: new Blob([data.data], { type: "application/pdf" }),
          name: `${props.type === DeliveryNoteTypes.pumping ? "BMD-" : "BL-"}_${
            dl?.folioReference != null ? dl?.folioReference : dl?.externalReference
          }.pdf`,
        };
        files.push(file);
      }
    }
    if (files.length > 0) {
      zipFiles(files);
    }
  };

  const dlOnepdf = async (dl: Deliverynote) => {
    let data = await axios({
      url: dl?.digitalNote?.url, //your url
      method: "GET",
      responseType: "blob", // important
    });

    return data;
  };

  const zipFiles = (files: any[]) => {
    var zip = new JSZip();
    files.forEach((f) => {
      zip?.file(f.name, f.data, { base64: true });
    });

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "SXDelivery_" + moment().format("DDMMYYYY_HHmm") + ".zip");
    });
  };

  return (
    <div className="deliveryNotes">
      <Menu
        selectedPage={
          props.type == DeliveryNoteTypes.departure
            ? "deliveryNotes-departure"
            : props.type == DeliveryNoteTypes.pumping
            ? "pumpingNotes"
            : "deliveryNotes"
        }
      ></Menu>

      <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

      <div className={"right table-container"}>
        <div className="title">
          {t(props.type == DeliveryNoteTypes.pumping ? "menu.pumpingnotes" : "menu.deliverynotes")}
        </div>

        {!filters.isOpenFilters ? (
          <div className="row">
            <div
              style={{ marginRight: 10 }}
              className={
                !filters.isOpenFilters ? "filter-simple filter-simple-openned" : "filter-simple filter-simple-closed"
              }
            >
              <div className="filterBar ">
                <div className="row" style={{ justifyContent: "space-between" }}>
                  <div className="row">
                    <SearchBar
                      readonly={!isFinishedLoading}
                      onFilter={(value) => updateFilters(value, DnFiltersType.SearchGlobal)}
                      placeholder={
                        props.type == DeliveryNoteTypes.pumping
                          ? t("deliverynote.search.pumping")
                          : t("deliverynote.search.deliverynote")
                      }
                      filterText={filters.searchGlobal}
                      minLength={3}
                    ></SearchBar>

                    <DatePicker
                      disabled={!isFinishedLoading}
                      className="calendar"
                      calendarClassName="calendar"
                      selected={filters.startDate}
                      locale={i18n.language}
                      disabledKeyboardNavigation={true}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => handleDateChange(date)}
                      startDate={filters?.startDate}
                      endDate={filters?.endDate}
                      selectsRange
                      shouldCloseOnSelect={false}
                      minDate={!isFinishedLoading ? new Date("2100-12_12") : null}
                      maxDate={!isFinishedLoading ? new Date("2020-01-01") : null}
                      customInput={<CalendarInput />}
                    >
                      {isDateSelected ? (
                        <div
                          className="clearDates"
                          style={{ textAlign: "center" }}
                          onClick={() => handleDateChange([null, null])}
                        >
                          {t("actions.clear")}
                        </div>
                      ) : null}
                    </DatePicker>
                  </div>
                  <div>
                    {!filters.isOpenFilters ? (
                      <button className="btn" style={{ marginTop: 11 }} onClick={() => search()} disabled={isLoading}>
                        <Search fill={"white"} />
                        <h6>{t("actions.search")}</h6>
                      </button>
                    ) : null}
                  </div>
                </div>
                <div
                  onClick={() => updateFilters(!filters.isOpenFilters, DnFiltersType.IsOpenFilters)}
                  className="toggleFilterBtn"
                >
                  {filters.isOpenFilters ? t("deliverynote.filter.hide") : t("deliverynote.filter.show")}
                  {/* // /"Masquer les filtres avancés" : "Afficher les filtres avancés" */}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className={filters.isOpenFilters ? "filters filters-openned" : "filters filters-closed"}>
          <div className="filterBar">
            <div className="filterLine" style={{ marginTop: 0 }}>
              {/* Bon livraison */}
              {t("deliverynote.title")}
            </div>
            <div className="row">
              {/* <SearchBar
                readonly={!isFinishedLoading}
                onFilter={(value) => updateFilters(value, DnFiltersType.DeliveryCustomer)}
                placeholder="Chantier/Client"
                filterText={filters.filterDeliveryCustomer}
                minLength={3}
              ></SearchBar> */}

              <SearchBar
                readonly={!isFinishedLoading}
                onFilter={(value) => updateFilters(value, DnFiltersType.DnNumber)}
                placeholder={
                  props.type == DeliveryNoteTypes.pumping ? t("deliverynote.pumping.number") : t("deliverynote.number")
                }
                filterText={filters.filterDnNumber}
                minLength={3}
              ></SearchBar>

              {/* <div className="row"> */}
              <SearchBar
                readonly={!isFinishedLoading}
                onFilter={(value) => updateFilters(value, DnFiltersType.OrderReference)}
                placeholder={t("deliverynote.filter.orderReference")}
                filterText={filters.orderReference}
                minLength={3}
              ></SearchBar>
              <SearchBar
                readonly={!isFinishedLoading}
                onFilter={(value) => updateFilters(value, DnFiltersType.CustomField1)}
                // placeholder="Réf chantier"
                placeholder={t("deliverynote.customField1")}
                filterText={filters.customField1}
                minLength={3}
              ></SearchBar>
              {/* </div> */}

              {props.type != DeliveryNoteTypes.departure ? (
                <Select
                  className={"input select basic-multi-select dnStatusSelect"}
                  isMulti={true}
                  value={filters.statusesFilter}
                  options={getDnStatuses()}
                  noOptionsMessage={t("noOptions")}
                  // noOptionsMessage={() => t('noOptions')}
                  // placeholder={"Statut"}
                  placeholder={t("deliverynote.status.title")}
                  onChange={(input) => updateFilters(input, DnFiltersType.DnStatus)}
                />
              ) : null}

              {props.type == DeliveryNoteTypes.normal ? (
                <Select
                  className="input select"
                  isMulti={true}
                  options={[
                    { label: t("deliverynote.filter.departure"), value: "departure" },
                    { label: t("deliverynote.filter.nodeparture"), value: "nodeparture" },
                    // "BL Rendu",
                  ]}
                  value={filters.collectedFilterValue}
                  loadingMessage={() => t("loading")}
                  noOptionsMessage={t("noOptions")}
                  placeholder={t("deliverynote.filter.type")}
                  // "Type de bon"}
                  onChange={(input) => {
                    updateDeliveryNoteCollectedFilters(input);
                  }}
                />
              ) : null}

              <DatePicker
                className="calendar"
                calendarClassName="calendar"
                selected={filters.startDate}
                locale={i18n.language}
                disabledKeyboardNavigation={true}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => handleDateChange(date)}
                startDate={filters?.startDate}
                endDate={filters?.endDate}
                selectsRange
                shouldCloseOnSelect={false}
                minDate={!isFinishedLoading ? new Date("2100-12_12") : null}
                maxDate={!isFinishedLoading ? new Date("1970-01-01") : null}
                customInput={<CalendarInput style={{ marginTop: 0, marginLeft: 30 }} />}
              >
                {isDateSelected ? (
                  <div
                    className="clearDates"
                    style={{ textAlign: "center" }}
                    onClick={() => handleDateChange([null, null])}
                  >
                    {t("actions.clear")}
                    {/* Effacer */}
                  </div>
                ) : null}
              </DatePicker>
            </div>

            <div className="row">
              {props.type != DeliveryNoteTypes.departure ? (
                <Select
                  className="input select"
                  isMulti={true}
                  options={[
                    { label: t("deliverynote.filter.demat"), value: "demat" },
                    { label: t("deliverynote.filter.nodemat"), value: "nodemat" },
                  ]}
                  value={filters.dematFilterValue}
                  loadingMessage={() => t("loading")}
                  noOptionsMessage={() => t("noOptions")}
                  placeholder={t("deliverynote.filter.demat")}
                  onChange={(input) => {
                    updateDeliveryNoteDematFilters(input);
                  }}
                />
              ) : null}

              <label className="checkbox">
                <input
                  name="available"
                  type="checkbox"
                  checked={filters.withAdditionnalServices}
                  onChange={(e) => updateFilters(e?.target.checked, DnFiltersType.WithAdditionnalServices)}
                />
                <span style={{ color: Colors.grey500 }}>{t("deliverynote.additionalService")}</span>
              </label>
            </div>

            <div className="filterLine">{t("carrier")}</div>

            <div className="row">
              {props.type != DeliveryNoteTypes.departure && !Roles.isCarrier() && !Roles.isPumper() ? (
                <SearchBar
                  readonly={!isFinishedLoading}
                  onFilter={(value) => updateFilters(value, DnFiltersType.Carrier)}
                  placeholder={props.type == DeliveryNoteTypes.pumping ? "Pompiste" : "Transporteur"}
                  filterText={filters.filterCarrier}
                  minLength={2}
                ></SearchBar>
              ) : null}

              {props.type != DeliveryNoteTypes.departure ? (
                <SearchBar
                  readonly={!isFinishedLoading}
                  onFilter={(value) => updateFilters(value, DnFiltersType.Truck)}
                  placeholder={t("vehicle")}
                  filterText={filters.filterTruck}
                  minLength={2}
                ></SearchBar>
              ) : null}

              {props.type != DeliveryNoteTypes.departure ? (
                <div>
                  <label className="checkbox">
                    <input
                      name="associated"
                      type="checkbox"
                      checked={filters.withDriverCommentsFilter}
                      onChange={(e) => updateFilters(e?.target.checked, DnFiltersType.WithDriverCommentsFilter)}
                    />
                    <span style={{ color: Colors.grey500 }}>{t("deliverynote.driverComment")}</span>
                    {/* Commentaire chauffeur */}
                  </label>
                </div>
              ) : null}

              {props.type == DeliveryNoteTypes.normal ? (
                <div>
                  <label className="checkbox">
                    <input
                      name="returnedProduct"
                      type="checkbox"
                      checked={filters.withReturnedProduct}
                      onChange={(e) => updateFilters(e?.target.checked, DnFiltersType.WithReturnedProduct)}
                    />
                    <span style={{ color: Colors.grey500 }}>{t("deliverynote.returnedProduct")}</span>
                    {/*  Retour produit */}
                  </label>
                </div>
              ) : null}
            </div>

            <div className="filterLine">{t("customer")}</div>

            <div className="row">
              {!Roles.isUserBilling() ? (
                <SearchBar
                  readonly={!isFinishedLoading}
                  onFilter={(value) => updateFilters(value, DnFiltersType.BillingCustomer)}
                  placeholder={t("customer")}
                  filterText={filters.filterBillingCustomer}
                  minLength={3}
                ></SearchBar>
              ) : null}

              {!Roles.isUserDelivery() ? (
                <SearchBar
                  readonly={!isFinishedLoading}
                  onFilter={(value) => updateFilters(value, DnFiltersType.DeliveryCustomer)}
                  placeholder={t("delivery")}
                  filterText={filters.filterDeliveryCustomer}
                  minLength={3}
                ></SearchBar>
              ) : null}

              {props.type != DeliveryNoteTypes.departure ? (
                <div>
                  <label className="checkbox">
                    <input
                      name="associated"
                      type="checkbox"
                      checked={filters.withCustomerCommentsFilter}
                      onChange={(e) => updateFilters(e?.target.checked, DnFiltersType.WithCustomerCommentsFilter)}
                    />
                    <span style={{ color: Colors.grey500 }}>{t("deliverynote.clientComments")}</span>
                  </label>
                </div>
              ) : null}
            </div>
            {!Roles.isSite() ? (
              <>
                <div className="filterLine">{t("site")}</div>

                <div className="row">
                  <Select
                    className="input select"
                    isMulti={true}
                    isLoading={isLoadingSites}
                    options={siteOptions}
                    value={filters.sitesFilterValue}
                    loadingMessage={() => t("loading")}
                    noOptionsMessage={() =>
                      filterSiteText.length < 1
                        ? t("deliverynote.filter.placeholderLength", { length: 1 })
                        : t("noOptions", { length: 1 })
                    }
                    placeholder={t("deliverynote.filter.site")}
                    onInputChange={(e) => startSearchingSites(e)} // fetchSites(e, filters)
                    isSearchable={true}
                    onChange={(input) => {
                      updateSiteFilters(input);
                    }}
                  />
                </div>
              </>
            ) : null}
            <div className="row align-center" style={{ marginTop: 20 }}>
              <div className="row" style={{ justifyContent: "space-between", width: "100%" }}>
                <div
                  onClick={() => updateFilters(!filters.isOpenFilters, DnFiltersType.IsOpenFilters)}
                  className="toggleFilterBtn"
                  style={{ marginTop: 0 }}
                >
                  {t("deliverynote.filter.hide")}
                </div>
                <button
                  className="validateBtn btn"
                  onClick={() => search()}
                  disabled={isLoading}
                  style={{ marginTop: 0 }}
                >
                  <Search fill={"white"} />

                  <h6>{t("actions.search")}</h6>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15, marginTop: 30, alignItems: "center" }} className="row space-between">
          <div className="row" style={{ alignItems: "center" }}>
            {/* <div className={"nbElements"} style={{ marginTop: 0 }}>
              <span>{totalRows}</span> {totalResource > 1 ? t("results") : t("result")}
            </div> */}

            <div className="filter" style={{ width: "fit-content" }}>
              <div></div>
            </div>
          </div>

          <div className="row right-btn" style={{ alignItems: "center" }}>
            <div className="" onClick={() => setIsOpenFilterColumns(!isOpenFilterColumns)}>
              <Settings
                fill={Colors.primary}
                className="icon"
                width="25"
                height="25"
                style={{ marginLeft: 10, marginRight: 10 }}
              />
            </div>

            {selectedRows.length > 0 ? (
              <div className="validateBtn" style={{ marginTop: 0, marginRight: 20 }} onClick={() => downloadPdf()}>
                <h6>{t("deliverynote.dlPdf")}</h6>
              </div>
            ) : null}

            <ExportCSV filterParams={dataSended} type={props.type} />
          </div>
        </div>

        <div ref={ref} className={isOpenFilterColumns ? "popup-filtercolumns" : "popup-filter hide"}>
          <div className="table-link" style={{ marginBottom: 10 }} onClick={() => showAllColumns()}>
            {t("deliverynote.columns.showAll")}
          </div>
          <div className="table-link" style={{ marginBottom: 10 }} onClick={() => hideAllColumns()}>
            {t("deliverynote.columns.hideAll")}
          </div>
          {columns
            .filter((c) => c.cantHide != true)
            .map((col) => {
              return (
                <div key={"filterCol_" + col.id} onClick={() => toggleColumn(col.id)}>
                  <label className="checkbox row" style={{ alignItems: "center" }}>
                    {col.omit ? (
                      <EyeOff className="eye" width="25" height="25" fill={Colors.primary} />
                    ) : (
                      <Eye className="eye" width="25" height="25" fill={Colors.primary} />
                    )}
                    <div style={{ marginLeft: 5 }}>{col.name}</div>
                  </label>
                </div>
              );
            })}
        </div>

        {isLoading ? (
          <div>{t("loading")}</div>
        ) : (
          <DataTable
            onColumnOrderChange={(cols) => onColumnOrderChange(cols)}
            className="table"
            defaultSortFieldId={sortedField}
            defaultSortAsc={sortAsc}
            sortServer={true}
            onSort={(data) => onSort(data)}
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            selectableRows
            selectableRowDisabled={rowDisabledCriteria}
            onSelectedRowsChange={handleSelectedRowsChange}
            data={filteredItems}
            conditionalRowStyles={conditionalRowStyles}
            noDataComponent={
              <div className="noResults">
                {props.type == DeliveryNoteTypes.pumping ? t("deliverynote.pumping.noOne") : t("deliverynote.noOne")}
              </div>
            }
            // pagination
            // paginationServer
            // paginationTotalRows={totalRows}
            // paginationDefaultPage={currentPage}
            // onChangeRowsPerPage={handlePerRowsChange}
            // onChangePage={handlePageChange}
            // paginationPerPage={filters.perPage}
            // paginationRowsPerPageOptions={[10, 25, 50, 100]}
            // paginationComponentOptions={{
            //   rowsPerPageText: t("table.perpage"),
            //   rangeSeparatorText: t("table.on"),
            //   noRowsPerPage: false,
            //   selectAllRowsItem: false,
            //   selectAllRowsItemText: "All",
            // }}
          />
        )}

        {isLoading || isLastPage ? null : (
          <div className="bottomTable">
            {isLoadingNewDns ? (
              <div>{t("loading")}</div>
            ) : (
              <div
                className="btn"
                onClick={() => fetchDns((filters?.pageNumber ?? 0) + 1, filters.perPage, filters.sort, filters, true)}
              >
                <h6>{t("seeMore")}</h6>
              </div>
            )}
          </div>
        )}
      </div>

      <PopPop
        open={isOpenAssignPumpingNote}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenAssignPumpingNote(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <AssignPumperTruck
          pumpingNote={currrentPumpingNoteToAssign}
          confirm={(registationPlate) => onSuccessAssign(registationPlate)}
          cancel={() => setIsOpenAssignPumpingNote(false)}
          onError={() => onErrorAssign()}
        />
      </PopPop>

      <PopPop
        open={isOpenUpdateCommand}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenUpdateCommand(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <ConfirmPopup
          message={
            startRefuseCommand
              ? t("deliverynote.refuseCommand.question", {
                  ref:
                    currrentPumpingNoteToAssign?.folioReference != undefined
                      ? currrentPumpingNoteToAssign?.folioReference
                      : currrentPumpingNoteToAssign?.externalReference,
                })
              : t("deliverynote.acceptCommand.question", {
                  ref:
                    currrentPumpingNoteToAssign?.folioReference != undefined
                      ? currrentPumpingNoteToAssign?.folioReference
                      : currrentPumpingNoteToAssign?.externalReference,
                })

            // startRefuseCommand
            //   ? `Etes vous sûr de refuser la commande ${

            //     } ?`
            //   : `Etes vous sûr d'accepter la commande ${
            //       currrentPumpingNoteToAssign?.folioReference != undefined
            //         ? currrentPumpingNoteToAssign?.folioReference
            //         : currrentPumpingNoteToAssign?.externalReference
            //     } ?`
          }
          confirmMessage={startRefuseCommand ? t("actions.refuse") : t("actions.validate")}
          cancelMessage={t("actions.cancel")}
          cancel={() => setIsOpenUpdateCommand(false)}
          confirm={() => confirmUpdateCommand()}
        />
      </PopPop>
    </div>
  );
});

export default DeliveryNotes;
