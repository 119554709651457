/* eslint-disable */
import jwt_decode from "jwt-decode";
import Workspace from "../models/Workspace";
import WorkspaceByType from "../models/WorkspacebByType";
import PermissionResolver from "@synaxe/permission-resolver/dist";
import Organization from "../models/Organization";
import { WorkspaceTypes } from "../models/WorkspaceTypes";
import { Scopes } from "../models/Scopes";
import eventBus from "../components/EventBus";
import { t } from "i18next";

export default class Roles {
  static getToken = () => {
    let token = localStorage.getItem("token");
    if (token != null) {
      let decoded = jwt_decode<any>(token?.toString());
      return decoded;
    } else {
      return null;
    }
  };

  static getEmail() {
    let email = localStorage.getItem("email");
    if (email != null) {
      return email;
    }
  }

  static getWorkspaceByType = (workspaces: any[]) => {
    let data: WorkspaceByType[] = [];
    workspaces.forEach((value: Workspace) => {
      // Group initialization
      if (data.filter((d) => d.type == Roles.getWorkspaceType(value.organization)).length == 0) {
        data.push({ type: Roles.getWorkspaceType(value.organization), workspaces: [value], roles: value.roles });
      } else {
        let current = data.filter((d) => d.type == Roles.getWorkspaceType(value.organization))[0];
        current.workspaces?.push(value);
      }
    });
    return data;
  };

  static getWorkspaceType(organization: Organization | undefined) {
    // if (organization?.type == "billing") {
    //   return "delivery";
    // } else {
    return organization?.type;
    // }
  }

  static saveWorkspaces(data: any[]) {
    let groupBy = this.getWorkspaceByType(data);
    this.setWorkspace(data[1]);
    localStorage.setItem("workspaces", JSON.stringify(groupBy));
  }

  static saveUser(uuid: string) {
    localStorage.setItem("userUuid", uuid);
    Roles.setHotjarUserId();
  }

  static getUserId() {
    return localStorage.getItem("userUuid");
  }

  static getWorkspaces = (): WorkspaceByType[] => {
    let workspaces = localStorage.getItem("workspaces");
    if (workspaces != null) {
      return JSON.parse(workspaces);
    }
    return [];
  };

  static getAllWorkspaces = (): Workspace[] => {
    let workspacesByTypes = Roles.getWorkspaces();
    let workspaces: Workspace[] = [];
    workspacesByTypes.forEach((t) => {
      t.workspaces?.forEach((w) => {
        workspaces.push(w);
      });
    });

    return workspaces;
  };

  static isSite = (): boolean => {
    return Roles.getCurrentWorkspace()?.organization?.type == "site";
  };

  static isRegion = (): boolean => {
    return Roles.getCurrentWorkspace()?.organization?.type == "region";
  };

  static hasBMD = (): boolean => {
    return Roles.hasMenu("pumping_note");
  };
  static hasMenu = (menu: string): boolean => {
    if (Roles.getCurrentWorkspace()?.extranetMenu != undefined)
      return Roles.getCurrentWorkspace()?.extranetMenu?.findIndex((m) => m == menu) != -1;
    else return false;
  };

  static canSeeBmdStats = () => {
    return (
      Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_STATS) &&
      Roles.hasBMD() &&
      (Roles.isAffiliate() || Roles.isRegion())
    );
  };

  static canSeeBlStats = () => {
    return Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_NOTE_STATS) && (Roles.isAffiliate() || Roles.isRegion());
  };

  static getCurrentWorkspace = (): Workspace | null => {
    let workspace = localStorage.getItem("workspace");
    if (workspace != null) {
      return JSON.parse(workspace);
    }
    return workspace;
  };

  static hasWithdrawalPoint = () => {
    let wp = Roles.getCurrentWorkspace();
    return wp?.organization?.withdrawalPoint;
  };

  static isPumper = () => {
    let wp = Roles.getCurrentWorkspace();
    return wp?.organization?.type == "pumper";
  };

  static isTransporter = () => {
    return Roles.isCarrier() || Roles.isPumper();
  };
  static getCurrentWorkspaceUuid = () => {
    let workspace = Roles.getCurrentWorkspace();
    if (workspace != null) {
      return workspace.organization?.uuid;
    }
    return null;
  };

  static setWorkspace(workspace: Workspace) {
    localStorage.setItem("workspace", JSON.stringify(workspace));
    eventBus.dispatch("updateWorkspace", { workspace: workspace });
    Roles.setHotjarUserId();
  }

  static containsScope(scopes: string[], currentScope: string) {
    // if (scopes == null) return false;
    // return scopes.filter((s) => s == currentScope).length > 0;
    // let resolver = new PermissionResolver({
    //   extranet: {
    //     driver: { view: [], create: [], remove: [], manage: ["view", "create", "remove"] },
    //     delivery_note: {
    //       preview: [],
    //       view: ["preview"],
    //       manage: ["view", "preview", "emails", "validate"],
    //       validation_notification: [],
    //       emails: [],
    //       validate: [],
    //       stats: [],
    //     },
    //     pumping_note: {
    //       preview: [],
    //       view: ["preview"],
    //       manage: ["view", "preview", "emails", "validate"],
    //       validation_notification: [],
    //       emails: [],
    //       validate: [],
    //       associate: [],
    //       accept: [],
    //       stats: [],
    //     },
    //     user: { view: [], manage: ["view"] },
    //     vehicle: { view: [], manage: ["view"] },
    //     billing_customer: { view: [], emails: [], manage: ["view", "emails"] },
    //     delivery_customer: { view: [], emails: [], manage: ["view", "emails"] },
    //     carrier: { view: [], emails: [], manage: ["view", "emails"] },
    //     pumper: { view: [], emails: [], manage: ["view", "emails"] },
    //     region: { view: [], manage: ["view"] },
    //     site: { view: [], manage: ["view"] },
    //   },
    //   mobile: {
    //     delivery_note: { view: [], manage: ["view"] },
    //     vehicle: { view: [], manage: ["view"] },
    //   },
    //   core: {
    //     user: { me: [], password: [] },
    //   },
    // });

    let resolver = new PermissionResolver({
      extranet: {
        driver: { view: [], create: [], remove: [], manage: ["view", "create", "remove"] },
        delivery_note: {
          preview: [],
          view: ["preview"],
          manage: ["view", "preview", "emails", "validate"],
          validation_notification: [],
          emails: [],
          validate: [],
          stats: [],
        },
        pumping_note: {
          preview: [],
          view: ["preview"],
          manage: ["view", "preview", "emails", "validate"],
          validation_notification: [],
          emails: [],
          validate: [],
          associate: [],
          accept: [],
          stats: [],
        },
        user: { view: [], manage: ["view"] },
        vehicle: { view: [], location: [], manage: ["view", "location"] },
        billing_customer: { view: [], emails: [], manage: ["view", "emails"] },
        delivery_customer: { view: [], emails: [], manage: ["view", "emails"] },
        carrier: { view: [], emails: [], manage: ["view", "emails"] },
        pumper: { view: [], emails: [], manage: ["view", "emails"] },
        region: { view: [], manage: ["view"] },
        site: { view: [], manage: ["view"] },
      },
      mobile: {
        delivery_note: { view: [], manage: ["view"] },
        vehicle: { view: [], manage: ["view"] },
      },
      core: {
        user: { me: [], password: [] },
      },
    });

    if (resolver.verifyScopeAuthorized(scopes, currentScope) === true) {
      return true;
    } else {
      return false;
    }
  }

  static getLibelle(scopes: string[] | undefined): string {
    if (scopes != null) {
      if (scopes?.filter((s) => s.includes("manage")).length > 0) {
        return "admin";
      } else {
        return "user";
      }
    } else {
      return "user";
    }
  }

  static hasRight(permission: string) {
    let wkspace = Roles.getCurrentWorkspace();

    if (wkspace != null) {
      return Roles.containsScope(wkspace.scopes, permission);
    }
    return false;
  }

  static hasRightInAnyWorkspace(permission: string) {
    let hasPermission = false;
    let wkspaces = Roles.getAllWorkspaces();

    wkspaces.forEach((w) => {
      if (Roles.containsScope(w.scopes, permission)) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  static getCurrentRole = () => {
    let workspace = Roles.getCurrentWorkspace();
    return workspace?.organization?.type;
  };
  static isAffiliate = () => {
    let workspace = Roles.getCurrentWorkspace();
    return workspace?.organization?.type == WorkspaceTypes.affiliate;
  };

  static isCarrier = () => {
    let workspace = Roles.getCurrentWorkspace();
    return workspace?.organization?.type == WorkspaceTypes.carrier;
  };

  static isUserSite = () => {
    let workspace = Roles.getCurrentWorkspace();
    return workspace?.organization?.type == WorkspaceTypes.site;
  };

  static isUserDelivery = () => {
    let workspace = Roles.getCurrentWorkspace();
    return workspace?.organization?.type == WorkspaceTypes.delivery;
  };

  static isUserBilling = () => {
    let workspace = Roles.getCurrentWorkspace();
    return workspace?.organization?.type == WorkspaceTypes.billing;
  };

  static isUserClient = () => {
    return Roles.isUserBilling() || Roles.isUserDelivery();
  };

  static isReadOnly = () => {
    let isReadonly = false;
    let wp = Roles.getCurrentWorkspace();
    if (wp != null && wp.roles.filter((r) => r == "extranet.visitor").length > 0) {
      isReadonly = true;
    }
    return isReadonly;
  };

  static setHotjarUserId() {
    var userId = Roles.getUserId() || null; // Replace your_user_id with your own if available.
    let currentWindow: any = window;
    console.log("setHotjarUserId", userId);
    let user = {
      role: this.isUserClient() ? "client" : Roles.getCurrentRole(),
      mail: Roles.getEmail(),
      env: process.env.REACT_APP_DEV_ENVIRONMENT,
      organizaionUuid: Roles.getCurrentWorkspaceUuid(),
      userUuid: Roles.getUserId(),
    };
    currentWindow.hj("identify", userId, {
      role: this.isUserClient() ? "client" : Roles.getCurrentRole(),
      mail: Roles.getEmail(),
      env: process.env.REACT_APP_DEV_ENVIRONMENT,
      organizaionUuid: Roles.getCurrentWorkspaceUuid(),
      userUuid: Roles.getUserId(),

      // Add your own custom attributes here. Some EXAMPLES:
      // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
      // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
      // 'Total purchases': 15, // Send numbers without quotes.
      // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
      // 'Last refund date': null, // Send null when no value exists for a user.
    });
    // console.log(user);
  }

  static clearHotjarUser() {
    let currentWindow: any = window;
    currentWindow.hj("identify", -1, {
      role: "unknown",
    });
  }
  static affiateSiteRegion() {
    return Roles.isAffiliate() || Roles.isUserSite() || Roles.isRegion();
  }

  static canSeeDriverInfos() {
    return Roles.isAffiliate() || Roles.isCarrier() || Roles.isUserSite() || Roles.isRegion();
  }
  static canSeeTracking() {
    return Roles.isAffiliate() || Roles.isCarrier() || Roles.isPumper();
  }
  static getOrganizationDisplayType(type: string | undefined) {
    if (type == undefined) {
      return "";
    }
    return type.toString();
    // switch (type) {
    //   case WorkspaceTypes.carrier:
    //     return 'carrier';// "Transporteur";
    //   case WorkspaceTypes.region:
    //     return return 'region';
    //   case WorkspaceTypes.affiliate:
    //     return t() "Affilié";
    //   case WorkspaceTypes.billing:
    //     return "Client";
    //   case WorkspaceTypes.delivery:
    //     return "Chantier";
    //   case WorkspaceTypes.site:
    //     return "Site";
    //   case WorkspaceTypes.pumper:
    //     return "Pompiste";
    // }
  }
}
