import React from "react";
import "./Store.scss";
import logo from "../assets/logo.jpeg";
import appStore from "../assets/app-store.svg";
import { useTranslation } from "react-i18next";
const Store = React.memo(() => {
  const { t } = useTranslation();
  return (
    <div className="storeContent" style={{}}>
      <img src={logo} alt="logo sx delivery" width="200" className="logo" />
      <h1>{t("store")}</h1>

      <a href="https://play.google.com/store/apps/details?id=com.sxdelivery.driver.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img
          style={{ width: "289px" }}
          alt="Disponible sur Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
        />
      </a>

      <a href="https://apps.apple.com/fr/app/sx-delivery/id1557926594">
        <img src={appStore} alt="telecharger sur l'app store" width="250" />
      </a>
    </div>
  );
});

export default Store;
