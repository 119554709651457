export default {
  deliverynote: {
    title: "Bon de livraison",
    noOne: "Aucun bon de livraison",
    emailssend: "Emails renvoyés",
    emailsend: "Email renvoyé",
    validateSuccess: "Le bon de livraison a bien été validé",
    signError: "Une erreur est survenue lors de la signature du BL. Veuillez réessayer",
    manualValidateSuccess: "Bon de livraison a bien été validé manuellement",

    mailNotification: "Notification mail",
    selectAll: "Tout sélectionner",
    emailOpennedAt: "Email ouvert le {{date}} à {{hour}}",
    emailNotopen: "Email non ouvert",
    sending: "Envoi en cours...",
    resend: "Renvoyer Email",
    sentAt: "Envoyé le {{date}}",
    cancelledDn: {
      title: "Bon de livraison annulé",
      reason: "Raison",
      success: "Bon de livraison annulé",
      date: "Date d'annulation",
    },
    refusedDn: {
      title: "Bon de livraison refusé",
      incident: "Incident de livraison",
    },
    transport: "Transport",
    deliveryDate: "Date de livraison",
    dlPdf: "Télécharger PDF",
    number: "Numéro de BL",
    additionalService: "Service additionnel",
    clientComments: "Commentaires client",
    driverComment: "Commentaire chauffeur",
    returnedProduct: "Retour produit",
    completedByApp: "Démat",
    vehicleName: "Camion",
    registrationPlate: "Immatriculation",
    product: "Produit",
    productQuantity: "Quantité",
    pumpType: "Type de pompe",
    date: "Date",
    estimatedPumpedQuantity: "Quantité pompée estimée",
    realPumpedQuantity: "Quantité pompée réelle",
    productUnit: "Unité",
    comments: "Commentaires",
    purchaseZone: "Zone d'achat",
    transportZone: "Zone de transport",
    orderReference: "Référence de commande",
    customField1: "Réf chantier",
    vehicle: "Camion",
    productList: "Liste des produits commandés",
    quantity: "Quantité",
    unitev: "U.V",
    newDn: "Un nouveau bon de livraison est disponible",
    securityMeasures: {
      title: "Mesures de sécurité",
      worksiteAccess: "L'accès au chantier est correct",
      presenceOfElectricalLines: "Lignes électrique sur la zone de travail",
      worksiteWithGutter: "Le chantier est réalisable à la goulotte ",
      parkingVehicle: "Stationnement sécurisé",
      machineStabilization: "Stabilisation sécurisée",
      otherRisks: "Autres risques",
    },
    geoloc: {
      title: "Géolocalisation des étapes de la livraison",
      map: "Carte de la livraison",
      startLabel: "Début du trajet",
      endLabel: "Fin du trajet",
      deliveryLabel: "Chantier",
    },
    co2emission: "Le transport a émis {{value}} kg de CO2",
    deliveryTimes: {
      title: "Temps de livraison",
      production: "Production",
      chartered: "Confirmation de chargement par le chauffeur",
      worksiteArrivalTime: "Arrivée chantier réelle",
      worksiteUnloadingStartTime: "Début déchargement",
      worksiteUnloadingEndTime: "Fin déchargement",
      worksiteInstallationStartTime: "Début installation",
      worksiteInstallationEndTime: "Fin installation",
      worksitePumpingStartTime: "Début pompage",
      worksitePumpingEndTime: "Fin pompage",
      signature: "Signature du client",
      worksiteDepartureTime: "Départ chantier",
    },
    documents: "Documents",
    digitalDisclaimer: "Décharge de résponsabilité",
    transportLetter: "Lettre de transport",
    weightTicket: "Ticket-de-pesée",
    signature: "Signature",
    pumping: {
      number: "Numéro de BMD",
      noOne: "Aucun bon de mise à disposition",
    },
    driverDetails: "Détails chauffeurs",
    clientDetails: "Détails client",
    filter: {
      orderReference: "Réf commande",
      show: "Afficher les filtres avancés",
      hide: "Masquer les filtres avancés",
      departure: "BL départ",
      nodeparture: "Bl rendu",
      type: "Type",
      demat: "Démat",
      nodemat: "Non",
      site: "Site",
      placeholderLength: "Entrez {{length}} caractère(s) pour lancer une recherche",
    },
    confirmDelivery: "Est-ce que la livraison a eu lieu ?",
    confirmDeliveryQuestion: "Confirmez-vous la livraison ?",
    cancelDeliveryQuestion: "Etes-vous sûr d'annuler ce bon de livraison ?",
    services: "Liste des ajouts",
    search: {
      deliverynote: "Chercher un bon de livraison",
      pumping: "Chercher un bon de mise à disposition",
    },
    columns: {
      showAll: "Afficher toutes les colonnes",
      hideAll: "Masquer toutes les colonnes",
    },
    acceptCommand: {
      title: "Accepter la commande",
      success: "La commande a bien été acceptée",
      question: "Etes-vous sûr d'accepter cette commande ?",
    },
    refuseCommand: {
      title: "Refuser la commande",
      success: "La commande a bien été refusée",
      question: "Etes-vous sûr de refuser cette commande ?",
    },
    assign: {
      title: "Assigner",
      success: "Le bon de mise à disposition a bien été assigné",
      error: "Le statut actuel du BMD ne permet pas la modification de la pompe",
    },
    status: {
      title: "Statut",
      new: "Pas d'info",
      chartered: "En route",
      accepted: "En Route",
      delivering: "Chantier",
      validated: "Signé",
      active: "En attente",
      canceled: "Annulé",
      deleted: "Supprimé",
      inactive: "Inactif",
      partially_refused: "Retour béton",
      refused: "Refus",
      pumping: "Pompage",
      unloading: "Déchargement",
      supported: "En route",
      installed: "Installé",
      ordered: "Nouveau",
      confirmed_order: "Validé",
      accepted_order: "Accepté",
      refused_order: "Refusé",
      terminated: "Terminé",
      delivered: "Livré",
    },
    signDn: {
      title: "Signer le bon de livraison",
      action: "Signature du bon de livraison",
      signerName: "Nom du signataire",
      goSign: "Passer à la signature",
    },
  },
};
