import login from "./login";
import menu from "./menu";
import deliveynote from "./deliverynote";
import stats from "./stats";

let trad = {
  "login.error.email.required": "L'adresse email est obligatoire",
  store: "Cliquer sur votre store et télécharge",
  common: {
    "translate-button": "FR",
    error: "Une erreur est survenue. Veuillez réessayer",
  },
  see: "Voir",
  and: "et",
  others: "autre(s)",
  account: {
    create: "Créer mon compte",
    edit: "Modifier mon compte",
    delete: "Supprimer mon compte",
    updated: "Compte modifié",
    confirmPassword: "Confirmation du mot de passe",
    phoneNumber: "Numéro de téléphone",
  },
  role: {
    title: "Rôle",
    choose: "Choisissez un rôle",
    admin: "Administrateur",
    user: "Utilisateur",
  },
  users: {
    added: "Utilisateur ajouté",
    addToSite: "Ajouter à un ou plusieurs site(s)",
    addToRegion: "Ajouter à une ou plusieurs région(s)",
    sites: "Site(s)",
    regions: "Région(s)",
    update: "Modifier un utilisateur",
    delete: "Supprimer un utilisateur",
    deleted: "Utilisateur supprimé",
    updated: "Utilisateur modifié",
    created: "Utilisateur créé",
    selectDeliveries: "Choisir un ou plusieurs chantiers",
    selectRegion: "Choisir une ou plusieurs régions",
    selectSite: "Choisir un ou plusieurs sites",
    organisations: "Organisations",
    changeRole: "Passer en ",
    deleteQuestion: "Etes-vous sûr de supprimer l'utilisateur {{username}} pour l'organisation {{workspacename}} ?",
    deleteQuestion2: "Etes-vous sûr de supprimer l'utilisateur {{username}} ?",
    search: "Chercher un utilisateur",
    noOne: "Aucun utilisateur",
    add: {
      title: "Ajouter un utilisateur",
    },
  },
  optional: "Optionnel",
  dn: "Bon de livraison",
  bmd: "BMD ",
  designation: "Désignation",
  denomination: "Dénomination",
  label: "Libellé",
  back: "Retour",
  goBackHome: "Retour à l'accueil",
  yes: "Oui",
  no: "Non",
  reference: "Référence",
  type: "Type",
  emails: "Emails",
  seeDetails: "Voir les détails",
  actions: {
    create: "Créer",
    edit: "Modifier",
    delete: "Supprimer",
    save: "Enregistrer",
    cancel: "Annuler",
    close: "Fermer",
    add: "Ajouter",
    validate: "Valider",
    search: "Rechercher",
    clear: "Effacer",
    sign: "Signer",
  },
  carrier: "Transporteur",
  region: "Région",
  affiliate: "Affilié",
  billing: "Client",
  delivery: "Chantier",
  site: "Site",
  pumper: "Pompiste",
  admin: "Admin",
  user: "Utilisateur",
  result: "résultat",
  results: "resultats",
  vehicle: "Camion",
  vehicleLabel: "Libellé Camion",
  customer: "Client",
  notFilled: "Non renseigné",
  table: {
    perpage: "Par page",
    on: "sur",
    total: "Total",
    lastupdated: "Dernière mise à jour le {{day}} à {{hour}}h{{minute}}",
  },
  name: "Nom",
  firstname: "Prénom",
  lastUsedTime: "Dernière utilisation",
  loading: "Chargement en cours",
  seeMore: "Voir plus",
  "searchLength.error": "La rechercherche doit être superieur à {{length}} caractères",
  noOptions: "Pas de résultat",
  noSite: "Aucun site",
  seeDns: "Voir les BL",
  seeBMD: "Voir les BMD",
  saving: "Enregistrement en cours",
  "email.added": "L'email a bien été ajouté",
  "user.deleted": "L'utilisateur a bien été supprimé",
  regions: {
    noOne: "Aucune région",
    search: "Chercher une région",
    deleteSite: {
      question: "Etes-vous sûr de désassocier le site {{site}} de la région {{region}} ?",
      success: "Le site a bien été supprimé de la région",
    },
    add: {
      title: "Ajouter une région",
      success: "La région a bien été ajoutée",
      error: "La région existe déjà",
    },
    addSite: {
      title: "Ajouter un site",
      successMultiple: "Les sites ont bien été ajoutés à la région",
      success: "Le site a bien été ajouté à la région",
      list: "Sites associés à la région",
      list_noSite: "Aucun site associé",
      choose: "Choisir un site",
    },
    update: {
      inprogress: "Mise à jour en cours",
    },
    save: {
      inprogress: "Sauvegarde en cours",
    },
  },
  sites: {
    activeDeparture: "Activer le mode Bl départ",
    deactiveDeparture: "Désactiver le mode Bl départ",
    deactiveWithdrawalPointSuccess: "Le mode BL départt a bien été désactivé",
    activeWithdrawalPointSuccess: "Le mode BL départ a bien été activé",
    search: "Chercher un site",
    noOne: "Aucun site",
    deactiveWithdrawalPointQuestion: "Etes-vous sûr de désactiver les Bls départ sur le site {{site}} ?",
    activeWithdrawalPointQuestion: "Etes-vous sûr d'activer les Bls départ sur le site {{site}} ?",
  },
  carriers: {
    choose: "Choisir un transporteur",
    search: "Chercher un transporteur",
    noOne: "Aucun transporteur",
    choosePumper: "Choisir une pompe",
    assignBMPPump: " Assigner une plaque d'immatriculation pour le BMD",

    add: {
      title: "Ajouter un transporteur",
    },
  },
  drivers: {
    search: "Chercher un chauffeur",
    noOne: "Aucun chauffeur",
    add: {
      title: "Ajouter un chauffeur",
      success: "Le chauffeur a bien été ajouté",
    },
    create: "Le chauffeur a bien été crée",
    deleteQuestion: "Etes-vous sûr de supprimer le chauffeur {{driver}} ?",
    deleteSuccess: "Le chauffeur a bien été supprimé",
  },
  deliveries: {
    search: "Chercher un chantier",
    noOne: "Aucun chantier",
  },
  customers: {
    search: "Chercher un client",
    noOne: "Aucun client",
    emailPlaceholder: "Ajouter un email et valider",
  },
  vehicles: {
    search: "Chercher un camion",
    immat: "Plaque d'immatriculation",
    label: "Libellé du camion",
    type: "Type de camion",
    chooseType: "Choisir un type de camion",
    types: {
      concrete_mixer: "Camion toupie",
      dump_truck: "Camion benne",
      pumi: "Pumi",
      pump_truck: "Pompe",
      unknown: "Type inconnu",
    },
    noOne: "Aucun camion",
    add: {
      title: "Ajouter un camion",
    },
    status: {
      associated: "Associé",
      available: "Disponible",
    },
    updated: "Le camion a bien été mis à jour",
    created: "Le camion a bien été crée",
  },
  errors: {
    "lastname.required": "Le nom est obligatoire",
    "firstname.required": "Le prénom est obligatoire",
    "email.required": "L'adresse email est obligatoire",
    "email.invalid": "L'adresse email est invalide",
    "password.required": "Le mot de passe est obligatoire",
    "password.confirm.required": "La confirmation du mot de passe est obligatoire",
    "password.confirmation": "Les mots de passe doivent être identiques",
    "password.minlength": "Le mot de passe doit contenir au moins 8 caractères",
    "phone.required": "Le numéro de téléphone est obligatoire",
    "phone.invalid": "Le numéro de téléphone est invalide",
    "role.required": "Le rôle est obligatoire",
    "region.required": "La région est obligatoire",
    "site.required": "Le site est obligatoire",
    "carrier.required": "Le transporteur est obligatoire",
    "user.alreadyExist": "L'utilisateur existe déjà",
    "name.required": "Le nom est obligatoire",
    "denomination.required": "La dénomination est obligatoire",
    saveColumn: "Une erreur est survenue lors de la sauvegarde de l'ordre des colonnes",
    "vehicle.required": "Le camion est obligatoire",
    "vehicle.alreadyExist": "Le camion existe déjà",
    "immat.required": "La plaque d'immatriculation est obligatoire",
    "immat.minLength": "La plaque d'immatriculation doit contenir au moins 4 caractères",
    "vehicle.label.required": "Le libellé du camion est obligatoire",
    "immat.invalid": "La plaque d'immatriculation n'est pas valide",
    "link.invalid": "Le lien n'est pas valide",
    "updatePumper.cantUpdate": "Le statut actuel du BMD ne permet pas la modification de la pompe",
    "signerName.required": "Le nom du signataire est obligatoire",
    madeby: "Made in",
  },
  appCode: {
    title: "Si la redirection ne fonctionne pas ",
    description: "Rentrez le code suivant :",
  },
};

let finalTrad = Object.assign(trad, login);
finalTrad = Object.assign(finalTrad, menu);
finalTrad = Object.assign(finalTrad, deliveynote);
finalTrad = Object.assign(finalTrad, stats);

export default finalTrad;
