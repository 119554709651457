import React, { useEffect, useState } from "react";
import "./Regions.scss";
import "../styles/table.scss";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Menu from "./Menu";
import TableStyles from "../styles/tableStyles";
import Config from "../Config";
import SearchBar from "./SearchBar";
import Region from "../models/Region";
import Roles from "../utils/Roles";
import { Scopes } from "../models/Scopes";
import PopupStyle from "../styles/popupContentStyle";
import PopPop from "react-poppop";
import AddRegion from "./AddRegion";
import AddRegionSite from "./AddRegionSite";
import ToastComponent from "./ToastComponent";
import Toast from "../models/Toast";
import { ToastTypes } from "../models/ToastTypes";
import Site from "../models/Site";
import { ReactComponent as Close } from "../assets/svg/close.svg";
import ConfirmPopup from "./ConfirmPopup";
import { useTranslation } from "react-i18next";

const Regions = React.memo(() => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResource, setTotalResource] = useState(0);
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false);
  const [isOpenAddSites, setIsOpenAddSites] = useState(false);
  const [currentRegion, setCurrentRegion] = useState<Region | undefined>(undefined);
  const [toasts, setToast] = useState<Toast[]>([]);
  const [currentSiteToDelete, setCurrentSiteToDelete] = useState<Site>();
  const [sortColumn, setSortColumn] = useState("externalReference");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isOpenConfirmDeleteSite, setIsOpenConfirmDeleteSite] = useState(false);
  const { t } = useTranslation();
  const columns: any[] = [
    // {
    //   name: "",
    //   sortable: false,
    //   right: true,
    //   cell: (row: any) => (
    //   <Link className="table-link" to={seeCarrierBls(row)}>
    //   {t("seeDns")}
    // </Link>
    //   ),
    //   button: true,
    // },
    {
      name: t("designation"),
      selector: (row: Region) => row.externalReference,
      cell: (row: Region) => <div className="firstColumn">{row.externalReference}</div>,
      sortable: true,
      right: false,
      id: "externalReference",
    },
    {
      name: t("label"),
      selector: (row: Region) => row.name,
      sortable: true,
      right: false,
      id: "name",
    },
    {
      name: t("menu.sites"),
      selector: (row: Region) => row.sites,
      cell: (row: Region) => (
        <div className={`status`}>
          {row.sites?.map((site) => {
            return (
              <div key={site.uuid} className="row">
                <div style={{ paddingTop: 0, cursor: "pointer" }} onClick={() => deleteSite(row, site)}>
                  <Close className="icon" width="20" height="20" fill={"#90a0b7"} />
                </div>
                <div style={{ marginLeft: 10 }}>{site.externalReference}</div>
                <div style={{ marginLeft: 10 }}>{site.name}</div>
              </div>
            );
          })}
        </div>
      ),
      // sortable: true,
      right: false,
      id: "sites",
    },
  ];

  // if (Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_VIEW) && Roles.hasBMD()) {
  //   columns.unshift({
  //     name: "",
  //     sortable: true,
  //     cell: (row: any) => (
  //       <div className="table-link" onClick={() => seeSiteBMD(row)}>
  //         {t("seeBMD")}
  //       </div>
  //     ),
  //     button: true,
  //   });
  // }
  if (Roles.hasRight(Scopes.SCOPE_EXTRANET_REGION_MANAGE)) {
    columns.push({
      name: "",
      // selector: "vehicle.",
      sortable: false,
      cell: (row: Region) => (
        <div
          className="validateBtn"
          style={{ padding: 12, marginTop: 0 }}
          onClick={() => {
            setCurrentRegion(row);
            setIsOpenAddSites(true);
          }}
        >
          <h6>{t("regions.addSite.title")} </h6>
        </div>
      ),
    });
  }

  const [filteredItems, setFilterereditems] = useState<Region[]>([]);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    fetchRegions(1, perPage);
  }, []);

  // const seeSiteDns = (site: Region) => {
  //   let url = "deliverynotes?site=" + site.name;
  //   history.push(url);
  // };
  // const seeSiteBMD = (site: Region) => {
  //   let url = "pumpingNotes?site=" + site.name;
  //   history.push(url);
  // };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const onSuccessCreateRegion = () => {
    addToast(t("region.add.success"), ToastTypes.success);
    fetchRegions(currentPage, perPage);
    setIsOpenAddPopup(false);
  };

  const onSuccessAddRegionSite = (sitesLength: number) => {
    if (sitesLength > 1) {
      addToast(t("region.addsite.successMultiple"), ToastTypes.success);
    } else {
      addToast(t("region.addsite.success"), ToastTypes.success);
    }
    fetchRegions(currentPage, perPage);
    setIsOpenAddSites(false);
  };

  const onSuccessDeleteRegionSite = () => {
    addToast(t("region.deleteSite.success"), ToastTypes.success);
    fetchRegions(currentPage, perPage);
    setIsOpenAddSites(false);
    setIsOpenConfirmDeleteSite(false);
  };

  const fetchRegions = (
    page: number,
    newPerPage: number,
    newFilterText: string = filterText,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection
  ) => {
    const token = localStorage.getItem("token");
    let url =
      "regions?page=" +
      page +
      "&limit=" +
      newPerPage +
      "&search=" +
      newFilterText +
      "&sort_by=" +
      newSortColumn +
      "&order_by=" +
      newSortDirection;
    setCurrentPage(page);

    if (newFilterText != filterText) {
      setFilterText(newFilterText);
    }

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    axios
      .get(Config.getUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.totalResource);
        setFilterereditems(res.data.content.items);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };

  const deleteSite = (region: Region, site: Site | undefined) => {
    setCurrentRegion(region);
    setCurrentSiteToDelete(site);
    setIsOpenConfirmDeleteSite(true);
  };

  const onDeleteSiteOk = () => {
    let token = localStorage.getItem("token");

    let dataToSend = {
      region: currentRegion?.uuid,
      site: currentSiteToDelete?.uuid,
    };

    let url = `regions/sites/remove`;
    axios
      .post(Config.getUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        onSuccessDeleteRegionSite();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 400) {
            if (error.response.data.code == "ERR4010003" || error.response.data.code == "ERR4000301") {
              //   setErrorMessage("Le site est déjà associé à la région");
            }
          }
        } else if (error.request) {
          //  setErrorMessage("common.error");
        } else {
          // setErrorMessage("common.error");
        }
      });
  };

  const handlePageChange = (pageNumber: number) => {
    fetchRegions(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchRegions(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == "asc") {
        newSortOrder = "desc";
      } else {
        newSortOrder = "asc";
      }
    } else {
      newSortOrder = "asc";
    }
    fetchRegions(1, perPage, filterText, data.id, newSortOrder);
  };

  return (
    <div className="sites">
      <Menu selectedPage="regions"></Menu>

      <div className={"right table-container"}>
        <div className="title">{t("menu.regions")}</div>
        <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

        <div className="filterBar">
          <SearchBar placeholder={t("regions.search")} onFilter={(value) => fetchRegions(1, perPage, value)} />
        </div>

        <div className="buttonContainer">
          <div className="nbElements">
            {filterText != "" ? <span>{filteredItems.length}/</span> : <span>{totalRows}/</span>}
            {totalResource} {totalResource > 1 ? t("results") : t("result")}
          </div>

          {Roles.hasRight(Scopes.SCOPE_EXTRANET_USER_MANAGE) && !isLoading ? (
            <button className="validateBtn right-btn" onClick={() => setIsOpenAddPopup(true)}>
              <h6>{t("regions.add.title")}</h6>
            </button>
          ) : null}
        </div>

        {isLoading ? (
          <div>{t("loading")}</div>
        ) : (
          <DataTable
            className="table"
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            data={filteredItems}
            noDataComponent={<div className="noResults">{t("regions.noOne")}</div>}
            defaultSortAsc={sortDirection == "asc"}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            paginationComponentOptions={{
              rowsPerPageText: t("table.perpage"),
              rangeSeparatorText: t("table.on"),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
          />
        )}
      </div>

      <PopPop
        open={isOpenAddPopup}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenAddPopup(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <AddRegion
          // userType="carriers"
          // customer={currentCustomer}
          onsuccess={onSuccessCreateRegion}
          // oncancel={() => setIsOpenAddPopup(false)}
        />
      </PopPop>

      <PopPop
        open={isOpenAddSites}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenAddSites(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <AddRegionSite
          currentRegion={currentRegion}
          onSuccess={(number) => onSuccessAddRegionSite(number)}
          onSuccessDeleteSite={onSuccessDeleteRegionSite}
        />
      </PopPop>

      <PopPop
        open={isOpenConfirmDeleteSite}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenConfirmDeleteSite(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <ConfirmPopup
          message={t("regions.deleteSite.question", { site: currentSiteToDelete?.name, region: currentRegion?.name })}
          confirm={() => onDeleteSiteOk()}
          cancel={() => setIsOpenConfirmDeleteSite(false)}
          cancelMessage={t("no")}
          confirmMessage={t("yes")}
        />
      </PopPop>
    </div>
  );
});

export default Regions;
