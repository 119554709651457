import React, { useEffect, useState } from "react";
import "./Carriers.scss";
import "../styles/table.scss";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import Menu from "./Menu";
import TableStyles from "../styles/tableStyles";
import Config from "../Config";
import Carrier from "../models/Carrier";
import SearchBar from "./SearchBar";
import PopupStyle from "../styles/popupContentStyle";
import PopPop from "react-poppop";
import Roles from "../utils/Roles";
import { Scopes } from "../models/Scopes";
import AddDriver from "./AddDriver";
import Toast from "../models/Toast";
import { ToastTypes } from "../models/ToastTypes";
import ToastComponent from "./ToastComponent";
import UpdateCustomer from "./UpdateCustomer";
import { useTranslation } from "react-i18next";

const Carriers = React.memo(() => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenAddDriverPopup, setIsOpenAddDriverPopup] = useState(false);
  const history = useHistory();
  const [toasts, setToast] = useState<Toast[]>([]);
  const [currentCustomer, setCurrentCustomer] = useState<Carrier>();
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false);
  const [totalRows, settotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [totalResource, setTotalResource] = useState(0);

  const columns: any[] = [
    {
      name: t("name"),

      cell: (row: any) => <div className="firstColumn">{row.name}</div>,
      sortable: true,
      selector: (row: Carrier) => row.name,
      right: false,
      id: "name",
    },

    {
      name: t("reference"),
      selector: (row: Carrier) => row.externalReference,
      sortable: true,
      right: false,
      id: "externalReference",
    },

    {
      name: t("reference"),
      selector: (row: Carrier) => row.type,
      cell: (row: any) => <div className="">{t(row.type)}</div>,
      sortable: false,
      right: false,
      id: "type",
    },
  ];

  if (Roles.hasRight(Scopes.SCOPE_EXTRANET_CARRIER_EMAILS)) {
    columns.push({
      name: t("emails"),
      cell: (row: Carrier) => {
        return row?.users != null && row.users.length > 1 ? (
          <div>
            <span className="bold">{row.users[0]}</span>{" "}
            <span>
              {t("and")} {row.users.length - 1} {t("others")}
            </span>
          </div>
        ) : (
          <div className="bold">{row?.users != null && row?.users.length == 1 ? row.users[0] : ""}</div>
        );
      },
      sortable: false,
      right: false,
      id: "emails",
    });

    columns.unshift({
      name: "",
      sortable: true,
      cell: (row: any) => (
        <div className="table-link" onClick={() => seeClientDetails(row)}>
          {t("seeDetails")}
        </div>
      ),
      button: true,
    });
  }

  if (Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_VIEW) && Roles.hasBMD()) {
    columns.unshift({
      name: "",
      sortable: true,
      cell: (row: Carrier) =>
        row.type == "pumper" ? (
          <Link className="table-link" to={seeCarriersBMD(row)}>
            {t("seeBMD")}
          </Link>
        ) : null,
      button: true,
    });
  }

  columns.unshift({
    name: "",
    sortable: true,
    cell: (row: any) => (
      <Link className="table-link" to={seeCarrierBls(row)}>
        {t("seeDns")}
      </Link>
    ),
    button: true,
  });

  const [filteredItems, setFilterereditems] = useState<Carrier[]>([]);
  const [filterText, setFilterText] = useState("");

  const seeClientDetails = (customer: Carrier) => {
    setCurrentCustomer(customer);
    setIsOpenAddPopup(true);
  };

  const seeCarrierBls = (carrier: Carrier) => {
    return "deliverynotes?carrier=" + carrier.name;
  };

  const seeCarriersBMD = (carrier: Carrier) => {
    return "pumpingNotes?carrier=" + carrier.name;
  };

  const onSuccessAddDriver = () => {
    setIsOpenAddDriverPopup(false);
    addToast(t("drivers.add.success"), ToastTypes.success);
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const onSuccessUpdateCustomer = () => {
    setIsOpenAddPopup(false);
    setTimeout(() => fetchCarriers(currentPage, perPage), 200);
    addToast(t("email.added"), ToastTypes.success);
  };

  const onDeleteEmail = () => {
    setTimeout(() => fetchCarriers(currentPage, perPage), 200);
    addToast(t("user.deleted"), ToastTypes.success);
  };

  useEffect(() => {
    fetchCarriers(1, perPage);
  }, []);

  const fetchCarriers = (
    page: number,
    newPerPage: number,
    newFilterText: string = filterText,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection
  ) => {
    setCurrentPage(page);
    if (newFilterText != filterText) {
      setFilterText(newFilterText);
    }

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }
    setIsLoading(true);
    setFilterereditems([]);

    let baseUrl = "carriers";

    const token = localStorage.getItem("token");
    let url = `${baseUrl}?page=${page}&limit=${newPerPage}&search=${newFilterText}&sort_by=${newSortColumn}&order_by=${newSortDirection}`;

    axios
      .get(Config.getUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setFilterereditems(res.data.content);
        // setIsLoading(false);

        settotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.totalResource);
        setFilterereditems(res.data.content.items);
        // setDls(res.data.content.items);
        // setCarriers(res.data.content.items);
        // setFilterereditems(res.data.content.items);

        setIsLoading(false);
        // if (nbPages > 1) {
        //   for (let i = 2; i <= nbPages; i++) {
        //     getNewPage(i, newPerPage, res.data.meta.total);
        //   }
        // } else {
        //   setIsLoading(false);
        // }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };

  const handlePageChange = (pageNumber: number) => {
    fetchCarriers(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchCarriers(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == "asc") {
        newSortOrder = "desc";
      } else {
        newSortOrder = "asc";
      }
    } else {
      newSortOrder = "asc";
    }
    fetchCarriers(1, perPage, filterText, data.id, newSortOrder);
  };

  return (
    <div className="carriers">
      <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

      <Menu selectedPage={"carriers"}></Menu>

      <div className={"right table-container"}>
        <div className="title">{t("menu.carriers")}</div>
        <div className="filterBar">
          <SearchBar
            placeholder={t("carriers.search")}
            onFilter={(value) => fetchCarriers(1, perPage, value)}
            minLength={3}
          ></SearchBar>
        </div>
        <div className="buttonContainer">
          <div className="nbElements">
            {filterText != "" ? <span>{filteredItems.length}/</span> : <span>{totalRows}/</span>}
            {totalResource} {totalResource > 1 ? t("results") : t("result")}
          </div>

          {Roles.hasRight(Scopes.SCOPE_EXTRANET_DRIVER_CREATE) && !isLoading ? (
            <button className="validateBtn right-btn" onClick={() => setIsOpenAddDriverPopup(true)}>
              <h6>{t("drivers.add.title")}</h6>
            </button>
          ) : null}
        </div>

        {isLoading ? (
          <div>{t("loading")}</div>
        ) : (
          <DataTable
            className="table"
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            sortServer={true}
            data={filteredItems}
            defaultSortAsc={sortDirection == "asc"}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            noDataComponent={<div className="noResults">{t("carriers.noOne")}</div>}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            paginationComponentOptions={{
              rowsPerPageText: t("table.perpage"),
              rangeSeparatorText: t("table.on"),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
          />
        )}
        <PopPop
          open={isOpenAddDriverPopup}
          closeOnOverlay={true}
          closeOnEsc={true}
          onClose={() => setIsOpenAddDriverPopup(false)}
          className="popup"
          position="topCenter"
          contentStyle={PopupStyle.popupContentStyle}
          overlayStyle={PopupStyle.overlaystyle}
        >
          <AddDriver from="carrier" onsuccess={onSuccessAddDriver} />
        </PopPop>

        <PopPop
          open={isOpenAddPopup}
          closeOnOverlay={true}
          closeOnEsc={true}
          onClose={() => setIsOpenAddPopup(false)}
          className="popup"
          position="topCenter"
          contentStyle={PopupStyle.popupContentStyle}
          overlayStyle={PopupStyle.overlaystyle}
        >
          <UpdateCustomer
            userType="carriers"
            customer={currentCustomer}
            onsuccess={onSuccessUpdateCustomer}
            onDeleteEmail={onDeleteEmail}
            oncancel={() => setIsOpenAddPopup(false)}
            isPumpers={currentCustomer?.type == "pumper"}
          />
        </PopPop>
      </div>
    </div>
  );
});

export default Carriers;
