export default {
  login: {
    title: "Login",
    accessTitle: "Access to your space",
    email: "Email",
    password: "Password",
    updatePassword: "Update password",
    submit: "Log in",
    "forgot-password": "Forgot password?",
    "choose-workspace-explanation":
      "You are associated with several SX Delivery workspaces. Choose a workspace to connect",
    "choose-workspace.submit": "Go to workspace",
    selectWorkspace: "Select workspace",
    goToDn: "Go to Delivery Note",
    "resetpassword.success": "An email has been sent to you to reset your password",
    error: {
      "email.required": "Email is required",
      "email.invalid": "Email is invalid",
      password: "Password is required",
      invalid: "Invalid email or password",
    },
    newLinkSend: "New login link sent!",
    newLinkSendDescription: "Check your email address and click on the new login link received.",
    sendNewLink: "Send a new link",
    expiredLink: {
      title: "Expired login link",
      description1: "Your login link has expired!",
      description2: "Click on the button below to receive a new one on your email address.",
    },
  },
};
