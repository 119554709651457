import { FilterType } from "../models/FilterType";

const localStorageKey = "filters";

export default class Filters {
  static async clear(type: FilterType) {
    localStorage.removeItem(localStorageKey + "-" + type.toString());
  }
  static updateFilter(filters: any, type: FilterType) {
    localStorage.removeItem(localStorageKey + "-" + type.toString());
    localStorage.setItem(localStorageKey + "-" + type.toString(), JSON.stringify(filters));
  }
  static getFilters = (type: FilterType): any => {
    let filters = localStorage.getItem(localStorageKey + "-" + type.toString());
    if (filters != null) {
      return JSON.parse(filters);
    } else {
      return null;
    }
  };
}
