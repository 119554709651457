import React, { useEffect, useState } from "react";
import "./CustomersContainer.scss";
import "../styles/table.scss";
import Menu from "./Menu";
import Stats from "./Stats";
import StatsBMD from "./StatsBMD";
import { useOuterClick } from "react-outer-click";

import { ReactComponent as Calendar } from "../assets/svg/calendar.svg";
import moment from "moment";
import Roles from "../utils/Roles";
import { useTranslation } from "react-i18next";

const CalendarInput = ({ onClick }: any) => (
  <div className={"calendar-input dateselected"} onClick={onClick}>
    <span className="icon">
      <Calendar className={"icon"} width="25" height="20" fill={"white"} />
    </span>
  </div>
);

enum StatisticPeriod {
  ALL = "all",
  THIS_YEAR = "thisYear",
  THIS_MONTH = "thisMonth",
  THIRTY_DAYS = "thirtyDays",
  FIFTEEN_DAYS = "fifteenDays",
  SEVEN_DAYS = "sevenDays",
  YESTERDAY = "yesterday",
  TODAY = "today",
}
interface DeliveryNoteStats {
  totalDeliveryNote: any;
  totalDigitalDeliveryNote: any;
  deliveredDigitalDeliveryNote: any;
  collectedDigitalDeliveryNote: any;
  organization: string;
  collectedDeliveryNote: number;
  deliveredDeliveryNote: number;
  uuid: string;
}

interface PumpingNoteStats {
  totalPumpingNote: any;
  totalDigitalPumpingNote: any;
  organization: string;
  uuid: string;
}

const StatsContainer = React.memo(() => {
  useEffect(() => {
    if (Roles.canSeeBlStats()) {
      setCurrentTab("delivery-notes");
    } else {
      setCurrentTab("pumping-notes");
    }
  }, []);
  const [currentTab, setCurrentTab] = useState("delivery-notes");

  const [isOpenFilterPeriod, setIsOpenFilterPeriod] = useState(false);
  const [currentPeriod, setCurrentPeriod] = useState<StatisticPeriod>(StatisticPeriod.TODAY);
  const [currentRegion, setCurrentRegion] = useState<DeliveryNoteStats | PumpingNoteStats>();

  const onChangePeriod = (period: StatisticPeriod) => {
    setCurrentPeriod(period);

    setIsOpenFilterPeriod(false);
    // fetchStats(currentTab, period, currentRegion);
  };
  const ref = React.useRef(null);
  useOuterClick(ref, () => {
    setIsOpenFilterPeriod(false);
  });

  const { t } = useTranslation();

  const [lastUpdate, setLastUpdate] = useState<Date | undefined>(undefined);
  return (
    <div className="carriers">
      <Menu selectedPage={"stats"}></Menu>

      <div className={"right table-container"}>
        <div className="row" style={{ justifyContent: "space-between" }}>
          <div className="row">
            <h3>
              {t("menu.stats")}

              <span style={{ fontSize: 16, fontStyle: "italic" }}>
                {currentRegion != null && currentRegion?.organization != ""
                  ? " - " + currentRegion?.organization
                  : null}
              </span>
            </h3>

            <div ref={ref} className="filter" style={{ width: "fit-content" }}>
              <div>
                <div className="" onClick={() => setIsOpenFilterPeriod(!isOpenFilterPeriod)}>
                  <CalendarInput />
                </div>
              </div>

              <div className={isOpenFilterPeriod ? "popup-filtercolumns" : "popup-filter hide"}>
                <div
                  className={currentPeriod == StatisticPeriod.TODAY ? "selectedPeriod" : "period"}
                  onClick={() => onChangePeriod(StatisticPeriod.TODAY)}
                >
                  {t("stats.date.today")}
                </div>
                <div
                  className={currentPeriod == StatisticPeriod.YESTERDAY ? "selectedPeriod" : "period"}
                  onClick={() => onChangePeriod(StatisticPeriod.YESTERDAY)}
                >
                  {t("stats.date.yesterday")}
                </div>
                <div
                  className={currentPeriod == StatisticPeriod.SEVEN_DAYS ? "selectedPeriod" : "period"}
                  onClick={() => onChangePeriod(StatisticPeriod.SEVEN_DAYS)}
                >
                  {t("stats.date.lastweek")}
                </div>

                <div
                  className={currentPeriod == StatisticPeriod.FIFTEEN_DAYS ? "selectedPeriod" : "period"}
                  onClick={() => onChangePeriod(StatisticPeriod.FIFTEEN_DAYS)}
                >
                  {t("stats.date.last15days")}
                </div>

                <div
                  className={currentPeriod == StatisticPeriod.THIRTY_DAYS ? "selectedPeriod" : "period"}
                  onClick={() => onChangePeriod(StatisticPeriod.THIRTY_DAYS)}
                >
                  {t("stats.date.last30days")}
                </div>

                <div
                  className={currentPeriod == StatisticPeriod.THIS_MONTH ? "selectedPeriod" : "period"}
                  onClick={() => onChangePeriod(StatisticPeriod.THIS_MONTH)}
                >
                  {t("stats.date.thismonth")}
                </div>

                <div
                  className={currentPeriod == StatisticPeriod.THIS_YEAR ? "selectedPeriod" : "period"}
                  onClick={() => onChangePeriod(StatisticPeriod.THIS_YEAR)}
                >
                  {t("stats.date.thisyear")}
                </div>
                <div
                  className={currentPeriod == StatisticPeriod.ALL ? "selectedPeriod" : "period"}
                  onClick={() => onChangePeriod(StatisticPeriod.ALL)}
                >
                  {t("stats.date.all")}
                </div>
              </div>
            </div>
          </div>
          <div style={{ verticalAlign: "center", alignSelf: "center" }}>
            {lastUpdate != null ? (
              <div className="updateDate">
                {t("table.lastupdated", {
                  day: moment(lastUpdate).format("DD/MM/YYYY"),
                  hour: moment(lastUpdate).format("HH"),
                  minute: moment(lastUpdate).format("mm"),
                })}
              </div>
            ) : null}
          </div>
        </div>

        {Roles.canSeeBmdStats() && Roles.canSeeBlStats() ? (
          <div className="row">
            <div
              className={currentTab == "delivery-notes" ? "tabButton selected" : "tabButton"}
              onClick={() => setCurrentTab("delivery-notes")}
            >
              {t("dn")}
            </div>

            <div
              className={currentTab != "delivery-notes" ? "tabButton selected" : "tabButton"}
              onClick={() => setCurrentTab("pumping-notes")}
            >
              {t("BMD")}
            </div>
          </div>
        ) : null}

        {currentTab == "delivery-notes" ? (
          <Stats
            currentPeriod={currentPeriod}
            onUpdateCurrentRegion={(region: any) => {
              setCurrentRegion(region);
            }}
            onUpdateLastUpdateDate={(date: any) => {
              setLastUpdate(date);
            }}
          />
        ) : (
          <StatsBMD
            currentPeriod={currentPeriod}
            onUpdateCurrentRegion={(region: any) => {
              setCurrentRegion(region);
            }}
            onUpdateLastUpdateDate={(date: any) => {
              setLastUpdate(date);
            }}
          />
        )}
      </div>
    </div>
  );
});

export default StatsContainer;
