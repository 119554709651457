import React, { useEffect, useState } from "react";
import "./UpdatePassword.scss";
import "../styles/table.scss";
import "../styles/popup.scss";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ToastComponent from "./ToastComponent";
import Toast from "../models/Toast";
import { ToastTypes } from "../models/ToastTypes";
import Config from "../Config";
import { useTranslation } from "react-i18next";

const UpdatePassword = React.memo(() => {
  const { t } = useTranslation();
  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToast] = useState<Toast[]>([]);

  const schema = yup.object().shape({
    password: yup.string().required("errors.password.required"),
    confirmPassword: yup
      .string()
      .required("errors.password.confirm.required")
      .oneOf([yup.ref("password"), null], "errors.password.confirmation"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  const updatePassword = (password: string) => {
    setPassword(password);
    setErrorMessage("");
  };

  const updateConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);
    setErrorMessage("");
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const onSubmitPassword = () => {
    setIsAddingLoading(true);

    let token = localStorage.getItem("token");
    let dataToSend = {
      password: window.btoa(password),
    };

    axios
      .post(Config.get_API_EXTRANET_URL() + "auth/changepassword", dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsAddingLoading(false);
        addToast("Mot de passe modifié", ToastTypes.success);
        setConfirmPassword("");
        setPassword("");
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            if (error.response.data.code == "ERR4010002") {
              setErrorMessage("common.error");
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  useEffect(() => {}, []);

  return (
    <div className="">
      <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

      <h3 className="title">{t("login.updatePassword")}</h3>

      <form onSubmit={handleSubmit(onSubmitPassword)}>
        <div className="label">{t("login.password")}</div>
        <input
          type="password"
          name="password"
          ref={register}
          value={password}
          placeholder={t("login.password")}
          onChange={(e) => updatePassword(e?.target.value.toString())}
        />
        <p>{t(errors.password?.message)}</p>

        <div className="label">{t("account.confirmPassword")}</div>
        <input
          type="password"
          name="confirmPassword"
          ref={register}
          value={confirmPassword}
          placeholder={t("account.confirmPassword")}
          onChange={(e) => updateConfirmPassword(e?.target.value.toString())}
        />
        <p>{t(errors.confirmPassword?.message)}</p>

        <div>{errorMessage}</div>
        <button type="submit" disabled={isAddingLoading}>
          <div>{isAddingLoading ? t("saving") : t("actions.edit")}</div>
        </button>
      </form>
    </div>
  );
});

export default UpdatePassword;
