import Roles from "./utils/Roles";

export default class Config {
  static get_API_EXTRANET_URL(path: string = "") {
    return process.env.REACT_APP_API_URL + "extranet/" + path;
  }

  static get_API_URL() {
    return process.env.REACT_APP_API_URL;
  }

  static isDev() {
    return location.hostname === "localhost" || location.hostname === "127.0.0.1";
  }

  static getUrl(path: string) {
    if (path.includes("?")) {
      return (
        this.get_API_EXTRANET_URL() + Roles.getCurrentWorkspaceUuid() + "/" + path + "&d=" + new Date().toISOString()
      );
    } else {
      return (
        this.get_API_EXTRANET_URL() + Roles.getCurrentWorkspaceUuid() + "/" + path + "?d=" + new Date().toISOString()
      );
    }
  }
}
