import React, { useEffect, useState } from "react";
import "./Customers.scss";
import "../styles/table.scss";
import "../styles/popup.scss";
import axios from "axios";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Config from "../Config";
import { useTranslation } from "react-i18next";

interface AddRegionProps {
  isUpdate?: boolean;
  onsuccess(): void;
}

const AddRegion = React.memo((props: AddRegionProps) => {
  const schema = yup.object().shape({
    name: yup.string().required("errors.name.required"),
    externalReference: yup.string().required("errors.denomination.required"),
  });

  const { t } = useTranslation();

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [externalReference, setExternalReference] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {}, [props.isUpdate]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),

    reValidateMode: "onBlur",
  });

  const updateName = (name: string) => {
    setName(name);
    setErrorMessage("");
  };

  const updateExternalReference = (externalReference: string) => {
    setExternalReference(externalReference);
    setErrorMessage("");
  };

  const onSubmit = () => {
    setIsAddingLoading(true);

    let token = localStorage.getItem("token");

    let dataToSend = {
      name: name,
      externalReference: externalReference,
    };

    axios
      .post(Config.getUrl("regions/add"), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsAddingLoading(false);
        setExternalReference("");
        setName("");
        props.onsuccess();
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 400) {
            if (error.response.data.code == "ERR4000401") {
              setErrorMessage(t("regions.add.error"));
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  return (
    <div className="popup">
      <div className="title">{t("regions.add.title")}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="label">{t("name")} *</div>
        <input
          type="text"
          name="name"
          ref={register}
          value={name}
          placeholder={t("name")}
          onChange={(e) => updateName(e?.target.value.toString())}
        />
        <p>{t(errors.name?.message)}</p>

        <div className="label">{t("denomination")} *</div>
        <input
          type="text"
          name="externalReference"
          ref={register}
          value={externalReference}
          placeholder={t("denomination")}
          onChange={(e) => updateExternalReference(e?.target.value.toString())}
        />
        <p>{t(errors.externalReference?.message)}</p>

        <div>{t(errorMessage)}</div>
        <button type="submit" disabled={isAddingLoading}>
          {props.isUpdate ? (
            <div>{isAddingLoading ? t("update.inprogress") : t("actions.edit")}</div>
          ) : (
            <div>{isAddingLoading ? t("save.inprogress") : t("actions.add")}</div>
          )}
        </button>
      </form>
    </div>
  );
});

export default AddRegion;
