import React, { useEffect, useState } from "react";
import "./Users.scss";
import "../styles/table.scss";
import axios, { AxiosError } from "axios";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Menu from "./Menu";
import TableStyles from "../styles/tableStyles";
import PopupStyle from "../styles/popupContentStyle";
import PopPop from "react-poppop";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ToastComponent from "./ToastComponent";
import Toast from "../models/Toast";
import { ToastTypes } from "../models/ToastTypes";
import Roles from "../utils/Roles";
import Config from "../Config";
import User from "../models/User";
import SearchBar from "./SearchBar";
import Site from "../models/Site";
import { Scopes } from "../models/Scopes";
import Select, { createFilter } from "react-select";
import { ReactComponent as Close } from "../assets/svg/close.svg";
import ConfirmPopup from "./ConfirmPopup";
import Organization from "../models/Organization";
import Permission from "../models/Permission";
import Region from "../models/Region";
import BillingCustomer from "../models/BillingCustomer";
import { useTranslation } from "react-i18next";

const Users = React.memo(() => {
  const { t } = useTranslation();
  // const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false);
  const [isOpenDetailPopup, setIsOpenDetailPopup] = useState(false);

  const [firstname, setFirstname] = useState("");

  const [lastname, setLastname] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [currentUser, setCurrentUser] = useState<User>();
  const [newUsersites, setnewUserSites] = useState<string[]>([]);
  const [optionsSite, setOptionsSite] = useState<any[]>([]);

  const [newUserDelivery, setNewUserDelivery] = useState<string[]>([]);
  const [optionsDelivery, setOptionsDelivery] = useState<any[]>([]);

  const [optionsRegion, setOptionsRegion] = useState<any[]>([]);
  const [newUserRegions, setnewUserRegions] = useState<string[]>([]);
  const [extraInfoMode, setExtraInfoMode] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToast] = useState<Toast[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResource, setTotalResource] = useState(0);

  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [currentWorkspaceToDelete, setCurrentWorkSpaceToDelete] = useState<Organization>();

  const [sortColumn, setSortColumn] = useState("lastname");
  const [sortDirection, setSortDirection] = useState("asc");

  let columns: any[] = [
    {
      name: t("name"),
      selector: (row: User) => row.lastname,
      id: "lastname",
      cell: (row: any) => <div className="firstColumn">{row.lastname}</div>,
      sortable: true,
      right: false,
      sortFunction: (a: User, b: User) => {
        if (a.lastname != undefined && b.lastname != undefined) {
          if (a.lastname < b.lastname) {
            return -1;
          }
          if (a.lastname > b.lastname) {
            return 1;
          }
        }

        return 0;
      },
    },

    {
      name: t("firstname"),
      id: "firstname",
      selector: (row: User) => row.firstname,
      sortable: true,
      right: false,
    },

    {
      id: "username",
      name: "Email",
      selector: (row: User) => row.username,
      sortable: true,
      right: false,
    },
  ];

  const schema = yup.object().shape({
    firstname: yup.string().required("errors.firstname.required"),
    lastname: yup.string().required("errors.firstname.required"),
    email: yup.string().email("errors.email.invalid").required("errors.email.required"),
    role: yup.string().required("errors.role.required"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  const updateFirstname = (firstname: string) => {
    setFirstname(firstname);
    setErrorMessage("");
  };

  const updateLastname = (lastname: string) => {
    setLastname(lastname);
    setErrorMessage("");
  };

  const updateEmail = (email: string) => {
    setEmail(email);
    setErrorMessage("");
  };

  const updateRole = (role: string) => {
    setRole(role);
    setErrorMessage("");
  };

  const updateSite = (newSites: any) => {
    let sitesUuid: any[] = [];
    newSites.forEach((s: any) => {
      let site = s.value;
      sitesUuid.push(site);
    });
    setnewUserSites(sitesUuid);
  };

  const updateDelivery = (newDeliveries: any) => {
    let deliveryUuids: any[] = [];
    newDeliveries.forEach((s: any) => {
      deliveryUuids.push(s.value);
    });
    setNewUserDelivery(deliveryUuids);
  };

  const setSiteOptions = (sites: Site[]) => {
    let options: any[] = [];

    if (sites != null) {
      sites.forEach((s) => {
        let option = {
          value: s.uuid,
          label: s.name,
        };
        options.push(option);
      });

      setOptionsSite(options);
    }
  };

  const setDeliveryOptions = (sites: BillingCustomer[]) => {
    let options: any[] = [];

    if (sites != null) {
      sites.forEach((s) => {
        let option = {
          value: s.uuid,
          label: s.name,
        };
        options.push(option);
      });

      setOptionsDelivery(options);
    }
  };

  const setRegionOptions = (sites: Region[]) => {
    let options: any[] = [];

    if (sites != null) {
      sites.forEach((s) => {
        let option = {
          value: s.uuid,
          label: s.name,
        };
        options.push(option);
      });

      setOptionsRegion(options);
    }
  };

  const updateRegion = (newRegions: any) => {
    let regionsUuid: any[] = [];
    newRegions.forEach((s: any) => {
      let region = s.value;
      regionsUuid.push(region);
    });
    setnewUserRegions(regionsUuid);
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const getSites = () => {
    let token = localStorage.getItem("token");
    axios
      .get(Config.getUrl("sites?limit=20000"), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSiteOptions(res.data.content.items);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status == 401) {
          history.push("/");
        }
      });
  };

  const getDelivery = () => {
    let token = localStorage.getItem("token");
    axios
      .get(Config.getUrl("delivery-customers?limit=20000"), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDeliveryOptions(res.data.content.items);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status == 401) {
          history.push("/");
        }
      });
  };

  const getRegions = () => {
    let token = localStorage.getItem("token");
    axios
      .get(Config.getUrl("regions?limit=20000"), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRegionOptions(res.data.content.items);
      });
  };

  const onSubmit = () => {
    setIsAddingLoading(true);

    let token = localStorage.getItem("token");
    let finalRole = "";
    let dataToSend = null;

    if (newUsersites.length > 0) {
      finalRole = "extranet.site." + role;
      dataToSend = {
        firstname: firstname,
        lastname: lastname,
        role: finalRole,
        email: email,
        site: newUsersites,
      };
    } else if (newUserRegions.length > 0) {
      finalRole = "extranet.region." + role;
      dataToSend = {
        firstname: firstname,
        lastname: lastname,
        role: finalRole,
        email: email,
        region: newUserRegions,
      };
    } else if (newUserDelivery.length > 0) {
      finalRole = "extranet.delivery." + role;
      dataToSend = {
        firstname: firstname,
        lastname: lastname,
        role: finalRole,
        email: email,
        deliveryCustomer: newUserDelivery,
      };
    } else {
      finalRole = "extranet." + Roles.getCurrentWorkspace()?.organization?.type + "." + role;
      dataToSend = {
        firstname: firstname,
        lastname: lastname,
        role: finalRole,
        email: email,
        site: null,
      };
    }

    axios
      .post(Config.getUrl("users"), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setFirstname("");
        setLastname("");
        setRole("");
        setEmail("");
        setExtraInfoMode("");
        setIsAddingLoading(false);
        addToast("users.added", ToastTypes.success);
        setIsOpenAddPopup(false);
        fetchUsers(1, perPage);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            if (error.response.data.code == "ERR4010003") {
              setErrorMessage("errors.user.alreadyExist");
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  if (!Roles.isSite()) {
    columns.push({
      name: "Organisation",
      cell: (row: User) => (
        <div className={`status ${row.organization}`}>
          {row.permissions?.map((permission) => {
            return (
              <div key={permission.organization?.name}>
                {permission?.organization?.name} <span className="role">{getRoleDisplayName(permission)}</span>
              </div>
            );
          })}
        </div>
      ),
      sortable: false,
      right: false,
    });
  }

  if (Roles.hasRight(Scopes.SCOPE_EXTRANET_USER_MANAGE)) {
    columns.unshift({
      name: "",
      sortable: true,
      cell: (row: any) => (
        <div className="table-link" onClick={() => seeUserDetail(row)}>
          {t("see")}
        </div>
      ),
      button: true,
    });
  }

  const [filteredItems, setFilterereditems] = useState<User[]>([]);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    fetchUsers(1, perPage);
  }, []);

  // useEffect(() => {
  //   setFilterereditems(users);
  // }, [isLoading]);

  const seeUserDetail = (user: User) => {
    setCurrentUser(user);
    setIsOpenDetailPopup(true);
  };

  const getRoleDisplayName = (permission: any) => {
    if (permission != null) {
      if (permission.roles.length > 0) {
        let roles = permission.roles.filter((r: string) => r != "extranet.notification");
        let role = roles[0];
        if (role != undefined) {
          if (role.includes("admin")) {
            return "- " + t("role.admin");
          } else {
            return "- " + t("role.user");
          }
        } else {
          return "- " + t("role.user");
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getRole = (permission: any): "admin" | "user" => {
    if (permission != null) {
      if (permission.roles.length > 0) {
        let roles = permission.roles.filter((r: string) => r != "extranet.notification");
        if (roles.length > 0) {
          let role = roles[0];
          if (role.includes("admin")) {
            return "admin";
          } else {
            return "user";
          }
        } else {
          return "user";
        }
      } else {
        return "user";
      }
    } else {
      return "user";
    }
  };

  const getNextRole = (role: "admin" | "user") => {
    return role == "admin" ? t("role.user") : t("role.admin");
  };

  const fetchUsers = (
    page: number,
    newPerPage: number,
    newFilterText: string = filterText,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection
  ) => {
    setIsLoading(true);
    setFilterereditems([]);
    setCurrentPage(page);
    const token = localStorage.getItem("token");
    let url =
      "users?page=" +
      page +
      "&limit=" +
      newPerPage +
      "&search=" +
      newFilterText +
      "&sort_by=" +
      newSortColumn +
      "&order_by=" +
      newSortDirection;

    if (newFilterText != filterText) {
      setFilterText(newFilterText);
    }

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    setCurrentPage(page);

    axios
      .get(Config.getUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTotalRows(res.data.meta.total);
        setIsLoading(false);
        setTotalResource(res.data.meta.totalResource);
        setFilterereditems(res.data.content.items);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });

    if (Roles.isRegion() || Roles.isAffiliate()) {
      getSites();
    }
    if (Roles.isAffiliate()) {
      getRegions();
    }

    if (Roles.isUserBilling()) {
      getDelivery();
    }
  };

  const handlePageChange = (pageNumber: number) => {
    fetchUsers(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchUsers(1, newPerPage);
  };

  const filterConfig = {
    stringify: (option: any) => `${option.label}`,
  };

  const onDeletePermission = (workspace: Organization | undefined) => {
    setCurrentWorkSpaceToDelete(workspace);
    setIsOpenConfirmDelete(true);
  };

  const onDeleteOk = () => {
    let url = "users/remove-permission";

    let dataToSend = {
      username: currentUser?.username,
      workspaceRemovedPermissionUuid: currentWorkspaceToDelete?.uuid,
    };
    let token = localStorage.getItem("token");

    axios
      .post(Config.getUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsOpenConfirmDelete(false);
        addToast(t("user.deleted"), ToastTypes.success);
        let user = JSON.parse(JSON.stringify(currentUser));
        if (user != null && currentUser != null && currentUser.permissions != null) {
          user.permissions = currentUser.permissions.filter(
            (p) => p.organization?.uuid != currentWorkspaceToDelete?.uuid
          );
          setCurrentUser(user);
          fetchUsers(currentPage, perPage);
        }
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage("common.error");
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const updatePermission = (permission: Permission) => {
    let role = getRole(permission);

    let nextRole = "user";
    if (role == "user") {
      nextRole = "admin";
    }

    let newRole = "extranet." + permission.organization?.type + "." + nextRole;

    let dataToSend = {
      username: currentUser?.username,
      workspaceUpdatedPermissionUuid: permission.organization?.uuid,
      role: newRole,
    };

    let token = localStorage.getItem("token");

    axios
      .post(Config.getUrl("users/update-permission"), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsOpenDetailPopup(false);
        addToast("le Rôle a bien été modifié", ToastTypes.success);
        fetchUsers(1, perPage);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            if (error.response.data.code == "ERR4010003") {
              setErrorMessage("user.alreadyExist");
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == "asc") {
        newSortOrder = "desc";
      } else {
        newSortOrder = "asc";
      }
    } else {
      newSortOrder = "asc";
    }
    fetchUsers(1, perPage, filterText, data.id, newSortOrder);
  };

  const openAddPopup = () => {
    setnewUserSites([]);
    setnewUserRegions([]);
    setNewUserDelivery([]);
    setIsOpenAddPopup(true);
  };

  return (
    <div className="users">
      <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

      <Menu selectedPage="users"></Menu>

      <div className={"right table-container"}>
        <div className="title">{t("menu.users")}</div>

        <div className="filterBar">
          <SearchBar placeholder={t("users.search")} onFilter={(value) => fetchUsers(1, perPage, value)} />
        </div>
        <div className="buttonContainer">
          <div className="nbElements">
            {totalRows}/{totalResource} {totalResource > 1 ? t("results") : t("result")}
          </div>

          {Roles.hasRight(Scopes.SCOPE_EXTRANET_USER_MANAGE) && !isLoading ? (
            <button className="validateBtn right-btn" onClick={() => openAddPopup()}>
              <h6>{t("users.add.title")}</h6>
            </button>
          ) : null}
        </div>

        {isLoading ? (
          <div>{t("loading")}</div>
        ) : (
          <DataTable
            className="table"
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            data={filteredItems}
            noDataComponent={<div className="noResults">{t("users.noOne")}</div>}
            defaultSortAsc={sortDirection == "asc"}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            paginationComponentOptions={{
              rowsPerPageText: t("table.perpage"),
              rangeSeparatorText: t("table.on"),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
          />
        )}
      </div>
      <PopPop
        open={isOpenAddPopup}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenAddPopup(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <div className="popup">
          <div className="title">{t("users.add.title")}</div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="label">{t("firstname")}</div>
            <input
              type="text"
              name="firstname"
              ref={register}
              value={firstname}
              placeholder={t("firstname")}
              onChange={(e) => updateFirstname(e?.target.value.toString())}
            />
            <p>{t(errors.firstname?.message)}</p>

            <div className="label">{t("name")}</div>
            <input
              type="text"
              name="lastname"
              ref={register}
              value={lastname}
              placeholder={t("name")}
              onChange={(e) => updateLastname(e?.target.value.toString())}
            />
            <p>{t(errors.lastname?.message)}</p>

            <div className="label">{t("login.email")}</div>
            <input
              type="email"
              name="email"
              ref={register}
              value={email}
              placeholder="exemple@exemple.com"
              onChange={(e) => updateEmail(e?.target.value.toString())}
            />
            <p>{t(errors.email?.message)}</p>

            <div className="label">{t("role.title")}</div>
            <select
              name="role"
              onChange={(value) => updateRole(value.target.value.toString())}
              value={role.toString()}
              ref={register}
            >
              <option value="">{t("role.choose")}</option>
              <option value="user">{t("role.user")}</option>
              <option value="admin">{t("role.admin")}</option>
            </select>

            <p>{t(errors.role?.message)}</p>

            {Roles.isAffiliate() || Roles.isRegion() ? (
              <div>
                {Roles.isAffiliate() ? (
                  <div className="userExtraInfoTab">
                    <div className={extraInfoMode == "site" ? "selected" : ""} onClick={() => setExtraInfoMode("site")}>
                      {t("users.addToSite")} ({t("optional")})
                    </div>
                    <div
                      className={extraInfoMode == "region" ? "selected" : ""}
                      onClick={() => setExtraInfoMode("region")}
                    >
                      {t("users.addToRegion")} ({t("optional")})
                    </div>
                  </div>
                ) : null}

                {extraInfoMode == "site" || Roles.isRegion() ? (
                  <div className="sitechoice">
                    <div className="label">
                      {t("user.sites")} ({t("optional")})
                    </div>
                    <Select
                      className="input"
                      isMulti={true}
                      options={optionsSite}
                      noOptionsMessage={() => t("noOptions")}
                      placeholder={t("users.selectSite")}
                      onChange={(value) => updateSite(value)}
                      filterOption={createFilter(filterConfig)}
                    />

                    <p>{t(errors.site?.message)}</p>
                  </div>
                ) : null}

                {extraInfoMode == "region" && !Roles.isRegion() ? (
                  <div className="sitechoice">
                    <div className="label">
                      {t("users.regions")} ({t("optional")})
                    </div>
                    <Select
                      className="input"
                      isMulti={true}
                      options={optionsRegion}
                      noOptionsMessage={() => t("noOptions")}
                      placeholder={t("users.selectRegion")}
                      onChange={(value) => updateRegion(value)}
                      filterOption={createFilter(filterConfig)}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            {Roles.isUserBilling() ? (
              <div>
                <div className="sitechoice">
                  <div className="label">
                    {t("users.deliveries")}({t("optional")})
                  </div>
                  <Select
                    className="input"
                    isMulti={true}
                    options={optionsDelivery}
                    noOptionsMessage={() => t("noOptions")}
                    placeholder={t("users.selectDeliveries")}
                    onChange={(value) => updateDelivery(value)}
                    filterOption={createFilter(filterConfig)}
                  />

                  <p>{t(errors.site?.message)}</p>
                </div>
                {/* ) : null} */}
              </div>
            ) : null}

            <div>{errorMessage}</div>
            <button type="submit" disabled={isAddingLoading}>
              <div>{isAddingLoading ? t("saving") : t("actions.add")}</div>
            </button>
          </form>
        </div>
      </PopPop>

      <PopPop
        open={isOpenDetailPopup}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenDetailPopup(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <div className="popup">
          <div className="title">{t("users.update")}</div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="label">{t("firstname")}</div>
            <input
              disabled
              type="text"
              name="firstname"
              ref={register}
              value={currentUser?.firstname}
              placeholder={t("firstname")}
              onChange={(e) => updateFirstname(e?.target.value.toString())}
            />
            <p>{t(errors.firstname?.message)}</p>

            <div className="label">{t("name")}</div>
            <input
              disabled
              type="text"
              name="lastname"
              ref={register}
              value={currentUser?.lastname}
              placeholder={t("name")}
              onChange={(e) => updateLastname(e?.target.value.toString())}
            />
            <p>{t(errors.lastname?.message)}</p>

            <div className="label">{t("login.email")}</div>
            <input disabled type="email" name="email" ref={register} value={currentUser?.username} />
            <p>{t(errors.email?.message)}</p>

            <div className="label">{t("users.organisations")}</div>

            <div>
              {currentUser?.permissions?.map((permission) => {
                return (
                  <div key={permission.organization?.name} className="row" style={{ marginTop: 10 }}>
                    <div>
                      <Close
                        className="icon"
                        width="20"
                        height="20"
                        fill={"#90a0b7"}
                        onClick={() => onDeletePermission(permission.organization)}
                      />
                    </div>
                    <div style={{ marginTop: 1 }}>
                      {permission?.organization?.name} <span className="role">{getRoleDisplayName(permission)}</span>
                    </div>
                    <div onClick={() => updatePermission(permission)} style={{ marginTop: 1 }}>
                      <span className="link" style={{ marginLeft: 10 }}>
                        {t("users.changeRole")} {getNextRole(getRole(permission))}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div>{errorMessage}</div>
          </form>
        </div>
      </PopPop>

      <PopPop
        open={isOpenConfirmDelete}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenConfirmDelete(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <ConfirmPopup
          message={t("users.deleteQuestion", {
            username: currentUser?.username,
            workspacename: currentWorkspaceToDelete?.name,
          })}
          confirm={onDeleteOk}
          cancel={() => setIsOpenConfirmDelete(false)}
          cancelMessage={t("no")}
          confirmMessage={t("yes")}
        />
      </PopPop>
    </div>
  );
});

export default Users;
