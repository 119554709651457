const popupContentStyle = {
  transition: "all 0.3s",
  backgroundColor: "white",
  borderRadius: "12px",
  zIndex: 2000,
  position: "relative",
  boxShadow: "0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28)",
  overflow: "hidden",
  maxWidth: "850px",
  padding: "50px 40px",
};

const overlaystyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 99,
  backgroundColor: "rgba(1, 15, 75, 0.9)",
};

export default { popupContentStyle, overlaystyle };
