import React, { useRef, useState } from "react";
import "./SearchBar.scss";
import "../styles/table.scss";
import moment from "moment";
import axios from "axios";
import Config from "../Config";
import Deliverynote from "../models/Deliverynote";
import { CSVLink } from "react-csv";
import Roles from "../utils/Roles";
import { ReactComponent as Download } from "../assets/svg/download.svg";
import { DeliveryNoteTypes } from "./DeliveryNotes";
import { Colors } from "../utils/Colors";

import { useTranslation } from "react-i18next";

interface ExportCSVProps {
  type: DeliveryNoteTypes;
  filterParams: any;
}

interface ExportDn {
  blNumber: string | undefined;
  demat: string | undefined;
  clientName: string | undefined;
  clientReference: string | undefined;
  carrierName: string | undefined;
  vehicleRegistration: string | undefined;
  vehicleName: string | undefined;
  date: string | undefined;
  hour: string | undefined;
  status: string | undefined;
  comments: string | undefined;
  deliveryCustomeName: string | undefined;
  siteName?: string | undefined;

  product?: string | undefined;
  productQuantity?: string | undefined;
  productUnit?: string | undefined;

  estimatedPumpedQuantity?: string | undefined;
  realPumpedQuantity?: string | undefined;

  transportZone?: string | undefined;
  purchaseZone?: string | undefined;

  orderReference?: string | undefined;
  customField1?: string | undefined;
}

const ExportCSV = React.memo((props: ExportCSVProps) => {
  const csvLinkEl = useRef<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStarted, setIsStarted] = useState<boolean>(false);

  const [filteredItems, setFilterereditems] = useState<ExportDn[]>([]);

  const perPage = 200;

  const { t } = useTranslation();

  const headers = [
    { label: t("deliverynote.number"), key: "blNumber" },
    { label: t("deliverynote.completedByApp"), key: "demat" },
    { label: t("deliverynote.date"), key: "date" },
    { label: t("deliverynote.hour"), key: "hour" },
    { label: t("deliverynote.status.title"), key: "status" },
    { label: t("site"), key: "siteName" },
  ];

  // affiliate / site / region
  if (Roles.isAffiliate() || Roles.isSite() || Roles.isRegion()) {
    headers.push(
      { label: t("customer"), key: "clientName" },
      { label: t("delivery"), key: "deliveryCustomeName" },
      { label: t("carrier"), key: "carrierName" },
      { label: t("vehicle"), key: "vehicleRegistration" },
      { label: t("vehicleLabel"), key: "vehicleName" }
    );
  }

  if (Roles.isUserClient()) {
    headers.push(
      // { label: "Client", key: "clientName" },
      { label: t("delivery"), key: "deliveryCustomeName" },
      { label: t("carrier"), key: "carrierName" },
      { label: t("vehicle"), key: "vehicleRegistration" }
    );
  }

  if (Roles.isTransporter()) {
    headers.push(
      { label: t("customer"), key: "clientName" },
      { label: t("delivery"), key: "deliveryCustomeName" },
      { label: t("vehicle"), key: "vehicleRegistration" }
    );
  }

  if (props.type != DeliveryNoteTypes.pumping) {
    headers.push({ label: t("deliverynote.product"), key: "product" });
    headers.push({ label: t("deliverynote.quantity"), key: "productQuantity" });
    headers.push({ label: t("deliverynote.unit"), key: "productUnit" });
  } else {
    headers.push({ label: t("deliverynote.estimatedQuantity"), key: "estimatedPumpedQuantity" });
    headers.push({ label: t("deliverynote.realPumpedQuantity"), key: "realPumpedQuantity" });
  }

  if (Roles.isTransporter()) {
    headers.push({ label: t("deliverynote.purchaseZone"), key: "purchaseZone" });
  } else {
    headers.push({ label: t("deliverynote.transportZone"), key: "transportZone" });
  }

  if (Roles.isAffiliate() || Roles.isSite() || Roles.isRegion()) {
    headers.push({ label: t("deliverynote.purchaseZone"), key: "purchaseZone" });
  }

  headers.push({ label: t("deliverynote.orderReference"), key: "orderReference" });
  headers.push({ label: t("deliverynote.customField1"), key: "customField1" });

  const getProduct = (row: Deliverynote) => {
    if (row?.products != undefined) {
      let hasReturnableProduct = row?.products?.filter((p) => p.returnable).length > 0;
      if (hasReturnableProduct) {
        return row?.products?.filter((p) => p.returnable)[0];
      }
      const products = row?.products
        ?.filter(
          (p) =>
            p.unit != undefined &&
            (p.unit.toLowerCase() === "M3".toLowerCase() ||
              p.unit.toLowerCase() === "TON".toLowerCase() ||
              p.unit.toLowerCase() === "T".toLowerCase())
        )
        .sort((a, b) => {
          return a.quantity != undefined && b.quantity != undefined ? a.quantity - b.quantity : 0;
        });

      if (products != undefined && products?.length > 0) {
        return products[0];
      } else {
        return null;
      }
    }
    return null;
  };

  const transformDntoExportDn = (dns: Deliverynote[]): ExportDn[] => {
    let exportDns: ExportDn[] = [];

    dns.forEach((dn: Deliverynote) => {
      let exportDn: ExportDn = {
        blNumber: dn.folioReference != undefined ? dn.folioReference : dn.externalReference,
        clientName: dn.billingCustomer.name,
        demat: dn.completedByApp ? t("yes") : "",
        clientReference: dn.billingCustomer.externalReference,
        carrierName: dn?.carrier?.name != undefined ? dn.carrier?.name : dn.pumper?.name,
        vehicleRegistration: dn.vehicle?.registrationPlate,
        vehicleName: dn.vehicle?.externalReference,
        date: moment(dn.loadedAt).format("DD/MM/YYYY"),
        hour: moment(dn.loadedAt).format("HH:mm"),
        status: t("deliverynote.status." + dn.status),
        comments: dn.deliveryCustomerComment,
        deliveryCustomeName: dn.deliveryCustomer.name,
      };

      if (props.type != DeliveryNoteTypes.pumping && getProduct(dn) != undefined) {
        exportDn.product = getProduct(dn)?.designation;
        exportDn.productQuantity = getProduct(dn)?.quantity?.toString()?.replace(".", ",");
        exportDn.productUnit = getProduct(dn)?.unit;
      }

      if (props.type == DeliveryNoteTypes.pumping) {
        exportDn.estimatedPumpedQuantity = dn.estimatedPumpedQuantity?.toString()?.replace(".", ",");
        exportDn.realPumpedQuantity = dn.realPumpedQuantity?.toString()?.replace(".", ",");
      }

      if (props.type == DeliveryNoteTypes.pumping) {
        exportDn.date = moment(dn.pumpingDate).format("DD/MM/YYYY");
        exportDn.hour = moment(dn.pumpingDate).format("HH:mm");
      }
      if (!Roles.isSite()) {
        exportDn.siteName = dn.site.name;
      }

      if (props.type == DeliveryNoteTypes.normal) {
        exportDn.purchaseZone = dn.purchaseZone;
        exportDn.transportZone = dn.transportZone;
      }

      exportDn.orderReference = dn.orderReference;
      exportDn.customField1 = dn.customField1;

      exportDns.push(exportDn);
    });

    return exportDns;
  };
  const fetchDns = async (withFilters = false) => {
    setIsStarted(true);
    const token = localStorage.getItem("token");

    setFilterereditems([]);
    setIsLoading(true);

    let baseUrl = props.type == DeliveryNoteTypes.pumping ? "pumping-notes" : "delivery-notes";
    let url = `${baseUrl}?page=${1}&limit=${perPage}`;

    let dataToSend: any = {};

    if (props.type == DeliveryNoteTypes.departure) {
      dataToSend.collectedByClient = true;
    }

    if (withFilters) {
      dataToSend = props.filterParams;
    }

    await axios
      .post(Config.getUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setFilterereditems(transformDntoExportDn(res.data.content.items));

        let nbPages = res.data.meta.lastPage;
        if (nbPages > 1) {
          getNewPage(2, nbPages);
        } else {
          setIsLoading(false);
          setIsStarted(false);
          setTimeout(() => {
            csvLinkEl?.current?.link.click();
          }, 200);
        }
      })
      .catch(() => {
        setIsStarted(false);
        setIsLoading(false);
      });
  };

  const getNewPage = async (page: number, finalPage: number) => {
    const token = localStorage.getItem("token");

    setIsLoading(true);

    let baseUrl = props.type == DeliveryNoteTypes.pumping ? "pumping-notes" : "delivery-notes";
    let url = `${baseUrl}?page=${page}&limit=${perPage}`;

    let dataToSend: any = {};

    dataToSend = props.filterParams;

    if (props.type == DeliveryNoteTypes.departure) {
      dataToSend.collectedByClient = true;
    }

    // if (props.startDate != null) {
    //   dataToSend.beginDate = moment(props.startDate).format("YYYYMMDD");
    // }

    // if (props.endDate != null) {
    //   dataToSend.endDate = moment(props.endDate).format("YYYYMMDD");
    // }

    await axios
      .post(Config.getUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setFilterereditems((items) => {
          return items.concat(transformDntoExportDn(res.data.content.items));
        });

        if (page < finalPage) {
          let nextPage = page + 1;
          getNewPage(nextPage, finalPage);
        } else {
          setIsLoading(false);
          setIsStarted(false);
          setTimeout(() => {
            csvLinkEl?.current?.link.click();
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsStarted(false);
      });
  };

  return (
    <>
      {!isStarted ? (
        <div className={"link"} onClick={() => fetchDns(true)}>
          <Download className="icon" width="25" height="25" fill={Colors.primary}></Download>
        </div>
      ) : null}
      {isLoading && isStarted ? (
        <div className="" style={{ fontSize: 15, fontWeight: "normal", position: "absolute", right: 10 }}>
          {t("loading")}
        </div>
      ) : (
        <CSVLink
          headers={headers}
          filename={`SxDelivery_export_BL${new Date().toISOString()}.csv`}
          data={filteredItems}
          separator={";"}
          ref={csvLinkEl}
        />
      )}
    </>
  );
});

export default ExportCSV;
