/*eslint-disable */
import React, { useEffect, useState } from "react";
import "./SearchBar.scss";
import "../styles/table.scss";
import { ReactComponent as Close } from "../assets/svg/close.svg";
import usePrevious from "./usePrevious";
import { useTranslation } from "react-i18next";

export interface SearchBarProps {
  onFilter: (a: string | undefined) => void;
  placeholder: string;
  filterText?: string;
  isLoading?: boolean;
  readonly?: boolean;
  minLength?: number;
}

let timer: any;

const SearchBar = React.memo((props: SearchBarProps) => {
  const [filterText, setFilterText] = useState<string>("");

  const prevFilterText = usePrevious(filterText ?? "");

  const onFilter = (value: string) => {
    if (!props.readonly) {
      setFilterText(value);
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    setFilterText(props.filterText ?? "");
    if (props.filterText == null || props.filterText == undefined) {
      setFilterText("");
    }
  }, [props.filterText]);

  useEffect(() => {
    if (prevFilterText !== undefined) {
      clearTimeout(timer);
      if (!canSearch(filterText)) {
        return;
      }

      timer = setTimeout(() => {
        props.onFilter(filterText);
      }, 500);
    }
  }, [filterText]);

  const onClear = () => {
    setFilterText("");
    props.onFilter("");
  };

  useEffect(() => {
    setFilterText("");
  }, [props.isLoading]);

  const canSearch = (value: string | undefined) => {
    if (props.minLength == null) {
      return true;
    } else {
      return value?.length == 0 || (value != undefined && value.length > 0 && value?.length >= props.minLength);
    }
  };

  return (
    <div>
      <div className="searchbar">
        <input
          disabled={props.readonly}
          id="search"
          className="search"
          type="text"
          placeholder={props.placeholder}
          value={filterText}
          onChange={(e) => onFilter(e?.target.value.toString())}
        />
        {filterText != "" ? (
          <div className="clearSearch" onClick={onClear}>
            <span>
              <Close className="icon" width="23" height="23" fill={"#90A0B7"}></Close>
            </span>
          </div>
        ) : null}
      </div>
      {props.minLength != null &&
      filterText != undefined &&
      filterText.length > 0 &&
      filterText.length < props.minLength ? (
        <div className="errorMessage">{t("searchLength.error", { length: props.minLength })}</div>
      ) : null}
    </div>
  );
});

export default SearchBar;
