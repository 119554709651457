import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Menu.scss";
import { ReactComponent as Truck } from "../assets/svg/truck.svg";
import { ReactComponent as Home } from "../assets/svg/home.svg";
import { ReactComponent as File } from "../assets/svg/file.svg";
import { ReactComponent as Flash } from "../assets/svg/transporteur.svg";
import { ReactComponent as Clients } from "../assets/svg/client.svg";
import { ReactComponent as Delivery } from "../assets/svg/delivery.svg";

import { ReactComponent as Logout } from "../assets/svg/logout.svg";
import { ReactComponent as Navigation } from "../assets/svg/navigation-2.svg";
import { ReactComponent as Site } from "../assets/svg/site.svg";
import { ReactComponent as Logo } from "../logo.svg";
import { ReactComponent as StatIcon } from "../assets/svg/stat.svg";
import { ReactComponent as Region } from "../assets/svg/regions.svg";
import { ReactComponent as Users } from "../assets/svg/users.svg";

import PopPop from "react-poppop";
import PopupStyle from "../styles/popupContentStyle";

import Roles from "../utils/Roles";
import { Colors } from "../utils/Colors";
import SelectWorkspace from "./SelectWorkspace";
import { Scopes } from "../models/Scopes";
import Toast from "../models/Toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Config from "../Config";
import { ToastTypes } from "../models/ToastTypes";
import Filters from "../utils/Filters";
import { FilterType } from "../models/FilterType";
import pusherJs, { Channel } from "pusher-js";
import { useTranslation } from "react-i18next";
import { MadeBySynaxe } from "./Login";

export interface MenuProps {
  selectedPage: string;
}

const schema = yup.object().shape({
  lastname: yup.string().required("errors.lastname.required"),
  firstname: yup.string().required("errors.firstname.required"),
  password: yup
    .string()
    .required("errors.password.required")
    .test("len", "errors.password.minLength", (val) => val != null && val?.length >= 8),
  confirmPassword: yup
    .string()
    .required("errors.password.confirm.required")
    .oneOf([yup.ref("password"), null], "errors.password.confirmation"),
});

const Menu = React.memo((props: MenuProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  //create account
  const [IsOpenCreateAccountPopup, setIsOpenCreateAccountPopup] = useState(false);

  const [toasts, setToast] = useState<Toast[]>([]);
  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [password, setPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [totalBlToSign, setTotalBlToSign] = useState(0);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  //end create account

  // const [organization, setOrganization] = useState<Organization>();

  const goHome = () => {
    history.push("/deliverynotes");
  };

  const canSeeSiteBlock = () => {
    return Roles.hasRight(Scopes.SCOPE_EXTRANET_SITE_VIEW) || Roles.hasRight(Scopes.SCOPE_EXTRANET_REGION_VIEW);
  };

  const canSeeCarrierBlock = () => {
    return (
      Roles.hasRight(Scopes.SCOPE_EXTRANET_CARRIER_VIEW) ||
      Roles.isUserSite() ||
      Roles.hasRight(Scopes.SCOPE_EXTRANET_DRIVER_VIEW) ||
      Roles.hasRight(Scopes.SCOPE_EXTRANET_VEHICLE_VIEW)
    );
  };

  const canSeeClientBlock = () => {
    return (
      Roles.hasRight(Scopes.SCOPE_EXTRANET_BILLING_CUSTOMER_VIEW) ||
      Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_CUSTOMER_VIEW)
    );
  };
  const getTotalBlToSign = () => {
    let total = localStorage.getItem("totalCollectedBlToSign");
    if (total != null) {
      let totalInt = Number.parseInt(total);
      setTotalBlToSign(totalInt);
    } else {
      setTotalBlToSign(0);
    }
  };

  const to = (route: string) => {
    return "/" + route;
  };

  const goToBLList = () => {
    Filters.clear(FilterType.DeliveryNote);
  };

  const logout = () => {
    if (Roles.isSite()) {
      let currentWindow: any = window;

      let pusher: any = null;
      if (currentWindow.Pusher.instances.length > 0) {
        pusher = currentWindow.Pusher.instances[0];
      } else {
        let pusherKey = process.env.REACT_APP_PUSHER_KEY ?? "";
        pusher = new pusherJs(pusherKey, {
          cluster: "eu",
        });
      }
      pusher.allChannels().forEach((channel: Channel) => {
        pusher.unsubscribe(channel.name);
      });

      pusher.disconnect();
    }

    localStorage.clear();
    Roles.clearHotjarUser();
    history.push("/");
  };

  const startcreateAccount = () => {
    setIsOpenCreateAccountPopup(true);
  };

  const updateFirstName = (firstname: string) => {
    setFirstname(firstname);
    setErrorMessage("");
  };

  const updateLastName = (lastname: string) => {
    setLastname(lastname);
    setErrorMessage("");
  };

  const updatePassword = (password: string) => {
    setPassword(password);
    setErrorMessage("");
  };

  const updateConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);
    setErrorMessage("");
  };
  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const onSubmit = () => {
    setIsAddingLoading(true);

    let token = localStorage.getItem("token");

    let dataToSend: any = {
      password: password,
      firstname: firstname,
      lastname: lastname,
    };

    axios
      .post(Config.get_API_EXTRANET_URL() + "auth/register", dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        addToast(t("login.accountcreation.success"), ToastTypes.success);
        setIsOpenCreateAccountPopup(false);
        history.push("/");
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            if (error.response.data.code == "ERR4010003") {
              setErrorMessage(t("errors.user.alreadyExist"));
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token != null) {
      // let decoded = jwt_decode<any>(token?.toString());
      // setOrganization(decoded.organization);
    }
    getTotalBlToSign();
    setInterval(() => getTotalBlToSign(), 500);
  }, []);

  return (
    <div className={"left menu"}>
      <div className="logoName" onClick={() => goHome()}>
        <Logo fill="white" />
      </div>

      {!Roles.isReadOnly() ? (
        <div className="selectWorkspace noShadow">
          <SelectWorkspace parent={"menu"} />
        </div>
      ) : null}

      {/* <div className="organizationName">{organization?.name}</div> */}

      {Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_NOTE_VIEW) ? (
        <div className="block">
          <div className="links" onClick={() => goToBLList()}>
            <Link to={to("deliveryNotes")}>
              <div className={props.selectedPage == "deliveryNotes" ? "linkSelected" : "link"}>
                <span className="icon">
                  <File
                    className="icon"
                    width="25"
                    height="20"
                    fill={props.selectedPage == "deliveryNotes" ? Colors.primary : "#90A0B7"}
                  ></File>
                </span>
                <span style={{ marginLeft: 10 }}>{t("menu.deliverynotes")}</span>
              </div>
            </Link>
          </div>

          {Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_VIEW) && Roles.hasBMD() ? (
            <div className="links" onClick={() => goToBLList()}>
              <Link to={to("pumpingNotes")}>
                <div className={props.selectedPage == "pumpingNotes" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <File
                      className="icon"
                      width="25"
                      height="20"
                      fill={props.selectedPage == "pumpingNotes" ? Colors.primary : "#90A0B7"}
                    ></File>
                  </span>
                  <span style={{ marginLeft: 10 }}>{t("menu.pumpingnotes")}</span>
                </div>
              </Link>
            </div>
          ) : null}

          {Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_NOTE_VIEW) && Roles.hasWithdrawalPoint() ? (
            <div className="links" onClick={() => goToBLList()}>
              <Link to={to("deliverynotes-departure")}>
                <div className={props.selectedPage == "deliveryNotes-departure" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <File
                      className="icon"
                      width="25"
                      height="20"
                      fill={props.selectedPage == "deliveryNotes-departure" ? Colors.primary : "#90A0B7"}
                    ></File>
                  </span>
                  <div style={{ marginLeft: 10, display: "flex", flexDirection: "row" }}>
                    <div>{t("menu.depaturenotes")} </div>
                    {totalBlToSign > 0 ? <div className="red-badge">&nbsp;</div> : null}
                  </div>
                </div>
              </Link>
            </div>
          ) : null}

          {Roles.canSeeBlStats() || Roles.canSeeBmdStats() ? (
            <div className="links">
              <Link to={to("stats")}>
                <div className={props.selectedPage == "stats" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <StatIcon
                      className="icon"
                      width="25"
                      height="20"
                      fill={props.selectedPage == "stats" ? Colors.primary : "#90A0B7"}
                    ></StatIcon>
                  </span>
                  <span style={{ marginLeft: 10 }}>{t("menu.stats")}</span>
                </div>
              </Link>
            </div>
          ) : null}
        </div>
      ) : null}

      {canSeeSiteBlock() && (
        <div className="block">
          {Roles.hasRight(Scopes.SCOPE_EXTRANET_REGION_VIEW) ? (
            <div className="links">
              <Link to={to("regions")}>
                <div className={props.selectedPage == "regions" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <Region
                      className="icon"
                      width="25"
                      height="20"
                      fill={props.selectedPage == "regions" ? Colors.primary : "#90A0B7"}
                    ></Region>
                  </span>
                  <span style={{ marginLeft: 10 }}>{t("menu.regions")}</span>
                </div>
              </Link>
            </div>
          ) : null}

          {Roles.hasRight(Scopes.SCOPE_EXTRANET_SITE_VIEW) ? (
            <div className="links">
              <Link to={to("sites")}>
                <div className={props.selectedPage == "sites" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <Site
                      className="icon"
                      width="25"
                      height="20"
                      fill={props.selectedPage == "sites" ? Colors.primary : "#90A0B7"}
                    ></Site>
                  </span>
                  <span style={{ marginLeft: 10 }}>{t("menu.sites")}</span>
                </div>
              </Link>
            </div>
          ) : null}
        </div>
      )}

      {canSeeCarrierBlock() && (
        <div className="block">
          {Roles.hasRight(Scopes.SCOPE_EXTRANET_CARRIER_VIEW) || Roles.isUserSite() ? (
            <div className="links">
              <Link to={to("carriers")}>
                <div className={props.selectedPage == "carriers" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <Flash
                      className="icon"
                      width="25"
                      height="20"
                      fill={props.selectedPage == "carriers" ? Colors.primary : "#90A0B7"}
                    ></Flash>
                  </span>{" "}
                  <span style={{ marginLeft: 10 }}>{t("menu.carriers")}</span>
                </div>
              </Link>
            </div>
          ) : null}

          {Roles.hasRight(Scopes.SCOPE_EXTRANET_VEHICLE_VIEW) ? (
            <div className="links">
              <Link to={to("vehicles")}>
                <div className={props.selectedPage == "vehicles" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <Truck
                      className="icon"
                      width="25"
                      height="20"
                      fill={props.selectedPage == "vehicles" ? Colors.primary : "#90A0B7"}
                    ></Truck>
                  </span>
                  <span style={{ marginLeft: 10 }}>{t("menu.vehicles")}</span>
                </div>
              </Link>
            </div>
          ) : null}

          {Roles.hasRight(Scopes.SCOPE_EXTRANET_DRIVER_VIEW) ? (
            <div className="links">
              <Link to={to("drivers")}>
                <div className={props.selectedPage == "drivers" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <Navigation
                      className="icon"
                      width="25"
                      height="20"
                      fill={props.selectedPage == "drivers" ? Colors.primary : "#90A0B7"}
                    />
                  </span>
                  <span style={{ marginLeft: 10 }}>{t("menu.drivers")}</span>
                </div>
              </Link>
            </div>
          ) : null}
        </div>
      )}

      {canSeeClientBlock() && (
        <div className="block">
          {Roles.hasRight(Scopes.SCOPE_EXTRANET_BILLING_CUSTOMER_VIEW) ? (
            <div className="links">
              <Link to={to("billingCustomers")}>
                <div className={props.selectedPage == "billingCustomers" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <Clients
                      className="icon"
                      width="25"
                      height="20"
                      fill={props.selectedPage == "billingCustomers" ? Colors.primary : "#90A0B7"}
                    ></Clients>
                  </span>
                  <span style={{ marginLeft: 10 }}>{t("menu.customers")}</span>
                </div>
              </Link>
            </div>
          ) : null}

          {Roles.hasRight(Scopes.SCOPE_EXTRANET_DELIVERY_CUSTOMER_VIEW) ? (
            <div className="links">
              <Link to={to("deliveryCustomers")}>
                <div className={props.selectedPage == "deliveryCustomers" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <Delivery
                      className="icon"
                      width="25"
                      height="20"
                      stroke={props.selectedPage == "deliveryCustomers" ? Colors.primary : "#90A0B7"}
                      fill={props.selectedPage == "deliveryCustomers" ? Colors.primary : "#90A0B7"}
                    ></Delivery>
                  </span>
                  <span style={{ marginLeft: 10 }}>{t("menu.deliveries")}</span>
                </div>
              </Link>
            </div>
          ) : null}
        </div>
      )}

      <div className="bottom">
        {Roles.hasRight(Scopes.SCOPE_EXTRANET_USER_VIEW) ? (
          <div className="block">
            <div className="links">
              <Link to={to("users")}>
                <div className={props.selectedPage == "users" ? "linkSelected" : "link"}>
                  <span className="icon">
                    <Users
                      className="icon"
                      width="25"
                      height="20"
                      fill={props.selectedPage == "users" ? Colors.primary : "#90A0B7"}
                    ></Users>
                  </span>{" "}
                  <span style={{ marginLeft: 10 }}>{t("menu.users")}</span>
                </div>
              </Link>
            </div>
          </div>
        ) : null}

        <div className="userEmail">{Roles.getEmail()}</div>

        {Roles.hasRight(Scopes.SCOPE_CORE_USER_CHANGE_PASSWORD) ? (
          <div>
            <Link
              className={props.selectedPage == "account" ? "linkSelected account" : "link account"}
              to={to("account")}
            >
              {t("menu.account")}
            </Link>
          </div>
        ) : null}

        {Roles.isReadOnly() ? (
          <div className="links" onClick={() => startcreateAccount()}>
            <div className={"link"}>
              <span className="icon">
                <Home className="icon" width="25" height="20" fill={"#90A0B7"}></Home>
              </span>
              <span style={{ marginLeft: 10 }}>{t("menu.account.create")}</span>
            </div>
          </div>
        ) : null}

        <div className="links" onClick={() => logout()}>
          <div className={"link"}>
            <span className="icon">
              <Logout className="icon" width="25" height="20" fill={"#90A0B7"}></Logout>
            </span>
            <span style={{ marginLeft: 10 }}>{t("menu.logout")}</span>
          </div>
        </div>

        <div className="versionNumber">V {process.env.REACT_APP_VERSION}</div>
        <MadeBySynaxe />
      </div>

      <PopPop
        open={IsOpenCreateAccountPopup}
        closeOnOverlay={true}
        closeOnEsc={true}
        onClose={() => setIsOpenCreateAccountPopup(false)}
        className="popup"
        position="topCenter"
        contentStyle={PopupStyle.popupContentStyle}
        overlayStyle={PopupStyle.overlaystyle}
      >
        <div className="popup">
          <div className="title">{t("account.create")}</div>

          <div className="label">{t("login.email")}</div>
          <input type="text" name="email" value={Roles.getEmail()} disabled={true} />

          <div className="label">{t("name")}</div>
          <input
            type="text"
            name="lastname"
            autoComplete="family-name"
            ref={register}
            value={lastname}
            placeholder={t("name")}
            onChange={(e) => updateLastName(e?.target.value.toString())}
          />
          <p>{t(errors.lastname?.message)}</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="label">Prénom </div>
            <input
              type="text"
              name="firstname"
              autoComplete="given-name"
              ref={register}
              value={firstname}
              placeholder={t("firstname")}
              onChange={(e) => updateFirstName(e?.target.value.toString())}
            />
            <p>{t(errors.firstname?.message)}</p>

            <div className="label">{t("login.password")}</div>
            <input
              type="password"
              name="password"
              ref={register}
              value={password}
              placeholder={t("login.password")}
              onChange={(e) => updatePassword(e?.target.value.toString())}
            />
            <p>{t(errors.password?.message)}</p>

            <div className="label">{t("account.confirmPassword")}</div>
            <input
              type="password"
              name="confirmPassword"
              ref={register}
              value={confirmPassword}
              placeholder={t("account.confirmPassword")}
              onChange={(e) => updateConfirmPassword(e?.target.value.toString())}
            />
            <p>{t(errors.confirmPassword?.message)}</p>

            <div>{errorMessage}</div>
            <button type="submit" disabled={isAddingLoading}>
              <div>{isAddingLoading ? t("saving") : t("account.create")}</div>
            </button>
          </form>
        </div>
      </PopPop>
    </div>
  );
});

export default Menu;
