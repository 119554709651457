import React, { useEffect, useState } from "react";
import "./AssignPumperTruck.scss";
import "../styles/table.scss";
import "../styles/popup.scss";
import axios from "axios";
import Config from "../Config";
import Select, { createFilter } from "react-select";
import Deliverynote from "../models/Deliverynote";
import Vehicle from "../models/Vehicle";
import { useTranslation } from "react-i18next";

interface AssignPumperTruckProps {
  pumpingNote: Deliverynote | undefined;
  confirm(registrationPlate?: string): void;
  cancel(): void;
  onError(): void;
}

const AssignPumperTruck = React.memo((props: AssignPumperTruckProps) => {
  const { t } = useTranslation();
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [isLoadingVehicles, setIsLoadingVehicles] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterText, setFilterText] = useState("");
  const [vehicle, setVehicle] = useState("");

  useEffect(() => {}, [props.pumpingNote]);

  const updateVehicle = (newVehicle: string) => {
    setVehicle(newVehicle);
    setErrorMessage("");
  };

  const onSubmit = () => {
    if (vehicle == "") {
      setErrorMessage("errors.immat.required");
      return;
    } else {
      setIsAddingLoading(true);

      let token = localStorage.getItem("token");

      let dataToSend: any = {
        registrationPlate: vehicle,
      };
      axios
        .post(Config.getUrl(`pumping-notes/${props.pumpingNote?.uuid}/associateVehicle`), dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setIsAddingLoading(false);
          props.confirm(vehicle);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (error.response.status == 403) {
              if (error.response.data.code == "ERR4030502") {
                setErrorMessage("errors.updatePumper.cantUpdate");
                props.onError();
              }
            }
          } else if (error.request) {
            setErrorMessage("common.error");
          } else {
            setErrorMessage("common.error");
          }
        });
    }
  };

  const filterConfig = {
    stringify: (option: any) => `${option.label}`,
  };

  const fetchVehicles = (newfitlerText: string) => {
    setFilterText(newfitlerText);
    if (newfitlerText.length > 1) {
      setIsLoadingVehicles(true);
      const token = localStorage.getItem("token");
      let url = "vehicles?page=" + 1 + "&limit=" + 200 + "&search=" + newfitlerText;

      axios
        .get(Config.getUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoadingVehicles(false);
          setVehiclesOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoadingVehicles(false);
          setVehiclesOptions([]);
        });
    } else {
      setVehiclesOptions([]);
    }
  };

  const setVehiclesOptions = (carriers: Vehicle[]) => {
    let options: any[] = [];

    if (carriers != null) {
      carriers.forEach((s) => {
        let option = {
          value: s.registrationPlate,
          label: s.registrationPlate,
        };
        options.push(option);
      });

      setOptions(options);
    }
  };

  return (
    <div className="popup">
      <div className="title">
        {t("carriers.assignBMPPump")} {props.pumpingNote?.externalReference}
      </div>

      <div>
        <div className="label">{t("vehicles.immat")}</div>

        <Select
          className="input"
          isMulti={false}
          isLoading={isLoadingVehicles}
          options={options}
          loadingMessage={() => t("loading")}
          noOptionsMessage={() =>
            filterText.length < 2 ? t("deliverynote.filter.placeholderLength", { length: 2 }) : t("noOptions")
          }
          placeholder={t("carriers.choosePumper")}
          onInputChange={(e) => fetchVehicles(e)}
          name="vehicle"
          isSearchable={true}
          onChange={(input) => {
            input != null && input.value != null ? updateVehicle(input.value?.toString()) : null;
          }}
          filterOption={createFilter(filterConfig)}
        />
      </div>

      <div>{t(errorMessage)}</div>
      <button type="button" disabled={isAddingLoading} onClick={() => onSubmit()}>
        <div>{isAddingLoading ? t("saving") : t("actions.add")}</div>
      </button>
    </div>
  );
});

export default AssignPumperTruck;
