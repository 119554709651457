import React, { useEffect, useState } from "react";
import "./Customers.scss";
import "../styles/table.scss";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import TableStyles from "../styles/tableStyles";
import Config from "../Config";
import SearchBar from "./SearchBar";
import BillingCustomer from "../models/BillingCustomer";
import PopPop from "react-poppop";
import PopupStyle from "../styles/popupContentStyle";
import UpdateCustomer from "./UpdateCustomer";
import { ToastTypes } from "../models/ToastTypes";
import Toast from "../models/Toast";
import ToastComponent from "./ToastComponent";
import Roles from "../utils/Roles";
import { Scopes } from "../models/Scopes";
import Menu from "./Menu";
import { useTranslation } from "react-i18next";

export interface CustomersProps {
  isDelivery: boolean;
}

const Customers = React.memo((props: CustomersProps) => {
  const { t } = useTranslation();
  const [currentCustomer, setCurrentCustomer] = useState<BillingCustomer>();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false);
  const [toasts, setToast] = useState<Toast[]>([]);
  const [totalResource, setTotalResource] = useState(0);

  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");

  const [totalRows, settotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredItems, setFilterereditems] = useState<BillingCustomer[]>([]);
  const [filterText, setFilterText] = useState("");

  const columns: any[] = [
    {
      name: "Client",
      cell: (row: any) => <div className="firstColumn">{row.name}</div>,
      sortable: true,
      right: false,
      id: "name",
    },
    {
      name: t("reference"),
      selector: (row: any) => row.externalReference,
      sortable: true,
      right: false,
      id: "externalReference",
    },
  ];

  if (Roles.hasRight(Scopes.SCOPE_EXTRANET_BILLING_CUSTOMER_EMAILS)) {
    columns.push({
      name: "Emails",
      cell: (row: BillingCustomer) => {
        return row?.users != null && row.users.length > 1 ? (
          <div>
            <span className="bold">{row.users[0]}</span>{" "}
            <span>
              {" "}
              {t("and")} {row.users.length - 1}
              {t("others")}
            </span>
          </div>
        ) : (
          <div className="bold">{row?.users != null && row?.users.length == 1 ? row.users[0] : ""}</div>
        );
      },
      sortable: false,
      right: false,
    });
    columns.unshift({
      name: "",
      sortable: false,
      cell: (row: any) => (
        <div className="table-link" onClick={() => seeClientDetails(row)}>
          {t("seeDetails")}
        </div>
      ),
      button: true,
    });
  }

  if (Roles.hasRight(Scopes.SCOPE_EXTRANET_PUMPING_NOTE_VIEW) && Roles.hasBMD()) {
    columns.unshift({
      name: "",
      sortable: true,
      cell: (row: any) => (
        <Link className="table-link" to={seeClientPumpingNotes(row)}>
          {t("seeBMD")}
        </Link>
      ),
      button: true,
    });
  }

  columns.unshift({
    name: "",
    sortable: false,
    cell: (row: any) => (
      <Link className="table-link" to={seeClientDns(row)}>
        {t("seeDns")}
      </Link>
    ),
    button: true,
  });

  useEffect(() => {
    setIsLoading(true);
    settotalRows(0);
    setFilterText("");
    fetchCustomers(1, perPage, "");
  }, [props.isDelivery]);

  const fetchCustomers = (
    page: number,
    newPerPage: number,
    newFilterText: string = filterText,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection
  ) => {
    setIsLoading(true);
    setCurrentPage(page);
    if (newFilterText != filterText) {
      setFilterText(newFilterText);
    }

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    const token = localStorage.getItem("token");

    let url = "billing-customers";
    if (props.isDelivery) {
      url = "delivery-customers";
    }

    url +=
      "?page=" +
      page +
      "&limit=" +
      newPerPage +
      "&search=" +
      newFilterText +
      "&sort_by=" +
      newSortColumn +
      "&order_by=" +
      newSortDirection;
    axios
      .get(Config.getUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        settotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.totalResource);
        setFilterereditems(res.data.content.items);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/");
          }
        }
      });
  };
  const onSuccessUpdateCustomer = () => {
    setIsOpenAddPopup(false);
    addToast(t("email.added"), ToastTypes.success);
    setIsLoading(true);
    setFilterText("");
    fetchCustomers(currentPage, perPage);
  };

  const onDeleteEmail = () => {
    setTimeout(() => fetchCustomers(currentPage, perPage), 100);
    addToast(t("user.deleted"), ToastTypes.success);
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const seeClientDetails = (customer: BillingCustomer) => {
    setCurrentCustomer(customer);
    setIsOpenAddPopup(true);
  };

  const seeClientDns = (customer: BillingCustomer) => {
    let url = "deliverynotes?bcustomer=" + customer.name;
    if (props.isDelivery) {
      url = "deliverynotes?dcustomer=" + customer.name;
    }
    return url;
  };

  const seeClientPumpingNotes = (customer: BillingCustomer) => {
    let url = "pumpingNotes?bcustomer=" + customer.name;
    if (props.isDelivery) {
      url = "pumpingNotes?dcustomer=" + customer.name;
    }
    return url;
  };

  const handlePageChange = (pageNumber: number) => {
    fetchCustomers(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchCustomers(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == "asc") {
        newSortOrder = "desc";
      } else {
        newSortOrder = "asc";
      }
    } else {
      newSortOrder = "asc";
    }
    fetchCustomers(1, perPage, filterText, data.id, newSortOrder);
  };

  return (
    <div className="carriers">
      <Menu selectedPage={props.isDelivery ? "deliveryCustomers" : "billingCustomers"}></Menu>

      <div className={"right table-container"}>
        <div className="title">{props.isDelivery ? t("menu.deliveries") : t("menu.customers")}</div>
        <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

        <div className="filterBar">
          <SearchBar
            minLength={3}
            placeholder={props.isDelivery ? t("deliveries.search") : t("customers.search")}
            onFilter={(value) => fetchCustomers(1, perPage, value)}
            filterText={filterText}
          ></SearchBar>
        </div>
        <div className="nbElements">
          {totalRows}/{totalResource} {totalResource > 1 ? t("results") : t("result")}
        </div>

        {isLoading ? (
          <div>{t("loading")}</div>
        ) : (
          <DataTable
            className="table"
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            data={filteredItems}
            noDataComponent={
              <div className="noResults">{props.isDelivery ? t("deliveries.noOne") : t("customers.noOne")}</div>
            }
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            defaultSortAsc={sortDirection == "asc"}
            defaultSortFieldId={sortColumn}
            sortServer
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            paginationComponentOptions={{
              rowsPerPageText: t("table.perpage"),
              rangeSeparatorText: t("table.on"),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
          />
        )}

        <PopPop
          open={isOpenAddPopup}
          closeOnOverlay={true}
          closeOnEsc={true}
          onClose={() => setIsOpenAddPopup(false)}
          className="popup"
          position="topCenter"
          contentStyle={PopupStyle.popupContentStyle}
          overlayStyle={PopupStyle.overlaystyle}
        >
          <UpdateCustomer
            userType={props.isDelivery ? "delivery-customers" : "billing-customers"}
            customer={currentCustomer}
            onsuccess={onSuccessUpdateCustomer}
            oncancel={() => setIsOpenAddPopup(false)}
            onDeleteEmail={onDeleteEmail}
          />
        </PopPop>
      </div>
    </div>
  );
});

export default Customers;
