export default {
  deliverynote: {
    title: "Delivery note",
    noOne: "No delivery note",
    emailssend: "Emails sent",
    emailsend: "Email sent",
    validateSuccess: "The delivery note has been validated",
    signError: "An error occurred while signing the BL. Please try again",
    manualValidateSuccess: "Delivery note has been validated manually",

    mailNotification: "Mail notification",
    selectAll: "Select all",
    emailOpennedAt: "Email opened on {{date}} at {{hour}}",
    emailNotopen: "Email not opened",
    sending: "Sending...",
    resend: "Resend Email",
    sentAt: "Sent on {{date}}",
    cancelledDn: {
      title: "Cancelled delivery note",
      reason: "Reason",
      success: "Delivery note cancelled",
      date: "Cancellation date",
    },
    refusedDn: {
      title: "Refused delivery note",
      incident: "Delivery incident",
    },
    transport: "Transport",
    deliveryDate: "Delivery date",
    dlPdf: "Download PDF",
    number: "BL number",
    additionalService: "Additional service",
    clientComments: "Client comments",
    driverComment: "Driver comment",
    returnedProduct: "Returned product",
    completedByApp: "Digital",
    vehicleName: "Truck",
    registrationPlate: "License plate",
    product: "Product",
    productQuantity: "Quantity",
    pumpType: "Pump type",
    date: "Date",
    estimatedPumpedQuantity: "Estimated pumped quantity",
    realPumpedQuantity: "Real pumped quantity",
    productUnit: "Unit",
    comments: "Comments",
    purchaseZone: "Purchase zone",
    transportZone: "Transport zone",
    orderReference: "Order reference",
    customField1: "Site reference",
    vehicle: "Truck",
    productList: "Product list",
    quantity: "Quantity",
    unitev: "Unit",
    newDn: "A new delivery note is availables",
    securityMeasures: {
      title: "Security measures",
      worksiteAccess: "The site access is correct",
      presenceOfElectricalLines: "Electrical lines on the work area",
      worksiteWithGutter: "The site is realizable to the gutter",
      parkingVehicle: "Secure parking",
      machineStabilization: "Secure stabilization",
      otherRisks: "Other risks",
    },
    geoloc: {
      title: "Geolocation",
      map: "Map",
      startLabel: "Trip start",
      endLabel: "Trip end",
      deliveryLabel: "Delivery",
    },
    co2emission: "Transport emitted {{value}} kg of CO2",
    deliveryTimes: {
      title: "Delivery times",
      production: "Production",
      chartered: "Chartered",
      worksiteArrivalTime: "Arrival time at the worksite",
      worksiteUnloadingStartTime: "Start unloading time",
      worksiteUnloadingEndTime: "End unloading time",
      worksiteInstallationStartTime: "Start installation time",
      worksiteInstallationEndTime: "End installation time",
      worksitePumpingStartTime: "Start pumping time",
      worksitePumpingEndTime: "End pumping time",
      signature: "Signature",
      worksiteDepartureTime: "Departure",
    },
    documents: "Documents",
    digitalDisclaimer: "Release of liability",
    transportLetter: "Transport letter",
    weightTicket: "Weight ticket",
    signature: "Signature",
    pumping: {
      number: "Pumping number",
      noOne: "No pumping",
    },
    driverDetails: "Driver details",
    clientDetails: "Client details",
    filter: {
      orderReference: "Order reference",
      show: "Show advanced filters",
      hide: "Hide advanced filters",
      departure: "Departure delivery note",
      nodeparture: "Rendered delivery note",
      type: "Type",
      demat: "Digital",
      nodemat: "No",
      site: "Site",
      placeholderLength: "Enter at least {{length}} characters to search",
    },
    confirmDelivery: "Est-ce que la livraison a eu lieu ?",
    confirmDeliveryQuestion: "Do you confirm delivery?",
    cancelDeliveryQuestion: "Are you sure you want to cancel this delivery order?",
    services: "Liste of addons",
    search: {
      deliverynote: "Search delivery note",
      pumping: "Search pumping note",
    },
    columns: {
      showAll: "Show all columns",
      hideAll: "Hide all columns",
    },
    acceptCommand: {
      title: "Accept command",
      success: "The command has been accepted",
      question: "Are you sure you want to accept this command?",
    },
    refuseCommand: {
      title: "Refuse the command",
      success: "The order has been refused",
      question: "Are you sure you want to refuse this command?",
    },
    assign: {
      title: "Assign",
      success: "The staging voucher has been successfully assigned",
      error: "The current status of the pumping note does not allow the modification of the pump",
    },
    status: {
      title: "Status",
      new: "nNo info",
      chartered: "On the road",
      accepted: "On the road",
      delivering: "Delivering",
      validated: "Signed",
      active: "Waiting",
      canceled: "Cancelled",
      deleted: "Deleted",
      inactive: "Inactive",
      partially_refused: "Partially refused",
      refused: "Refused",
      pumping: "Pumping",
      unloading: "Unloading",
      supported: "On the road",
      installed: "Installed",
      ordered: "New",
      confirmed_order: "Validated",
      accepted_order: "Accepted",
      refused_order: "Refused",
      terminated: "Terminated",
      delivered: "Livré",
    },
    signDn: {
      title: "Sign delivery note",
      action: "Delivery note signature",
      signerName: "Signer name",
      goSign: "Go to signature",
    },
  },
};
