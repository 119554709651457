import React, { useEffect, useState } from "react";
import "./Login.scss";

import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

import axios from "axios";
import Config from "../Config";
import ToastComponent from "./ToastComponent";
import { ToastTypes } from "../models/ToastTypes";
import Toast from "../models/Toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../logo.svg";
import { Colors } from "../utils/Colors";
import { MadeBySynaxe } from "./Login";

function ResetPassword() {
  const { t } = useTranslation();
  const [firstToken, setFirstToken] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();

  useEffect(() => {
    let token = new URLSearchParams(window.location.search).get("token");
    if (token != null) {
      try {
        let decoded = atob(token);
        onSubmitToken(decoded);
      } catch (e) {
        setErrorMessage("errors.link.invalid");
      }
    } else {
      setErrorMessage("errors.link.invalid");
    }
  }, []);

  const onSubmitToken = (token: string) => {
    setFirstToken(token);
    localStorage.setItem("token", token);

    if (token != null) {
      let decoded = jwt_decode<any>(token?.toString());
      setEmail(decoded.sub);

      if (Date.now() >= decoded.exp * 1000) {
        setErrorMessage("Lien expiré");
      }
    } else {
      setErrorMessage("errors.link.invalid");
    }
  };

  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToast] = useState<Toast[]>([]);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required("errors.password.required")
      .test("len", "errors.password.minLength", (val) => val != null && val?.length >= 8),
    confirmPassword: yup
      .string()
      .required("errors.password.confirm.required")
      .oneOf([yup.ref("password"), null], "errors.password.confirmation"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  const updatePassword = (password: string) => {
    setPassword(password);
    setErrorMessage("");
  };

  const updateConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);
    setErrorMessage("");
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, "", type)]);
  };

  const onSubmitPassword = () => {
    setIsAddingLoading(true);

    let dataToSend = {
      password: window.btoa(password),
    };

    axios
      .post(Config.get_API_EXTRANET_URL() + "auth/changepassword", dataToSend, {
        headers: {
          Authorization: `Bearer ${firstToken}`,
        },
      })
      .then(() => {
        setIsAddingLoading(false);
        addToast("Mot de passe modifié", ToastTypes.success);
        setTimeout(() => history.push("/login"), 500);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 401) {
            if (error.response.data.code == "ERR4010002") {
              setErrorMessage("common.error");
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  return (
    <div className="login">
      <div className="leftImage"></div>
      <div className="right">
        <div className="content">
          <div style={{ textAlign: "center" }}>
            <Logo fill={Colors.primary}></Logo>
          </div>
          <div className="">
            <ToastComponent toastList={toasts} position={"top-right"} autoDelete={true} dismissTime={1500} key={1} />

            <h1>{t("login.reinit")} </h1>

            {errorMessage == "" ? (
              <form onSubmit={handleSubmit(onSubmitPassword)}>
                <h5>{t("login.email")}</h5>
                <input type="email" name="email" value={email} disabled />

                <h5>{t("login.password")}</h5>
                <input
                  type="password"
                  name="password"
                  ref={register}
                  value={password}
                  placeholder={t("login.password")}
                  onChange={(e) => updatePassword(e?.target.value.toString())}
                />
                <p>{t(errors.password?.message)}</p>

                <h5>{t("account.confirmPassword")}</h5>
                <input
                  type="password"
                  name="confirmPassword"
                  ref={register}
                  value={confirmPassword}
                  placeholder={t("account.confirmPassword")}
                  onChange={(e) => updateConfirmPassword(e?.target.value.toString())}
                />
                <p>{t(errors.confirmPassword?.message)}</p>

                <div>{errorMessage}</div>
                <button className="validateBtn" type="submit" disabled={isAddingLoading}>
                  <div>{isAddingLoading ? t("saving") : t("actions.edit")}</div>
                </button>

                <MadeBySynaxe />
              </form>
            ) : (
              <div>
                <div>{t("errors.link.invalid")}</div>
                <div style={{ marginTop: 30 }}>
                  <span className="link" onClick={() => history.push("/login")}>
                    {t("goBackHome")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
