import React, { useEffect, useState } from "react";
import "./Customers.scss";
import "../styles/table.scss";
import "../styles/popup.scss";
import axios from "axios";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import parsePhoneNumber from "libphonenumber-js";
import Roles from "../utils/Roles";
import Config from "../Config";
import Carrier from "../models/Carrier";
import Select, { createFilter } from "react-select";
import Driver from "../models/Driver";
import { useTranslation } from "react-i18next";

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

interface AddDriverProps {
  isUpdate?: boolean;
  from?: string;
  onsuccess(driver?: Driver): void;
  currentDriver?: Driver;
}

const AddDriver = React.memo((props: AddDriverProps) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    phone: yup.string().matches(phoneRegExp, "errors.phone.invalid").required("errors.phone.required"),
    lastname: yup.string().required("errors.firstname.required"),
  });

  const schemaUpdate = yup.object().shape({
    lastname: yup.string().required("errors.firstname.required"),
  });

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [isLoadingCarriers, setIsLoadingCarriers] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [driverPhone, setDriverPhone] = useState("");
  const [carrier, setCarrier] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    if (props.isUpdate && props.currentDriver != null) {
      let currentDriver = props.currentDriver;
      if (currentDriver.firstname != undefined) {
        setFirstname(currentDriver.firstname);
      }
      if (currentDriver.lastname != undefined) {
        setLastname(currentDriver.lastname);
      }
      if (currentDriver.mobile != undefined) {
        setDriverPhone(currentDriver.mobile);
      }
    }
  }, [props.isUpdate, props.currentDriver]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(props.isUpdate ? schemaUpdate : schema),

    reValidateMode: "onBlur",
  });

  const updatePhone = (phone: string) => {
    setDriverPhone(phone);
    setErrorMessage("");
  };

  const updateLastName = (lastname: string) => {
    setLastname(lastname);
    setErrorMessage("");
  };

  const updateFirstName = (firstname: string) => {
    setFirstname(firstname);
  };

  const updateCarrier = (newCarrier: string) => {
    setCarrier(newCarrier);
    setErrorMessage("");
  };

  const onSubmit = () => {
    if (props.isUpdate) {
      onSubmitUpdate();
      return;
    }
    setIsAddingLoading(true);

    const phoneNumber = parsePhoneNumber(driverPhone, "FR");

    if (props.from == "carrier" && carrier == "") {
      setErrorMessage("errors.carrier.required");
      return;
    }
    if (phoneNumber?.isValid()) {
      let token = localStorage.getItem("token");

      let dataToSend: CreateDriver = {
        mobile: phoneNumber?.number.toString(),
        lastname: lastname,
        firstname: firstname,
        carrier: props.from == "carrier" ? carrier : Roles?.getCurrentWorkspace()?.organization?.uuid,
      };

      if (firstname == "") {
        dataToSend = {
          mobile: phoneNumber?.number.toString(),
          lastname: lastname,
          firstname: null,
          carrier: props.from == "carrier" ? carrier : Roles?.getCurrentWorkspace()?.organization?.uuid,
        };
      }
      axios
        .post(Config.getUrl("drivers"), dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setIsAddingLoading(false);
          setFirstname("");
          setLastname("");
          setDriverPhone("");
          props.onsuccess();
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (error.response.status == 400) {
              if (error.response.data.code == "ERR4010003" || error.response.data.code == "ERR4000301") {
                setErrorMessage("errors.user.alreadyExist");
              } else {
                setErrorMessage("common.error");
              }
            }
          } else if (error.request) {
            setErrorMessage("common.error");
          } else {
            setErrorMessage("common.error");
          }
        });
    } else {
      setErrorMessage("errors.phone.invalid");
    }
  };

  const onSubmitUpdate = () => {
    setIsAddingLoading(true);

    const phoneNumber = parsePhoneNumber(driverPhone, "FR");

    if (phoneNumber?.isValid()) {
      let token = localStorage.getItem("token");

      let dataToSend: CreateDriver = {
        mobile: phoneNumber?.number.toString(),
        lastname: lastname,
        firstname: firstname,
        carrier: Roles?.getCurrentWorkspace()?.organization?.uuid,
      };

      axios
        .post(Config.getUrl("drivers/update"), dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setIsAddingLoading(false);
          setFirstname("");
          setLastname("");
          setDriverPhone("");
          let updatedDriver = props.currentDriver;
          if (updatedDriver != null) {
            updatedDriver.firstname = firstname;
            updatedDriver.mobile = driverPhone;
            updatedDriver.lastname = lastname;
          }

          props.onsuccess(updatedDriver);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (error.response.status == 400) {
              if (error.response.data.code == "ERR4010003" || error.response.data.code == "ERR4000301") {
                setErrorMessage("errors.user.alreadyExist");
              }
            }
          } else if (error.request) {
            setErrorMessage("common.error");
          } else {
            setErrorMessage("common.error");
          }
        });
    } else {
      setErrorMessage("phone.invalid");
    }
  };

  const filterConfig = {
    stringify: (option: any) => `${option.label}`,
  };

  const fetchCarriers = (fitlerText: string) => {
    setFilterText(filterText);
    if (fitlerText.length > 1) {
      setIsLoadingCarriers(true);
      const token = localStorage.getItem("token");

      let baseUrl = "carriers";
      let url = `${baseUrl}?page=${1}&limit=${200}&search=${fitlerText}`;
      if (Roles.isSite() || Roles.isRegion()) {
        url += "&affiliateCarriers=true";
      }
      axios
        .get(Config.getUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoadingCarriers(false);
          setCarriersOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoadingCarriers(false);
          setCarriersOptions([]);
        });
    } else {
      setCarriersOptions([]);
    }
  };

  const setCarriersOptions = (carriers: Carrier[]) => {
    let options: any[] = [];

    if (carriers != null) {
      carriers.forEach((s) => {
        let option = {
          value: s.uuid,
          label: s.name,
        };
        options.push(option);
      });

      setOptions(options);
    }
  };

  return (
    <div className="popup">
      <div className="title">{t("drivers.add.title")}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="label">{t("firstname")}</div>
        <input
          type="text"
          name="firstname"
          ref={register}
          value={firstname}
          placeholder={t("firstname")}
          onChange={(e) => updateFirstName(e?.target.value.toString())}
        />
        <p>{t(errors.firstname?.message)}</p>

        <div className="label">{t("name")} *</div>
        <input
          type="text"
          name="lastname"
          ref={register}
          value={lastname}
          placeholder={t("name")}
          onChange={(e) => updateLastName(e?.target.value.toString())}
        />
        <p>{t(errors.lastname?.message)}</p>
        <div className="label">{t("account.phoneNumber")} *</div>
        <input
          type="text"
          name="phone"
          ref={register}
          disabled={props.isUpdate}
          value={driverPhone}
          placeholder="0601020304"
          onChange={(e) => updatePhone(e?.target.value.toString())}
        />
        <p>{t(errors.phone?.message)}</p>

        {props.from == "carrier" ? (
          <div>
            <div className="label">{t("carrier")}</div>

            <Select
              className="input"
              isMulti={false}
              isLoading={isLoadingCarriers}
              options={options}
              loadingMessage={() => t("loading")}
              noOptionsMessage={() =>
                filterText.length < 2 ? t("deliverynote.filter.placeholderLength", { length: 2 }) : t("noOptions")
              }
              placeholder={t("carriers.choose")}
              onInputChange={(e) => fetchCarriers(e)}
              isSearchable={true}
              onChange={(input) => {
                input != null && input.value != null ? updateCarrier(input.value?.toString()) : null;
              }}
              filterOption={createFilter(filterConfig)}
            />
          </div>
        ) : null}

        <div>{t(errorMessage)}</div>
        <button type="submit" disabled={isAddingLoading}>
          {props.isUpdate ? (
            <div>{isAddingLoading ? t("saving") : t("actions.edit")}</div>
          ) : (
            <div>{isAddingLoading ? t("saving") : t("actions.add")}</div>
          )}
        </button>
      </form>
    </div>
  );
});

class CreateDriver {
  lastname: string | undefined;
  firstname: string | undefined | null;
  mobile: string | undefined;
  carrier: string | undefined;
}

export default AddDriver;
