export default {
  login: {
    title: "Login",
    accessTitle: "Accédez à votre espace ",
    email: "Adresse mail",
    password: "Mot de passe",
    updatePassword: "Modifier le mot de passe",
    submit: "Se connecter",
    "forgot-password": "Mot de passe oublié ?",
    "choose-workspace-explanation":
      "Vous êtes associés à plusieurs workspaces SX Delivery. Choisissez un workspace pour vous connecter",
    "choose-workspace.submit": "Accéder au workspace",
    selectWorkspace: "Selectionner workspace",
    goToDn: "Accédez au bon de livraison",
    "resetpassword.success": "Un email vous a été envoyé pour réinitialiser votre mot de passe",
    reinit: "Réinitialisation du mot de passe",
    error: {
      "email.required": "L'adresse email est obligatoire",
      "email.invalid": "L'adresse mail est invalide",
      password: "Le mot de passe est obligatoire",
      invalid: "Utilisateur ou mot de passe incorrect",
    },
    newLinkSend: "Nouveau lien de connexion envoyé !",
    newLinkSendDescription: "Consultez votre adresse email et cliquez sur le nouveau lien de connexion reçu.",
    sendNewLink: "Envoyer un nouveau lien",
    expiredLink: {
      title: "Lien de connextion expiré",
      description1: "Votre de lien de connextion a expiré !",
      description2: "Cliquer sur le bouton ci-dessous pour en recevoir un nouveau sur votre adresse email.",
    },
  },
};
