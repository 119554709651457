import React, { useEffect, useState } from "react";
import "./Customers.scss";
import "../styles/table.scss";
import "../styles/popup.scss";
import axios from "axios";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Config from "../Config";
import Vehicle, { VehicleTypes } from "../models/Vehicle";
import Select from "react-select";
import { useTranslation } from "react-i18next";

interface AddVehicleProps {
  isUpdate?: boolean;
  onsuccess(driver?: Vehicle): void;
  currentVehicle?: Vehicle;
}

const AddVehicle = React.memo((props: AddVehicleProps) => {
  const { t } = useTranslation();
  const registrationPlateRegex =
    /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$|^[0-9]{2,4}[a-zA-Z]{2,3}[0-9]{2}$|^[a-zA-Z]{1}[0-9]{3}$|^[0-9]{3}[a-zA-Z]{1}$/;
  const schema = yup.object().shape({
    registrationPlate: yup.string().required("errors.immat.required").min(4, "errors.immat.minLength"),
  });

  const schemaUpdate = yup.object().shape({
    registrationPlate: yup.string().required("errors.immat.required"),
    externalReference: yup.string().required("errors.vehicle.label.required"),
  });

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<any[]>([]);
  const [registrationPlate, setRegistrationPlate] = useState("");
  const [externalReference, setExternalReference] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [currentTypeOption, setCurrentTypeOption] = useState<any | null>(null);

  useEffect(() => {
    if (props.isUpdate && props.currentVehicle != undefined) {
      let currentVehicle = props.currentVehicle;
      if (currentVehicle.externalReference != undefined) {
        setExternalReference(currentVehicle.externalReference);
      }
      if (currentVehicle.registrationPlate != undefined) {
        setRegistrationPlate(currentVehicle.registrationPlate);
      }
      if (currentVehicle.type != undefined) {
        setVehicleType(currentVehicle.type);
      }
    }
    initVehicleTypeOptions(props.currentVehicle?.type);
  }, [props.isUpdate, props.currentVehicle]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(props.isUpdate ? schemaUpdate : schema),

    reValidateMode: "onBlur",
  });

  const initVehicleTypeOptions = (currentType: VehicleTypes | undefined) => {
    let options: any[] = [];

    options.push({ label: t("vehicles.types." + VehicleTypes.PUMI), value: VehicleTypes.PUMI });
    options.push({ label: t("vehicles.types." + VehicleTypes.PUMP_TRUCK), value: VehicleTypes.PUMP_TRUCK });

    // if (props.isUpdate) {
    options.push({ label: t("vehicles.types." + VehicleTypes.CONCRETE_MIXER), value: VehicleTypes.CONCRETE_MIXER });
    // }

    options.push({ label: t("vehicles.types." + VehicleTypes.DUMP_TRUCK), value: VehicleTypes.DUMP_TRUCK });

    setVehicleTypeOptions(options);

    if (currentType != undefined) {
      let currentOption = options.filter((o) => o.value == currentType)[0];
      setCurrentTypeOption(currentOption);
    }
  };

  const updateVehicleType = (input: any) => {
    setVehicleType(input.value.toString());
    setCurrentTypeOption(input);
  };

  const updateExternalReference = (externalReference: string) => {
    setExternalReference(externalReference);
    setErrorMessage("");
  };

  const updateRegistrationPlate = (registrationPlate: string) => {
    setRegistrationPlate(registrationPlate);
    setErrorMessage("");
  };

  const onSubmit = () => {
    const finalImmat = registrationPlate.replace(/[\W_]+/g, "");
    if (!registrationPlateRegex.test(finalImmat)) {
      setErrorMessage("errors.immat.invalid");
      return;
    }
    if (props.isUpdate) {
      onSubmitUpdate();
      return;
    }
    setIsAddingLoading(true);

    let token = localStorage.getItem("token");

    let dataToSend: CreateVehicle = {
      registrationPlate: finalImmat,

      type: vehicleType,
    };

    if (externalReference != "") {
      dataToSend.externalReference = externalReference;
    }

    axios
      .post(Config.getUrl("vehicles/add"), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsAddingLoading(false);
        setRegistrationPlate("");
        setExternalReference("");
        props.onsuccess();
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 400) {
            if (error.response.data.code == "ERR4000105") {
              setErrorMessage("errors.vehicle.alreadyExist");
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  const onSubmitUpdate = () => {
    setIsAddingLoading(true);
    const finalImmat = registrationPlate.replace(/[\W_]+/g, "");
    let token = localStorage.getItem("token");

    let dataToSend: CreateVehicle = {
      registrationPlate: registrationPlate,
      externalReference: externalReference,
      type: vehicleType,
    };

    axios
      .post(Config.getUrl("vehicles/update/" + props.currentVehicle?.uuid), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsAddingLoading(false);
        setExternalReference("");
        setRegistrationPlate("");
        let currentVehicle = props.currentVehicle;
        if (currentVehicle != null) {
          currentVehicle.registrationPlate = externalReference;
          currentVehicle.registrationPlate = finalImmat;
        }

        props.onsuccess(currentVehicle);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (error.response.status == 400) {
            if (error.response.data.code == "ERR4010003" || error.response.data.code == "ERR4000301") {
              setErrorMessage("errors.vehicle.alreadyExist");
            }
          }
        } else if (error.request) {
          setErrorMessage("common.error");
        } else {
          setErrorMessage("common.error");
        }
      });
  };

  return (
    <div className="popup">
      <div className="title">{props.isUpdate ? t("actions.edit") : t("vehicles.add.title")}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="label">{t("vehicles.immat")} *</div>
        <input
          type="text"
          name="registrationPlate"
          ref={register}
          value={registrationPlate}
          placeholder={t("vehicles.immat")}
          onChange={(e) => updateRegistrationPlate(e?.target.value.toString())}
        />
        <p>{t(errors.registrationPlate?.message)}</p>
        <div className="label">{t("vehicles.label")} </div>
        <input
          type="text"
          name="externalReference"
          ref={register}
          value={externalReference}
          placeholder={t("vehicles.label")}
          onChange={(e) => updateExternalReference(e?.target.value.toString())}
        />
        <p></p>
        <div className="label">{t("vehicles.type")} *</div>
        <Select
          className="input"
          isMulti={false}
          options={vehicleTypeOptions}
          value={currentTypeOption}
          placeholder={t("vehicles.chooseType")}
          isSearchable={true}
          onChange={(input) => {
            input != null && input.value != null ? updateVehicleType(input) : null;
          }}
        />

        <div>{t(errorMessage)}</div>
        <button type="submit" disabled={isAddingLoading}>
          {props.isUpdate ? (
            <div>{isAddingLoading ? t("saving") : t("actions.edit")}</div>
          ) : (
            <div>{isAddingLoading ? t("saving") : t("actions.add")}</div>
          )}
        </button>
      </form>
    </div>
  );
});

class CreateVehicle {
  registrationPlate: string | undefined;
  externalReference?: string | undefined | null;
  type?: string;
}

export default AddVehicle;
