/*eslint-disable*/
import React, { useEffect, useState } from "react";
import "./SignDeliveryNote.scss";
import "../styles/table.scss";
import "../styles/popup.scss";
import axios from "axios";
import Config from "../Config";
import Deliverynote from "../models/Deliverynote";
import SignatureCanvas from "react-signature-canvas";
import { ReactComponent as Close } from "../assets/svg/close-circle.svg";
import SignaturePadComponent from "./SignaturePadComponent";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

export interface SignDeliveryNoteProps {
  onSuccessSign(): void;
  onSignError(): void;
  onCancelSign(): void;
  deliveryNote: Deliverynote | undefined;
  deliveryNoteUuid: string;
}

const SignDeliveryNote = React.memo((props: SignDeliveryNoteProps) => {
  useEffect(() => {}, []);

  const { t } = useTranslation();

  const ref = React.useRef<SignatureCanvas>(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [signatureImage, setSignatureImage] = useState("");

  const [isPadConnected, setIsPadConnected] = useState(false);

  const [signername, setSignername] = useState("");
  const [isSignatureStep, setIsSignatureStep] = useState(false);

  const schema = yup.object().shape({
    signername: yup.string().required("errors.signerName.required"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  const onClickSign = (signatureData: any) => {
    const token = localStorage.getItem("token");

    let dataToSend = {
      termsValidated: true,
      refused: false,
      signature: {
        signedAt: new Date(),
        signerName: signername != "" ? signername : "-",
        file: {
          name: "signature.png",
          body: signatureData,
        },
      },
    };

    let url = "delivery-notes/" + props.deliveryNoteUuid + "/validate";
    axios
      .post(Config.getUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        props.onSuccessSign();
      })
      .catch(() => {
        props.onSignError();
        // setErrorMessage("common.error");
        // if (error.response) {
        // }
      });
  };

  const onSignaturePadOk = (signature: string) => {
    setSignatureImage(signature);
    onClickSign(signature);
  };

  const onSubmitSignerName = () => {
    setIsSignatureStep(true);
  };

  const onEndSignature = () => {
    if (ref?.current?.isEmpty()) {
      setErrorMessage("La signature est obligatoire");
    } else {
      if (ref.current != null) {
        let image = ref?.current.toDataURL();
        image = image.split(",")[1];
        setSignatureImage(image);
      }
    }
  };

  const resetSignature = () => {
    if (ref.current != null) {
      ref?.current.clear();
    }
  };

  const isSignatureValid = (): boolean => {
    if (ref.current != null) {
      // ref?.current.clear();
      return !ref?.current.isEmpty();
    } else {
      return false;
    }
  };

  return (
    <div className="popup">
      <div className="title">{t("deliverynote.signDn.title")}</div>

      <div className="">
        <div className="label" style={{ marginBottom: 10 }}>
          {t("deliverynote.signDn.action") +
            "    " +
            (props.deliveryNote?.folioReference != undefined
              ? " " + props.deliveryNote?.folioReference
              : " " + props.deliveryNote?.externalReference)}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
          className="panelRow"
        >
          <div>
            <div
              className="panelTitle"
              style={{ borderBottomWidth: props.deliveryNote?.products?.length ?? 0 > 0 ? 0 : 1 }}
            >
              <div className="panelTitleText">{t("deliverynote.product")}</div>
            </div>
          </div>
          <div>
            <div
              className="panelTitle"
              style={{ borderBottomWidth: props.deliveryNote?.products?.length ?? 0 > 0 ? 0 : 1 }}
            >
              <div className="panelTitleText">{t("deliverynote.quantity")}</div>
            </div>
          </div>
        </div>
        {props.deliveryNote?.products?.map((product, index) => {
          return (
            <div className="panelRow" style={{ display: "flex", flexDirection: "row" }} key={product.code}>
              <div>
                <div
                  className="panelTitle"
                  style={{ borderBottomWidth: index == (props.deliveryNote?.products?.length ?? 0) - 1 ? 1 : 0 }}
                >
                  <div className="panelTitleText">{product.designation}</div>
                </div>
              </div>
              <div>
                <div
                  className="panelTitle"
                  style={{ borderBottomWidth: index == (props.deliveryNote?.products?.length ?? 0) - 1 ? 1 : 0 }}
                >
                  <div className="panelTitleText">{product.quantity + " " + product.unit}</div>
                </div>
              </div>
            </div>
          );
        })}
        <div style={{ marginTop: 20 }}></div>

        <div className="label">{t("deliverynote.signDn.signerName") + " *"}</div>
        <input
          type="text"
          name="signername"
          value={signername}
          ref={register}
          placeholder={t("name")}
          onChange={(e) => setSignername(e?.target.value.toString())}
        />

        <p>{t(errors.signername?.message)}</p>
        <div style={{ marginTop: 20 }}></div>

        {isSignatureStep ? (
          <>
            <SignaturePadComponent
              dl={props.deliveryNote}
              onPadConnected={(isConnected: boolean) => setIsPadConnected(isConnected)}
              onSignatureOk={(signature: string) => onSignaturePadOk(signature)}
            />

            {!isPadConnected ? (
              <>
                <div className="resetButton" onClick={() => resetSignature()}>
                  <Close className={"icon"} width="30" height="30" fill={"#90a0b7"} />
                </div>

                <SignatureCanvas
                  ref={ref}
                  penColor="black"
                  canvasProps={{ width: 400, height: 300, className: "signCanvas" }}
                  onEnd={() => onEndSignature()}
                />
              </>
            ) : null}
          </>
        ) : (
          <button type="button" onClick={handleSubmit(onSubmitSignerName)}>
            <div>{t("deliverynote.signDn.goSign")}</div>
          </button>
        )}
      </div>

      <div>{errorMessage}</div>
      {isSignatureValid() ? (
        <button type="button" onClick={() => onClickSign(signatureImage)} disabled={false}>
          <div>{t("actions.validate")}</div>
        </button>
      ) : null}
    </div>
  );
});

export default SignDeliveryNote;
